import { InvoiceProps } from 'typings/invoices';
import { pick, omit } from 'lodash';

export const transformCreatePayload = (data: InvoiceProps, invoice_type: string) => {
  if (invoice_type === 'invoice') {
    return omit(data, ['adjustment_type', 'amount_cents']);
  }

  const payload = {
    status: 'pending',
    invoice_type,
    ...pick(data, [
      'billing_group_id',
      'invoice_type',
      'parent_invoices',
      'assignment_id',
      'voucher_id',
      'voucher_processing_status',
    ]),
    revenue_adjustment: {
      ...pick(data, [
        'amount_cents',
        'job_id',
        'dispute_id',
        'adjustment_type',
        'notes',
        'description',
        'agency_adjustment_route_to_payout',
      ]),
    },
  };
  if (invoice_type === 'debit_memo') {
    Object.assign(payload, {
      voucher_id: data.voucher_id,
      voucher_processing_status: data.voucher_processing_status,
    });
  }
  return payload;
};

export const transformEditPayload = (data: InvoiceProps, invoice_type: string) => {
  if (invoice_type === 'invoice') {
    return pick(data, [
      'assignment_id',
      'due_date',
      'failed_count',
      'voucher_id',
      'voucher_processing_status',
    ]);
  }

  return pick(data, ['parent_invoices', 'assignment_id']);
};
