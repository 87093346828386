import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput } from 'react-admin';
import InputUtils from 'utils/input';

const AgencyInput = (props) => (
  <ReferenceInput gql={['name']} {...props}>
    <AutocompleteInput
      optionText={InputUtils.optionText('id', 'name')}
      {...getChildrenProps(props)}
    />
  </ReferenceInput>
);

AgencyInput.defaultProps = {
  label: 'Agency',
  source: 'agency_id',
  reference: 'Agency',
  sort: { field: 'id', order: 'ASC' },
  perPage: 25,
  fullWidth: false,
};

export default AgencyInput;
