import { gql } from '@apollo/client';
import List from './List';
import Show from './Show';

export default {
  name: 'assignmentProfiles',
  inputShow: gql`
    query assignmentProfiles($input: AssignmentProfileQueryInput) {
      assignmentProfiles(input: $input) {
        id
      }
    }
  `,
  list: List,
  show: Show,
  options: {
    group: 'Billing',
    label: 'Assignment Profile',
  },
};
