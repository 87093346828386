import EnumSelectInput from 'components/Enum/EnumSelectInput';
import React from 'react';
import { pick } from 'ramda';
import {
  Edit,
  SimpleForm,
  TextField,
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  required,
  Labeled,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { ProfessionalQualificationStatus } from 'typings/enums';
import ProfessionalQualificationAttributesInput from 'resources/professional_qualification_attributes/ProfessionalQualificationAttributesInput';
import { ProfessionalAside } from 'resources/professionals/ProfessionalAside';

const redirect = (_resource, _id, data) => `professional/${data.professional_id}/show/credentials`;

const transformPayload = (payload) => {
  const { professional_qualification_attributes, ...rest } = payload;
  const filteredArray = professional_qualification_attributes
    ?.filter((attr) => {
      if (attr._destroy) {
        return true;
      }
      return attr.value !== null && attr.value !== undefined;
    })
    ?.map((attr) =>
      pick(['id', 'qualification_attribute_id', 'value', 'extension', '_destroy'], attr),
    );
  return {
    ...rest,
    professional_qualification_attributes_attributes: filteredArray,
  };
};

type Props = {
  label: string;
  source: string;
};

const CheckrTextField = ({ label, source }: Props) => {
  const record = useRecordContext();
  const isBackgroundCheck = record?.qualification?.qualification_type === 'background_check';

  return isBackgroundCheck ? (
    <Labeled>
      <TextField label={label} source={source} />
    </Labeled>
  ) : null;
};

const CheckrTextInput = ({ label, source }: Props) => {
  const record = useRecordContext();
  const isBackgroundCheck = record?.qualification?.qualification_type === 'background_check';

  return isBackgroundCheck ? (
    <Labeled fullWidth>
      <TextInput label={label} source={source} />
    </Labeled>
  ) : null;
};

const ProfessionalQualificationEdit = () => (
  <Edit
    transform={transformPayload}
    aside={<ProfessionalAside />}
    mutationMode="pessimistic"
    redirect={redirect}
  >
    <SimpleForm>
      <Labeled>
        <TextField label="ID" source="id" />
      </Labeled>
      <Labeled>
        <TextField source="qualification_id" />
      </Labeled>
      <Labeled>
        <TextField label="Qualification Name" source="qualification.long_name" />
      </Labeled>
      <Labeled>
        <TextField label="Professional Name" source="professional.account.name" />
      </Labeled>
      <Labeled>
        <TextField label="Professional" source="professional_id" />
      </Labeled>
      <EnumSelectInput source="status" filterOut={['pending']} fullWidth />

      <CheckrTextField label="Checkr Report ID" source="metadata.report_id" />
      <CheckrTextField label="Checkr Report Status" source="metadata.report_status" />
      <CheckrTextField label="Checkr Candidate ID" source="metadata.candidate_id" />
      <CheckrTextInput label="Notes" source="metadata.notes" />

      <FormDataConsumer>
        {({ formData }) =>
          formData.status === ProfessionalQualificationStatus.rejected && (
            <>
              <ReferenceInput
                perPage={50}
                source="credential_rejected_reason_id"
                label="Reject Reason"
                reference="CredentialRejectedReason"
                validate={required()}
                sort={{ field: 'rank', order: 'ASC' }}
              >
                <AutocompleteInput optionText="label" fullWidth />
              </ReferenceInput>
              <TextInput source="additional_rejection_context" fullWidth />
            </>
          )
        }
      </FormDataConsumer>
      <ProfessionalQualificationAttributesInput source="professional_qualification_attributes" />
    </SimpleForm>
  </Edit>
);

export default ProfessionalQualificationEdit;
