import LinkField from 'components/LinkField';
import React from 'react';
import { ArrayField, SingleFieldList } from 'react-admin';

const ParentInvoicesField = (_props: any) => {
  return (
    <ArrayField source="parent_invoice_links">
      <SingleFieldList
        linkType="show"
        sx={{ margin: 0, '.RaSingleFieldList-link': { marginRight: '8px' } }}
      >
        <LinkField source="parent_invoice_id" />
      </SingleFieldList>
    </ArrayField>
  );
};

export default ParentInvoicesField;
