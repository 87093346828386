import React from 'react';
import {
  TextInput,
  required,
  BooleanInput,
  NumberInput,
  SelectInput,
  SelectArrayInput,
} from 'react-admin';
import ImageInput from 'components/ImageInput';
import LocationInput from 'components/LocationInput';
import StateInput from 'components/StateInput';
import JsonTextInput from 'components/JsonTextInput';
import DateInput from 'components/DateInput';
import { QualificationAttributeFieldTypes } from 'typings/enums';

const qualificationAttributeDataToChoices = (qualificationAttributeData: any) => {
  const values = qualificationAttributeData?.values;
  if (values) {
    const choices = values.map((value: string, index: number) => {
      const name = qualificationAttributeData.labels[index] ?? value;
      return { id: value, name };
    });
    return choices;
  } else {
    return [];
  }
};

const formatBoolean = (v: boolean | string) => {
  if (typeof v === 'boolean') {
    return v;
  }
  return v === 'true';
};

const ProfessionalQualificationAttributesInputValues = ({
  proQualificationAttribute,
  qualificationAttribute,
  index,
  source,
}) => {
  const valueInputProps = {
    source: `${source}.${index}.value`,
    defaultValue: proQualificationAttribute.value,
    validate: [qualificationAttribute.required && required()].filter(Boolean),
  };

  switch (qualificationAttribute.field_type) {
    case QualificationAttributeFieldTypes.json:
      return <JsonTextInput label="JSON" {...valueInputProps} />;

    case QualificationAttributeFieldTypes.image_back:
    case QualificationAttributeFieldTypes.image_front:
      return (
        <ImageInput
          label="Image"
          {...valueInputProps}
          extensionSource={`${source}.${index}.extension`}
          destroySource={`${source}.${index}._destroy`}
        />
      );

    case QualificationAttributeFieldTypes.pdf:
      return (
        <ImageInput
          label="PDF"
          accept=".pdf"
          {...valueInputProps}
          extensionSource={`${source}.${index}.extension`}
          destroySource={`${source}.${index}._destroy`}
        />
      );

    case QualificationAttributeFieldTypes.csv:
      return (
        <ImageInput
          label="CSV, XLS"
          accept={['.csv', '.xlsx', '.xls']}
          {...valueInputProps}
          extensionSource={`${source}.${index}.extension`}
          destroySource={`${source}.${index}._destroy`}
        />
      );

    case QualificationAttributeFieldTypes.date:
    case QualificationAttributeFieldTypes.expiration_date:
    case QualificationAttributeFieldTypes.valid_date:
      return <DateInput label="Date" {...valueInputProps} />;

    case QualificationAttributeFieldTypes.location:
      return <LocationInput label="Location" {...valueInputProps} />;

    case QualificationAttributeFieldTypes.state:
      return <StateInput label="State" {...valueInputProps} />;

    case QualificationAttributeFieldTypes.string:
    case QualificationAttributeFieldTypes.external_id:
    case QualificationAttributeFieldTypes.license_number:
    case QualificationAttributeFieldTypes.signed_document:
      return <TextInput label="Value" {...valueInputProps} />;

    case QualificationAttributeFieldTypes.boolean:
      return <BooleanInput label="Boolean" {...valueInputProps} format={formatBoolean} />;

    case QualificationAttributeFieldTypes.integer:
      return <NumberInput label="Number" {...valueInputProps} />;

    case QualificationAttributeFieldTypes.enumerable:
    case QualificationAttributeFieldTypes.enumerable_multi:
      const choices = qualificationAttributeDataToChoices(qualificationAttribute.data);
      if (choices.length === 0) {
        return <div>No Data</div>;
      }

      if (qualificationAttribute.field_type === QualificationAttributeFieldTypes.enumerable) {
        return <SelectInput label="Value" choices={choices} {...valueInputProps} />;
      }

      return <SelectArrayInput label="Select" choices={choices} {...valueInputProps} />;
  }

  return <div>Unknown Qualification Attribute Field Type: {qualificationAttribute.field_type}</div>;
};

export default ProfessionalQualificationAttributesInputValues;
