import React from 'react';
import { gql } from '@apollo/client';
import { Show } from 'react-admin';
import RateSettingsShow from '../RateSettingsShow';

const MarketRateSettingShow = () => (
  <Show>
    <RateSettingsShow />
  </Show>
);

MarketRateSettingShow.query = gql`
  query marketRateSetting($id: Int!) {
    marketRateSetting(id: $id, include_parents: true) {
      id
      active
      created_at
      market_id
      settings
      updated_at
    }
  }
`;

export default MarketRateSettingShow;
