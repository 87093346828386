import React, { useEffect, useState } from 'react';
import {
  TextField,
  Datagrid,
  SimpleShowLayout,
  Pagination,
  ReferenceManyField,
  FunctionField,
  useRecordContext,
  useGetManyReference,
  TextInput,
  SelectArrayInput,
  EditButton,
  ReferenceArrayInput,
  required,
} from 'react-admin';
import LinkField from 'components/LinkField';
import { getAllChildrenOfLocationGroup } from './utils';
import QuickCreateButton from 'components/QuickCreateButton';
import useCheckAccess from 'hooks/useCheckAccess';

const ChildGroupsField = ({ label }) => {
  const record = useRecordContext();
  const [locationGroups, setLocationGroups] = useState([]);
  const { data, isLoading } = useGetManyReference('LocationGroup', {
    target: 'company_id',
    id: record.company_id,
  });

  useEffect(() => {
    if (data) {
      setLocationGroups(data);
    }
  }, [data]);

  if (isLoading || !data) {
    return null;
  }

  return (
    <FunctionField
      label={label}
      render={(r) => getAllChildrenOfLocationGroup(r, locationGroups).allChildGroups.length}
    />
  );
};
ChildGroupsField.defaultProps = { label: 'Child Groups' };

const LocationsField = ({ label }) => {
  const record = useRecordContext();
  const [locationGroups, setLocationGroups] = useState([]);
  const { data, isLoading } = useGetManyReference('LocationGroup', {
    target: 'company_id',
    id: record.company_id,
  });

  useEffect(() => {
    if (data) {
      setLocationGroups(data);
    }
  }, [data]);

  if (isLoading || !data) {
    return null;
  }

  return (
    <FunctionField
      label={label}
      render={(r) => getAllChildrenOfLocationGroup(r, locationGroups).allLocations.length}
    />
  );
};
LocationsField.defaultProps = { label: 'Locations' };

const LocationGroupsTab = () => {
  const checkAccess = useCheckAccess();
  const record = useRecordContext();
  const company_id = record.id;

  return (
    <SimpleShowLayout>
      <QuickCreateButton resource="GroupNode" url={`v3/admin/companies/${record.id}/group_nodes`}>
        {checkAccess('create', 'GroupNode', 'name') && (
          <TextInput label="Group Name" source="name" validate={[required()]} />
        )}
        {checkAccess('create', 'GroupNode', 'description') && (
          <TextInput label="Description" source="description" />
        )}
        {checkAccess('create', 'GroupNode', 'location_ids') && (
          <ReferenceArrayInput source="location_ids" reference="Location" filter={{ company_id }}>
            <SelectArrayInput
              label="Locations"
              optionText={(o) => o.name}
              options={{
                MenuProps: {
                  style: { maxHeight: 400 },
                },
              }}
            />
          </ReferenceArrayInput>
        )}
        {checkAccess('create', 'GroupNode', 'child_ids') && (
          <ReferenceArrayInput source="child_ids" reference="LocationGroup" filter={{ company_id }}>
            <SelectArrayInput
              label="Child Groups"
              optionText={(o) => o.name}
              options={{
                MenuProps: {
                  style: { maxHeight: 400 },
                },
              }}
            />
          </ReferenceArrayInput>
        )}
      </QuickCreateButton>
      <ReferenceManyField
        pagination={<Pagination />}
        label=""
        reference="LocationGroup"
        target="company_id"
      >
        <Datagrid bulkActionButtons={false}>
          <LinkField label="ID" source="id" />
          <TextField label="Name" source="name" />
          <FunctionField label="Accounts" render={(r) => r.account_ids?.length} />
          <ChildGroupsField />
          <LocationsField />
          <FunctionField render={() => <EditButton />} />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(LocationGroupsTab);
