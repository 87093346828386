import type { IProfessional, IProfessionalQualification } from '@medely/types';

export class ProfessionalDisplayHelper {
  constructor(private professional: IProfessional) {}

  get type() {
    if (this.professional.account?.agency_id) {
      return 'Agency';
    }

    if (this.professional.account?.company_id) {
      return 'IRP';
    }

    return 'Marketplace';
  }

  get backgroundCheck() {
    const sortedBgChecks = [...(this.professional.background_check_pqs ?? [])].sort((a, b) =>
      new Date(b.created_at) > new Date(a.created_at) ? 1 : -1,
    );

    let mostRecentBgCheck: IProfessionalQualification | null = null;

    for (let i = 0; i < sortedBgChecks.length; i++) {
      const bgCheck = sortedBgChecks[i];

      if (bgCheck.status === 'approved') {
        return bgCheck;
      }

      // @ts-expect-error TODO: add metadata field to typings
      if (bgCheck.metadata?.candidate_id && !mostRecentBgCheck) {
        mostRecentBgCheck = bgCheck;
      }
    }

    return mostRecentBgCheck;
  }
}
