import React from 'react';
import {
  TextField,
  Datagrid,
  FunctionField,
  Pagination,
  SimpleShowLayout,
  ListBase,
  ListToolbar,
  BooleanInput,
  useResourceContext,
  useRecordContext,
  ResourceContextProvider,
} from 'react-admin';
import { displayDate } from 'utils/helpers';
import { humanize } from 'inflection';
import LinkField from 'components/LinkField';
import MoneyField from 'components/MoneyField';
import DateTimeField from 'components/DateTimeField';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const Filters = [
  <BooleanInput alwaysOn label="Hide 0 value Invoices" source="search.hide_zero_value_invoices" />,
  <EnumSelectInput alwaysOn source="status" />,
];

export const InvoicesDatagrid = ({ resource }) => (
  <Datagrid bulkActionButtons={false}>
    <LinkField label="ID" source="id" />
    {resource !== 'BillingGroup' && (
      <LinkField label="Billing Group ID" source="billing_group_id" typename="BillingGroup" />
    )}
    {resource !== 'BillingGroup' && (
      <TextField label="Billing Group Name" source="billing_group.name" />
    )}
    {resource !== 'Statement' && <LinkField label="Statement ID" source="statement_id" />}
    {resource !== 'Assignment' && <LinkField label="Assignment ID" source="assignment_id" />}
    <LinkField label="Agency ID" source="agency_id" />
    <MoneyField label="Original Amount" source="total_cents" />
    <MoneyField label="Current Amount" source="amount_due_cents" />
    <FunctionField label="Status" render={({ status }) => humanize(status)} />
    <FunctionField label="Type" render={({ invoice_type }) => humanize(invoice_type)} />
    <FunctionField
      label="Due Date"
      render={({ invoice_type, due_date }) =>
        invoice_type === 'credit_memo' ? '' : displayDate(due_date)
      }
    />
    <DateTimeField
      label="Paid Date"
      format="MM/dd/yyyy"
      source="paid_date"
      timeZone="America/Los_Angeles"
    />
    <FunctionField
      label="Days Late"
      render={({ invoice_type, days_late }) => (invoice_type === 'credit_memo' ? '' : days_late)}
    />
    <TextField label="Voucher ID" source="voucher_id" />
  </Datagrid>
);

const InvoicesTab = ({ target, showFilters, sort = { field: 'id', order: 'DESC' } }) => {
  const resource = useResourceContext();
  const record = useRecordContext();

  return (
    <SimpleShowLayout>
      <ResourceContextProvider value="Invoice">
        <ListBase
          resource="Invoice"
          sort={sort}
          filter={{ [target]: record.id }} // replacement for ReferenceManyField target prop
        >
          {showFilters && <ListToolbar filters={Filters} />}
          <InvoicesDatagrid resource={resource} />
          <Pagination />
        </ListBase>
      </ResourceContextProvider>
    </SimpleShowLayout>
  );
};

export default React.memo(InvoicesTab);
