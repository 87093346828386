import React from 'react';
import { Button, Confirm, useRecordContext, useRefresh } from 'react-admin';
import useDialog from 'hooks/useDialog';
import useApi from 'hooks/useApi';

const ApprovalButton = () => {
  const api = useApi();
  const { open, openDialog, closeDialog } = useDialog();
  const record = useRecordContext();
  const refresh = useRefresh();

  const onConfirm = async () => {
    await api({
      url: `/v3/admin/jobs/${record.id}/approve_irregularities`,
      method: 'POST',
    });
    refresh();
    closeDialog();
  };

  return (
    <>
      <Button onClick={openDialog} label="Approve Exceptions" />
      <Confirm
        isOpen={open}
        onClose={closeDialog}
        onConfirm={onConfirm}
        title="Approve Exceptions"
        content={
          'Approving this job will update the shift to clocked out and disable the ability for the facility to dispute it. ' +
          'Please confirm the shift details are correct or cancel and let the facility submit a dispute with the desired changes.'
        }
      />
    </>
  );
};

export default ApprovalButton;
