import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField, BooleanField, NumberField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import LinkField from 'components/LinkField';
import { LegacyRichTextField } from 'components/RichTextField';
import EnumField from 'components/Enum/EnumField';

const Work_historiesShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField label="Id" source="id" />
      <LinkField label="Professional" source="professional_id" />
      <TextField label="Organization Name" source="organization_name" />
      <TextField label="Job Title" source="position_title" />
      <TextField label="Position" source="position_id" />
      <BooleanField label="Clinical" source="clinical" />
      <BooleanField label="Floating" source="floating" />
      <TextField label="Floating Details" source="floating_details" />
      <NumberField label="Hours Per Week" source="hours_per_week" />
      <TextField label="Travel Agency" source="travel_agency" />
      <TextField label="Location" source="location" />
      <LinkField label="Location Type" source="location_type.label" />
      <TextField label="Location Trauma Level" source="location_trauma_level" />
      <NumberField label="Location Number Of Beds" source="location_number_of_beds" />
      <BooleanField label="Location Is Teaching" source="location_is_teaching" />
      <LegacyRichTextField source="description" />
      <TextField label="Start Date" source="start_date" />
      <TextField label="End Date" source="end_date" />
      <BooleanField label="Currently Work Here" source="currently_work_here" />
      <BooleanField label="Travel" source="travel" />
      <TextField label="Unit" source="unit" />
      <LinkField label="State" source="state.name" />
      <EnumField label="Employment Type" source="employment_type" />
      <BooleanField label="Direct Care" source="bedside_care" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

Work_historiesShow.query = gql`
  query GET_ONE($id: Int!) {
    workHistory(id: $id) {
      bedside_care
      clinical
      created_at
      currently_work_here
      description
      employment_type
      end_date
      floating
      floating_details
      hours_per_week
      id
      professional_id
      location
      location_is_teaching
      location_number_of_beds
      location_trauma_level
      location_type {
        id
        label
      }
      organization_name
      position {
        id
      }
      position_title
      start_date
      state {
        id
        name
      }
      travel
      travel_agency
      updated_at
      unit
    }
  }
`;

export default Work_historiesShow;
