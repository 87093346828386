import React from 'react';
import { TextField, NumberField, SimpleShowLayout } from 'react-admin';
import MoneyField from 'components/MoneyField';
import EnumField from 'components/Enum/EnumField';
import LinkField from 'components/LinkField';
import DateTimeField from 'components/DateTimeField';
import useCheckAccess from 'hooks/useCheckAccess';
import TimestampFields from 'components/TimestampFields';

const SummaryTab = () => {
  const checkAccess = useCheckAccess();

  return (
    <SimpleShowLayout>
      {checkAccess('view', 'FinancialTransaction', 'id') && <TextField label="ID" source="id" />}
      {checkAccess('view', 'FinancialTransaction', 'billing_group_id') && (
        <LinkField label="Billing Group ID" source="billing_group_id" typename="BillingGroup" />
      )}
      {checkAccess('view', 'FinancialTransaction', 'amount_cents') && (
        <MoneyField label="Amount" source="amount_cents" />
      )}
      {checkAccess('view', 'FinancialTransaction', 'amount_left_cents') && (
        <MoneyField label="Amount Left" source="amount_left_cents" />
      )}
      {checkAccess('view', 'FinancialTransaction', 'kind') && (
        <EnumField label="Type" source="kind" />
      )}
      {checkAccess('view', 'FinancialTransaction', 'transaction_id') && (
        <NumberField label="Transaction ID" source="transaction_id" />
      )}
      {checkAccess('view', 'FinancialTransaction', 'original_financial_transaction_id') && (
        <LinkField
          label="Original Financial Transaction ID"
          source="original_financial_transaction_id"
          to={(r) => {
            return `/FinancialTransaction/${r.original_financial_transaction_id}/show`;
          }}
        />
      )}
      {checkAccess('view', 'FinancialTransaction', 'data_source') && (
        <TextField label="Data Source" source="data_source" />
      )}
      {checkAccess('view', 'FinancialTransaction', 'yaypay_external_id') && (
        <TextField label="Yaypay External ID" source="yaypay_external_id" />
      )}
      {checkAccess('view', 'FinancialTransaction', 'revenue_date') && (
        <DateTimeField label="Revenue Date" source="revenue_date" timeZone="America/Los_Angeles" />
      )}
      {checkAccess('view', 'FinancialTransaction', 'facility_notes') && (
        <TextField label="Facility Notes" source="facility_notes" />
      )}
      {checkAccess('view', 'FinancialTransaction', 'balance_sheet_id') && (
        <LinkField label="Balance Sheet ID" source="balance_sheet.id" />
      )}
      {checkAccess('view', 'FinancialTransaction', 'created_at') && <TimestampFields />}
    </SimpleShowLayout>
  );
};
export default React.memo(SummaryTab);
