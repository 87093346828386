import React from 'react';
import { Edit } from 'react-admin';
import PositionRateSettingsForm from '../PositionRateSettingsForm';
import { getTransform } from '../rateSettingsTransform';

const AgencyLocationPositionRateSettingEdit = () => {
  return (
    <Edit
      mutationMode="pessimistic"
      transform={getTransform(['agency_id', 'location_id'], 'agency_location_position_rate_setting')}
    >
      <PositionRateSettingsForm settingsField="agency_location_position_rate_setting" />
    </Edit>
  );
};

export default AgencyLocationPositionRateSettingEdit;
