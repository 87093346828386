import React, { memo } from 'react';
import {
  TextField,
  FunctionField,
  DateField,
  NumberField,
  SimpleShowLayout,
  BooleanField,
} from 'react-admin';
import { Box } from '@mui/material';
import TimestampFields from 'components/TimestampFields';
import LinkField from 'components/LinkField';
import EnumField from 'components/Enum/EnumField';
import Heading from 'components/Heading';
import { Link } from 'react-router-dom';
import { ENUMS } from 'components/Enum/enums';
import DateTimeField from 'components/DateTimeField';
import { capitalize } from 'inflection';
import TravelProfileRemainingStepsField from 'components/TravelProfileRemainingStepsField';

const ApplicationTab = () => (
  <SimpleShowLayout>
    <Heading>Professional Application</Heading>
    <TextField label="ID" source="id" />
    <LinkField label="Professional ID" source="professional_id" typename="Professional" />
    <TextField label="Name" source="professional.account.name" data-testid="application-tab-name" />
    <FunctionField
      label="Distance from Assignment (Miles)"
      render={({ coordinate_distance, assignment }) => {
        if (!coordinate_distance || !assignment) {
          return;
        }

        return (
          <>
            <NumberField source="coordinate_distance" options={{ maximumFractionDigits: 1 }} />{' '}
            {assignment.local_only && !assignment.is_w2 ? '(Local)' : '(Stripe)'}
          </>
        );
      }}
    />

    <NumberField label="Travel Profile %" source="travel_profile_strength" />
    <TravelProfileRemainingStepsField />
    <DateField label="Applied On" source="created_at" />
    <FunctionField
      label="Status"
      render={(v) => (
        <Box>
          <EnumField label="Status" record={v} source="status" />
          {ENUMS.AssignmentRequest.status.rejected === v.status && (
            <Box>Reason: {v.rejection_reason}</Box>
          )}
        </Box>
      )}
    />
    <FunctionField
      label="Unapplied At"
      render={(r) =>
        (r.status === 'unapplied' || r.status === 'offer_declined') && (
          <DateTimeField record={r} source="unapplied_at" />
        )
      }
    />
    <FunctionField
      label="Unapply Reason"
      render={(r) =>
        (r.status === 'unapplied' || r.status === 'offer_declined') && r.unapply_reason
      }
    />
    <FunctionField
      label="Referrer"
      render={(r) =>
        r.referrer_professional && (
          <Link to={`/Professional/${r.referrer_professional.id}/show`}>
            {r.referrer_professional.account.name}
          </Link>
        )
      }
    />
    <FunctionField
      label="Recruiter"
      render={(r) =>
        r.professional?.recruiter && (
          <Link to={`/Administrator/${r.professional.recruiter.id}/show`}>
            {r.professional.recruiter.account.name}
          </Link>
        )
      }
    />
    <TextField label="Interview Availability" source="interview_availability" />
    <TextField label="Pro Previously Worked Here" source="professional_prev_worked_here" />
    <DateField label="Preferred Start Date" source="preferred_start_date" />
    <BooleanField label="ASAP" source="asap" />
    <TextField label="Time Off Request" source="time_off_request" />
    <TextField label="Exceptions" source="exceptions" />
    <TextField label="Bonus Type" source="bonus_type" />
    <TextField label="Note" source="note" />
    <TextField label="Offer Source" source="offer_source" />
    <TimestampFields />

    <Heading>Assignment</Heading>
    <LinkField label="Assignment ID" source="assignment.id" />
    <DateTimeField
      label="Assignment Start Date"
      source="assignment.starts_date"
      format="MM/dd/yyyy"
    />
    <FunctionField label="Assignment Status" render={(v) => capitalize(v.assignment.status)} />
    <BooleanField label="VMS Hold" source="assignment.on_hold" />
    <BooleanField label="Applications Paused" source="assignment.paused" />

    <TextField label="Notes" source="assignment.notes" />

    <Heading>Professional Profile (Recruiter)</Heading>
    <TextField label="VMS Profile Status" source="vms_profile_status" />
    <DateTimeField label="VMS Profile Submitted At" source="vms_profile_submitted_at" />
    <TextField label="VMS Profile Rejected by CAM Reason" source="cam_reject_reason" />
    <TextField label="Contract Type" source="contract_type" />
    <TextField label="Recruiter Notes" source="recruiter_notes" />

    <Heading>VMS Application status (CAM)</Heading>
    <TextField label="Rejected by VMS Reason" source="vms_reject_reason" />
    <TextField label="VMS Application Status" source="vms_application_status" />
    <FunctionField
      label="Submitting CAM"
      render={(r) =>
        r.submitting_cam?.name && (
          <Link to={`/Administrator/${r.submitting_cam.administrator.id}/show`}>
            {r.submitting_cam.name}
          </Link>
        )
      }
    />
    <DateTimeField label="VMS Application Submitted At" source="vms_application_submitted_at" />
    <TextField label="CAM Notes" source="cam_notes" />
  </SimpleShowLayout>
);

export default memo(ApplicationTab);
