import React from 'react';
import {
  Datagrid,
  useRecordContext,
  ResourceContextProvider,
  List,
  FunctionField,
  Tab,
} from 'react-admin';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import { humanize } from 'inflection';

const ChildMemosTab = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const id =
    record.child_invoice_links?.map((r: { child_invoice_id: number }) => r.child_invoice_id) ?? [];

  return (
    <Tab label="Child memos" path="child-memos">
      <ResourceContextProvider value="Invoice">
        <List resource="Invoice" filter={{ id }} empty={false} actions={null}>
          <Datagrid bulkActionButtons={false}>
            <LinkField label="ID" source="id" />
            <FunctionField label="Type" render={({ invoice_type }) => humanize(invoice_type)} />
            <MoneyField label="Amount" source="total_cents" />
            <FunctionField label="Status" render={({ status }) => humanize(status)} />
          </Datagrid>
        </List>
      </ResourceContextProvider>
    </Tab>
  );
};

export default React.memo(ChildMemosTab);
