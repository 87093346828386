import { omit, pick } from 'ramda';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { BULK_CREATE_LOCATION_POSITION_IDS } from 'constants/rateSettings';
import moment from 'moment-timezone';

export const getTransform = (rateLevels: string[], settingsName: string) => (data: any) => {
  const values = {
    ...data,
    settings: {
      ...data[settingsName],
    },
  };
  const settings = values.settings;
  const keysToOmit = Object.keys(settings).filter((key) => {
    if (typeof settings[key] === 'string') {
      return isEmpty(settings[key]);
    }
    return isNil(settings[key]);
  });
  keysToOmit.push('pro_rate_cents', '__typename');
  values.settings = omit(keysToOmit, settings);

  ['w2', 'ten99_per_diem', 'ten99_assignment'].forEach((subSettingKey) => {
    const subSetting = values.settings[subSettingKey];
    if (subSetting) {
      const subKeysToOmit = Object.keys(subSetting).filter((key) => subSetting[key] === null);
      values.settings[subSettingKey] = omit(subKeysToOmit, subSetting);
    }
  });

  return pick(
    [...rateLevels, 'position_id', 'specialty_id', 'active', 'settings', 'last_rate_review'],
    values,
  );
};

export const transformBulkLocationPosition = (data) => ({
  create_multiple: true,
  id: data.location_rate_settings[0].id,
  location_position_rate_settings: BULK_CREATE_LOCATION_POSITION_IDS.flatMap((position_id) =>
    data[position_id].enabled
      ? {
          location_id: data.id,
          position_id,
          expires_at: moment
            .tz(data[position_id].expires_at, data.timezone_lookup)
            .endOf('day')
            .format(),
          settings: {
            charge_rate_cents: data[position_id].charge_rate_cents,
          },
        }
      : [],
  ),
});
