import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import IdField from 'components/IdField';
import List from 'components/List';

const ProfessionalSpecialtiesList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField label="Professional ID" source="professional_id" />
      <TextField label="Specialty Name" source="specialty.name" />
    </Datagrid>
  </List>
);

ProfessionalSpecialtiesList.query = gql`
  query GET_LIST($input: ProfessionalSpecialtyQueryInput) {
    _professionalSpecialtiesMeta(input: $input) {
      count
    }
    professionalSpecialties(input: $input) {
      id
      specialty_id
      professional_id
      specialty {
        id
        name
        label

        positions {
          id
          display_name
        }
      }
    }
  }
`;

export default ProfessionalSpecialtiesList;
