import { RaThemeOptions, defaultTheme } from 'react-admin';
import { lighten, darken } from '@mui/material/styles';
import config from 'config';
import { deepmerge } from '@mui/utils';

const MAIN_COLOR = config.color;

type ThemeType = 'dark' | 'light';

const adjustColor = {
  dark: lighten,
  light: darken,
};

const themeOptions = (type: ThemeType): RaThemeOptions => {
  const main = adjustColor[type](MAIN_COLOR, 0.2);
  return deepmerge(defaultTheme, {
    palette: {
      mode: type,
      primary: {
        main,
      },
      secondary: {
        main,
      },
      background: {
        default: type === 'dark' ? '#121212' : '#ffffff',
        paper: type === 'dark' ? '#121212' : '#ffffff',
      },
    },
    components: {
      RaFilterForm: {
        styleOverrides: {
          root: {
            alignItems: 'center',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: '#0000000a',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '&.MuiInputBase-formControl': {
              minWidth: '228px',
            },
          },
        },
      },
      RaNullableBooleanInput: {
        styleOverrides: {
          root: {
            '&.RaNullableBooleanInput-input': {
              width: 'auto',
            },
          },
        },
      },
    },
    shape: {
      borderRadius: 4,
    },
  });
};

const light = themeOptions('light');
const dark = themeOptions('dark');
const themes = { dark, light };

export const themeFromType = (type: ThemeType) => themes[type] ?? themes.light;
