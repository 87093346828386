import * as R from 'ramda';
import inflection from 'inflection';
import * as reactResources from '../../../resources';
import { listQueryName, oneQueryName } from 'utils/helpers';
import { gqlResourceToRails } from 'providers/data_provider/utils';
import _ from 'lodash';

export function getResourceQueries(resourceName: string) {
  const resource_name = gqlResourceToRails(resourceName);
  const resource = reactResources[resource_name];
  const listName = listQueryName({ name: resourceName });
  return {
    list: {
      query: resource.list.query,
      name: listName,
      metaName: `_${listName}Meta`,
    },
    show: {
      query: resource.show.query,
      name: oneQueryName({ name: resourceName }),
    },
    inputShow: resource.inputShow,
  };
}

const isManyReference = _.overEvery([
  _.isArray,
  R.complement(R.isEmpty),
  _.flowRight(_.isObject, R.head),
  _.flowRight(R.hasIn('id'), R.head),
]);

const isReferenceField = _.overEvery([_.isObject, R.hasIn('id'), R.hasIn('__typename')]);

type Pair = [string, any];
type PairReducer = (acc: Pair[], pair: Pair) => Pair[];

const referencePairReducer: PairReducer = (acc, [key, value]) => {
  const refPairs: Pair[] = [];
  if (isReferenceField(value)) {
    const singularKey = inflection.singularize(key);
    refPairs.push(R.pair(`${singularKey}_id`, value.id));
    refPairs.push(R.pair(`${singularKey}_type`, value.__typename));
  }
  return R.concat(acc, [R.pair(key, value), ...refPairs]);
};

const referenceManyPairReducer: PairReducer = (acc, [key, value]) => {
  const refPairs: Pair[] = [];
  if (isManyReference(value)) {
    const singularKey = inflection.singularize(key);
    refPairs.push(R.pair(`${singularKey}_ids`, R.map(_.property('id'), value)));
  }
  return R.concat(acc, [R.pair(key, value), ...refPairs]);
};

const transformResponseObj = (reducer) =>
  _.flowRight<any, any, any, any>(R.fromPairs, (col) => _.reduce(col, reducer, []), R.toPairs);

const addReferenceFields = transformResponseObj(referencePairReducer);
const addReferenceManyFields = transformResponseObj(referenceManyPairReducer);

export const parseResponse = _.flowRight<any, any, any>(addReferenceManyFields, addReferenceFields);
