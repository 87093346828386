import React from 'react';
import {
  TextInput,
  Edit,
  SimpleForm,
  required,
  DeleteWithConfirmButton,
  Toolbar,
  SaveButton,
} from 'react-admin';

const CustomToolbar = () => (
  <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
    <SaveButton />
    <DeleteWithConfirmButton
      confirmContent="Are you sure you want to delete this group? This will affect all accounts that are associated to these locations via groups."
      mutationMode="pessimistic"
    />
  </Toolbar>
);

const LocationGroupEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show" data-testid="location-group-edit">
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="name" validate={[required()]} data-testid="location-group-edit-name" />
      <TextInput
        label="Description"
        source="description"
        data-testid="location-group-edit-description"
      />
    </SimpleForm>
  </Edit>
);

export default LocationGroupEdit;
