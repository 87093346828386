import EnumSelectInput from 'components/Enum/EnumSelectInput';
import React from 'react';
import {
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  required,
  FormDataConsumer,
  TextInput,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import ProfessionalQualificationAttributesInput from 'resources/professional_qualification_attributes/ProfessionalQualificationAttributesInput';
import ProfessionalInput from 'components/ProfessionalInput';
import { ProfessionalQualificationStatus } from 'typings/enums';

const transformPayload = (payload) => {
  const professional_qualification_attributes_attributes =
    payload.professional_qualification_attributes_attributes.filter(
      (attr) => attr.value !== null && attr.value !== undefined,
    );
  return {
    ...payload,
    professional_qualification_attributes_attributes,
  };
};

const QualificationCreate = () => {
  const { state } = useLocation();

  return (
    <Create redirect="show" transform={transformPayload}>
      <SimpleForm>
        <ReferenceInput source="qualification_id" reference="Qualification" validate={required()}>
          <AutocompleteInput
            optionText="long_name"
            data-testid="pro_qualification_create_field"
            fullWidth
          />
        </ReferenceInput>
        <ProfessionalInput
          optionText={(option) => `${option?.id} ${option?.name}`}
          defaultValue={state?.record?.professionalId}
          validate={required()}
          fullWidth
        />
        <EnumSelectInput source="status" validate={required()} fullWidth />
        <FormDataConsumer>
          {({ formData }) =>
            formData.status === ProfessionalQualificationStatus.rejected && (
              <>
                <ReferenceInput
                  perPage={50}
                  source="credential_rejected_reason_id"
                  label="Reject Reason"
                  reference="CredentialRejectedReason"
                  validate={required()}
                  sort={{ field: 'rank', order: 'ASC' }}
                >
                  <AutocompleteInput optionText="label" fullWidth />
                </ReferenceInput>
                <TextInput source="additional_rejection_context" fullWidth />
              </>
            )
          }
        </FormDataConsumer>
        <ProfessionalQualificationAttributesInput />
      </SimpleForm>
    </Create>
  );
};

export default QualificationCreate;
