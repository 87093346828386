import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import AccountsTab from 'components/tabs/AccountsTab';

const LocationAccountsTab = () => (
  <SimpleShowLayout>
    <AccountsTab target="location_id" />
  </SimpleShowLayout>
);

export default LocationAccountsTab;
