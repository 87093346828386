import React from 'react';
import { CreateButton, FilterButton, TopToolbar } from 'react-admin';
import config from 'config';
import BulkUploadButtons from 'components/BulkUploadButtons';

export const JobsBoardsListActions = () => (
  <TopToolbar>
    <FilterButton />
    <BulkUploadButtons
      downloadUrl="https://medely-public-files.s3.us-west-2.amazonaws.com/csv_templates/job_board_bulk_upload_template.csv"
      uploadUrl={`v3/admin/jobs_boards_import`}
    />
    <CreateButton />
  </TopToolbar>
);
