import React from 'react';
import { FunctionField } from 'react-admin';
import { Link, Typography } from '@mui/material';
import { ProfessionalDisplayHelper } from 'utils/displayHelpers/professional';
import { DisplayHelpers } from 'utils/helpers';

export const BackgroundCheckField = (props) => (
  <FunctionField
    {...props}
    render={(v) => {
      if (!v) {
        return null;
      }
      const backgroundCheck = new ProfessionalDisplayHelper(v).backgroundCheck;
      if (!backgroundCheck) {
        return null;
      }
      // @ts-expect-error TODO: add metadata field to typings
      return backgroundCheck.metadata?.candidate_id ? (
        <Link
          target="_blank"
          // @ts-expect-error TODO: add metadata field to typings
          href={`https://dashboard.checkr.com/candidates/${backgroundCheck.metadata.candidate_id}`}
        >
          {DisplayHelpers.professional.status(backgroundCheck.status)}
        </Link>
      ) : (
        <Typography>{DisplayHelpers.professional.status(backgroundCheck.status)}</Typography>
      );
    }}
  />
);
