import React from 'react';
import LinkField from 'components/LinkField';

const parentReference = (account) => {
  if (account.agency_id) {
    return {
      name: account.agency?.name,
      link: `/Agency/${account.agency_id}/show`,
    };
  } else if (account.company_id) {
    return {
      name: account.company?.name,
      link: `/Company/${account.company_id}/show`,
    };
  }
  return null;
};

const parentLink = (professional) => {
  const referenced = parentReference(professional.account);
  return referenced ? <LinkField to={referenced.link}> {referenced.name} </LinkField> : null;
};

export default parentLink;
