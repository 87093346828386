import { AxiosInstance } from 'axios';
import RequestError from '../RequestError';
import config from '../../../config';

export default function impersonate(ajax: AxiosInstance) {
  return (id: number) => {
    return ajax({
      url: impersonateUrl(id),
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (res.status > 422) {
          throw new RequestError({ status: res.status });
        }
        return res.data;
      })
      .then((json) => {
        if (json.error) {
          throw new RequestError({ status: 422, message: json.error });
        }
        return { data: { token: json.token } };
      });
  };
}

function impersonateUrl(id: number) {
  return `${config.rails_url}/v3/admin/accounts/${id}/impersonate`;
}
