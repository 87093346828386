import React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FormControl, TextField } from '@mui/material';
import { isValid } from 'date-fns';
import { format } from 'date-fns-tz';

const DateInput = ({ value, onChange }) => {
  const handleChange = (date) => {
    if (isValid(date)) {
      const formatted = format(date, "yyyy-MM-dd'T'HH:mm:ssXX", undefined);
      onChange(formatted);
    } else {
      onChange(null);
    }
  };
  return (
    <FormControl>
      <DateTimePicker
        value={value}
        renderInput={(props) => (
          <FormControl required={false}>
            <TextField variant="filled" {...props} />
          </FormControl>
        )}
        inputFormat="MM/dd/yyyy HH:mm"
        ampm={false}
        componentsProps={{
          actionBar: {
            actions: ['clear', 'cancel', 'accept'],
          },
        }}
        mask="__/__/____ __:__"
        onChange={handleChange}
      />
    </FormControl>
  );
};

export default DateInput;
