/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  BooleanInput,
  Button,
  CreateBase,
  DateInput,
  NumberInput,
  SaveButton,
  SimpleForm,
  useRecordContext,
} from 'react-admin';
import { Box, Dialog, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import orderBy from 'lodash/orderBy';
import { IPosition } from '@medely/types';
import MoneyInput from 'components/MoneyInput';
import { DisplayHelpers } from 'utils/helpers';
import { useFormContext, useWatch } from 'react-hook-form';
import { formatDateTime } from 'components/DateTimeField';
import { addDays, addYears, isAfter, parseISO } from 'date-fns';
import { transformBulkLocationPosition } from 'resources/rate_settings/rateSettingsTransform';
import { BULK_CREATE_LOCATION_POSITION_IDS } from 'constants/rateSettings';
import { differenceInPercentage, isWithinMargin, subtractPercentage } from 'utils/number';

const GET_LOCATION_POSITION_RATES = gql`
  query LocationPositionRateSettings($input: LocationPositionRateSettingQueryInput) {
    locationPositionRateSettings(input: $input) {
      id
      settings {
        charge_rate_cents
        charge_rate_minimum_cents
      }
    }
  }
`;

const dateFormat = 'yyyy-MM-dd';
const today = formatDateTime({})(dateFormat)(new Date());
const expires_at = formatDateTime({})(dateFormat)(addYears(new Date(), 1));
const minExpiresAt = formatDateTime({})(dateFormat)(addDays(new Date(), 30));
const maxExpiresAt = formatDateTime({})(dateFormat)(addDays(new Date(), 548));

const defaultValues = Object.assign(
  {},
  ...BULK_CREATE_LOCATION_POSITION_IDS.map((positionId) => ({
    [positionId]: { enabled: false, charge_rate_cents: 0, expires_at, discount: 0 },
  })),
);

const PositionFields = ({ position }: { position: IPosition }) => {
  const record = useRecordContext();
  const location_ids = [record?.id];
  const position_ids = [position.id];
  const [shouldUpdateDiscount, setShouldUpdateDiscount] = useState<boolean>(false);
  const [shouldUpdateChargeRate, setshouldUpdateChargeRate] = useState<boolean>(false);

  const { setValue, trigger } = useFormContext();

  const setValueAndValidate = ({ field, value }: { field: string; value: number | string }) =>
    setValue(field, value, { shouldValidate: true, shouldTouch: true });

  const chargeRateField = `${position.id}.charge_rate_cents`;
  const discountField = `${position.id}.discount`;
  const expirationField = `${position.id}.expires_at`;
  const enabledField = `${position.id}.enabled`;

  const chargeRateValue = useWatch({ name: chargeRateField });
  const discountValue = useWatch({ name: discountField });
  const isEnabled = useWatch({ name: enabledField });

  const { data, loading: isLoading } = useQuery(GET_LOCATION_POSITION_RATES, {
    variables: {
      input: {
        filter: {
          for_assignment: true,
          for_job: true,
          include_parents: true,
          location_ids,
          max_only: true,
        },
        search: {
          location_ids,
          position_ids,
        },
      },
    },
  });

  const rateSettings = data?.locationPositionRateSettings[0].settings;
  const baseRate = rateSettings?.charge_rate_cents ?? 0;
  const chargeMimimum = rateSettings?.charge_rate_minimum_cents ?? 0;

  const validateDiscount = (value) => {
    if (!isEnabled) {
      return;
    }

    if (value < 0) {
      return 'Min: 0';
    }
    if (value > 100) {
      return 'Max: 100%';
    }
  };

  const validateChargeRate = (value) => {
    if (!isEnabled) {
      return undefined;
    }

    if (value >= baseRate) {
      return `Must be inferior to Base Rate (${DisplayHelpers.money(baseRate)})`;
    }
    if (value < chargeMimimum) {
      return `Min: ${DisplayHelpers.money(chargeMimimum)}`;
    }
  };

  const validateExpiration = (value) => {
    if (!isEnabled) {
      return;
    }

    const parsedValue = parseISO(value);
    const parsedToday = parseISO(today);

    if (!isAfter(parsedValue, parsedToday)) {
      return 'Must be in the future';
    }

    if (!isAfter(parsedValue, parseISO(minExpiresAt))) {
      return 'At least 30 days from now';
    }

    if (isAfter(parsedValue, parseISO(maxExpiresAt))) {
      return 'At most 1.5 years from now';
    }
  };

  const handleChargeRateChange = (_event) => setShouldUpdateDiscount(true);

  const handleDiscountChange = (_event) => setshouldUpdateChargeRate(true);

  useEffect(() => {
    if (!shouldUpdateChargeRate) {
      return;
    }

    setshouldUpdateChargeRate(false);
    const chargeRate = subtractPercentage({ value: baseRate, percentage: discountValue });
    const hasValueUpdated = `${chargeRate}` !== `${chargeRateValue}`;

    if (hasValueUpdated) {
      setValueAndValidate({ field: chargeRateField, value: chargeRate });
    }
  }, [shouldUpdateChargeRate]);

  useEffect(() => {
    if (!shouldUpdateDiscount) {
      return;
    }

    setShouldUpdateDiscount(false);
    const discount = differenceInPercentage({ value: chargeRateValue, baseValue: baseRate });
    const hasValueChanged = !isWithinMargin({
      value: discount,
      target: discountValue,
      margin: 0.0125,
    });

    if (hasValueChanged) {
      setValueAndValidate({ field: discountField, value: discount });
    }
  }, [shouldUpdateDiscount]);

  useEffect(() => {
    trigger();
  }, [isEnabled]);

  return (
    <Box pt={2}>
      <Box pb={1}>
        <BooleanInput
          source={enabledField}
          label={position.display_name}
          helperText={`Custom rates for ${position.display_name} will ${
            isEnabled ? '' : 'not'
          } be created`}
        />
      </Box>
      <Box display={isEnabled ? 'initial' : 'none'}>
        <Box sx={{ opacity: isEnabled ? 1 : 0.5 }} display="flex" alignItems="center" columnGap={2}>
          <Box width="100px">
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                color: theme.palette.grey[600],
              })}
            >
              Base rate
            </Typography>
            <Typography data-testid={`base-rate-helper-${position.id}`} variant="body2">
              {DisplayHelpers.money(baseRate)}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              sx={(theme) => ({
                color: theme.palette.grey[600],
              })}
            >
              Charge Minimum
            </Typography>
            <Typography data-testid={`minimum-rate-helper-${position.id}`} variant="body2">
              {DisplayHelpers.money(chargeMimimum)}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" columnGap={2}>
          <NumberInput
            data-testid={`discount-input-${position.id}`}
            label="Discount (%)"
            validate={validateDiscount}
            source={discountField}
            disabled={!isEnabled}
            step={1}
            sx={{ width: '100px' }}
            onChange={handleDiscountChange}
          />
          <MoneyInput
            data-testid={`rate-input-${position.id}`}
            disabled={isLoading || !isEnabled}
            label="Custom Rate"
            source={chargeRateField}
            validate={validateChargeRate}
            onChange={handleChargeRateChange}
          />
          <DateInput
            data-testid={`expiration-input-${position.id}`}
            source={expirationField}
            disabled={!isEnabled}
            validate={validateExpiration}
          />
        </Box>
      </Box>
    </Box>
  );
};

const Toolbar = () => {
  const {
    getValues,
    formState: { isValid },
  } = useFormContext();

  const hasEnabledPosition = BULK_CREATE_LOCATION_POSITION_IDS.map((id) =>
    getValues(`${id}.enabled`),
  ).some((enabled) => enabled);

  const isSubmitDisabled = !isValid || !hasEnabledPosition;

  return (
    <Box display="flex" justifyContent="end" mt={2}>
      <SaveButton disabled={isSubmitDisabled} label="Create rates" />
    </Box>
  );
};

const CustomRates = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState<boolean>(false);

  const isLoading = !record;

  const locationPositions = orderBy(
    record?.positions.filter(({ id }) => BULK_CREATE_LOCATION_POSITION_IDS.includes(id)),
    ['id'],
  );

  return (
    <>
      <Button
        data-testid="add-custom-rates"
        variant="outlined"
        label="Add custom rates"
        onClick={() => setOpen(true)}
      />
      <Dialog fullWidth maxWidth="lg" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Custom Rates</DialogTitle>
        <DialogContent>
          <CreateBase
            record={record}
            redirect={() => `LocationPositionRateSetting?filter={"location_id":${record.id}}`}
            resource="LocationRateSetting"
            transform={transformBulkLocationPosition}
          >
            <SimpleForm
              defaultValues={defaultValues}
              mode="onChange"
              reValidateMode="onChange"
              toolbar={<Toolbar />}
            >
              {!isLoading &&
                locationPositions.map((position, index) => (
                  <Box width="100%" key={`custom-rates-fields-for-${position.id}`}>
                    <PositionFields position={position} />
                    {index < locationPositions.length - 1 && <Divider />}
                  </Box>
                ))}
            </SimpleForm>
          </CreateBase>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CustomRates;
