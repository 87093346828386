import * as React from 'react';
import {
  Datagrid,
  FunctionField,
  ReferenceManyField,
  SelectInput,
  TextField,
  useRecordContext,
} from 'react-admin';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import DateFilter from 'components/Filter/DateFilter';
import DateTimeField from 'components/DateTimeField';
import List from 'components/List';
import { choicesFromEnum } from 'utils/helpers';
import { ItemizedTransactionSourceKinds } from 'typings/enums';

const Filters = [
  <SelectInput
    label="From source"
    source="search.from_source"
    emptyText="All"
    choices={choicesFromEnum(ItemizedTransactionSourceKinds)}
  />,
  <SelectInput
    label="To source"
    source="search.to_source"
    emptyText="All"
    choices={choicesFromEnum(ItemizedTransactionSourceKinds)}
  />,
  <DateFilter label="Created at" source="search.created_at" />,
];

const ItemizedTransactionsTab = () => {
  const record = useRecordContext();

  return (
    <ReferenceManyField label="" reference="ItemizedTransaction" target="billing_group_id">
      <List
        exporter={false}
        filters={Filters}
        hasCreate={false}
        filter={{ billing_group_id: record.id }}
      >
        <Datagrid bulkActionButtons={false}>
          <FunctionField
            label="ID"
            render={(v) => {
              return <LinkField to={`/ItemizedTransaction/${v.id}/show`}>{v.id}</LinkField>;
            }}
          />
          <FunctionField
            label="Financial Transaction ID"
            render={(v) => {
              if (v.financial_transaction_id) {
                return (
                  <LinkField to={`/FinancialTransaction/${v.financial_transaction_id}/show`}>
                    {v.financial_transaction_id}
                  </LinkField>
                );
              }
            }}
          />
          <MoneyField label="Amount" source="amount_cents" />
          <DateTimeField source="created_at" timeZone="America/Los_Angeles" />
          <TextField source="from_source" />
          <TextField source="to_source" />
          <FunctionField
            label="InvoiceId"
            render={(v) =>
              v.invoice && (
                <LinkField to={`/Invoice/${v.invoice.id}/show`}>{v.invoice.id}</LinkField>
              )
            }
          />
          <TextField source="notes" />
        </Datagrid>
      </List>
    </ReferenceManyField>
  );
};

export default React.memo(ItemizedTransactionsTab);
