import List from './List';
import Show from './Show';
import Edit from './Edit';
import { gql } from '@apollo/client';

export default {
  name: 'location_groups',
  inputShow: gql`
    query locationGroups($input: LocationGroupQueryInput) {
      locationGroups(input: $input) {
        id
        __typename
      }
    }
  `,
  list: List,
  edit: Edit,
  show: Show,
  options: {
    group: 'Misc',
    label: 'Location Groups',
  },
};
