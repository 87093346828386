import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import List from 'components/List';
import LinkField from 'components/LinkField';
import DateTimeField from 'components/DateTimeField';
import IdField from 'components/IdField';

const EducationList = () => (
  <List exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Professional ID" source="professional_id" />
      <TextField label="School Name" source="school_name" />
      <TextField label="Degree Type" source="degree_type" />
      <TextField label="Field of Study" source="field_of_study" />
      <TextField label="City" source="city" />
      <TextField label="State" source="state.name" />
      <DateTimeField label="Start Date" source="start_date" />
      <DateTimeField label="End Date" source="end_date" />
    </Datagrid>
  </List>
);

EducationList.query = gql`
  query GET_LIST($input: EducationQueryInput) {
    _educationsMeta(input: $input) {
      count
    }
    educations(input: $input) {
      city
      degree_type
      end_date
      field_of_study
      id
      location
      professional_id
      school_name
      start_date
      state {
        id
        name
      }
      state_id
    }
  }
`;

export default EducationList;
