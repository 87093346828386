import React from 'react';
import { minValue } from 'react-admin';
import CreateView from '../components/CreateView';

const defaultValues = {
  adjustment_type: '',
  amount_cents: '0.00',
};

const CreditMemosCreate = () => (
  <CreateView
    invoice_type="debit_memo"
    title="Create Debit Memos"
    defaultValues={defaultValues}
    amountCentsValidation={minValue(1, 'Must be a positive amount')}
  />
);

export default CreditMemosCreate;
