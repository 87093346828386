export const AssignmentContractRange = [
  { id: 1, label: 'Less than $10,000', value: { min: null, max: 1000000 } },
  { id: 2, label: '$10,000 - $15,000', value: { min: 1000000, max: 1500000 } },
  { id: 3, label: '$15,000 - $25,000', value: { min: 1500000, max: 2500000 } },
  { id: 4, label: '$25,000 - $35,000', value: { min: 2500000, max: 3500000 } },
  { id: 5, label: '$35,000 - $45,000', value: { min: 3500000, max: 4500000 } },
  { id: 6, label: '$45,000 - $55,000', value: { min: 4500000, max: 5500000 } },
  { id: 7, label: '$55,000 - $65,000', value: { min: 5500000, max: 6500000 } },
  { id: 8, label: '$65,000 - $75,000', value: { min: 6500000, max: 7500000 } },
  { id: 9, label: '$75,000 - $85,000', value: { min: 7500000, max: 8500000 } },
  { id: 10, label: '$85,000 - $95,000', value: { min: 8500000, max: 9500000 } },
  { id: 11, label: 'More than $95,000', value: { min: 9500000, max: null } },
];
