import React, { useState } from 'react';
import { RaRecord, useRecordContext, TextField, Datagrid, ArrayField } from 'react-admin';
import * as R from 'ramda';
import PdfButton from 'components/PdfButton';
import GetApp from '@mui/icons-material/GetApp';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatDate } from 'utils/date';
import LinkField from 'components/LinkField';
import CredentialsFilter, { FilterEnum } from './CredentialsFilter';
import { Link } from 'react-router-dom';

const statusEq = R.propEq('status');

const color = R.cond<any, string>([
  [statusEq('review'), R.always('#5188ec')],
  [R.propEq('expired', true), R.always('#e65f4c')],
  [statusEq('approved'), R.always('#54ca64')],
  [statusEq('rejected'), R.always('#e65f4c')],
  [statusEq(undefined), R.always('#e65f4c')],
  [R.T, R.always('#E6EBEC')],
]);

const credentialRowStyle = (record: RaRecord) => ({
  borderLeft: '4px solid',
  borderColor: color(record),
  textDecoration: 'none',
});

const EmptyCredentialRow = () => (
  <TableRow>
    <TableCell
      sx={{ textAlign: 'center', paddingY: 8 }}
      colSpan={9}
      data-testid="professional_show-credentials_no_records"
    >
      No records found
    </TableCell>
  </TableRow>
);

const CredentialTable = ({ data }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell data-testid="professional_show-credentials_id_header">ID</TableCell>
        <TableCell data-testid="professional_show-credentials_credential_header">
          Credential
        </TableCell>
        <TableCell data-testid="professional_show-credentials_type_header">Type</TableCell>
        <TableCell data-testid="professional_show-credentials_state_header">State</TableCell>
        <TableCell data-testid="professional_show-credentials_original_issue_date_header">
          Original Issue Date
        </TableCell>
        <TableCell data-testid="professional_show-credentials_expires_header">Expires</TableCell>
        <TableCell data-testid="professional_show-credentials_status_header">Status</TableCell>
        <TableCell data-testid="professional_show-credentials_created_at_header">
          Created At
        </TableCell>
        <TableCell data-testid="professional_show-credentials_updated_at_header">
          Updated At
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {!data.length && <EmptyCredentialRow />}
      {data.map((row, index) => (
        <TableRow key={index} style={credentialRowStyle(row)}>
          <TableCell>
            {row.id && (
              <LinkField OVERRIDE_resource="ProfessionalQualification" source="id" record={row}>
                {row.id}
              </LinkField>
            )}
          </TableCell>
          <TableCell data-testid="professional_show-credentials_credential_field">
            {row?.long_name}
          </TableCell>
          <TableCell data-testid="professional_show-credentials_type_field">
            {row?.qualification_type}
          </TableCell>
          <TableCell data-testid="professional_show-credentials_state_field">
            {
              row?.professional_qualification_attributes?.find((attribute) => !!attribute.state)
                ?.state
            }
          </TableCell>
          <TableCell data-testid="professional_show-credentials_original_issue_date_field">
            {formatDate(
              row?.professional_qualification_attributes?.find((attribute) =>
                attribute.qualification_attribute?.slug.includes('original_issue_date'),
              )?.value,
            )}
          </TableCell>
          <TableCell data-testid="professional_show-credentials_expires_field">
            {formatDate(
              row?.professional_qualification_attributes?.find(({ type }) =>
                type.includes('ExpirationDate'),
              )?.value,
            )}
          </TableCell>
          <TableCell data-testid="professional_show-credentials_status_field">
            <Typography color={row.status ? 'inherit' : 'error'}>
              {row.status ?? 'Missing'}
            </Typography>
          </TableCell>
          <TableCell data-testid="professional_show-credentials_created_at_field">
            {formatDate(row?.created_at)}
          </TableCell>
          <TableCell data-testid="professional_show-credentials_updated_at_field">
            {formatDate(row?.updated_at)}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

const EmptyConditionsTable = () => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell>Id</TableCell>
        <TableCell>Label</TableCell>
        <TableCell>Condition Type</TableCell>
        <TableCell>Payload</TableCell>
      </TableRow>
    </TableHead>
    <EmptyCredentialRow />
  </Table>
);

const SatisfiedConditionsTable = () => (
  <ArrayField source="satisfied_conditions">
    <Datagrid bulkActionButtons={false} empty={<EmptyConditionsTable />}>
      <TextField source="id" />
      <TextField source="label" />
      <TextField source="condition_type" />
      <TextField source="payload" />
    </Datagrid>
  </ArrayField>
);

export const CredentialsTab = () => {
  const [currentFilter, setCurrentFilter] = useState<FilterEnum>(FilterEnum.ALL);

  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const createFileName = () => {
    const firstName = record?.account?.first_name ?? '';
    const lastName = record?.account?.last_name ?? '';

    return firstName && lastName
      ? `${lastName}.${firstName}.compliance_package.pdf`
      : 'unnamed.compliance_package.pdf';
  };

  const viewingSatisfiedConditions = currentFilter === FilterEnum.SATISFIED_CONDITIONS;
  const isAgencyProfessional = Boolean(record?.account?.agency_id);

  return (
    <>
      {!isAgencyProfessional && (
        <PdfButton
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<GetApp />}
          download={createFileName()}
          href={`/professionals/${record?.id}/compliance_package`}
          sx={{ width: 205 }}
        >
          Compliance Package
        </PdfButton>
      )}
      <CredentialsFilter currentFilter={currentFilter} setCurrentFilter={setCurrentFilter}>
        {({ data }) =>
          viewingSatisfiedConditions ? (
            <SatisfiedConditionsTable />
          ) : (
            <CredentialTable data={data} />
          )
        }
      </CredentialsFilter>
      <Button
        component={Link}
        to="/ProfessionalQualification/create"
        state={{ record: { professional_id: record.id } }}
        variant="contained"
        size="small"
        sx={{ width: 205, marginTop: '16px' }}
        data-testid="professional_show-add_credentials_button"
      >
        Add Credentials
      </Button>
    </>
  );
};

export default CredentialsTab;
