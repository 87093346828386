import React from 'react';
import { FunctionField, Create, SimpleForm, TextInput, AutocompleteInput } from 'react-admin';
import ImageInput from 'components/ImageInput';
import ReferenceInput from 'components/ReferenceInput';
import {
  ProfessionalQualificationAttribute,
  redirectOnUpsert,
} from 'resources/professional_qualification_attributes/utils';

const AgencyQualificationAttributeCreate = () => (
  <Create redirect={redirectOnUpsert}>
    <SimpleForm>
      <ReferenceInput
        alwaysOn
        emptyText="All"
        source="agency_qualification.id"
        reference="AgencyQualification"
      >
        <AutocompleteInput optionText="id" />
      </ReferenceInput>
      <TextInput source="type" />
      <FunctionField
        label="Value"
        render={(record) => {
          const pqa = new ProfessionalQualificationAttribute(record);
          if (pqa.isImage) {
            return <ImageInput record={record} source="value" extensionSource="extension" />;
          }
          return <TextInput source="value" />;
        }}
      />
    </SimpleForm>
  </Create>
);

export default AgencyQualificationAttributeCreate;
