import React from 'react';
import { Edit } from 'react-admin';
import PositionRateSettingsForm from '../PositionRateSettingsForm';
import { getTransform } from '../rateSettingsTransform';

const LocationPositionRateSettingEdit = () => {
  return (
    <Edit
      mutationMode="pessimistic"
      transform={getTransform(['location_id'], 'location_position_rate_setting')}
    >
      <PositionRateSettingsForm settingsField="location_position_rate_setting" />
    </Edit>
  );
};

export default LocationPositionRateSettingEdit;
