import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import IdField from 'components/IdField';
import List from 'components/List';

const AgencyQualificationAttributesList = () => (
  <List exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <TextField source="agency_qualification_id" />
      <TextField label="Qualification" source="qualification_attribute.qualification.long_name" />
      <TextField label="Qualification Attribute" source="qualification_attribute.name" />
    </Datagrid>
  </List>
);

AgencyQualificationAttributesList.query = gql`
  query GET_LIST($input: AgencyQualificationAttributeQueryInput) {
    agencyQualificationAttributes(input: $input) {
      id
      agency_qualification_id
      qualification_attribute_id
      type
      value
      metadata
      qualification_attribute {
        id
        name
        qualification {
          id
          long_name
        }
      }
    }
    _agencyQualificationAttributesMeta(input: $input) {
      count
    }
  }
`;

export default AgencyQualificationAttributesList;
