import React, { useRef, useState } from 'react';
import { useRecordContext, useResourceContext } from 'ra-core';
import { useNavigate } from 'react-router-dom';
import { Datagrid, FunctionField, List, useNotify, Pagination } from 'react-admin';
import { Box, Button, Dialog, Typography, LinearProgress } from '@mui/material';
import ImpersonateButton from './ImpersonateButton';
import LinkField from './LinkField';
import config from 'config';
import create from 'utils/api/create';
import update from 'utils/api/update';
import useDialog from 'hooks/useDialog';

const AvailableProfessionalsSearch = () => {
  const record = useRecordContext<{ id: number }>();
  const resource = useResourceContext();
  const bookableId = record?.id;
  const { open, openDialog, closeDialog } = useDialog();

  const isAvailableProfessionalsLoading = useRef(false);
  const [professionalsList, setProfessionalsList] = useState<number[]>([]);

  const availableProfessionalsSearch = async () => {
    openDialog();
    const resourceName = resource === 'Job' ? 'jobs' : 'assignments';

    isAvailableProfessionalsLoading.current = true;
    const { data } = await create(
      `${config.rails_url}/v3/development/admin/${resourceName}/${record.id}/available_professionals_list`,
      {},
    );

    isAvailableProfessionalsLoading.current = false;
    setProfessionalsList(data?.professional_ids ?? []);
  };

  return (
    <>
      <Button size="small" color="primary" onClick={availableProfessionalsSearch}>
        Available Professionals
      </Button>
      <Dialog open={open} onClose={closeDialog}>
        <Typography variant="h6" px={2} pt={3}>
          {`Available Professionals for ${resource} ${record.id}`}
        </Typography>
        <Box>
          {isAvailableProfessionalsLoading.current ? (
            <Box>
              <LinearProgress />
            </Box>
          ) : (
            <List
              resource="Account"
              filter={{ search: { professional_ids: professionalsList } }}
              pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50]} />}
              actions={null}
            >
              <Datagrid bulkActionButtons={false} size="small">
                <LinkField source="professional.id" label="" />
                <FunctionField render={(record) => <ImpersonateButton account={record} />} />
                <FunctionField
                  render={(record) =>
                    resource === 'Job' ? (
                      <BookButton
                        closeDialog={closeDialog}
                        jobId={bookableId}
                        professionalId={record.professional.id}
                      />
                    ) : (
                      <ApplyButton
                        assignmentId={bookableId}
                        closeDialog={closeDialog}
                        professionalId={record.professional.id}
                      />
                    )
                  }
                />
              </Datagrid>
            </List>
          )}
        </Box>
      </Dialog>
    </>
  );
};

const BookButton = ({
  closeDialog,
  jobId,
  professionalId,
}: {
  closeDialog: () => void;
  jobId: number;
  professionalId: number;
}) => {
  const notify = useNotify();
  const navigate = useNavigate();

  const bookProfessional = async () => {
    await update(
      `${config.rails_url}/v3/admin/jobs/${jobId}`,
      {
        booked_at: new Date(),
        professional_id: professionalId,
        status: 'booked',
      },
      {},
      'Job',
    );
    closeDialog();
    navigate(0);
    notify(`Professional ${professionalId} booked on job ${jobId}`);
  };

  return (
    <Button size="small" color="primary" onClick={bookProfessional}>
      Book
    </Button>
  );
};

const ApplyButton = ({
  assignmentId,
  closeDialog,
  professionalId,
}: {
  assignmentId: number;
  closeDialog: () => void;
  professionalId: number;
}) => {
  const notify = useNotify();
  const navigate = useNavigate();

  const applyProfessional = async () => {
    try {
      await create(`${config.rails_url}/v3/admin/assignment_requests`, {
        assignment_id: assignmentId,
        professional_id: professionalId,
        status: 'applied',
      });
      closeDialog();
      navigate(`/Assignment/${assignmentId}/show/requests`);
      notify(`Professional ${professionalId} applied to assignment ${assignmentId}`);
    } catch (error: any) {
      notify(`Error: ${error.message}`, { type: 'warning' });
    }
  };

  return (
    <Button size="small" color="primary" onClick={applyProfessional}>
      Apply
    </Button>
  );
};

export default AvailableProfessionalsSearch;
