import React from 'react';
import { SelectInput, useResourceContext } from 'react-admin';
import EnumError from './EnumError';
import { choicesFromEnum } from 'utils/helpers';
import { ENUMS } from './enums';
import { getName } from './getName';
import { getObjectPath } from 'utils/object';
import { sortByName } from 'utils/sortObjectArray';

const getEnumeration = (resource, source) => {
  if (source.startsWith('search.')) {
    const sourceWithoutSearch = source.replace('search.', '');
    const enumeration = getObjectPath(ENUMS, [resource, sourceWithoutSearch]);
    if (enumeration) {
      return enumeration;
    }
  }
  return getObjectPath(ENUMS, [resource, source]);
};

const EnumSelectInput = (props) => {
  try {
    const resource = useResourceContext(props);
    const { source, sort = sortByName, filterOut } = props;
    const enumeration: any = getEnumeration(resource, source);
    const choices = choicesFromEnum(enumeration);
    const filtered = filterOut
      ? choices.filter((choice) => !filterOut.includes(choice.id))
      : choices;
    const translatedChoices = filtered.map((c) => ({
      ...c,
      name: getName(resource, source, c),
    }));
    return (
      <SelectInput
        emptyText="All"
        {...props}
        choices={sort ? sort(translatedChoices) : translatedChoices}
        data-testid="enum-select-input"
      />
    );
  } catch {
    return <EnumError />;
  }
};

export default EnumSelectInput;
