import React from 'react';
import { BooleanInput, FormDataConsumer } from 'react-admin';
import { ENUMS } from './Enum/enums';

const getAgencyMemoPayoutLabel = (isNegative: boolean) => {
  return isNegative
    ? 'Should the adjustments also be deducted from the agency?'
    : 'Should the adjustments also be paid to the agency?';
};
const AGENCY_MEMO_PAYOUT_HELP_TEXT =
  'This toggle only works if the invoice/memo is associated with an agency';

const AgencyAdjustmentRouteToPayoutToggle = () => {
  const AdjustmentType = ENUMS.RevenueAdjustment.adjustment_type;
  const allowedAdjustmentTypes = [
    AdjustmentType.HOURLY_OTHER,
    AdjustmentType.ORIENTATION,
    AdjustmentType.BONUS,
  ];

  return (
    <FormDataConsumer>
      {({ formData }) => {
        const isNegative = formData.amount_cents < 0;
        return allowedAdjustmentTypes.includes(formData.adjustment_type) ? (
          <BooleanInput
            defaultValue={true}
            source="agency_adjustment_route_to_payout"
            label={getAgencyMemoPayoutLabel(isNegative)}
            helperText={AGENCY_MEMO_PAYOUT_HELP_TEXT}
          />
        ) : null;
      }}
    </FormDataConsumer>
  );
};

export default AgencyAdjustmentRouteToPayoutToggle;
