import React from 'react';
import { gql } from '@apollo/client';
import { NumberField, TextField, Show, SimpleShowLayout, BooleanField } from 'react-admin';
import PolymorphicField from 'components/PolymorphicField';
import TimestampFields from 'components/TimestampFields';

const FundingSourceShow = () => (
  <Show data-testid="funding-source-show" actions={null}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <PolymorphicField source="personable" />
      <TextField label="Stripe ID" source="stripe_id" />
      <TextField source="brand" />
      <NumberField label="Exp Month" source="exp_month" />
      <NumberField label="Exp Year" source="exp_year" />
      <TextField label="Last 4 Digits" source="last4" />
      <TextField label="Account Type" source="account_type" />
      <TextField label="Bank Name" source="bank_name" />
      <BooleanField label="Active" source="active" />
      <TextField source="status" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

FundingSourceShow.query = gql`
  query fundingSource($id: Int!) {
    fundingSource(id: $id) {
      id
      personable {
        ... on BillingGroup {
          id
          __typename
        }
        ... on Professional {
          id
          __typename
        }
      }
      stripe_id
      brand
      exp_month
      exp_year
      last4
      active
      status
      account_type
      bank_name
      created_at
      updated_at
    }
  }
`;

export default FundingSourceShow;
