import React from 'react';
import { CreateButton, FilterButton, TopToolbar } from 'react-admin';
import { Button, CircularProgress } from '@mui/material';
import { useFileUpload } from 'hooks/useFileUpload';
import config from 'config';
import useRequestCsv from 'hooks/useRequestCsv';

export const LocationListActions = () => {
  const downloadCSV = useRequestCsv();

  const { hiddenInput, openFilePrompt, uploading } = useFileUpload({
    url: `${config.rails_url}/v3/admin/locations/import`,
    accept: 'text/csv',
  });

  const {
    hiddenInput: hiddenInputVMS,
    openFilePrompt: openFilePromptVMS,
    uploading: uploadingVMS,
  } = useFileUpload({
    url: `${config.rails_url}/v3/admin/locations_import`,
    accept: 'text/csv',
  });

  return (
    <TopToolbar>
      <FilterButton />
      <Button
        size="small"
        variant="outlined"
        onClick={() =>
          downloadCSV({
            url: `/v3/admin/locations/download_import_template`,
            filename: `medely_locations_import.csv`,
          })
        }
      >
        Download Import Template
      </Button>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {hiddenInput}
        <Button
          disabled={uploading}
          onClick={openFilePrompt}
          variant="outlined"
          size="small"
          endIcon={uploading ? <CircularProgress size={20} /> : undefined}
        >
          Import Locations
        </Button>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {hiddenInputVMS}
        <Button
          disabled={uploadingVMS}
          onClick={openFilePromptVMS}
          variant="outlined"
          size="small"
          endIcon={uploadingVMS ? <CircularProgress size={20} /> : undefined}
        >
          Import VMS
        </Button>
      </div>
      <CreateButton />
    </TopToolbar>
  );
};
