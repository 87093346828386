import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

const renderRole = function (accounts) {
  const { company, professional, administrator, agency } = accounts;
  let id;
  let name;
  if (professional) {
    id = professional.id;
    name = 'Professional';
  } else if (company) {
    id = company.id;
    name = 'Company';
  } else if (administrator) {
    id = administrator.id;
    name = 'Administrator';
  } else if (agency) {
    id = agency.id;
    name = 'Agency';
  } else {
    return;
  }
  return (
    <Link component={RouterLink} to={`/${name}/${id}/show`}>
      {name}: {id}
    </Link>
  );
};

export default renderRole;
