import config from '../config';
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
export const firebaseApp = initializeApp(config.firebaseConfig);

import { ReactNode } from 'react';

export const auth = getAuth(firebaseApp);

export type BaseContextArgs = { children: ReactNode | ReactNode[] };
