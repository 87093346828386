import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { FormDataConsumer, SelectInput } from 'react-admin';
import { ListItemText } from '@mui/material';

const ClinicalContactInput = ({ source, ...props }) => (
  <FormDataConsumer>
    {({ formData }) => {
      const companyId = formData.company_id || props.company_id || formData.location?.company?.id;

      if (!companyId) {
        return null;
      }

      return (
        <ReferenceInput
          label="Clinical Contact"
          reference="Account"
          source={source}
          filter={{ company_id: companyId }}
          gql={['id', 'first_name', 'last_name', 'phone_number']}
          perPage={1000}
        >
          <SelectInput
            optionText={(r) => (
              <ListItemText primary={`${r.first_name} ${r.last_name}`} secondary={r.phone_number} />
            )}
            {...getChildrenProps(props)}
            data-testid="clinical-contact-input"
          />
        </ReferenceInput>
      );
    }}
  </FormDataConsumer>
);

ClinicalContactInput.defaultProps = {
  label: 'Clinical Contact',
  reference: 'Account',
  source: 'clinical_contact_account_id',
  sort: { field: 'first_name', order: 'ASC' },
};

export default ClinicalContactInput;
