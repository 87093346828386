import React from 'react';
import { gql } from '@apollo/client';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  NumberField,
  NumberInput,
  TextField,
} from 'react-admin';
import { IdFilter } from 'components/Filter';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import IdField from 'components/IdField';
import List from 'components/List';

const BalanceSheetsFilters = [
  <IdFilter />,
  <EnumSelectInput label="Month" source="search.month" alwaysOn sort="" />,
  <NumberInput label="Year" alwaysOn source="search.year" />,
  <NumberInput label="Close Period" alwaysOn source="search.close_period" />,
  <BooleanInput label="Closed" alwaysOn source="search.closed" />,
];

const BalanceSheetList = () => (
  <List title="Financial Reports" exporter={false} filters={BalanceSheetsFilters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <NumberField label="Close Period" source="close_period" />
      <TextField label="Date" source="date" sortable={false} />
      <TextField label="Month" source="month" sortable={false} />
      <TextField label="Year" source="year" sortable={false} />
      <BooleanField label="Closed" source="closed" sortable={false} />
    </Datagrid>
  </List>
);

BalanceSheetList.query = gql`
  query GET_LIST($input: BalanceSheetQueryInput) {
    balanceSheets(input: $input) {
      id
      close_period
      total_cents
      closed
      date
      month
      year
      first_datetime
    }
  }
`;

export default BalanceSheetList;
