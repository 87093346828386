import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

type Props = {
  ids: number[];
  invoice_type: string;
};

const LinksToMemos = ({ ids, invoice_type }: Props) => {
  return (
    <>
      {ids.map((id: number) => {
        return (
          <Link to={`/Invoice/${invoice_type}/${id}/show`}>
            <Typography>Link to memo {id}</Typography>
          </Link>
        );
      })}
    </>
  );
};

export default LinksToMemos;
