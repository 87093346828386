import React from 'react';
import {
  ReferenceManyField,
  Datagrid,
  Pagination,
  SimpleShowLayout,
  FunctionField,
} from 'react-admin';
import LinkField from 'components/LinkField';
import DateTimeField from 'components/DateTimeField';
import EnumField from 'components/Enum/EnumField';

const AssignmentsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="Assignment"
      target="search.location_id"
      sort={{ field: 'starts_date', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false}>
        <LinkField label="ID" source="id" />
        <LinkField label="Professional ID" source="professional_id" />
        <EnumField source="status" />
        <DateTimeField
          label="Starts Date"
          source="starts_date"
          format="MM/dd/yyyy zzz"
          tzSource="primary_location.timezone_lookup"
        />
        <DateTimeField
          label="Ends Date"
          source="ends_date"
          format="MM/dd/yyyy zzz"
          tzSource="primary_location.timezone_lookup"
        />
        <FunctionField
          label="Booked Pro"
          render={(record) =>
            record.professional
              ? [
                  record.professional.account.first_name,
                  record.professional.account.last_name,
                ].join(' ')
              : ''
          }
        />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default React.memo(AssignmentsTab);
