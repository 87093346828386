import React, { useState, useMemo } from 'react';
import { useGetList, Loading, maxValue, minValue } from 'react-admin';
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Checkbox,
  Tooltip,
  Typography,
} from '@mui/material';
import { DisplayHelpers } from 'utils/helpers';
import { useFormContext } from 'react-hook-form';
import MoneyInput from 'components/MoneyInput';
import { capitalize } from 'inflection';

const PayInvoicesTable = ({ record, amountLeft, showBillingGroupIDField }) => {
  const { getValues, setValue, watch } = useFormContext();
  const invoices = watch('invoices');
  const billing_group_balances = watch('billing_group_balances');
  const billing_group_ids = watch('billing_group_ids');
  const [billingGroups, setBillingGroups] = useState([]);

  const { data: invoicesData, isLoading: areInvoicesLoading } = useGetList('Invoice', {
    pagination: { page: 1, perPage: 9999 },
    sort: { field: 'ends_time', order: 'asc' },
    filter: {
      search: {
        billing_group_ids,
        statuses: ['past_due', 'disputed', 'due'],
      },
    },
  });

  const { data: billingGroupsData, isLoading: areBillingGroupsLoading } = useGetList(
    'BillingGroup',
    {
      pagination: { page: 1, perPage: 9999 },
      filter: {
        search: {
          billing_group_ids,
        },
      },
    },
  );

  useMemo(() => {
    const bgData = billingGroupsData ? JSON.parse(JSON.stringify(billingGroupsData)) : [];
    const billingGroups = [];
    bgData.forEach((bg) =>
      billingGroups.push({
        id: bg.id,
        originalBalanceCents: bg.balance_cents,
        balance_cents: bg.balance_cents,
      }),
    );
    setBillingGroups(billingGroups);
  }, [billingGroupsData]);

  if (areInvoicesLoading || areBillingGroupsLoading) {
    return <Loading />;
  }
  if (!invoicesData || !billingGroupsData) {
    return null;
  }

  const amount = amountLeft(record, invoices, billing_group_balances);

  return (
    <Box style={{ width: '100%' }}>
      <Typography color={amount < 0 ? 'error' : 'textSecondary'}>
        Financial transaction balance: {DisplayHelpers.money(amount)}
      </Typography>
      {billingGroups?.map((bg) => (
        <Typography color={bg.balance_cents < 0 ? 'error' : 'textSecondary'} key={bg.id}>
          Billing group #{bg.id}'s credit balance: {DisplayHelpers.money(bg.balance_cents)}
        </Typography>
      ))}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Select</TableCell>
            <TableCell>Invoice Id</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Voucher ID</TableCell>
            {showBillingGroupIDField && <TableCell>BillingGroup ID</TableCell>}
            <TableCell>Amount Due</TableCell>
            <Tooltip arrow title="Amount to pay from the billing group's credit balance">
              <TableCell>Amount from credit balance</TableCell>
            </Tooltip>
            <Tooltip arrow title="Amount to pay from the Financial Transaction">
              <TableCell>Amount from FT</TableCell>
            </Tooltip>
            <TableCell>Internal Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!invoicesData.length && (
            <TableRow>
              <TableCell colSpan={4}>No invoices found for given users</TableCell>
            </TableRow>
          )}
          {invoicesData.map((row) => (
            <TableRow key={row.id}>
              {/* Select */}
              <TableCell>
                <Checkbox
                  color="primary"
                  name={`${row.id}_checkbox`}
                  disabled={
                    (!invoices.map((v) => v.id).includes(row.id) && amount <= 0) ||
                    row.amount_due_cents <= 0
                  }
                  onChange={() => {
                    const amountToPay = Math.min(row.amount_due_cents, amount);

                    const mutableCopy = JSON.parse(JSON.stringify(row));
                    mutableCopy.amount_cents = amountToPay;

                    const wasPreviouslyChecked = invoices.map((v) => v.id).includes(row.id);
                    const rows = wasPreviouslyChecked
                      ? invoices.filter((v) => v.id !== row.id)
                      : [...invoices, mutableCopy];

                    setValue('invoices', rows);
                    setValue(`${row.id}.amount_cents`, wasPreviouslyChecked ? '' : amountToPay, {
                      shouldDirty: !wasPreviouslyChecked,
                      shouldValidate: true,
                    });
                    const currentAmount = amountLeft(record, invoices, billing_group_balances);
                    setValue('blockNextButton', currentAmount < 0);
                    if (
                      wasPreviouslyChecked &&
                      getValues().creditBalances?.[row.billing_group_id]?.[row.id]
                    ) {
                      const creditBalances = getValues().creditBalances;
                      creditBalances[row.billing_group_id][row.id] = '';
                      setValue('creditBalances', creditBalances);
                    }
                  }}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </TableCell>
              {/* Invoice Id */}
              <TableCell>{row.id}</TableCell>
              {/* Status */}
              <TableCell>{capitalize(row.status.split('_').join(' '))}</TableCell>
              {/* Voucher ID */}
              <TableCell>{row.voucher_id}</TableCell>
              {/* BillingGroup ID */}
              {showBillingGroupIDField && <TableCell>{row.billing_group_id}</TableCell>}
              {/* Amount Due */}
              <TableCell>{DisplayHelpers.money(row.amount_due_cents)}</TableCell>
              {/* Amount from credit balance */}
              <TableCell>
                {invoices.map((v) => v.id).includes(row.id) && (
                  <MoneyInput
                    sx={{ minWidth: '150px' }}
                    disabled={!invoices.map((v) => v.id).includes(row.id)}
                    name={`${row.id}.credit_balance_usage_cents`}
                    label=""
                    validate={[
                      minValue(0, 'Cannot be less than $0.00'),
                      maxValue(
                        row.amount_due_cents,
                        `Cannot be more than ${DisplayHelpers.money(row.amount_due_cents)}`,
                      ),
                    ]}
                    onChange={({ target }) => {
                      const value = target?.value.length
                        ? parseInt(target.value.replace(/\D/g, ''))
                        : 0;
                      const formValues = getValues();
                      const creditBalances = formValues.creditBalances || {};
                      if (creditBalances[row.billing_group_id]) {
                        creditBalances[row.billing_group_id][row.id] = value;
                      } else {
                        creditBalances[row.billing_group_id] = { [row.id]: value };
                      }
                      setValue('creditBalances', creditBalances);

                      const currentAmount = amountLeft(record, invoices, billing_group_balances);
                      const isTransactionBalanceLessThanZero = currentAmount < 0;
                      const isValueLessThanZero = value < 0;
                      const isValueGreaterThanInvoiceAmount = value > row.amount_due_cents;

                      const bgIndex = billingGroups.findIndex(
                        (bg) => bg.id === row.billing_group_id,
                      );

                      const billingGroupsCreditBalanceUsed: number = Object.values(
                        creditBalances[row.billing_group_id],
                      ).reduce((acc: number, cb: number) => acc + cb, 0);
                      billingGroups[bgIndex].balance_cents =
                        billingGroups[bgIndex].originalBalanceCents -
                        billingGroupsCreditBalanceUsed;
                      setBillingGroups(billingGroups);
                      const isCreditBalanceLessThanZero = billingGroups[bgIndex].balance_cents < 0;
                      const financialTransactionAmount = formValues[row.id]?.amount_cents;
                      const valuePlusFinancialMoreThanAmountDue =
                        value + financialTransactionAmount > row.amount_due_cents;

                      setValue(
                        'blockNextButton',
                        isTransactionBalanceLessThanZero ||
                          isValueLessThanZero ||
                          isValueGreaterThanInvoiceAmount ||
                          isCreditBalanceLessThanZero ||
                          valuePlusFinancialMoreThanAmountDue,
                      );
                    }}
                  />
                )}
              </TableCell>
              {/* Amount from FT */}
              <TableCell height={75} width={192}>
                {invoices.map((v) => v.id).includes(row.id) && (
                  <MoneyInput
                    sx={{ minWidth: '150px' }}
                    disabled={!invoices.map((v) => v.id).includes(row.id)}
                    name={`${row.id}.amount_cents`}
                    label=""
                    validate={[
                      minValue(0, 'Cannot be less than $0.00'),
                      maxValue(
                        row.amount_due_cents,
                        `Cannot be more than ${DisplayHelpers.money(row.amount_due_cents)}`,
                      ),
                    ]}
                    onChange={({ target }) => {
                      const value = target?.value.length
                        ? parseInt(target.value.replace(/\D/g, ''))
                        : 0;
                      const rows = invoices.map((v) => {
                        if (v.id === row.id) {
                          v.amount_cents = value;
                        }
                        return v;
                      });
                      setValue('invoices', rows);
                      setValue(`${row.id}.amount_cents`, value);

                      const currentAmount = amountLeft(record, invoices, billing_group_balances);
                      const isTransactionBalanceLessThanZero = currentAmount < 0;
                      const isValueLessThanZero = value < 0;
                      const isValueGreaterThanInvoiceAmount = value > row.amount_due_cents;

                      const creditBalances = getValues().creditBalances || {};
                      const creditUsed = creditBalances?.[row.billing_group_id]?.[row.id];

                      const valuePlusCreditMoreThanAmountDue =
                        value + creditUsed > row.amount_due_cents;

                      setValue(
                        'blockNextButton',
                        isTransactionBalanceLessThanZero ||
                          isValueLessThanZero ||
                          isValueGreaterThanInvoiceAmount ||
                          valuePlusCreditMoreThanAmountDue,
                      );
                    }}
                  />
                )}
              </TableCell>
              {/* Internal Notes */}
              <TableCell height={75} width={250}>
                {invoices.map((v) => v.id).includes(row.id) && (
                  <TextField
                    disabled={
                      !invoices.map((v) => v.id).includes(row.id) || row.amount_due_cents === 0
                    }
                    style={{ paddingBottom: '23px', width: '250px' }}
                    label=""
                    onChange={({ target }) => {
                      const rows = invoices.map((v) => {
                        if (v.id === row.id) {
                          v.notes = target.value;
                        }
                        return v;
                      });
                      setValue('invoices', rows);
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default PayInvoicesTable;
