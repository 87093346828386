import React, { useEffect } from 'react';
import { TextInput, required } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { validateSlug } from 'utils/custom-validations';

const labelToSlug = (label: string): string => label?.toLowerCase().split(' ').join('_') || '';

const SlugInput = () => {
  const { setValue, watch } = useFormContext();
  const label = watch('label');

  useEffect(() => {
    setValue('slug', labelToSlug(label));
  }, [label]);

  return (
    <TextInput label="Slug" source="slug" validate={[required(), (value) => validateSlug(value)]} />
  );
};

export default SlugInput;
