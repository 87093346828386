import React from 'react';
import { ReferenceArrayInput, SelectArrayInput } from 'react-admin';

interface AccountLocationGroupInputProps {
  companyId: number;
  disabled: boolean;
}

const PER_PAGE = 1000;

const AccountLocationGroupInput = ({ companyId, disabled }: AccountLocationGroupInputProps) => {
  return (
    <ReferenceArrayInput
      source="group_node_ids"
      reference="LocationGroup"
      filter={{ company_id: companyId }}
      perPage={PER_PAGE}
    >
      <SelectArrayInput
        label="Location Groups"
        disabled={disabled}
        optionText={(o) => o.name}
        options={{
          MenuProps: {
            style: { maxHeight: 400 },
          },
        }}
      />
    </ReferenceArrayInput>
  );
};

export default AccountLocationGroupInput;
