import React from 'react';
import { NumberInput, TextInput } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import DateInput from 'components/DateInput';
import useCheckAccess from 'hooks/useCheckAccess';
import EditView from '../components/EditView';

const InvoicesEdit = () => {
  const checkAccess = useCheckAccess();

  return (
    <EditView invoice_type="invoice">
      {checkAccess('update', 'Invoice', 'due_date') && (
        <DateInput label="Due Date" source="due_date" />
      )}
      {checkAccess('update', 'Invoice', 'failed_count') && (
        <NumberInput
          label="Failed Count"
          source="failed_count"
          data-testid="invoices-edit-failed-count"
        />
      )}
      {checkAccess('update', 'Invoice', 'assignment_id') && (
        <NumberInput label="Assignment ID" source="assignment_id" />
      )}
      {checkAccess('update', 'Invoice', 'voucher_id') && (
        <TextInput label="Voucher ID" source="voucher_id" />
      )}
      {checkAccess('create', 'Invoice', 'voucher_processing_status') && (
        <EnumSelectInput label="Voucher Process Status" source="voucher_processing_status" />
      )}
    </EditView>
  );
};

export default InvoicesEdit;
