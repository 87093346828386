import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useRecordContext } from 'ra-core';
import { Show } from 'react-admin';

const InvoicesRedirect = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const { invoice_type, id } = record;
  const newPath = `/Invoice/${invoice_type}/${id}/show`;

  return (
    <Routes>
      <Route path="*" element={<Navigate to={newPath} replace />} />
    </Routes>
  );
};

const InvoicesShow = () => {
  return (
    <Show>
      <InvoicesRedirect />
    </Show>
  );
};

InvoicesShow.query = gql`
  query GET_ONE($id: Int!) {
    invoice(id: $id) {
      id
      invoice_type
      paid_date
      status
      created_at
      updated_at
      locked_at
      due_date
      total_cents
      amount_due_cents
      starts_time
      failed_reason
      failed_count
      timecard_ids
      ends_time
      processing_fee_cents
      stripe_invoice_number
      voucher_id
      voucher_processing_status
      assignment_id
      billing_group_id
      invoice_locked_balance_sheet_id
      invoice_paid_balance_sheet_id
      statement {
        id
        close_time
      }
      billing_group {
        plan
      }
      parent_invoice_links {
        parent_invoice_id
      }
      child_invoice_links {
        child_invoice_id
      }
      agency_id
    }
  }
`;

export default InvoicesShow;
