import React from 'react';
import inflection from 'inflection';
import { useRecordContext } from 'react-admin';

const InvoiceTitle = ({ invoice_type }) => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <span>
      {inflection.titleize(invoice_type)} #{record.id}
    </span>
  );
};

export default InvoiceTitle;
