import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import List from 'components/List';

const DisputedJobBillingsList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="job_billing_id" />
      <TextField source="dispute_id" />
    </Datagrid>
  </List>
);

DisputedJobBillingsList.query = gql`
  query GET_LIST($input: DisputedJobBillingQueryInput) {
    _disputedJobBillingsMeta(input: $input) {
      count
    }
    disputedJobBillings(input: $input) {
      id
      job_billing_id
      dispute {
        id
        status
      }
      job_irregularity {
        label
      }
    }
  }
`;

export default DisputedJobBillingsList;
