import { FunctionField, Labeled, NumberInput, useGetList, required, minValue } from 'react-admin';
import React, { useCallback, useEffect, useRef } from 'react';
import { DisplayHelpers } from 'utils/helpers';
import Heading from 'components/Heading';
import MoneyInput from 'components/MoneyInput';
import { TotalPayPackage } from '../TotalPayPackage';
import useAssignmentCalculator from '../useAssignmentCalculator';
import { useFormContext } from 'react-hook-form';

const { money, rate } = DisplayHelpers;

const MIN_PAYOUT_BASE_RATE = 1000;

const PayoutRatesTab = () => {
  const { setValue, watch } = useFormContext();
  const values = watch();

  const { isLoading: isLoadingRateSetting, data: rateSettingsList } = useGetList(
    'LocationPositionRateSetting',
    {
      filter: {
        max_only: true,
        include_parents: true,
        location_id: values.primary_location_id,
        search: {
          position_ids: values.position_ids,
          specialty_ids: values.specialty_ids,
        },
      },
    },
  );
  const rateSetting =
    rateSettingsList && rateSettingsList.length > 0 ? rateSettingsList[0].settings : undefined;

  const {
    payoutValues,
    totalNonTaxablePayCents,
    totalPayCents,
    totalTaxablePayCents,
    numberOfWeeks,
    profit,
    hours_per_shift,
    number_of_shifts_per_week,
    status,
    calculatedLodging,
    calculatedMie,
    payout_base_rate,
    payoutOnCallHourlyRateCents,
    totalWeeklyPayCents,
    weeklyNonTaxableIncomeCents,
    weeklyTaxableIncomeCents,
    callBackRateCents,
    is_w2,
    calculatedBonus,
    calculatedTravelReimbursement,
    calculatedGrossMargin,
  } = useAssignmentCalculator(values, rateSetting);

  const max_lodging_per_diem_cents = values?.max_lodging_per_diem_cents || 0;
  const max_mie_per_diem_cents = values?.max_mie_per_diem_cents || 0;

  const { validate: validateTravel } = useValidateTravelReimbursement(numberOfWeeks);
  const { validate: validateBaseHourlyRate } = useValidateBaseHourlyRate();
  const { validate: validateTaxableHourlyRate } = useValidateTaxableHourlyRate(
    is_w2,
    values.min_taxable_rate_cents,
  );
  const { validateOvertime, validateDoubleOvertime } = useValidatePayoutMultipliers(
    rateSetting,
    is_w2,
  );

  /************************************/
  /*  side effects from form form.change  */
  /************************************/
  // this one is the most complicated side effect...
  // if a user modifies a field that form.change the calculated values for stipends we need to form.change the form fields to the newly calculated values,

  // update the payout rates when the assignment calculator calculates new values
  const firstRun = useRef(true);
  useEffect(() => {
    const taxableRate = payoutValues.taxableRates.taxable_rate;
    if (!firstRun.current) {
      setValue('payout_taxable_hourly_rate_cents', taxableRate);
      setValue('payout_base_hourly_rate_cents', payout_base_rate);
    }
    firstRun.current = false;
  }, [payoutValues.taxableRates.taxable_rate, payout_base_rate, setValue]);

  useEffect(() => {
    setValue('payout_on_call_hourly_rate_cents', payoutOnCallHourlyRateCents);
  }, [setValue, payoutOnCallHourlyRateCents]);

  useEffect(() => {
    setValue('gross_profit_cents', profit);
  }, [setValue, profit]);

  const firstGrossMarginRun = useRef(true);
  useEffect(() => {
    if (!firstGrossMarginRun.current) {
      setValue('gross_margin', calculatedGrossMargin);
    }
    if (!isLoadingRateSetting) {
      firstGrossMarginRun.current = false;
    }
  }, [setValue, calculatedGrossMargin, isLoadingRateSetting]);

  /* ========== START local_only Side Effects ========== */
  // local_only assignments should have no stipends, and no one time bonus
  const firstLocalOnlyRun = useRef(true);
  useEffect(() => {
    if (!firstLocalOnlyRun.current && is_w2) {
      if (values.local_only) {
        setValue('lodging_per_diem_cents', 0);
        setValue('mie_per_diem_cents', 0);
        setValue('one_time_bonus_burden_cents', 0);
        setValue('travel_reimbursement_burden_cents', 0);
      } else {
        setValue('lodging_per_diem_cents', calculatedLodging);
        setValue('mie_per_diem_cents', calculatedMie);
        setValue('one_time_bonus_burden_cents', calculatedBonus);
        setValue('travel_reimbursement_burden_cents', calculatedTravelReimbursement);
      }
    }
    firstLocalOnlyRun.current = false;
    /* The above are default values based on local_only & w2 changes.
       They can be overridden by later changes in specific fields */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.local_only, setValue, is_w2]);

  // update lodging to calculated value on calculation change when not local_only
  const firstLodgingRun = useRef(true);
  useEffect(() => {
    if (!firstLodgingRun.current && !values.local_only) {
      setValue('lodging_per_diem_cents', calculatedLodging);
    }
    firstLodgingRun.current = false;
  }, [calculatedLodging, setValue, values.local_only]);

  // update m&ie to calculated value on calculation change when not local_only
  const firstMieRun = useRef(true);
  useEffect(() => {
    if (!firstMieRun.current && !values.local_only) {
      setValue('mie_per_diem_cents', calculatedMie);
    }
    firstMieRun.current = false;
  }, [setValue, calculatedMie, values.local_only]);

  // update bonus to calculated value on calculation change when not local_only
  const firstBonusRun = useRef(true);
  useEffect(() => {
    if (!firstBonusRun.current && !values.local_only) {
      setValue('one_time_bonus_burden_cents', calculatedBonus);
    }
    firstBonusRun.current = false;
  }, [setValue, calculatedBonus, values.local_only]);

  // update travel reimbursement to calculated value on calculation change when not local_only
  const firstTravelRun = useRef(true);
  useEffect(() => {
    if (!firstTravelRun.current && !values.local_only && values.is_w2) {
      setValue('travel_reimbursement_burden_cents', calculatedTravelReimbursement);
    }
    firstTravelRun.current = false;
  }, [setValue, calculatedTravelReimbursement, values.is_w2, values.local_only]);
  /* ========== END local_only Side Effects ========== */

  let grossMarginHelperText = '';
  if (is_w2 && values.gross_margin < 0.16) {
    grossMarginHelperText = 'The suggested minimum margin is 16%';
  }
  if (!is_w2 && values.gross_margin < 0.14) {
    grossMarginHelperText = 'The suggested minimum margin is 14%';
  }

  if (typeof status === 'undefined') {
    return null;
  }

  return (
    <>
      <Labeled label="Total weeks">
        <FunctionField
          render={() => numberOfWeeks.toLocaleString('en-US', { maximumFractionDigits: 2 })}
          data-testid="assignments_edit-payout_total_weeks"
        />
      </Labeled>
      <Labeled label="Hours per shift">
        <FunctionField
          render={() => hours_per_shift}
          data-testid="assignments_edit-payout_hours_per_shift"
        />
      </Labeled>
      <Labeled label="Shifts per week">
        <FunctionField
          render={() => number_of_shifts_per_week}
          data-testid="assignments_edit-payout_number_of_shifts_per_week"
        />
      </Labeled>
      <Labeled label="Hours per week">
        <FunctionField
          render={() => payoutValues.hours.total_time}
          data-testid="assignments_edit-payout_hours_per_week"
        />
      </Labeled>

      <NumberInput
        validate={minValue(0, 'Cannot be less than zero')}
        source="payout_max_daily_regular_hours"
        data-testid="assignments_edit-charge_max_daily_regular_hours"
      />
      <NumberInput
        validate={minValue(0, 'Cannot be less than zero')}
        source="payout_max_daily_ot_hours"
        data-testid="assignments_edit-charge_max_daily_ot_hours"
      />
      <NumberInput
        validate={minValue(0, 'Cannot be less than zero')}
        source="payout_max_weekly_regular_hours"
        data-testid="assignments_edit-charge_max_weekly_regular_hours"
      />

      <Heading>Professional Pay Settings</Heading>
      <NumberInput
        label="Gross Margin"
        source="gross_margin"
        helperText={grossMarginHelperText}
        data-testid="assignments_edit-payout_gross_margin"
      />
      <NumberInput
        label="On call Fee percent"
        source="on_call_fee_percent"
        data-testid="assignments_edit-payout_on_call_fee_percent"
      />
      <NumberInput
        source="payout_overtime_multiplier"
        data-testid="assignments_edit-payout_overtime_multiplier"
        validate={[validateOvertime, minValue(1.0, 'Cannot be less than 1.0')]}
      />
      <NumberInput
        source="payout_double_overtime_multiplier"
        data-testid="assignments_edit-payout_double_overtime_multiplier"
        validate={[validateDoubleOvertime, minValue(1.0, 'Cannot be less than 1.0')]}
      />
      <NumberInput
        source="payout_callback_multiplier"
        validate={[required(), minValue(1.0, 'Cannot be less than 1.0')]}
        data-testid="assignments_edit-payout_callback_multiplier"
      />
      {is_w2 && !values.local_only && (
        <MoneyInput
          style={{ width: 320 }}
          label={`Lodging Per Diem (Max rate: ${rate(max_lodging_per_diem_cents, 'day')})`}
          source="lodging_per_diem_cents"
        />
      )}
      {is_w2 && !values.local_only && (
        <MoneyInput
          style={{ width: 320 }}
          label={`M&IE Per Diem (Max rate: ${rate(max_mie_per_diem_cents, 'day')})`}
          source="mie_per_diem_cents"
        />
      )}

      <TotalPayPackage
        pay={totalPayCents}
        taxablePay={totalTaxablePayCents}
        nonTaxable={totalNonTaxablePayCents}
      />

      <Heading>Professional Weekly Pay Package</Heading>
      <Labeled label="Total weekly pay">
        <FunctionField
          render={() => money(totalWeeklyPayCents)}
          data-testid="assignments_edit-payout_total_weekly_pay"
        />
      </Labeled>
      {is_w2 && (
        <Labeled label="Weekly taxable income">
          <FunctionField
            render={() => money(weeklyTaxableIncomeCents)}
            data-testid="assignments_edit-payout_weekly_taxable_income"
          />
        </Labeled>
      )}
      {is_w2 && (
        <Labeled label="Weekly non-taxable income">
          <FunctionField
            render={() => money(weeklyNonTaxableIncomeCents)}
            data-testid="assignments_edit-payout_weekly_non_taxable_income"
          />
        </Labeled>
      )}

      <Heading>Professional Hourly Pay Package</Heading>
      <MoneyInput
        disabled
        label="Base hourly rate"
        source="payout_base_hourly_rate_cents"
        validate={validateBaseHourlyRate}
      />
      <Labeled label="Blended hourly rate">
        <FunctionField
          render={() => money(payoutValues.rates.blended_rate)}
          data-testid="assignments_edit-payout_blended_hourly_rate"
        />
      </Labeled>
      <MoneyInput disabled label="On call rate" source="payout_on_call_hourly_rate_cents" />
      <Labeled label="Call back rate">
        <FunctionField
          render={() => money(callBackRateCents)}
          data-testid="assignments_edit-payout_call_back_rate"
        />
      </Labeled>
      {is_w2 && (
        <MoneyInput
          disabled
          label="Taxable base rate"
          source="payout_taxable_hourly_rate_cents"
          validate={validateTaxableHourlyRate}
        />
      )}
      {is_w2 && (
        <Labeled label="Taxable blended rate">
          <FunctionField
            render={() => money(payoutValues.taxableRates.taxable_blended_rate)}
            data-testid="assignments_edit-payout_taxable_blended_rate"
          />
        </Labeled>
      )}

      <Heading>Burdens</Heading>

      <NumberInput
        label="Federal unemployment multiplier"
        source="federal_unemployment_percent_burden_multiplier"
        data-testid="assignments_edit-federal_unemployment_percent_burden_multiplier"
      />
      <NumberInput
        label="State unemployment multiplier"
        source="state_unemployment_percent_burden_multiplier"
        data-testid="assignments_edit-state_unemployment_percent_burden_multiplier"
      />
      <NumberInput
        disabled
        label="Social Security burden multiplier"
        source="social_security_burden_multiplier"
        data-testid="assignments_edit-social_security_burden_multiplier"
      />
      <NumberInput
        disabled
        label="Medicare burden multiplier"
        source="medicare_burden_multiplier"
        data-testid="assignments_edit-medicare_burden_multiplier"
      />
      <MoneyInput label="Compliance" source="compliance_burden_cents" />
      <NumberInput
        label="Non billable orientation hours"
        source="non_billable_orientation_burden_hours"
        data-testid="assignments_edit-non_billable_orientation_burden_hours"
      />
      <NumberInput
        label="Billable orientation hours"
        source="billable_orientation_hours"
        data-testid="assignments_edit-billable_orientation_hours"
      />
      <NumberInput
        label="VMS fee multiplier"
        source="vms_fee_burden_multiplier"
        data-testid="assignments_edit-vms_fee_burden_multiplier"
      />
      <MoneyInput label="ADP fee (for assignment)" source="adp_fee_burden_cents" />
      <MoneyInput
        label="Travel reimbursement (weekly)"
        source="travel_reimbursement_burden_cents"
        validate={validateTravel}
      />
      <MoneyInput label="Medical (Per Week)" source="medical_burden_cents" />
      <MoneyInput label="One time bonus" source="one_time_bonus_burden_cents" />
      <MoneyInput
        disabled
        label="Bonus Tier 2 Travel Amount"
        source="assignment_bonus_tier2_travel_amount_cents"
      />
      <MoneyInput
        disabled
        label="Bonus Tier 2 Travel TCV Amount"
        source="assignment_bonus_tier2_travel_amount_tcv_cents"
      />
      <MoneyInput
        disabled
        label="Bonus Tier 1 Travel Amount"
        source="assignment_bonus_tier1_travel_amount_cents"
      />
      <MoneyInput
        disabled
        label="Bonus Tier 1 Travel TCV Amount"
        source="assignment_bonus_tier1_travel_amount_tcv_cents"
      />

      <Heading>Profit</Heading>
      <MoneyInput disabled label="Gross profit" source="gross_profit_cents" />
    </>
  );
};

export default PayoutRatesTab;

function useValidateTravelReimbursement(numberOfWeeks) {
  const TOTAL_TRAVEL_REIMBURSEMENT_THRESHOLD = 500e2;
  const validate = useCallback(
    (value) => {
      // if (Permission.is.admin(permissions)) {
      //   return undefined;
      // }
      // TODO: Check if this is the correct permission to show validation
      return numberOfWeeks * value > TOTAL_TRAVEL_REIMBURSEMENT_THRESHOLD
        ? `Total travel reimbursement is greater ${money(TOTAL_TRAVEL_REIMBURSEMENT_THRESHOLD)}`
        : undefined;
    },
    [numberOfWeeks],
  );
  return { validate };
}

function useValidateBaseHourlyRate() {
  const validate = useCallback((value) => {
    if (value < MIN_PAYOUT_BASE_RATE) {
      return `Payout base rate is below ${money(MIN_PAYOUT_BASE_RATE)}`;
    }
    return undefined;
  }, []);
  return { validate };
}

function useValidateTaxableHourlyRate(is_w2, min_taxable_rate_cents) {
  const validate = useCallback(
    (value) => {
      if (is_w2 && value < min_taxable_rate_cents) {
        return `Taxable base rate is below the allowable amount of ${money(
          min_taxable_rate_cents,
        )} per hour`;
      }
      return undefined;
    },
    [is_w2, min_taxable_rate_cents],
  );
  return { validate };
}

function useValidatePayoutMultipliers(rateSetting: any, is_w2: boolean) {
  const w2RateSettings = rateSetting?.w2;
  const validateOvertime = useCallback(
    (value) => {
      return is_w2 && value < w2RateSettings?.payout_overtime_multiplier
        ? `Multiplier cannot be less than ${w2RateSettings?.payout_overtime_multiplier}`
        : undefined;
    },
    [w2RateSettings?.payout_overtime_multiplier, is_w2],
  );

  const validateDoubleOvertime = useCallback(
    (value) => {
      return is_w2 && value < w2RateSettings?.payout_double_overtime_multiplier
        ? `Multiplier cannot be less than ${w2RateSettings?.payout_double_overtime_multiplier}`
        : undefined;
    },
    [w2RateSettings?.payout_double_overtime_multiplier, is_w2],
  );

  return { validateOvertime, validateDoubleOvertime };
}
