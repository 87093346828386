import React from 'react';
import {
  TextField,
  Datagrid,
  SimpleShowLayout,
  Pagination,
  ReferenceManyField,
  FunctionField,
  useRecordContext,
} from 'react-admin';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/date';
import LinkField from 'components/LinkField';
import DateTimeField from 'components/DateTimeField';

const DocumentsTabActions = () => {
  const record = useRecordContext();
  return (
    <Button
      component={Link}
      to="/AgencyQualification/create"
      state={{ record: { agency_id: record?.id } }}
      variant="contained"
      size="small"
      sx={{ width: 205 }}
    >
      Add Qualification
    </Button>
  );
};

const DocumentsTab = () => (
  <SimpleShowLayout>
    <DocumentsTabActions />

    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="AgencyQualification"
      target="agency_id"
    >
      <Datagrid bulkActionButtons={false}>
        <LinkField label="ID" source="id" />
        <TextField label="Credential" source="qualification.long_name" />
        <TextField label="Type" source="qualification.qualification_type" />
        <FunctionField
          label="State"
          render={(v) =>
            v?.agency_qualification_attributes?.find((attribute) => !!attribute.state)?.state
          }
        />
        <FunctionField
          label="Original Issue Date"
          render={(v) =>
            formatDate(
              v?.agency_qualification_attributes?.find((attribute) =>
                attribute.qualification_attribute?.slug?.includes('original_issue_date'),
              )?.value,
            )
          }
        />
        <FunctionField
          label="Expires"
          render={(v) =>
            formatDate(
              v?.agency_qualification_attributes?.find((attribute) =>
                attribute.type?.includes('ExpirationDate'),
              )?.value,
            )
          }
        />
        <FunctionField
          label="Status"
          render={(v) => (
            <Typography color={v?.status ? 'inherit' : 'error'}>
              {v?.status ?? 'Missing'}
            </Typography>
          )}
        />
        <DateTimeField source="created_at" />
        <DateTimeField source="updated_at" />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default DocumentsTab;
