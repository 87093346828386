import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin';
import DateInput from 'components/DateInput';
import { ENUMS } from 'components/Enum/enums';
import ProfessionalInput from 'components/ProfessionalInput';
import StateInput from 'components/StateInput';

const degreeOptions = Object.keys(ENUMS.Education.degree_type)
  .filter((i) => isNaN(+i))
  .map((key) => ({
    name: key,
    id: ENUMS.Education.degree_type[key],
  }));

const EducationsEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <ProfessionalInput />
      <TextInput label="School Name" source="school_name" />
      <SelectInput label="Degree Type" source="degree_type" choices={degreeOptions} />
      <TextInput label="Field Of Study" source="field_of_study" />
      <TextInput label="City" source="city" />
      <StateInput />
      <DateInput label="Start Date" source="start_date" />
      <DateInput label="End Date" source="end_date" />
    </SimpleForm>
  </Edit>
);

export default EducationsEdit;
