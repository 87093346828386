import React from 'react';
import { EditButton, TopToolbar, useRecordContext } from 'react-admin';

const PositionRateSettingsShowActions = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <TopToolbar>
      <EditButton />
    </TopToolbar>
  );
};

export default PositionRateSettingsShowActions;
