import React, { useEffect } from 'react';
import { Error as RAError } from 'react-admin';
import { ProductionEnvironments } from './enums';

declare global {
  interface Window {
    Rollbar?: {
      error(err: Error, payload: unknown): void;
      warning(errorLabe: string, payload: unknown): void;
    };
  }
}

export const Error = (props) => {
  const { error, errorInfo } = props;
  useEffect(() => {
    if (
      error &&
      Object.values(ProductionEnvironments).includes(process.env.NODE_ENV as ProductionEnvironments)
    ) {
      window.Rollbar?.error(error, { errorInfo });
    }
  }, [error, errorInfo]);
  return <RAError {...props} />;
};
