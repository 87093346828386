import React from 'react';
import { gql } from '@apollo/client';
import { Show } from 'react-admin';
import PositionRateSettingsShow from '../PositionRateSettingsShow';
import PositionRateSettingsShowActions from '../PositionRateSettingsShowActions';

const LocationPositionRateSettingShow = () => (
  <Show actions={<PositionRateSettingsShowActions />}>
    <PositionRateSettingsShow />
  </Show>
);

LocationPositionRateSettingShow.query = gql`
  query locationPositionRateSetting($id: Int!, $include_parents: Boolean = true) {
    locationPositionRateSetting(id: $id, include_parents: $include_parents) {
      id
      active
      audits {
        action
        account {
          name
        }
        audited_changes
        created_at
        id
        user_id
      }
      created_at
      location_id
      location_position_rate_setting {
        agency_charge_rate_cents
        assignment_bonus_tier1_travel_amount_cents
        assignment_bonus_tier1_travel_amount_tcv_cents
        assignment_bonus_tier2_travel_amount_cents
        assignment_bonus_tier2_travel_amount_tcv_cents
        can_post_assignment
        can_post_job
        charge_on_call_hourly_rate_cents
        charge_on_call_hourly_rate_currency
        charge_overtime_multiplier
        charge_rate_cents
        fee_percent
        margin_percent_1099
        margin_percent_w2_local
        margin_percent_w2_tcv
        margin_percent_w2_travel
        margin_w2_tcv_cents
        on_call_fee_percent
        payout_on_call_hourly_rate_cents
        payout_on_call_hourly_rate_currency
        payout_overtime_multiplier
        ten99_assignment
        ten99_per_diem
        w2
        w2_fee_percent
        w2_per_diem
      }
      position {
        id
        display_name
      }
      position_id
      settings {
        agency_charge_rate_cents
        assignment_bonus_tier1_travel_amount_cents
        assignment_bonus_tier1_travel_amount_tcv_cents
        assignment_bonus_tier2_travel_amount_cents
        assignment_bonus_tier2_travel_amount_tcv_cents
        can_post_assignment
        can_post_job
        charge_on_call_hourly_rate_cents
        charge_on_call_hourly_rate_currency
        charge_overtime_multiplier
        charge_rate_cents
        fee_percent
        margin_percent_1099
        margin_percent_w2_local
        margin_percent_w2_tcv
        margin_percent_w2_travel
        margin_w2_tcv_cents
        on_call_fee_percent
        payout_on_call_hourly_rate_cents
        payout_on_call_hourly_rate_currency
        payout_overtime_multiplier
        ten99_assignment
        ten99_per_diem
        w2
        w2_fee_percent
        w2_per_diem
      }
      specialty {
        id
        label
      }
      specialty_id
      updated_at
    }
  }
`;

export default LocationPositionRateSettingShow;
