import React from 'react';
import { useDataProvider } from 'react-admin';
import { useQuery } from 'react-query';
import { useRecordContext } from 'ra-core';
import { Typography, CircularProgress } from '@mui/material';
import { ICondition } from '@medely/types';

const credentialConditionTypes = new Set<string>(['license', 'document', 'certification']);

type OnboardingStep = { label: string; isMissing: (missingConditions: ICondition[]) => boolean };

const onboardingSteps: OnboardingStep[] = [
  {
    label: 'Address Needed',
    isMissing: (missingConditions) =>
      missingConditions.some((missingCondition) => missingCondition.slug === 'home_address'),
  },
  {
    label: 'Background Check Needed',
    isMissing: (missingConditions) =>
      missingConditions.some((missingCondition) => missingCondition.slug === 'background_check'),
  },
  {
    label: 'Direct Deposit Needed',
    isMissing: (missingConditions) =>
      missingConditions.some((missingCondition) => missingCondition.slug === 'direct_deposit'),
  },
];

const credentialsStep: OnboardingStep = {
  label: 'Credentials Needed',
  isMissing: (missingConditions) =>
    missingConditions.some((missingCondition) =>
      credentialConditionTypes.has(missingCondition.condition_type),
    ),
};

const skillsStep: OnboardingStep = {
  label: 'Missing Skills',
  isMissing: (missingConditions) =>
    missingConditions.some((missingCondition) => missingCondition.condition_type === 'typeform'),
};

const saasOnboardingSteps = [credentialsStep, skillsStep];

const MissingOnboardingStepsField = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const isSaas = Boolean(record?.account?.company_id);

  const { data = { data: [] }, isLoading } = useQuery(
    ['professional', 'conditions', { professional_id: record?.id }],
    () =>
      dataProvider.getList('Condition', {
        pagination: {
          page: 1,
          perPage: 60,
        },
        sort: {
          field: 'id',
          order: 'desc',
        },
        filter: {
          search: {
            missing_conditions: {
              for_on_boarding: true,
              for_application: false,
              professional_id: record?.id,
            },
          },
        },
      }),
  );
  const missingConditions = data.data as ICondition[];

  const steps = isSaas ? saasOnboardingSteps : [...onboardingSteps, credentialsStep, skillsStep];
  const missedRequirements = steps
    .filter((step) => step.isMissing(missingConditions))
    .map((step) => step.label)
    .join(', ');
  if (isLoading) {
    return <CircularProgress />;
  }
  return <Typography variant="body2">{missedRequirements}</Typography>;
};

MissingOnboardingStepsField.defaultProps = {
  label: 'Missing Onboarding Steps',
  sortable: false,
};

export default MissingOnboardingStepsField;
