import React from 'react';
import { gql } from '@apollo/client';
import { Show, TabbedShowLayout, Tab, TabbedShowLayoutTabs } from 'react-admin';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';

const AssignmentProfileShow = () => (
  <Show>
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
      <Tab label="Info">
        <IdField />
        <LinkField label="Assignment ID" source="assignment_id" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

AssignmentProfileShow.query = gql`
  query GET_ONE($id: Int!) {
    assignmentProfile(id: $id) {
      id
      assignment_id
    }
  }
`;

export default AssignmentProfileShow;
