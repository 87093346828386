import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';

const AccountBillingGroupsList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
    </Datagrid>
  </List>
);

AccountBillingGroupsList.query = gql`
  query GET_LIST($input: AccountBillingGroupQueryInput) {
    _accountBillingGroupsMeta(input: $input) {
      count
    }
    accountBillingGroups(input: $input) {
      id
      account_id
      account {
        id
        name
        email
        permission_groups {
          id
          display_name
        }
        status
      }
      billing_group_id
      billing_group {
        id
        name
        status
        plan
        billing
        auto_pay
      }
    }
  }
`;

export default AccountBillingGroupsList;
