import React from 'react';
import { FunctionField, Link, useRecordContext } from 'react-admin';

export const SalesAssociateField = ({ label = 'Sales Associate', ...rest }) => {
  const record = useRecordContext();
  const salesAssociate = record?.assignment_locations?.find((al) => al.primary_location)?.location
    ?.sales_associate;
  return (
    <FunctionField
      {...rest}
      label={label}
      render={() => {
        if (salesAssociate) {
          return (
            <Link to={`/Administrator/${salesAssociate.id}/show`}>
              {salesAssociate.account.name}
            </Link>
          );
        }
        return null;
      }}
    />
  );
};
