import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import List from 'components/List';

const ApprovalGroupsList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="company_id" />
    </Datagrid>
  </List>
);

ApprovalGroupsList.query = gql`
  query GET_APPROVAL_GROUPS($input: ApprovalGroupQueryInput) {
    approvalGroups(input: $input) {
      id
      account_ids
      accounts {
        id
      }
      name
      company_id
      deleted_at
    }
  }
`;

export default ApprovalGroupsList;
