import React from 'react';
import { useRecordContext, type RaRecord } from 'react-admin';

interface IAttachmentFieldProps {
  record?: RaRecord;
  source: string;
}

export const AttachmentField = ({ record: initialRecord, source }: IAttachmentFieldProps) => {
  const record = useRecordContext();
  const value = (record || initialRecord)[source];
  return (
    <a href={value} target="_blank" download="attachment">
      Download Attachment
    </a>
  );
};
