import React from 'react';
import { BooleanInput, required, SimpleForm } from 'react-admin';
import { Box, Typography } from '@medely/web-components';
import { useFormContext } from 'react-hook-form';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import ConditionInput from 'components/ConditionInput';

const ApplicationRequirementFormFields = () => {
  const { watch } = useFormContext();
  const values = watch();
  const isEdit = Boolean(values.id);
  const isPositionChosen = Boolean(values.position_id);
  const isKindChosen = Boolean(values.kind);
  const isSpecialtyChosen = Boolean(values.specialty_id);
  const isSpecialtyVisible = isEdit ? isSpecialtyChosen || isPositionChosen : true;
  return (
    <>
      <ConditionInput validate={required()} fullWidth />
      <Box mt={-1} mb={-2}>
        <BooleanInput source="medely" defaultValue={true} fullWidth disabled={true} />
      </Box>
      <Box
        width="100%"
        pt={2}
        mb={1}
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.grey[200]}`,
        })}
      >
        <Box my={1}>
          <Typography variant="body2">Select only one of the following:</Typography>
        </Box>
        <EnumSelectInput fullWidth source="kind" disabled={isPositionChosen || isSpecialtyChosen} />
        <PositionInput fullWidth disabled={isSpecialtyChosen || isKindChosen} />
        <Box ml={3}>
          {isSpecialtyVisible && (
            <SpecialtyInput
              positionSource={
                isEdit ? (isPositionChosen ? 'position_id' : undefined) : 'position_id'
              }
              fullWidth
              emptyValue={null}
              disabled={isKindChosen}
              optionText="label"
            />
          )}
        </Box>
      </Box>
    </>
  );
};

const ApplicationRequirementForm = () => (
  <SimpleForm>
    <ApplicationRequirementFormFields />
  </SimpleForm>
);

export default ApplicationRequirementForm;
