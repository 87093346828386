import { downloadCSV } from 'react-admin';
import jsonExport from 'jsonexport/dist';
import { calcTotalCharge } from './rateSettingsCalculations';

export const buildCustomExporter = (rateSettings, filenamePrefix: string) => {
  const rateSettingsToExport = rateSettings.map((aRateSetting) => {
    const {
      id,
      location_position_rate_setting_active,
      market_position_rate_setting_active,
      state_position_rate_setting_active,
      position,
      specialty,
      settings,
    } = aRateSetting;
    const { ten99_per_diem, w2 } = settings;

    // Create object to contain export fields
    const rateSettingToExport = {
      id,
    };

    // Add state/market/location level specific fields
    if (id.startsWith('state')) {
      Object.assign(rateSettingToExport, {
        Active: state_position_rate_setting_active,
      });
    } else if (id.startsWith('market')) {
      Object.assign(rateSettingToExport, {
        Active: market_position_rate_setting_active,
      });
    } else {
      Object.assign(rateSettingToExport, {
        Active: location_position_rate_setting_active,
      });
    }

    // Add all the rest of the fields.
    Object.assign(rateSettingToExport, {
      'Active for Job': settings?.can_post_job,
      'Active for Assignment': settings?.can_post_assignment,
      'position.display_name': position?.display_name,
      'position.id': position?.id,
      'specialty.label': specialty?.label,
      'specialty.id': specialty?.id,
      'settings.charge_rate_cents': settings?.charge_rate_cents,
      'Charge Blended 12hr Rate': Math.round(
        calcTotalCharge(
          settings?.charge_rate_cents,
          12,
          ten99_per_diem?.charge_max_daily_regular_hours,
          ten99_per_diem?.charge_overtime_multiplier,
        ) / 12,
      ),
      'settings.margin_percent_1099': settings?.margin_percent_1099,
      'settings.charge_on_call_hourly_rate_cents': settings?.charge_on_call_hourly_rate_cents,
      'settings.on_call_fee_percent': settings?.on_call_fee_percent,
      // 1099 Charge settings
      '1099 charge_max_daily_regular_hours': ten99_per_diem?.charge_max_daily_regular_hours,
      '1099 charge_max_daily_overtime_hours': ten99_per_diem?.charge_max_daily_overtime_hours,
      '1099 charge_overtime_multiplier': ten99_per_diem?.charge_overtime_multiplier,
      '1099 charge_double_overtime_multiplier': ten99_per_diem?.charge_double_overtime_multiplier,
      '1099 charge_max_weekly_regular_hours': ten99_per_diem?.charge_max_weekly_regular_hours,
      '1099 charge_seventh_day_rule_enabled': ten99_per_diem?.charge_seventh_day_rule_enabled,
      // 1099 Payout settings
      '1099 payout_max_daily_regular_hours': ten99_per_diem?.payout_max_daily_regular_hours,
      '1099 payout_max_daily_overtime_hours': ten99_per_diem?.payout_max_daily_overtime_hours,
      '1099 payout_overtime_multiplier': ten99_per_diem?.payout_overtime_multiplier,
      '1099 payout_double_overtime_multiplier': ten99_per_diem?.payout_double_overtime_multiplier,
      '1099 payout_max_weekly_regular_hours': ten99_per_diem?.payout_max_weekly_regular_hours,
      '1099 payout_seventh_day_rule_enabled': ten99_per_diem?.payout_seventh_day_rule_enabled,
      // W2 settings
      'W2 Charge Overtime Multiplier': w2?.charge_overtime_multiplier,
      'W2 Charge Double Time Multiplier': w2?.charge_double_overtime_multiplier,
      'W2 Payout Overtime Multiplier': w2?.payout_overtime_multiplier,
      'W2 Payout Double Time Multiplier': w2?.payout_double_overtime_multiplier,
      'settings.margin_percent_w2_local': settings?.margin_percent_w2_local,
      'settings.margin_percent_w2_travel': settings?.margin_percent_w2_travel,
      'settings.margin_percent_w2_tcv': settings?.margin_percent_w2_tcv,
      'settings.margin_w2_tcv_cents': settings?.margin_w2_tcv_cents,
      // Assignment settings
      'settings.assignment_bonus_tier2_travel_amount_cents':
        settings?.assignment_bonus_tier2_travel_amount_cents,
      'settings.assignment_bonus_tier2_travel_amount_tcv_cents':
        settings?.assignment_bonus_tier2_travel_amount_tcv_cents,
      'settings.assignment_bonus_tier1_travel_amount_cents':
        settings?.assignment_bonus_tier1_travel_amount_cents,
      'settings.assignment_bonus_tier1_travel_amount_tcv_cents':
        settings?.assignment_bonus_tier1_travel_amount_tcv_cents,
    });

    return rateSettingToExport;
  });

  return jsonExport(rateSettingsToExport, {}, (_, csv) =>
    downloadCSV(csv, `${filenamePrefix} Position Rate Settings`),
  );
};
