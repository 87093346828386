import React from 'react';
import { Edit, SimpleForm, NumberInput, TextInput, BooleanInput } from 'react-admin';
import DisableableDateInput from 'components/DisableableDateInput';
import DateInput from 'components/DateInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const ProfessionalReferencesEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <NumberInput source="professional_profile_id" />
      <BooleanInput source="contact_me" />
      <BooleanInput source="receive_emails" />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <TextInput source="title" />
      <TextInput source="email" />
      <TextInput source="phone_number" />
      <TextInput source="location" />
      <TextInput source="department" />
      <BooleanInput source="rehire_eligibility" />
      <EnumSelectInput source="status" />
      <DateInput source="date_obtained" />
      <DateInput source="start_date" />
      <DisableableDateInput dateSource="end_date" disableSource="current_position" />
      <TextInput source="comments" fullWidth multiline />
      <NumberInput label="Work History ID" source="work_history_id" />
    </SimpleForm>
  </Edit>
);

export default ProfessionalReferencesEdit;
