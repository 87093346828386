import List from 'components/List';
import React from 'react';
import { gql } from '@apollo/client';
import { CreateDialog, EditDialog } from '@react-admin/ra-form-layout';
import {
  BooleanField,
  Datagrid,
  EditButton,
  ReferenceField,
  TextField,
  DeleteWithConfirmButton,
} from 'react-admin';
import IdField from 'components/IdField';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import ConditionInput from 'components/ConditionInput';
import ApplicationRequirementForm from 'resources/application_requirements/ApplicationRequirementForm';

const transformPayload = (payload) => ({
  ...payload,
  position_id: payload.specialty_id ? null : payload.position_id,
  specialty_id: payload.specialty_id || null,
});

const ApplicationRequirementListFilters = [
  <EnumSelectInput alwaysOn source="kind" />,
  <PositionInput alwaysOn source="position_id" />,
  <SpecialtyInput alwaysOn source="specialty_id" />,
  <ConditionInput alwaysOn />,
];

const ApplicationRequirementList = () => (
  <List hasCreate exporter={false} filters={ApplicationRequirementListFilters}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <BooleanField label="Is Medely" source="medely" />
      <ReferenceField label="State" source="state_id" reference="State" link="show">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="base" />
      <TextField source="kind" />
      <ReferenceField label="Position" source="position_id" reference="Position" link="show">
        <TextField source="display_name" />
      </ReferenceField>
      <ReferenceField label="Specialty" source="specialty_id" reference="Specialty" link="show">
        <TextField source="label" />
      </ReferenceField>
      <ReferenceField label="Condition" source="condition_id" reference="Condition" link="show">
        <TextField source="label" />
      </ReferenceField>
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
    <CreateDialog fullWidth transform={transformPayload}>
      <ApplicationRequirementForm />
    </CreateDialog>
    <EditDialog mutationMode="pessimistic" fullWidth transform={transformPayload}>
      <ApplicationRequirementForm />
    </EditDialog>
  </List>
);

ApplicationRequirementList.query = gql`
  query GET_APPLICATION_REQUIREMENTS_LIST($input: ApplicationRequirementQueryInput) {
    applicationRequirements(input: $input) {
      id
      medely
      state_id
      base
      kind
      position_id
      specialty_id
      condition_id
    }
    _applicationRequirementsMeta(input: $input) {
      count
    }
  }
`;

export default ApplicationRequirementList;
