import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import AccountsTab from 'components/tabs/AccountsTab';

const AgencyAccountsTab = () => (
  <SimpleShowLayout>
    <AccountsTab target="agency_id" filter={{ account_type: 'agency' }} />
  </SimpleShowLayout>
);

export default AgencyAccountsTab;
