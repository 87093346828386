import React, { Fragment } from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import UploadFileButton from 'components/UploadFileButton';

import { useFileDownload } from 'hooks/useFileDownload';

const BulkUploadButtons = ({
  medelyApiDownloadUrl,
  downloadUrl,
  uploadUrl,
}: {
  medelyApiDownloadUrl?: string;
  downloadUrl?: string;
  uploadUrl: string;
}) => {
  if (medelyApiDownloadUrl && downloadUrl) {
    return null;
  }

  const downloadCsv = useFileDownload({ fileName: 'template.csv' });
  const handleClick = (e) => {
    if (medelyApiDownloadUrl) {
      e.preventDefault();
      downloadCsv(medelyApiDownloadUrl);
    }
  };

  return (
    <Fragment>
      <UploadFileButton endpoint={uploadUrl} accept="text/csv" text="Import&nbsp;CSV" />
      <Button
        size="small"
        variant="outlined"
        startIcon={<DownloadIcon />}
        onClick={handleClick}
        href={downloadUrl}
      >
        Download CSV Template
      </Button>
    </Fragment>
  );
};

export default BulkUploadButtons;
