import React from 'react';
import { gql } from '@apollo/client';
import {
  TopToolbar,
  EditButton,
  Show,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
  useRecordContext,
} from 'react-admin';
import { Button, CircularProgress } from '@mui/material';
import { useFileUpload } from 'hooks/useFileUpload';
import config from 'config';
import AccountsTab from 'resources/agencies/tabs/AccountsTab';
import InfoTab from 'resources/agencies/tabs/InfoTab';
import LocationsTab from 'resources/agencies/tabs/LocationsTab';
import ProfessionalsTab from 'resources/agencies/tabs/ProfessionalsTab';
import DocumentsTab from 'resources/agencies/tabs/DocumentsTab';
import StripeAccountsTab from 'components/StripeAccountsTab';

const AgenciesActions = () => {
  const record = useRecordContext();

  const { hiddenInput, openFilePrompt, uploading } = useFileUpload({
    url: `${config.rails_url}/v3/admin/agencies/${record?.id}/professionals_import`,
    accept: 'text/csv',
  });

  return (
    <TopToolbar>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {hiddenInput}
        <Button
          disabled={uploading}
          onClick={openFilePrompt}
          variant="outlined"
          size="small"
          endIcon={uploading ? <CircularProgress size={20} /> : undefined}
        >
          Import&nbsp;Professionals&nbsp;CSV
        </Button>
      </div>
      <EditButton />
    </TopToolbar>
  );
};

const AgenciesShow = () => {
  return (
    <Show actions={<AgenciesActions />}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="info">
          <InfoTab />
        </Tab>

        <Tab label="Accounts" path="accounts">
          <AccountsTab />
        </Tab>

        <Tab label="Locations" path="locations">
          <LocationsTab />
        </Tab>

        <Tab label="Staff" path="staff">
          <ProfessionalsTab />
        </Tab>

        <Tab label="Documents" path="documents">
          <DocumentsTab />
        </Tab>

        <Tab label="Stripe Accounts" path="stripeAccounts">
          <StripeAccountsTab />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

AgenciesShow.query = gql`
  query GET_ONE($id: Int!) {
    agency(id: $id) {
      id
      name
      legal_name
      status
      job_types
    }
  }
`;

export default AgenciesShow;
