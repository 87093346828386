import * as React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  NumberField,
  BooleanField,
  SimpleShowLayout,
} from 'react-admin';
import DateTimeField from 'components/DateTimeField';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import { FormControlLabel, FormGroup, MenuItem, Select, Switch } from '@mui/material';
import inflection from 'inflection';
import { ENUMS } from 'components/Enum/enums';

const PayoutsTab = () => {
  const [hideZeroValuePayouts, setHideZeroValuePayouts] = React.useState(false);
  const [status, setStatus] = React.useState('all');
  const filter = React.useMemo(() => {
    const filter: any = {};
    if (hideZeroValuePayouts) {
      filter.search = { hide_zero_value_payouts: hideZeroValuePayouts };
    }
    if (status !== 'all') {
      filter.status = status;
    }
    return filter;
  }, [hideZeroValuePayouts, status]);

  return (
    <SimpleShowLayout>
      <FormGroup row>
        <FormControlLabel
          labelPlacement="start"
          control={
            <Switch
              onChange={({ target }) => setHideZeroValuePayouts(target.checked)}
              checked={hideZeroValuePayouts}
            />
          }
          label="Hide 0 value Payouts"
          data-testid="professional_show-payouts_tab_hide_zero"
        />
        <FormControlLabel
          labelPlacement="start"
          control={
            <Select value={status} onChange={({ target }) => setStatus(target.value)}>
              <MenuItem value="all">All</MenuItem>
              {Object.values(ENUMS.Payout.status).map((value) => (
                <MenuItem key={value} value={value}>
                  {inflection.humanize(value)}
                </MenuItem>
              ))}
            </Select>
          }
          label="Status"
          data-testid="professional_show-payouts_tab_status"
        />
      </FormGroup>
      <ReferenceManyField
        label=""
        reference="Payout"
        target="professional_id"
        filter={filter}
        pagination={<Pagination />}
      >
        <Datagrid bulkActionButtons={false} data-testid="professional_show-payouts_tab_table">
          <LinkField label="ID" source="id" />
          <MoneyField label="Amount" source="amount_cents" />
          <TextField
            label="Amount Currency"
            source="amount_currency"
            data-testid="professional_show-payouts_tab_table_amount_currency"
          />
          <DateTimeField label="Arrival Date" source="arrival_date" timeZone="America/Los_Angeles" />
          <TextField
            label="Status"
            source="status"
            data-testid="professional_show-payouts_tab_table_status"
          />
          <TextField
            label="Account Type"
            source="account_type"
            data-testid="professional_show-payouts_tab_table_account_type"
          />
          <LinkField label="Professional ID" source="professional_id" />
          <LinkField label="Assignment ID" source="assignment_id" />
          <TextField
            label="Stripe ID"
            source="stripe_id"
            data-testid="professional_show-payouts_tab_table_stripe_id"
          />
          <MoneyField label="Professional Balance" source="professional.balance_cents" />
          <BooleanField
            label="Pause"
            source="pause"
            data-testid="professional_show-payouts_tab_table_pause"
          />
          <TextField
            label="Source Type"
            source="source_type"
            data-testid="professional_show-payouts_tab_table_source_type"
          />
          <TextField
            label="Notes"
            source="notes"
            data-testid="professional_show-payouts_tab_table_notes"
          />
          <DateTimeField label="Processed On" source="processed_on" timeZone="America/Los_Angeles" />
          <NumberField
            label="Failed Count"
            source="failed_count"
            data-testid="professional_show-payouts_tab_table_failed_count"
          />
          <TextField
            label="Failed Reason"
            source="failed_reason"
            data-testid="professional_show-payouts_tab_table_failed_reason"
          />
          <DateTimeField label="Created At" source="created_at" timeZone="America/Los_Angeles" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default PayoutsTab;
