import React from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';
import MoneyInput from 'components/MoneyInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import _ from 'lodash';
import useCheckAccess from 'hooks/useCheckAccess';
import InvoiceInput from 'components/InvoiceInput';
import JobInput from 'components/JobInput';
import DisputeInput from 'components/DisputeInput';
import { revenueAdjustmentHelperText } from 'constants/stringConstants';
import RevenueAdjustmentDescriptionInput from 'components/RevenueAdjustmentDescriptionInput';
import { validateNonZero } from 'utils/custom-validations';

const Revenue_adjustmentsEdit = () => {
  const checkAccess = useCheckAccess();

  return (
    <Edit
      mutationMode="pessimistic"
      redirect="show"
      transform={(values) => _.omit(values, ['professional_id'])}
    >
      <SimpleForm>
        {checkAccess('update', 'RevenueAdjustment', 'job_id') && <JobInput />}
        {checkAccess('update', 'RevenueAdjustment', 'invoice_id') && (
          <InvoiceInput filter={{ search: { hide_locked: true } }} />
        )}
        {checkAccess('update', 'RevenueAdjustment', 'dispute_id') && <DisputeInput />}
        {checkAccess('update', 'RevenueAdjustment', 'amount_cents') && (
          <MoneyInput
            label="Amount"
            source="amount_cents"
            validate={[required(), validateNonZero]}
          />
        )}
        {checkAccess('update', 'RevenueAdjustment', 'adjustment_type') && (
          <EnumSelectInput label="Adjustment Type" source="adjustment_type" format={_.identity} />
        )}
        {checkAccess('update', 'RevenueAdjustment', 'description') && (
          <RevenueAdjustmentDescriptionInput />
        )}
        {checkAccess('update', 'RevenueAdjustment', 'notes') && (
          <TextInput label="Notes" source="notes" helperText={revenueAdjustmentHelperText.notes} />
        )}
      </SimpleForm>
    </Edit>
  );
};

export default Revenue_adjustmentsEdit;
