import React from 'react';
import { ReferenceArrayInput, SelectArrayInput, required } from 'react-admin';

const AccountsArrayInput = ({ company_id }) => (
  <ReferenceArrayInput
    source="account_ids"
    reference="Account"
    filter={{ company_id }}
    label="Accounts*"
  >
    <SelectArrayInput optionText={(o) => `${o.id}: ${o.name}`} validate={[required()]} />
  </ReferenceArrayInput>
);

export default AccountsArrayInput;
