import React from 'react';
import { gql } from '@apollo/client';
import { Show } from 'react-admin';
import RateSettingsShow from '../RateSettingsShow';

const StateRateSettingShow = () => (
  <Show>
    <RateSettingsShow />
  </Show>
);

StateRateSettingShow.query = gql`
  query stateRateSetting($id: Int!) {
    stateRateSetting(id: $id) {
      id
      active
      created_at
      settings
      state_id
      updated_at
    }
  }
`;

export default StateRateSettingShow;
