import React from 'react';
import { gql } from '@apollo/client';
import {
  TopToolbar,
  BooleanField,
  Show,
  SimpleShowLayout,
  TextField,
  DeleteWithConfirmButton,
} from 'react-admin';
import StateField from 'components/StateField';
import EnumField from 'components/Enum/EnumField';
import LinkField from 'components/LinkField';

const ApplicationRequirementShowActions = () => (
  <TopToolbar>
    <DeleteWithConfirmButton />
  </TopToolbar>
);

const ApplicationRequirementShow = () => (
  <Show actions={<ApplicationRequirementShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <BooleanField source="medely" />
      <StateField source="state_id" emptyText="-" />
      <BooleanField source="base" />
      <EnumField source="kind" />
      <LinkField source="position_id" reference="Position" />
      <LinkField source="specialty_id" reference="Specialty" />
      <LinkField source="condition_id" reference="Condition" />
    </SimpleShowLayout>
  </Show>
);

ApplicationRequirementShow.query = gql`
  query GET_ONE($id: Int!) {
    applicationRequirement(id: $id) {
      id
      medely
      state_id
      base
      kind
      position_id
      specialty_id
      condition_id
    }
  }
`;

export default ApplicationRequirementShow;
