import React from 'react';
import { Create, SimpleForm, ReferenceInput, AutocompleteInput, required } from 'react-admin';
import { useLocation } from 'react-router-dom';
import AgencyQualificationAttributesInput from 'resources/professional_qualification_attributes/ProfessionalQualificationAttributesInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import AgencyInput from 'components/AgencyInput';

const transformPayload = (payload) => {
  const agency_qualification_attributes_attributes =
    payload.agency_qualification_attributes_attributes.filter(
      (attr) => attr.value !== null && attr.value !== undefined,
    );
  return {
    ...payload,
    agency_qualification_attributes_attributes,
  };
};

const AgencyQualificationCreate = () => {
  const { state } = useLocation();

  return (
    <Create redirect="show" transform={transformPayload}>
      <SimpleForm>
        <ReferenceInput source="qualification_id" reference="Qualification" validate={required()}>
          <AutocompleteInput optionText="long_name" fullWidth />
        </ReferenceInput>
        <AgencyInput
          optionText={(option) => `${option?.id} ${option?.name}`}
          defaultValue={state?.record?.agencyId}
          validate={required()}
          fullWidth
        />
        <EnumSelectInput source="status" validate={required()} fullWidth />
        <AgencyQualificationAttributesInput
          label="Agency Qualification Attributes"
          source="agency_qualification_attributes_attributes"
          inputSource={['admin', 'user', 'system', 'agency']}
        />
      </SimpleForm>
    </Create>
  );
};

export default AgencyQualificationCreate;
