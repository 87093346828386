import React from 'react';
import { NumberInput } from 'react-admin';
import DateInput from 'components/DateInput';
import useCheckAccess from 'hooks/useCheckAccess';
import InvoiceInput from 'components/InvoiceInput';
import EditView from '../components/EditView';

const InvoicesEdit = () => {
  const checkAccess = useCheckAccess();

  return (
    <EditView invoice_type="debit_memo">
      {checkAccess('update', 'Invoice', 'due_date') && (
        <DateInput label="Due Date" source="due_date" />
      )}
      {checkAccess('update', 'Invoice', 'assignment_id') && (
        <NumberInput label="Assignment ID" source="assignment_id" />
      )}
      {checkAccess('update', 'InvoicesLink', 'parent_invoice_id') && (
        <InvoiceInput label="Parent Invoices" source="parent_invoices" multiselect={true} />
      )}
    </EditView>
  );
};

export default InvoicesEdit;
