import React from 'react';
import { gql } from '@apollo/client';
import {
  Datagrid,
  EditButton,
  FunctionField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
} from 'react-admin';

const ApprovalGroupsShowActions = () => {
  const record = useRecordContext();
  const showEditButton = record && !record.deleted_at;
  return <TopToolbar>{showEditButton && <EditButton />}</TopToolbar>;
};

const ApprovalGroupsShow = () => (
  <Show actions={<ApprovalGroupsShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <FunctionField
        label="Status"
        render={(record) => (record.deleted_at ? 'Inactive' : 'Active')}
        sortable={false}
      />
      <ReferenceManyField
        label="Accounts"
        reference="Account"
        source="account_ids"
        target="id"
        sortable={false}
      >
        <Datagrid bulkActionButtons={false} style={{ tableLayout: 'fixed' }}>
          <TextField label="ID" source="id" />
          <TextField source="name" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

ApprovalGroupsShow.query = gql`
  query GET_ONE_APPROVAL_GROUP($id: Int!) {
    approvalGroup(id: $id) {
      id
      account_ids
      accounts {
        id
      }
      name
      company_id
      deleted_at
    }
  }
`;

export default ApprovalGroupsShow;
