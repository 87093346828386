import React from 'react';
import { Edit } from 'react-admin';
import RateSettingsForm from '../RateSettingsForm';
import { getTransform } from '../rateSettingsTransform';

const AgencyLocationRateSettingEdit = () => (
  <Edit mutationMode="pessimistic" transform={getTransform(['agency_id', 'location_id'], 'settings')}>
    <RateSettingsForm />
  </Edit>
);

export default AgencyLocationRateSettingEdit;
