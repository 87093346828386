import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import {
  Button,
  Confirm,
  Datagrid,
  EditButton,
  Labeled,
  ReferenceManyField,
  SaveButton,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  Toolbar,
  TopToolbar,
  useGetManyReference,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import LinkField from 'components/LinkField';
import { Box, Dialog, Typography, useTheme } from '@mui/material';
import create from 'utils/api/create';
import { getAllChildrenOfLocationGroup } from 'resources/companies/tabs/utils';
import getAxios from 'getAxios';
import { Add, Delete } from '@mui/icons-material';
import useRollbarAlert from 'hooks/useRollbarAlert';

const PER_PAGE = 1000;

const LocationGroupActions = () => {
  const record = useRecordContext();

  return record ? (
    <TopToolbar>
      <EditButton />
    </TopToolbar>
  ) : null;
};

const RemoveResourceLinkButton = ({ parentRecord, title, resource }) => {
  const axios = getAxios();
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    setIsDeleting(true);
    const data: Record<string, any> = {};
    if (resource === 'locations') {
      data.location_ids = [record.id];
    } else if (resource === 'children') {
      data.child_ids = [record.id];
    } else if (resource === 'accounts') {
      data.account_ids = [record.id];
    }
    axios
      .delete(
        `v3/admin/companies/${parentRecord.company_id}/group_nodes/${parentRecord.id}/${resource}`,
        {
          data,
        },
      )
      .then(() => {
        notify('Deleted');
        refresh();
      })
      .catch((e) => notify(e.message, { type: 'error' }))
      .finally(() => setIsDeleting(false));
  };

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    handleDelete();
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={{ color: `${theme.palette.error.dark}` }}
        endIcon={<Delete />}
        label="Remove"
        onClick={handleClick}
      />
      <Confirm
        isOpen={open}
        loading={isDeleting}
        title={`Remove ${title} ${record && record.name}`}
        content={`Are you sure you want to remove this ${title}?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

const LocationFields = () => {
  const [isOpen, setIsOpen] = useState(false);
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const theme = useTheme();

  const { data: locationGroups = [] } = useGetManyReference('LocationGroup', {
    target: 'company_id',
    id: record?.company_id,
    pagination: { page: 1, perPage: PER_PAGE },
    sort: { field: 'name', order: 'ASC' },
  });

  useRollbarAlert(() => {
    if (locationGroups.length === PER_PAGE) {
      return {
        msg: 'LocationGroups limit reached',
      };
    }
  }, [locationGroups.length]);

  const { data: locations = [] } = useGetManyReference('Location', {
    target: 'company_id',
    id: record?.company_id,
    pagination: { page: 1, perPage: PER_PAGE },
    sort: { field: 'name', order: 'ASC' },
  });

  useRollbarAlert(() => {
    if (locations.length === PER_PAGE) {
      return {
        msg: 'Locations limit reached',
      };
    }
  }, [locations.length]);

  const { allLocations } = getAllChildrenOfLocationGroup(record, locationGroups);

  const createLocationLink = async (values) => {
    try {
      await create(`v3/admin/companies/${record.company_id}/group_nodes/${record.id}/locations`, {
        location_ids: [values.location_id],
      });
      notify('Save was successful', { type: 'success' });
      refresh();
      setIsOpen(false);
    } catch (error: any) {
      notify(`Error: ${error.message}`, { type: 'warning' });
    }
  };

  const choices = locations
    .map((choice) => ({ id: choice.id, name: choice.name }))
    .filter((choice) => !record.location_ids.includes(choice.id));

  return (
    <>
      <ReferenceManyField label="Locations" reference="Location" source="location_ids" target="id">
        <Box position="relative" minHeight="100px" width="100%">
          <Box position="absolute" top="0" left="67%" width="33%" zIndex="10">
            <Button
              sx={{ color: `${theme.palette.success.main}` }}
              endIcon={<Add />}
              label="Add"
              onClick={() => setIsOpen(true)}
            />
          </Box>
          <Datagrid bulkActionButtons={false} style={{ tableLayout: 'fixed' }}>
            <LinkField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <RemoveResourceLinkButton parentRecord={record} title="Location" resource="locations" />
          </Datagrid>
        </Box>
      </ReferenceManyField>

      <Dialog open={isOpen} fullWidth maxWidth="sm">
        <Typography variant="h6" px={3} pt={3}>
          Add new Location
        </Typography>
        <SimpleForm
          record={{}}
          onSubmit={createLocationLink}
          toolbar={
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <SaveButton label="Submit" />
              <Button label="Cancel" onClick={() => setIsOpen(false)} />
            </Toolbar>
          }
        >
          <SelectInput
            source="location_id"
            choices={choices}
            label="Locations"
            optionText={(choice) => `${choice.name}${allLocations.includes(choice.id) ? '*' : ''}`}
          />
          <Typography>
            * The location is included by another child group but may still be added to this group
            directly
          </Typography>
        </SimpleForm>
      </Dialog>
    </>
  );
};

const ChildGroupFields = () => {
  const [isOpen, setIsOpen] = useState(false);
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const theme = useTheme();

  const { data: locationGroups = [] } = useGetManyReference('LocationGroup', {
    target: 'company_id',
    id: record?.company_id,
    pagination: { page: 1, perPage: PER_PAGE },
    sort: { field: 'name', order: 'ASC' },
  });

  useRollbarAlert(() => {
    if (locationGroups.length === PER_PAGE) {
      return {
        msg: 'LocationGroups limit reached',
      };
    }
  }, [locationGroups.length]);

  const { allChildGroups } = getAllChildrenOfLocationGroup(record, locationGroups);
  const createChildLink = async (values) => {
    try {
      await create(`v3/admin/companies/${record.company_id}/group_nodes/${record.id}/children`, {
        child_ids: [values.child_id],
      });
      notify('Save was successful', { type: 'success' });
      refresh();
      setIsOpen(false);
    } catch (error: any) {
      notify(`Error: ${error.message}`, { type: 'warning' });
    }
  };

  const choices = locationGroups
    .map((choice) => ({ id: choice.id, name: choice.name }))
    .filter((choice) => !record.child_group_ids.includes(choice.id));

  return (
    <>
      <ReferenceManyField
        label="Child Groups"
        reference="LocationGroup"
        source="child_group_ids"
        target="id"
      >
        <Box position="relative" minHeight="100px" width="100%">
          <Box position="absolute" top="0" left="67%" width="33%" zIndex="10">
            <Button
              sx={{ color: `${theme.palette.success.main}` }}
              endIcon={<Add />}
              label="Add"
              onClick={() => setIsOpen(true)}
            />
          </Box>
          <Datagrid
            bulkActionButtons={false}
            style={{ tableLayout: 'fixed', position: 'relative' }}
          >
            <LinkField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <RemoveResourceLinkButton
              parentRecord={record}
              title="Child Group"
              resource="children"
            />
          </Datagrid>
        </Box>
      </ReferenceManyField>
      <Dialog open={isOpen} fullWidth maxWidth="sm">
        <Typography variant="h6" px={3} pt={3}>
          Add new Child Group
        </Typography>
        <SimpleForm
          record={{}}
          defaultValues={{
            parent_id: record.id,
          }}
          onSubmit={createChildLink}
          toolbar={
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <SaveButton label="Submit" />
              <Button label="Cancel" onClick={() => setIsOpen(false)} />
            </Toolbar>
          }
        >
          <SelectInput
            source="child_id"
            choices={choices}
            label="Child Groups"
            optionText={(choice) =>
              `${choice.name}${allChildGroups.includes(choice.id) ? '*' : ''}`
            }
          />
          <Typography>
            * This child group is included by another child group but may still be added to this
            group directly
          </Typography>
        </SimpleForm>
      </Dialog>
    </>
  );
};

const AccountFields = () => {
  const [isOpen, setIsOpen] = useState(false);
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const theme = useTheme();

  const { data: accounts = [] } = useGetManyReference('Account', {
    target: 'company_id',
    id: record?.company_id,
    pagination: { page: 1, perPage: PER_PAGE },
    sort: { field: 'id', order: 'DESC' },
  });

  useRollbarAlert(() => {
    if (accounts.length === PER_PAGE) {
      return {
        msg: 'Accounts limit reached',
      };
    }
  }, [accounts.length]);

  const createAccountLink = async (values) => {
    try {
      await create(`v3/admin/companies/${record.company_id}/group_nodes/${record.id}/accounts`, {
        account_ids: [values.account_id],
      });
      notify('Save was successful', { type: 'success' });
      refresh();
      setIsOpen(false);
    } catch (error: any) {
      notify(`Error: ${error.message}`, { type: 'warning' });
    }
  };

  const choices = accounts
    .filter((choice) => !choice.calculated_group_node_ids.includes(record.id))
    .map((choice) => ({ id: choice.id, name: choice.name }));

  return (
    <>
      <ReferenceManyField label="Accounts" reference="Account" source="account_ids" target="id">
        <Box position="relative" minHeight="100px" width="100%">
          <Box position="absolute" top="0" left="67%" width="33%" zIndex="10">
            <Button
              sx={{ color: `${theme.palette.success.main}` }}
              endIcon={<Add />}
              label="Add"
              onClick={() => setIsOpen(true)}
            />
          </Box>
          <Datagrid bulkActionButtons={false} style={{ tableLayout: 'fixed' }}>
            <LinkField label="ID" source="id" />
            <TextField label="Name" source="name" sortable={false} />
            <RemoveResourceLinkButton parentRecord={record} title="Account" resource="accounts" />
          </Datagrid>
        </Box>
      </ReferenceManyField>
      <Dialog open={isOpen} fullWidth maxWidth="sm">
        <Typography variant="h6" px={3} pt={3}>
          Add new Account
        </Typography>
        <SimpleForm
          record={{}}
          defaultValues={{
            parent_id: record.id,
          }}
          onSubmit={createAccountLink}
          toolbar={
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <SaveButton label="Submit" />
              <Button label="Cancel" onClick={() => setIsOpen(false)} />
            </Toolbar>
          }
        >
          <SelectInput source="account_id" choices={choices} label="Accounts" />
        </SimpleForm>
      </Dialog>
    </>
  );
};

const LocationGroupsShow = () => {
  return (
    <Show actions={<LocationGroupActions />}>
      <SimpleShowLayout>
        <TextField label="ID" source="id" />
        <TextField label="Group Name" source="name" />
        <TextField label="Description" source="description" />
        <Labeled label="Locations" style={{ width: '100%' }}>
          <LocationFields />
        </Labeled>
        <Labeled label="Child Groups" style={{ width: '100%' }}>
          <ChildGroupFields />
        </Labeled>
        <Labeled label="Accounts" style={{ width: '100%' }}>
          <AccountFields />
        </Labeled>
      </SimpleShowLayout>
    </Show>
  );
};

LocationGroupsShow.query = gql`
  query GET_LOCATION_GROUP($id: Int!) {
    locationGroup(id: $id) {
      id
      company_id
      name
      description
      calculated_descendant_ids
      child_group_ids
      location_ids
      account_ids
    }
  }
`;

export default LocationGroupsShow;
