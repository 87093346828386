import { CircularProgress } from '@medely/ui-kit/web';
import CompanyInput from 'components/CompanyInput';
import useLocation from 'hooks/useLocation';
import React, { useState } from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  Button,
  Link,
  ReferenceArrayInput,
  required,
  SimpleForm,
  Toolbar,
  useNotify,
} from 'react-admin';
import { useParams } from 'react-router';
import { useMigrateLocation } from './hooks';
import { Table, TableCell, TableHead, TableRow, Typography } from '@medely/web-components';
import { TableBody } from '@mui/material';
import config from 'config';

interface IChange {
  account_ids: number[] | undefined;
  assignment_ids: number[] | undefined;
  billing_group_ids: number[] | undefined;
  company_id: number[] | undefined;
  location_ids: number[] | undefined;
}

interface IResource {
  id: number;
  updated: IChange | undefined;
  added: IChange | undefined;
  removed: IChange | undefined;
}

interface IAssignmentChanges {
  count: number | undefined;
  updated: {
    company_id: number[] | undefined;
  };
}

interface IResponse {
  dry_run: boolean;
  admin_to_manager: boolean;
  request_uuid: string | undefined;
  results: {
    accounts: IResource[] | undefined;
    assignments: IAssignmentChanges | undefined;
    billing_groups: IResource[] | undefined;
    locations: IResource[] | undefined;
  };
}

interface IResourceTypeTableRow {
  resource_type: 'Account' | 'Assignment' | 'Billing Group' | 'Location';
  resource: IResource;
}

interface IChangesTableCellData {
  change_type: 'Added' | 'Removed' | 'Updated';
  change: IChange;
}

const LocationMigrateToolbar = () => {
  return (
    <Toolbar>
      <Button type="submit" label="Migrate" variant="contained" />
    </Toolbar>
  );
};

const ChangesTableCellData = ({ change_type, change }: IChangesTableCellData) => {
  const { account_ids, assignment_ids, billing_group_ids, company_id, location_ids } = change;
  return (
    <>
      {company_id && (
        <Typography>
          {`- Migrated from `}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={`/Company/${company_id[0]}/show`}
          >{`Company ${company_id[0]}`}</Link>
          {' to '}
          <Link
            target="_blank"
            rel="noopener noreferrer"
            to={`/Company/${company_id[1]}/show`}
          >{`Company ${company_id[1]}`}</Link>
        </Typography>
      )}
      {account_ids &&
        account_ids.map((account_id) => (
          <Typography>
            {`- ${change_type} `}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={`/Account/${account_id}/show`}
            >{`Account ${account_id}`}</Link>
          </Typography>
        ))}
      {assignment_ids &&
        assignment_ids.map((assignment_id) => (
          <Typography>
            {`- ${change_type} `}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={`/Assignment/${assignment_id}/show`}
            >{`Assignment ${assignment_id}`}</Link>
          </Typography>
        ))}
      {billing_group_ids &&
        billing_group_ids.map((billing_group_id) => (
          <Typography>
            {`- ${change_type} `}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={`/BillingGroup/${billing_group_id}/show`}
            >
              {`Billing Group ${billing_group_id}`}
            </Link>
          </Typography>
        ))}
      {location_ids &&
        location_ids.map((location_id) => (
          <Typography>
            {`- ${change_type} `}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={`/Location/${location_id}/show`}
            >{`Location ${location_id}`}</Link>
          </Typography>
        ))}
    </>
  );
};

const ResourceTypeTableRow = ({ resource_type, resource }: IResourceTypeTableRow) => {
  const { id } = resource;
  const resourceName = `${resource_type} ${id}`;
  const resourceLinkTo = resource_type === 'Billing Group' ? `/BillingGroup/${id}/show` : `/${resource_type}/${id}/show`;
  return (
    <TableRow>
      <TableCell>
        <Link target="_blank" rel="noopener noreferrer" to={resourceLinkTo}>
          {resourceName}
        </Link>
      </TableCell>
      <TableCell>
        {resource.updated && (
          <ChangesTableCellData change_type="Updated" change={resource.updated} />
        )}
        {resource.added && <ChangesTableCellData change_type="Added" change={resource.added} />}
        {resource.removed && (
          <ChangesTableCellData change_type="Removed" change={resource.removed} />
        )}
      </TableCell>
    </TableRow>
  );
};

const LocationMigrate = () => {
  const { id } = useParams();
  const notify = useNotify();
  const { data: location, isLoading: locationIsLoading } = useLocation(Number(id));
  const migrateLocation = useMigrateLocation();
  const [response, setResponse] = useState<IResponse | undefined>();

  const isLoading = locationIsLoading;

  const handleSubmit = async (values) => {
    try {
      const { data } = await migrateLocation({
        id: location.id,
        company_id: values.company_id,
        account_ids: values.account_ids,
        dry_run: values.dry_run,
        admin_to_manager: values.migrate_company_admins_to_facility_managers,
      });
      setResponse(data);
      notify(
        values.dry_run
          ? '(DRY RUN) Successfully migrated location'
          : 'Successfully migrated location',
        { type: 'success' },
      );
    } catch (e) {
      notify(e.message, { type: 'error' });
    }
  };

  const audit = response ? `${config.metabase_uuid_url}${response.request_uuid}` : '';

  return !isLoading ? (
    <>
      <SimpleForm sanitizeEmptyValues toolbar={<LocationMigrateToolbar />} onSubmit={handleSubmit}>
        <CompanyInput
          validate={[required()]}
          getOptionDisabled={(option) => option.id === location.company_id}
        />
        <ReferenceArrayInput
          source="billing_group_ids"
          reference="BillingGroup"
          filter={{ company_id: location.company_id }}
          disabled
        >
          <AutocompleteArrayInput
            optionText={({ name, id, status }) => `${name}:${id}:${status}`}
            disabled
            sx={{ minWidth: 256 }}
            defaultValue={location.billing_group_ids}
          />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source="account_ids"
          reference="Accounts"
          filter={{ company_id: location.company_id }}
          search={{ location_ids: [location.id] }}
          disabled
        >
          <AutocompleteArrayInput
            optionText={({ id, email, status }) => `${id}:${email}:${status}`}
            sx={{ minWidth: 256 }}
            validate={[required()]}
          />
        </ReferenceArrayInput>
        <BooleanInput source="dry_run" defaultValue={true} />
        <BooleanInput source="migrate_company_admins_to_facility_managers" defaultValue={true} />
      </SimpleForm>
      <Typography>Results</Typography>
      {response ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{`Resource${response.dry_run ? ' - DRY RUN' : ''}`}</TableCell>
                <TableCell>{`Changes${response.dry_run ? ' - DRY RUN' : ''}`}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response.results.locations &&
                response.results.locations.map((location) => (
                  <ResourceTypeTableRow resource={location} resource_type="Location" />
                ))}
              {response.results.billing_groups &&
                response.results.billing_groups.map((billing_group) => (
                  <ResourceTypeTableRow resource={billing_group} resource_type="Billing Group" />
                ))}
              {response.results.accounts &&
                response.results.accounts.map((account) => (
                  <ResourceTypeTableRow resource={account} resource_type="Account" />
                ))}
              {response.results.assignments && (
                <TableRow>
                  <TableCell>
                    <Typography>Assignments</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {`- Migrated ${response.results.assignments.count} Assignments from `}
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={`/Company/${response.results.assignments.updated.company_id[0]}/show`}
                      >{`Company ${response.results.assignments.updated.company_id[0]}`}</Link>
                      {' to '}
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={`/Company/${response.results.assignments.updated.company_id[1]}/show`}
                      >{`Company ${response.results.assignments.updated.company_id[1]}`}</Link>
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!response.dry_run && (
            <>
              <Typography>Audit</Typography>
              <Link target="_blank" rel="noopener noreferrer" to={audit}>
                {audit}
              </Link>
            </>
          )}
        </>
      ) : (
        <Typography>Run the migration with the button above</Typography>
      )}
    </>
  ) : (
    <CircularProgress />
  );
};

export default LocationMigrate;
