import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { StatsigProvider, StatsigUser, Statsig } from 'statsig-react';
import { useCurrentUserStorage } from './authProvider';
import config from '../config';

interface IContext {
  isLoading: boolean;
  statsigUser?: StatsigUser;
  setCustomAttributes?: (
    customAttributes: Record<string, string | number | boolean | string[]>,
  ) => void;
}

const FeatureGateContext = createContext({
  isLoading: true,
  statsigUser: undefined,
  setCustomAttributes: undefined,
});

export const FeatureGateProvider = ({ children }: { children: ReactNode }): React.ReactElement => {
  const { isLoading, currentUser } = useCurrentUserStorage();
  const [statsigUser, setStatsigUser] = useState<StatsigUser | undefined>();
  /**
   * Function for setting custom attributes in the current statsig user.  This can be used to set custom attirbutes
   * based on current data from the page for more complicated feature gates/experiements (ex. gating based on the location_id
   * of a job the pro is currently clocking out of)
   */
  const setCustomAttributes = (
    customAttributes: Record<string, string | number | boolean | string[]>,
  ) => {
    setStatsigUser((prevStatsigUser) => ({
      ...prevStatsigUser,
      custom: {
        ...prevStatsigUser.custom,
        ...customAttributes,
      },
    }));
  };

  useEffect(() => {
    if (currentUser?.id) {
      setStatsigUser({
        userID: currentUser?.id,
        email: currentUser?.email ?? undefined,
      });
    }
  }, [currentUser?.id, currentUser?.email]);

  if (!!window.Cypress) {
    (window as any).Statsig = Statsig;
  }

  return (
    <FeatureGateContext.Provider
      value={{
        isLoading,
        statsigUser,
        setCustomAttributes,
      }}
    >
      <StatsigProvider
        sdkKey={config.statsigSdkKey}
        user={statsigUser}
        waitForInitialization={false}
      >
        {children}
      </StatsigProvider>
    </FeatureGateContext.Provider>
  );
};

export const useFeatureGateContext = (): IContext => useContext(FeatureGateContext);
