import React from 'react';
import {
  SimpleShowLayout,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Datagrid,
  FunctionField,
  ReferenceManyField,
  useRecordContext,
  Pagination,
  NumberField,
} from 'react-admin';
import EnumField from 'components/Enum/EnumField';
import { JobTimeField } from 'resources/jobs/JobTimeField';
import LinkField from 'components/LinkField';

const JobsTab = ({ target }) => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <SimpleShowLayout>
      <ReferenceManyField
        label=""
        reference="Job"
        target={target}
        pagination={<Pagination />}
        sort={{ field: 'starts_time', order: 'DESC' }}
      >
        <Datagrid bulkActionButtons={false}>
          <LinkField label="ID" source="id" />
          <JobTimeField label="Starts Date" format="MM/dd/yy" source="starts_time" />
          <LinkField label="Location ID" source="location_id" />
          <NumberField label="Total Worked Hours" source="job_billing_sums.total_hours" />
          <NumberField label="Total Break Minutes" source="job_billing_sums.total_breaks_minutes" />
          <FunctionField
            label="Starts Time"
            render={(record) => {
              if (record.shift_type === 'call_back') {
                return (
                  <JobTimeField
                    label="Starts Time"
                    format="HH:mm zzz"
                    source="callback_starts_time"
                    record={record}
                  />
                );
              } else if (record.shift_type === 'on_call') {
                return (
                  <JobTimeField
                    label="Starts Time"
                    format="HH:mm zzz"
                    source="on_call_starts_time"
                    record={record}
                  />
                );
              } else {
                return (
                  <JobTimeField
                    label="Starts Time"
                    format="HH:mm zzz"
                    source="starts_time"
                    record={record}
                  />
                );
              }
            }}
          />
          <FunctionField
            label="Ends Time"
            render={(record) => {
              if (record.shift_type === 'call_back') {
                return (
                  <JobTimeField format="HH:mm zzz" source="callback_ends_time" record={record} />
                );
              } else if (record.shift_type === 'on_call') {
                return (
                  <JobTimeField format="HH:mm zzz" source="on_call_ends_time" record={record} />
                );
              } else {
                return <JobTimeField format="HH:mm zzz" source="ends_time" record={record} />;
              }
            }}
          />
          <TextField label="Shift Type" source="shift_type" />
          <EnumField label="Status" source="status" />
          <ArrayField source="positions">
            <SingleFieldList>
              <ChipField source="display_name" />
            </SingleFieldList>
          </ArrayField>
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(JobsTab);
