import React from 'react';
import config from 'config';
import { useFileUpload } from 'hooks/useFileUpload';
import { Button, CircularProgress } from '@mui/material';

type ImportTransactionsProps = {
  type: string;
};

const ImportTransactions = ({ type }: ImportTransactionsProps) => {
  const { hiddenInput, openFilePrompt, uploading } = useFileUpload({
    url: `${config.rails_url}/v3/admin/financial_transactions_import`,
    accept: 'text/csv',
  });
  return (
    <div>
      {hiddenInput}
      <Button
        disabled={uploading}
        onClick={openFilePrompt}
        variant="outlined"
        style={{ marginTop: '5px' }}
        size="small"
        endIcon={uploading ? <CircularProgress size={20} /> : undefined}
      >
        Import&nbsp;{type} Transactions
      </Button>
    </div>
  );
};
export default ImportTransactions;
