import React from 'react';
import { maxValue } from 'react-admin';
import CreateView from '../components/CreateView';

const defaultValues = {
  adjustment_type: 'rebate',
  amount_cents: '-0.00',
};

const CreditMemosCreate = () => (
  <CreateView
    invoice_type="credit_memo"
    title="Create Credit Memos"
    defaultValues={defaultValues}
    amountCentsValidation={maxValue(-1, 'Must be a negative amount')}
  />
);

export default CreditMemosCreate;
