import React from 'react';
import { FormControl, TextField } from '@mui/material';

const TextInput = ({ value, onChange }) => (
  <FormControl>
    <TextField value={value} onChange={(event) => onChange(event.target.value)} />
  </FormControl>
);

export default TextInput;
