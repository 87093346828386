import React, { useEffect, useState } from 'react';
import { Create, SimpleForm, DateInput } from 'react-admin';
import { endOfDay, startOfDay } from 'date-fns/fp';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import useCheckAccess from 'hooks/useCheckAccess';
import ProfessionalInput from 'components/ProfessionalInput';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { errorConstants } from 'constants/errorConstants';

const defaultValues = {
  starts_time: startOfDay(new Date()),
  ends_time: endOfDay(new Date()),
  status: 'open',
};

function EndsDateInput() {
  const [hasHintError, setHintError] = useState(false);
  const { watch } = useFormContext();
  const endTimeValue: Date | string = watch('ends_time');

  useEffect(() => {
    if (typeof endTimeValue !== 'string') {
      return;
    }
    const [_, month] = endTimeValue.split('-');

    // new Date().getMonth() is zero indexed
    const currentMonth = new Date().getMonth() + 1;

    const endTimeIsCurrentMonth = Number(month) === currentMonth;
    setHintError(!endTimeIsCurrentMonth);
  }, [endTimeValue]);

  return (
    <Box
      sx={(theme) => {
        return {
          '#ends_time-helper-text': {
            color: theme.palette.warning.main,
          },
        };
      }}
    >
      <DateInput
        label="Ends Date"
        source="ends_time"
        helperText={hasHintError ? errorConstants.endDateInPreviousMonth : null}
      />
    </Box>
  );
}

const PayoutsCreate = () => {
  const checkAccess = useCheckAccess();
  return (
    <Create redirect="show">
      <SimpleForm defaultValues={defaultValues} style={{ width: '20%' }}>
        {checkAccess('create', 'Payout', 'professional_id') && <ProfessionalInput />}
        {checkAccess('create', 'Payout', 'status') && (
          <EnumSelectInput
            label="Status"
            source="status"
            filterOut={['approved', 'failed', 'in_transit', 'paid', 'pending']}
          />
        )}
        {checkAccess('create', 'Payout', 'starts_time') && (
          <DateInput label="Starts Date" source="starts_time" />
        )}
        {checkAccess('create', 'Payout', 'ends_time') && <EndsDateInput />}
      </SimpleForm>
    </Create>
  );
};

export default PayoutsCreate;
