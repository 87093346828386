import { FormControl, FormControlLabel, Switch, Select, TextField, MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { useInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import {
  getSelectedMultiSelectValue,
  getSelectedSingleSelectValue,
} from 'resources/posting_fields/utils';

export interface IPostingField {
  field_type: string;
  id: number;
  label: string;
  show_on_assignment: boolean;
  show_on_per_diem: boolean;
  slug: string;
  description: string;
  constraints?: { label: string }[];
}

interface PostingFieldProps {
  postingField: IPostingField;
  defaultValue: string | undefined;
}

const PostingField = ({ postingField, defaultValue }: PostingFieldProps): JSX.Element | null => {
  const { slug, label, field_type, description, constraints } = postingField;
  const source = `posting_field_contents.${slug}`;
  const {
    field: { onChange },
  } = useInput({
    source,
  });
  const { getValues } = useFormContext();

  const handleChange = (value) => {
    onChange({
      label,
      description,
      content: value,
    });
  };
  const value = getValues()?.posting_field_contents?.[slug]?.content;

  const renderConstraints = () => {
    return constraints?.map((constraint) => (
      <MenuItem key={`${slug}-${constraint.label}`} value={constraint.label}>
        {constraint.label}
      </MenuItem>
    ));
  };

  useEffect(() => {
    // Set toggle value to false if defaultValue doesn't exist
    if (field_type === 'boolean' && !defaultValue) {
      handleChange('f');
    }
  }, []);

  switch (field_type) {
    case 'string':
      return (
        <FormControl>
          <TextField
            fullWidth
            label={label}
            onChange={(e) => handleChange(e.target.value)}
            defaultValue={defaultValue}
          />
        </FormControl>
      );
    case 'number':
      return (
        <FormControl>
          <TextField
            type="number"
            fullWidth
            label={label}
            onChange={(e) => handleChange(e.target.value)}
            defaultValue={defaultValue}
          />
        </FormControl>
      );
    case 'boolean':
      return (
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => handleChange(e.target.checked ? 't' : 'f')}
                defaultChecked={defaultValue === 't'}
              />
            }
            label={label}
          />
        </FormControl>
      );
    case 'single_select':
      return (
        <FormControl>
          <FormControlLabel
            label={label}
            control={
              <Select
                label={label}
                value={getSelectedSingleSelectValue(value)}
                onChange={(e) => {
                  onChange({
                    label,
                    description,
                    content: JSON.stringify([e.target.value]),
                  });
                }}
              >
                {renderConstraints()}
              </Select>
            }
          />
        </FormControl>
      );
    case 'multi_select':
      return (
        <FormControl>
          <FormControlLabel
            label={label}
            control={
              <Select
                multiple
                label={label}
                value={getSelectedMultiSelectValue(value)}
                onChange={(e) => {
                  onChange({
                    label,
                    description,
                    content: JSON.stringify(e.target.value),
                  });
                }}
              >
                {renderConstraints()}
              </Select>
            }
          />
        </FormControl>
      );
    default:
      return null;
  }
};

export default PostingField;
