import React from 'react';
import { Create } from 'react-admin';
import RateSettingsForm from '../RateSettingsForm';
import { getTransform } from '../rateSettingsTransform';

const LocationRateSettingCreate = () => (
  <Create transform={getTransform(['agency_id', 'location_id'], 'settings')}>
    <RateSettingsForm />
  </Create>
);

export default LocationRateSettingCreate;
