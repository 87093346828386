import * as R from 'ramda';
import React from 'react';
import DateTimeField from 'components/DateTimeField';
import {
  Datagrid,
  TextField,
  TextInput,
  FunctionField,
  SelectInput,
  BooleanField,
  BooleanInput,
  ArrayField,
  SingleFieldList,
  Link,
  NumberInput,
  NumberField,
} from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import { hoursPerWeek } from './utils';
import {
  IdFilter,
  SearchFilter,
  MarketFilter,
  AccountManagerFilter,
  AdministratorFilter,
  StateFilter,
  SalesAssociateFilter,
} from 'components/Filter';
import LinkField from 'components/LinkField';
import List from 'components/List';
import { DisplayHelpers } from 'utils/helpers';
import { ENUMS } from 'components/Enum/enums';
import IdField from 'components/IdField';
import ScreenRequestsField from './ScreenRequestsField';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import MoneyField from 'components/MoneyField';
import ReferenceInput from 'components/ReferenceInput';
import VmsTypeInput from 'components/VmsTypeInput';
import MspInput from 'components/MspInput';
import HealthSystemInput from 'components/HealthSystemInput';
import DateFilter from 'components/Filter/DateFilter';
import { gql } from '@apollo/client';
import { AssignmentContractRange } from 'constants/assignment';
import { SalesAssociateField } from 'resources/assignments/SalesAssociateField';

const AssignmentsFilters = [
  <SearchFilter alwaysOn />,
  <IdFilter />,
  <NumberInput
    label="Location ID"
    source="search.location_id"
    alwaysOn
    data-testid="assignments_filters-location_id"
  />,
  <NumberInput
    label="Company ID"
    source="search.company_id"
    alwaysOn
    data-testid="assignments_filters-company_id"
  />,
  <EnumSelectInput emptyText="All" alwaysOn source="status" />,
  <PositionInput alwaysOn multiple source="search.position_ids" />,
  <SpecialtyInput
    alwaysOn
    multiple
    source="search.specialty_ids"
    positionSource="search.position_ids"
  />,
  <SelectInput
    alwaysOn
    choices={AssignmentContractRange}
    format={(choice) => choice?.label}
    label="Contract Value"
    optionText="label"
    parse={(id) => AssignmentContractRange.find((x) => x.id === id)?.value}
    source="search.contract_range"
  />,
  <TextInput
    alwaysOn
    source="order_number"
    label="Order Number"
    data-testid="assignments_filters-order_number"
  />,
  <VmsTypeInput alwaysOn source="search.vms_type_id" />,
  <MspInput source="search.msp_id" />,
  <HealthSystemInput source="search.health_system_id" />,
  <SelectInput
    label="Has Travel Stipend"
    source="search.has_travel_stipend"
    choices={[
      { id: 'yes', name: 'Yes' },
      { id: 'no', name: 'No' },
    ]}
    emptyText="All"
    alwaysOn
    data-testid="assignments_filters-has_travel_stipend"
  />,
  <SelectInput
    label="Shift Times"
    source="search.shift_times"
    emptyText="All"
    alwaysOn
    choices={[
      { id: 0, name: 'Morning' },
      { id: 1, name: 'Afternoon' },
      { id: 2, name: 'Night' },
    ]}
    data-testid="assignments_filters-shift_times"
  />,
  <ReferenceInput
    source="search.surgery_type_id"
    label="Surgery Type"
    reference="SurgeryType"
    sort={{ field: 'display_name', order: 'ASC' }}
    perPage={55}
    gql={['display_name']}
  >
    <SelectInput
      optionText="display_name"
      label="Surgery Type"
      data-testid="assignments_filters-surgery_type"
    />
  </ReferenceInput>,
  <MarketFilter />,
  <AccountManagerFilter label="Account Manager" />,
  <SalesAssociateFilter label="Sales Associate" />,
  <AdministratorFilter
    label="Clinical Account Manager"
    source="search.recruiter_id"
    filter={{
      permission_group_roles: [ENUMS.PermissionGroup.role.sales_recruiter],
    }}
  />,
  <BooleanInput
    source="search.no_vms_company"
    label="Not A VMS Company"
    data-testid="assignments_filters-no_vms_company"
  />,
  <DateFilter label="Ends Date From" source="search.ends_date_from" />,
  <DateFilter label="Ends Date To" source="search.ends_date_to" />,
  <DateFilter label="Created At" source="search.created_at" />,
  <EnumSelectInput
    label="Assignment Request Status"
    emptyText="All"
    source="search.assignment_request_status"
  />,
  <EnumSelectInput
    label="Interview Status"
    emptyText="All"
    source="search.phone_screening_request_status"
  />,
  <DateFilter label="Starts Date" source="search.starts_date" />,
  <DateFilter label="Expiring Date" source="search.expiring_date" />,
  <BooleanInput label="W2" source="search.is_w2" data-testid="assignments_filters-is_w2" />,
  <BooleanInput
    label="Extension"
    source="search.is_extension"
    data-testid="assignments_filters-is_extension"
  />,
  <BooleanInput
    label="On Hold"
    source="search.on_hold"
    data-testid="assignments_filters-on_hold"
  />,
  <BooleanInput
    label="Completed Early"
    source="completed_early"
    data-testid="assignments_filters-completed_early"
  />,
  <BooleanInput
    label="Applications Paused"
    source="search.paused"
    data-testid="assignments_filters-paused"
  />,
  <BooleanInput label="1099" source="search.is_1099" data-testid="assignments_filters-is_1099" />,
  <BooleanInput
    label="Has Pending Offers"
    source="search.has_pending_offers"
    data-testid="assignments_filters-has_pending_offers"
  />,
  <BooleanInput
    label="Has Flexible Starts Date"
    source="search.flexible_starts_date"
    data-testid="assignments_filters-flexible_starts_date"
  />,
  <BooleanInput
    label="Compact State"
    source="search.compact"
    data-testid="assignments_filters-compact"
  />,
  <StateFilter label="States" multiple={true} source="search.state_ids" />,
  <BooleanInput
    label="Local Only"
    source="search.local_only"
    data-testid="assignments_filters-local_only"
  />,
];

const AssignmentsList = () => (
  <List exporter={false} filters={AssignmentsFilters} data-testid="assignments-list">
    <Datagrid bulkActionButtons={false} data-testid="assignments-table">
      <IdField />
      <TextField label="Status" source="status" data-testid="assignments_table-status" />
      <TextField
        label="VMS Type"
        source="company.vms_type.label"
        sortable={false}
        data-testid="assignments_table-vms_type"
      />
      <TextField
        label="VMS Order#"
        source="order_number"
        data-testid="assignments_table-order_number"
      />
      <TextField
        label="MSP"
        source="company.msp.label"
        sortable={false}
        data-testid="assignments_table-msp"
      />
      <TextField
        label="Health System"
        source="company.health_system.label"
        sortable={false}
        data-testid="assignments_table-health_system"
      />
      <BooleanField label="VMS Hold" source="on_hold" data-testid="assignments_table-on_hold" />
      <BooleanField
        label="Applications Paused"
        source="paused"
        data-testid="assignments_table-paused"
      />
      <LinkField label="Professional ID" source="professional_id" />
      <ArrayField sortable={false} label="Location ID(s)" source="locations">
        <SingleFieldList linkType={false}>
          <LinkField source="id" />
        </SingleFieldList>
      </ArrayField>
      <LinkField label="Company ID" source="company_id" />
      <DateTimeField label="Starts Date" format="MM/dd/yyyy" source="starts_date" />
      <DateTimeField label="Ends Date" format="MM/dd/yyyy" source="ends_date" />
      <TextField
        label="Shifts per Week"
        source="number_of_shifts_per_week"
        data-testid="assignments_table-number_of_shifts_per_week"
      />
      <FunctionField
        label="Hours per Week"
        render={hoursPerWeek}
        data-testid="assignments_table-hours_per_week"
      />
      <NumberField
        source="number_of_weeks"
        label="# of Weeks"
        data-testid="assignments_table-number_of_weeks"
      />
      <MoneyField label="Bill Rate" source="charge_base_hourly_rate_cents" />
      <MoneyField label="Weekly Revenue" source="charge_per_week_cents" />
      <MoneyField
        sortable={false}
        label="Total Revenue"
        source="charge_blended_hourly_rate_cents"
      />
      <FunctionField
        label="Applications"
        render={(record: any) =>
          record.assignment_request_applied_count && (
            <LinkField to={`/Assignment/${record.id}/show/requests`}>
              {record.assignment_request_applied_count}
            </LinkField>
          )
        }
        data-testid="assignments_table-applications"
      />
      <ScreenRequestsField />
      <FunctionField
        label="Position"
        render={DisplayHelpers.professional.positionNames}
        data-testid="assignments_table-position"
      />
      <FunctionField
        label="States"
        render={(v) => R.uniq(R.map((l) => l.state?.name, v.locations)).join('\n')}
        data-testid="assignments_table-states"
      />
      <FunctionField
        label="Markets"
        render={(v) => R.uniq(R.map((l) => l.market?.display_name, v.locations)).join('\n')}
        data-testid="assignments_table-markets"
      />
      <FunctionField
        label="Account Manager"
        render={(r) =>
          r.administrator &&
          r.administrator.account && (
            <Link to={`/Administrator/${r.administrator_id}/show`}>
              {r.administrator.account.name}
            </Link>
          )
        }
        data-testid="assignments_table-account_manager"
      />
      <SalesAssociateField label="Sales Associate" />
      <FunctionField
        label="Clinical Account Manager"
        render={(r) =>
          r.recruiter &&
          r.recruiter.account && (
            <Link to={`/Administrator/${r.recruiter_id}/show`}>{r.recruiter.account.name}</Link>
          )
        }
        data-testid="assignments_table-clinical_account_manager"
      />
      <TextField
        label="Number of Openings"
        source="number_of_openings"
        data-testid="assignments_table-number_of_openings"
      />
      <FunctionField
        label="Parent Assignment"
        render={(v) =>
          v.original_assignment_id ? (
            <LinkField to={`/Assignment/${v.original_assignment_id}/show`}>
              {v.original_assignment_id}
            </LinkField>
          ) : (
            ''
          )
        }
        data-testid="assignments_table-parent_assignment"
      />
    </Datagrid>
  </List>
);

AssignmentsList.query = gql`
  query assignments($input: AssignmentQueryInput) {
    _assignmentsMeta(input: $input) {
      count
    }
    assignments(input: $input) {
      id
      status
      company {
        id
        msp {
          id
          label
        }
        health_system {
          id
          label
        }
        vms_type {
          id
          label
          name
        }
      }
      order_number
      on_hold
      paused
      professional_id
      professional {
        id
        account {
          id
          first_name
          last_name
        }
      }
      locations {
        id
        timezone_lookup
        name
        market {
          id
          display_name
        }
        state {
          id
          name
        }
      }
      company_id
      starts_date
      ends_date
      number_of_shifts_per_week
      hours_per_shift
      charge_base_hourly_rate_cents
      charge_per_week_cents
      charge_blended_hourly_rate_cents
      assignment_request_applied_count
      assignment_requests {
        id
        phone_screening_request {
          id
          assignment_request {
            id
            professional_id
          }
          confirmed_phone_screening_time
          masked_professional_phone_number
          status
        }
      }
      positions {
        id
        display_name
      }
      administrator_id
      administrator {
        id
        account {
          id
          name
        }
      }
      recruiter_id
      recruiter {
        id
        account {
          id
          name
        }
      }
      number_of_openings
      original_assignment_id
      primary_location {
        id
        timezone_lookup
        covid_requirement
      }
      starts_time
      ends_time
      onboarding_due_date
      schedule_type
      is_w2
      pay_per_week_cents
      one_time_bonus_burden_cents
      flexible_starts_date
      number_of_weeks
      assignment_locations {
        primary_location
        location {
          id
          sales_associate {
            id
            account {
              name
            }
          }
        }
      }
    }
  }
`;

export default AssignmentsList;
