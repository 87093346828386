import * as R from 'ramda';
import { getResourceQueries } from './utils';
import { useGraphQLRequest } from 'providers/graphqlPermissions';

const destructureString = (stringName, value) => {
  const names = stringName.split('.');
  let obj = { [names.pop()]: value };
  for (let x = names.length - 1; x >= 0; x--) {
    const name = names[x];
    obj = { [name]: obj };
  }
  return obj;
};

export const useGetManyReference = () => {
  const { gqlRequest } = useGraphQLRequest();

  return (
    resource,
    {
      target,
      id,
      pagination: { page, perPage },
      sort: { field, order },
      filter: { search = {}, ...restFilter } = {},
    },
  ) => {
    const queries = getResourceQueries(resource);
    const fields = target.split('.');
    if (fields[0] === 'search') {
      target = fields[1];
      search = { ...search, [target]: id };
    } else {
      search = { ...search };
      restFilter = R.mergeAll([restFilter, { [target]: id }]);
    }
    return gqlRequest({
      fetchPolicy: 'no-cache',
      query: queries.list.query,
      variables: {
        input: {
          filter: restFilter,
          search: search,
          page,
          perPage,
          orderBy: field ? destructureString(field, order === 'ASC') : undefined,
        },
      },
    }).then((response) => {
      const data = response.data[queries.list.name];
      const metaData = response.data[queries.list.metaName];
      return {
        data,
        total: metaData ? metaData.count : data.length,
      };
    });
  };
};
