import React from 'react';
import { SimpleShowLayout } from 'react-admin';
import AccountsTab from 'components/tabs/AccountsTab';

const CompanyAccountsTab = () => (
  <SimpleShowLayout>
    <AccountsTab target="company_id" />
  </SimpleShowLayout>
);

export default CompanyAccountsTab;
