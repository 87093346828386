import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, List, TextField } from 'react-admin';
import IdField from 'components/IdField';

const LocationGroupsList = () => (
  <List exporter={false} title="Location Groups">
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <TextField label="Name" source="name" />
    </Datagrid>
  </List>
);

LocationGroupsList.query = gql`
  query GET_LOCATION_GROUP_LIST($input: LocationGroupQueryInput) {
    locationGroups(input: $input) {
      id
      company_id
      name
      description
      calculated_descendant_ids
      child_group_ids
      location_ids
      account_ids
    }
  }
`;

export default LocationGroupsList;
