import React from 'react';
import { Edit, SimpleForm, TextInput, SelectInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import AdministratorInput from 'components/Filter/AdministratorInput';
import DateTimeInput from 'components/DateTimeInput';
import TimesheetUpload from './TimesheetUpload';

const mealBreakMinutesChoices = [
  { id: 0, name: '0' },
  { id: 30, name: '30' },
  { id: 60, name: '60' },
];

const DisputeDateTimeInputs = () => {
  const context = useFormContext();
  const timezone = context.getValues()?.job?.location?.timezone_lookup;
  return (
    <>
      <DateTimeInput source="adjusted_clock_in" time_zone={timezone} />
      <DateTimeInput source="adjusted_clock_out" time_zone={timezone} />
    </>
  );
};

const DisputesEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <DisputeDateTimeInputs />
      <EnumSelectInput source="reason" />
      <EnumSelectInput source="category" />
      <EnumSelectInput source="resolution" />
      <EnumSelectInput source="status" />
      <AdministratorInput
        label="Assigned To"
        source="assigned_to_id"
        resettable
        filter={{ department: 'Dispute' }}
        sort={{ field: 'first_name', order: 'ASC' }}
      />
      <TextInput fullWidth multiline source="facility_notes" />
      <TextInput fullWidth multiline source="admin_notes" />
      <TimesheetUpload />
      <TextInput source="professional_notes" />
      <SelectInput
        label="Original Meal Break Length"
        source="original_meal_break_minutes"
        choices={mealBreakMinutesChoices}
      />
      <SelectInput
        label="Adjusted Meal Break Length"
        source="adjusted_meal_break_minutes"
        choices={mealBreakMinutesChoices}
      />
    </SimpleForm>
  </Edit>
);

export default DisputesEdit;
