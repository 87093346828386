import React, { useState } from 'react';
import {
  Button, ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from '@mui/material';
import useDialog from 'hooks/useDialog';
import create from 'utils/api/create';
import {useNotify, useRefresh, useResourceContext} from "react-admin";

interface DeactivationEventProps {
  data?: any;
  resource?: string;
  url: string;
  method: 'PUT' | 'POST';
  text: string;
}

const reasonChoices = [
  {
    label: 'Duplicate Account',
    value: 'duplicate_account',
  },
  {
    label: 'Advanced in Error',
    value: 'advanced_in_error',
  },
  {
    label: 'Compliance',
    value: 'compliance',
  },
  {
    label: 'Clinical Skills',
    value: 'clinical_skills',
  },
  {
    label: 'Non-Clinical Skills',
    value: 'non_clinical_skills',
  },
  {
    label: 'Reliability',
    value: 'reliability',
  },
  {
    label: 'Deletion Request',
    value: 'deletion_request',
  },
];

const DeactivationEvent = ({
  data,
  url,
  text,
  ...props
  }: DeactivationEventProps) => {
  const { open, closeDialog, openDialog } = useDialog();

  return (
    <>
      <Button onClick={openDialog}>{text}</Button>
      <DeactivationDialog
        open={open}
        closeDialog={closeDialog}
        url={url}
        data={data}
        props={props}
      />
    </>
  );
};

const DeactivationDialog = ({ closeDialog, data, open, props, url }) => {
  const [deactivationReason, setDeactivationReason] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const resourceContext = useResourceContext(props);

  const submitDeactivation = async () => {
    try {
      setIsLoading(true);
      const response = await create(
        url,
        { ...data, deactivation_reason: deactivationReason },
        resourceContext,
      );

      notify(response?.data?.message ?? 'Success', { type: 'success' });
    } catch (error: any) {
      notify(error?.message ?? 'Error', { type: 'error' });
    } finally {
      setIsLoading(false);
      refresh();
    }
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Deactivate Reason</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          value={deactivationReason}
          label="Select Deactivation Reason"
          onChange={({ target }) => setDeactivationReason(target.value as string)}
          required
          style={{ width: '550px', marginBottom: '20px' }}
        >
          {reasonChoices.map((reason) => (
            <MenuItem key={reason.value} value={reason.value}>
              {reason.label}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          disabled={!deactivationReason || isLoading}
          onClick={submitDeactivation}
          color="primary"
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeactivationEvent;
