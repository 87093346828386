import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { BooleanField, Datagrid, BooleanInput, DateInput, DateField } from 'react-admin';
import List from 'components/List';
import MarketInput from 'components/MarketInput';
import PositionInput from 'components/PositionInput';
import LinkField from 'components/LinkField';
import MoneyField from 'components/MoneyField';

const MarketPositionRateSettingListFilters = [
  <IdInput />,
  <PositionInput emptyText="All" alwaysOn />,
  <MarketInput alwaysOn resettable />,
  <BooleanInput alwaysOn source="active" />,
  <BooleanInput label="Below State Rate" alwaysOn source="search.below_state_rate" />,
  <DateInput alwaysOn label="Last review after" source="search.last_rate_review_from" />,
];

const MarketPositionRateSettingList = () => (
  <List filters={MarketPositionRateSettingListFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <LinkField label="ID" source="id" />
      <LinkField label="Market" source="market.display_name" />
      <LinkField label="Position" source="position.display_name" />
      <LinkField label="Specialty" source="specialty.label" />
      <MoneyField label="Charge Rate" source="settings.charge_rate_cents" />
      <MoneyField label="Charge Rate Minimum" source="settings.charge_rate_minimum_cents" />
      <BooleanField source="active" />
      <DateField source="last_rate_review" />
    </Datagrid>
  </List>
);

MarketPositionRateSettingList.query = gql`
  query marketPositionRateSettings($input: MarketPositionRateSettingQueryInput) {
    _marketPositionRateSettingsMeta(input: $input) {
      count
    }
    marketPositionRateSettings(input: $input) {
      id
      active
      last_rate_review
      market {
        id
        display_name
      }
      market_id
      market_position_rate_setting_active
      market_position_rate_setting_id
      position {
        id
        display_name
      }
      position_id
      settings {
        agency_charge_rate_cents
        assignment_bonus_tier1_travel_amount_cents
        assignment_bonus_tier1_travel_amount_tcv_cents
        assignment_bonus_tier2_travel_amount_cents
        assignment_bonus_tier2_travel_amount_tcv_cents
        can_post_assignment
        can_post_job
        charge_on_call_hourly_rate_cents
        charge_on_call_hourly_rate_currency
        charge_overtime_multiplier
        charge_rate_cents
        charge_rate_minimum_cents
        fee_percent
        margin_percent_1099
        margin_percent_w2_local
        margin_percent_w2_tcv
        margin_percent_w2_travel
        margin_w2_tcv_cents
        on_call_fee_percent
        payout_on_call_hourly_rate_cents
        payout_on_call_hourly_rate_currency
        payout_overtime_multiplier
        ten99_assignment
        ten99_per_diem
        w2
        w2_fee_percent
      }
      specialty {
        id
        label
      }
      specialty_id
      state_position_rate_setting_active
      state_position_rate_setting_id
    }
  }
`;

export default MarketPositionRateSettingList;
