import React from 'react';
import { Button, ButtonGroup, Chip } from '@mui/material';
import { useRecordContext } from 'react-admin';
import useCredentials from './useCredentials';

export enum FilterEnum {
  ALL = 'All',
  MEDELY_REQUIRED = 'Medely Required',
  OPTIONAL = 'Optional',
  ARCHIVED = 'Archived',
  SATISFIED_CONDITIONS = 'Satisfied Conditions',
}

type CredentialsFilterProps = {
  currentFilter: FilterEnum;
  setCurrentFilter: (filter: FilterEnum) => void;
  children: ({ data }) => React.ReactNode;
};

const CredentialsFilter = ({
  currentFilter,
  setCurrentFilter,
  children,
}: CredentialsFilterProps) => {
  const record = useRecordContext();
  const credentials = useCredentials(record.id);

  const credentialsFilterLength = (filter: FilterEnum) => {
    return filter === FilterEnum.SATISFIED_CONDITIONS
      ? record?.satisfied_conditions?.length ?? 0
      : credentials[filter].length;
  };

  return (
    <>
      <ButtonGroup sx={{ margin: '16px 0' }}>
        {Object.values(FilterEnum).map((filter) => (
          <Button
            data-testid="credentials-filter"
            variant={filter === currentFilter ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setCurrentFilter(filter)}
            key={filter}
            sx={{ fontWeight: 700 }}
          >
            {filter}
            <Chip
              size="small"
              label={credentialsFilterLength(filter)}
              sx={{ marginLeft: '0.5rem', backgroundColor: '#EEEEEE' }}
            />
          </Button>
        ))}
      </ButtonGroup>
      <div className="RaDatagrid-root">{children({ data: credentials[currentFilter] ?? [] })}</div>
    </>
  );
};

export default CredentialsFilter;
