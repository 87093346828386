import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, NumberInput } from 'react-admin';
import MoneyField from 'components/MoneyField';
import { IdFilter } from 'components/Filter';
import List from 'components/List';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';
import InvoiceInput from 'components/InvoiceInput';
import ImportTransactions from 'components/ImportTransactions';

const FinancialTransactionsFilters = [
  <IdFilter />,
  <InvoiceInput alwaysOn />,
  <NumberInput alwaysOn label="Billing Group ID" source="billing_group_id" />,
  <NumberInput alwaysOn label="Financial Transaction ID" source="financial_transaction_id" />,
];

const ItemizedTransactionsList = () => (
  <List
    exporter={false}
    filters={FinancialTransactionsFilters}
    additionalActions={<ImportTransactions type="Itemized" />}
  >
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Financial Transaction ID" source="financial_transaction.id" />
      <LinkField label="Billing Group ID" source="billing_group_id" typename="BillingGroup" />
      <MoneyField label="Amount" source="amount_cents" />
      <TextField label="From" source="from_source" />
      <TextField label="To" source="to_source" />
      <LinkField label="Invoice ID" source="invoice.id" />
      <TextField label="Notes" source="notes" />
    </Datagrid>
  </List>
);

ItemizedTransactionsList.query = gql`
  query GET_LIST($input: ItemizedTransactionQueryInput) {
    _itemizedTransactionsMeta(input: $input) {
      count
    }
    itemizedTransactions(input: $input) {
      id
      billing_group_id
      notes
      invoice {
        id
      }
      financial_transaction_id
      amount_cents
      billing_group_id
      amount_currency
      from_source
      to_source
      created_at
      financial_transaction {
        id
        amount_cents
        amount_left_cents
        kind
        transaction_id
      }
    }
  }
`;

export default ItemizedTransactionsList;
