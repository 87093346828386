import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, DateField } from 'react-admin';
import List from 'components/List';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';

const AssignmentProfileList = () => (
  <List exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Assignment" source="assignment_id" />
      <TextField source="professional_type" />
      <TextField source="payout_kind" />
      <DateField showTime options={{ hour12: false }} source="created_at" />
      <DateField showTime options={{ hour12: false }} source="updated_at" />
    </Datagrid>
  </List>
);

AssignmentProfileList.query = gql`
  query GET_LIST($input: AssignmentProfileQueryInput) {
    _assignmentProfilesMeta(input: $input) {
      count
    }
    assignmentProfiles(input: $input) {
      active
      agency_id
      assignment_id
      available_at
      available_to_professionals_at
      charge_base_hourly_rate_cents
      charge_callback_multiplier
      charge_double_overtime_multiplier
      charge_max_daily_ot_hours
      charge_max_daily_regular_hours
      charge_max_weekly_regular_hours
      charge_on_call_hourly_rate_cents
      charge_overtime_multiplier
      charge_seventh_day_rule_enabled
      id
      payout_base_hourly_rate_cents
      payout_callback_multiplier
      payout_double_overtime_multiplier
      payout_kind
      payout_max_daily_ot_hours
      payout_max_daily_regular_hours
      payout_max_weekly_regular_hours
      payout_on_call_hourly_rate_cents
      payout_overtime_multiplier
      payout_seventh_day_rule_enabled
      payout_taxable_hourly_rate_cents
      professional_type
    }
  }
`;

export default AssignmentProfileList;
