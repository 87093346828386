export const getAllChildrenOfLocationGroup = (locationGroup: any, locationGroups: any[]) => {
  const dictionary = Object.assign(
    {},
    ...(locationGroups || []).map((locGroup) => ({ [locGroup.id]: locGroup })),
  );

  const locations = new Set<string>();
  const childGroups = new Set<string>();

  const queue = [locationGroup.id];

  while (queue.length > 0) {
    const currentLocationGroupId = queue.shift();
    const currentLocationGroup = dictionary[currentLocationGroupId];
    if (currentLocationGroup?.location_ids.length) {
      currentLocationGroup.location_ids.forEach((locationId) => locations.add(locationId));
    }
    if (currentLocationGroup?.child_group_ids.length) {
      currentLocationGroup.child_group_ids.forEach((childGroupId) => {
        childGroups.add(childGroupId);
        queue.push(childGroupId);
      });
    }
  }

  return {
    allLocations: [...locations],
    allChildGroups: [...childGroups],
  };
};
