import React from 'react';
import {
  TextField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  SimpleShowLayout,
  FunctionField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
import LinkField from 'components/LinkField';
import DateTimeField from 'components/DateTimeField';
import FavoriteIdsField from 'components/FavoriteIdsField';
import EnumField from 'components/Enum/EnumField';

const JobsTab = () => (
  <SimpleShowLayout>
    <ReferenceManyField
      pagination={<Pagination />}
      label=""
      reference="Job"
      target="location_id"
      sort={{ field: 'starts_date', order: 'DESC' }}
    >
      <Datagrid bulkActionButtons={false}>
        <LinkField label="ID" source="id" />
        <LinkField label="Professional ID" source="professional_id" />

        <DateTimeField
          sortBy="starts_date"
          source="starts_time"
          tzSource="location.timezone_lookup"
        />
        <FunctionField
          label="Ends Time"
          render={(record) => {
            if (record.shift_type === 'call_back') {
              return (
                <DateTimeField
                  source="callback_ends_time"
                  record={record}
                  tzSource="location.timezone_lookup"
                />
              );
            } else if (record.shift_type === 'on_call') {
              return (
                <DateTimeField
                  source="on_call_ends_time"
                  record={record}
                  tzSource="location.timezone_lookup"
                />
              );
            } else {
              return (
                <DateTimeField
                  source="ends_time"
                  record={record}
                  tzSource="location.timezone_lookup"
                />
              );
            }
          }}
        />
        <TextField label="Shift Type" source="shift_type" />
        <ArrayField source="positions">
          <SingleFieldList linkType={false}>
            <ChipField source="display_name" />
          </SingleFieldList>
        </ArrayField>
        <EnumField source="status" />
        <FavoriteIdsField />
      </Datagrid>
    </ReferenceManyField>
  </SimpleShowLayout>
);

export default React.memo(JobsTab);
