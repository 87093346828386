import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { Button, useResourceContext, useRecordContext, useBasename } from 'react-admin';

const MergeCompaniesButton = (props) => {
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const basename = useBasename();
  if (!record) {
    return null;
  }
  return (
    <Button component={Link} to={`${basename}/${resource}/${record.id}/merge`} label="Merge" />
  );
};

export default memo(MergeCompaniesButton);
