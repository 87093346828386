import React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  SimpleShowLayout,
  NumberField,
  BooleanField,
  useRecordContext,
} from 'react-admin';
import IdField from 'components/IdField';
import LinkField from 'components/LinkField';
import useCheckAccess from 'hooks/useCheckAccess';
import MoneyField from 'components/MoneyField';
import DateTimeField from 'components/DateTimeField';

const AssignmentProfileAvailabilityField = (props) => {
  const record = useRecordContext();
  const timezone = record?.assignment?.location?.timezone_lookup ?? 'America/Los_Angeles';
  return <DateTimeField {...props} timeZone={timezone} />;
};

const AssignmentProfileTab = () => {
  const checkAccess = useCheckAccess();
  return (
    <SimpleShowLayout>
      <ReferenceManyField
        label=""
        pagination={<Pagination />}
        reference="AssignmentProfile"
        target="assignment_id"
      >
        <Datagrid bulkActionButtons={false}>
          {checkAccess('view', 'AssignmentProfile', 'id') && <IdField />}
          {checkAccess('view', 'AssignmentProfile', 'professional_type') && (
            <TextField source="professional_type" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'agency_id') && (
            <LinkField label="Agency" source="agency_id" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'payout_kind') && (
            <TextField source="payout_kind" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'active') && <BooleanField source="active" />}
          {checkAccess('view', 'AssignmentProfile', 'available_at') && (
            <AssignmentProfileAvailabilityField
              label="Available for booking"
              source="available_at"
              sortable={false}
            />
          )}
          {checkAccess('view', 'AssignmentProfile', 'available_to_professionals_at') && (
            <AssignmentProfileAvailabilityField
              label="Published to pros"
              source="available_to_professionals_at"
              sortable={false}
            />
          )}
          {checkAccess('view', 'AssignmentProfile', 'charge_base_hourly_rate_cents') && (
            <MoneyField label="Charge Base Hourly Rate" source="charge_base_hourly_rate_cents" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'charge_on_call_hourly_rate_cents') && (
            <MoneyField
              label="Charge On Call Hourly Rate"
              source="charge_on_call_hourly_rate_cents"
            />
          )}
          {checkAccess('view', 'AssignmentProfile', 'charge_callback_multiplier') && (
            <NumberField source="charge_callback_multiplier" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'charge_overtime_multiplier') && (
            <NumberField source="charge_overtime_multiplier" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'charge_double_overtime_multiplier') && (
            <NumberField source="charge_double_overtime_multiplier" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'charge_max_daily_regular_hours') && (
            <NumberField source="charge_max_daily_regular_hours" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'charge_max_daily_ot_hours') && (
            <NumberField source="charge_max_daily_ot_hours" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'charge_max_weekly_regular_hours') && (
            <NumberField source="charge_max_weekly_regular_hours" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'charge_seventh_day_rule_enabled') && (
            <BooleanField source="charge_seventh_day_rule_enabled" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'payout_base_hourly_rate_cents') && (
            <MoneyField label="Payout Base Hourly Rate" source="payout_base_hourly_rate_cents" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'payout_taxable_hourly_rate_cents') && (
            <MoneyField
              label="Payout Taxable Hourly Rate"
              source="payout_taxable_hourly_rate_cents"
            />
          )}
          {checkAccess('view', 'AssignmentProfile', 'payout_on_call_hourly_rate_cents') && (
            <MoneyField
              label="Payout On Call Hourly Rate"
              source="payout_on_call_hourly_rate_cents"
            />
          )}
          {checkAccess('view', 'AssignmentProfile', 'payout_callback_multiplier') && (
            <NumberField source="payout_callback_multiplier" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'payout_overtime_multiplier') && (
            <NumberField source="payout_overtime_multiplier" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'payout_double_overtime_multiplier') && (
            <NumberField source="payout_double_overtime_multiplier" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'payout_max_daily_regular_hours') && (
            <NumberField source="payout_max_daily_regular_hours" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'payout_max_daily_ot_hours') && (
            <NumberField source="payout_max_daily_ot_hours" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'payout_max_weekly_regular_hours') && (
            <NumberField source="payout_max_weekly_regular_hours" />
          )}
          {checkAccess('view', 'AssignmentProfile', 'payout_seventh_day_rule_enabled') && (
            <BooleanField source="payout_seventh_day_rule_enabled" />
          )}
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default AssignmentProfileTab;
