import React from 'react';
import { Edit, SimpleForm, TextInput, required, SelectInput } from 'react-admin';
import PayloadInput from './PayloadInput';
import SlugInput from './SlugInput';

const ConditionEdit = () => (
  <Edit redirect="show">
    <SimpleForm>
      <TextInput label="Name" source="name" validate={[required()]} />
      <TextInput label="Label" source="label" validate={[required()]} />
      <SlugInput />
      <SelectInput
        label="Condition Type"
        source="condition_type"
        choices={[
          { id: 'application_q_and_a', name: 'Application Q & A' },
          { id: 'certification', name: 'Certification' },
          { id: 'document', name: 'Document' },
          { id: 'facility_onboarding', name: 'Facility Onboarding' },
          { id: 'license', name: 'License' },
          { id: 'signed_document', name: 'Signed Document' },
          { id: 'typeform', name: 'Typeform' },
          { id: 'work_history', name: 'Work History' },
          { id: 'background_check', name: 'Background Check'},
        ]}
        validate={[required()]}
      />
      <PayloadInput />
    </SimpleForm>
  </Edit>
);

export default ConditionEdit;
