import React from 'react';
import { AutocompleteArrayInput, Identifier, Validator, required } from 'react-admin';

interface AccountPermissionLocationInputProps {
  disabled: boolean;
  choices: { id: Identifier; name: string }[];
  validation?: Validator | Validator[];
}

const AccountPermissionLocationInput = ({
  disabled,
  choices,
  validation = required(),
}: AccountPermissionLocationInputProps) => {
  return (
    <AutocompleteArrayInput
      key={`location_ids-${disabled}`}
      source="direct_location_ids"
      choices={choices}
      disabled={disabled}
      validate={disabled ? undefined : validation}
      data-testid="account-permission_group_location"
    />
  );
};

export default AccountPermissionLocationInput;
