import { Show, TabbedShowLayout, Tab, TabbedShowLayoutTabs } from 'react-admin';
import ChildMemosTab from '../tabs/ChildMemosTab';
import DisputesTab from 'components/tabs/DisputesTab';
import InvoiceShowActions from '../components/ShowActions';
import ItemizedTransactionsTab from '../tabs/ItemizedTransactionsTab';
import JobBillingsTab from 'components/tabs/JobBillingsTab';
import React from 'react';
import RevenueAdjustmentsTab from 'components/tabs/RevenueAdjustmentsTab';
import useCheckAccess from 'hooks/useCheckAccess';
import NotesTab from 'components/tabs/NotesTab';
import TimecardsTab from 'components/tabs/TimecardsTab';
import useUpdateAppLocation from 'hooks/useUpdateAppLocation';
import InvoiceTitle from './InvoiceTitle';

const InvoicesShow = ({ invoice_type, summaryTab, showDisputes, showChildMemos }) => {
  const checkAccess = useCheckAccess();
  useUpdateAppLocation(`Invoice_${invoice_type}`, 'Invoice.show');

  return (
    <Show
      title={<InvoiceTitle invoice_type={invoice_type} />}
      actions={<InvoiceShowActions />}
      data-testid="invoices-show"
    >
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="Summary">{summaryTab}</Tab>
        <Tab label="Itemized Transactions" path="itemized-transactions">
          <ItemizedTransactionsTab />
        </Tab>
        {checkAccess('view', 'RevenueAdjustment', 'invoice_id') && (
          <Tab label="Revenue Adjustments" path="revenue-adjustments">
            <RevenueAdjustmentsTab target="invoice_id" />
          </Tab>
        )}
        {checkAccess('view', 'JobBilling', 'invoice_id') && (
          <Tab label="Job Billings" path="job-billings">
            <JobBillingsTab target="invoice_id" />
          </Tab>
        )}
        {checkAccess('view', 'Dispute', 'invoice_id') && showDisputes && (
          <Tab label="Disputes" path="disputes">
            <DisputesTab target="invoice_id" />
          </Tab>
        )}
        <Tab label="Notes" path="notes">
          <NotesTab />
        </Tab>
        <Tab label="Timecards" path="timecards">
          {checkAccess('view', 'Timecard', 'id') && <TimecardsTab />}
        </Tab>
        {checkAccess('view', 'InvoicesLink', 'child_invoice_id') && showChildMemos && (
          <Tab label="Child memos" path="child-memos">
            <ChildMemosTab />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

export default InvoicesShow;
