import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import PolymorphicField from 'components/PolymorphicField';
import List from 'components/List';

const NotesList = () => (
  <List exporter={false} data-testid="notes-list">
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField label="Author" source="author.account.name" />
      <PolymorphicField source="recipient" />
      <TextField source="text" />
    </Datagrid>
  </List>
);

NotesList.query = gql`
  query GET_LIST($input: NoteQueryInput) {
    _notesMeta(input: $input) {
      count
    }
    notes(input: $input) {
      id
      created_at
      updated_at
      text
      recipient_type
      recipient_id
      recipient {
        ... on BillingGroup {
          id
        }
        ... on Location {
          id
        }
        ... on Professional {
          id
        }
        ... on FinancialTransaction {
          id
        }
      }
      author_id
      author {
        id
        account {
          name
        }
      }
    }
  }
`;

export default NotesList;
