import EnumSelectInput from 'components/Enum/EnumSelectInput';
import React from 'react';
import { Edit, TextInput, SimpleForm, SelectArrayInput } from 'react-admin';

const AgenciesEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <SimpleForm>
        <TextInput label="Name" source="name" />
        <TextInput label="Legal Name" source="legal_name" />
        <EnumSelectInput label="Status" source="status" />
        <SelectArrayInput
          label="Job Types"
          source="job_types"
          choices={[
            { id: 'per_diem', name: 'Per Diem' },
            { id: 'permanent_placement', name: 'Permanent Placement' },
            { id: 'travel', name: 'Travel' },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default AgenciesEdit;
