import React from 'react';
import { TextInput, TextInputProps } from 'react-admin';

interface SearchInputProps extends TextInputProps {
  maxLength?: number;
}

const SearchInput = ({ maxLength = 150, ...props }: SearchInputProps) => (
  <TextInput
    {...props}
    data-testid="search-input"
    parse={(value) => (value && value?.length > maxLength ? value?.slice(0, maxLength) : value)}
    label={`Search (Limit ${maxLength} characters)`}
  />
);

SearchInput.defaultProps = {
  alwaysOn: true,
  source: 'q',
};

export default SearchInput;
