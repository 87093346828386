import React from 'react';
import {
  DeleteWithConfirmButton,
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  WithRecord,
  useRecordContext,
} from 'react-admin';
import AccountsArrayInput from './AccountsArrayInput';

const CustomToolbar = () => {
  const record = useRecordContext();
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton />
      <DeleteWithConfirmButton
        confirmContent="Are you sure you want to delete this group?"
        mutationMode="pessimistic"
        redirect={record ? `/Company/${record.company_id}/show/approval-groups` : 'list'}
      />
    </Toolbar>
  );
};

const ApprovalGroupsEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect={false}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source="name" />
        <WithRecord render={(record) => <AccountsArrayInput company_id={record?.company_id} />} />
      </SimpleForm>
    </Edit>
  );
};

export default ApprovalGroupsEdit;
