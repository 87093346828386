import React from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput, AutocompleteArrayInput } from 'react-admin';
import InputUtils from 'utils/input';
import ReferenceArrayInput from './ReferenceArrayInput';

const InvoiceInput = (props) => (
  props.multiselect ? (
    <ReferenceArrayInput {...props}>
      <AutocompleteArrayInput
        optionText={InputUtils.optionText('id')}
        {...getChildrenProps(props)}
        data-testid="invoice-input"
      />
    </ReferenceArrayInput>
  ) : (
    <ReferenceInput {...props}>
      <AutocompleteInput
        optionText={InputUtils.optionText('id')}
        {...getChildrenProps(props)}
        data-testid="invoice-input"
      />
    </ReferenceInput>
  )
);

InvoiceInput.defaultProps = {
  label: 'Invoice ID',
  source: 'invoice_id',
  reference: 'Invoice',
  sort: { field: 'id', order: 'ASC' },
  perPage: 25,
  fullWidth: false,
};

export default InvoiceInput;
