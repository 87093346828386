import React from 'react';
import {
  ReferenceManyField,
  TextInput,
  NumberInput,
  TextField,
  SelectInput,
  FormDataConsumer,
  SimpleShowLayout,
  Datagrid,
  DeleteWithConfirmButton,
  FunctionField,
  Pagination,
  ArrayField,
  SingleFieldList,
  ChipField,
  useRecordContext,
  AutocompleteInput,
} from 'react-admin';
import { Box } from '@mui/material';
import LinkField from 'components/LinkField';
import PolymorphicField from 'components/PolymorphicField';
import { ENUMS } from '../Enum/enums';
import { choicesFromEnum } from 'utils/helpers';
import QuickCreateButton from '../QuickCreateButton';
import ReferenceInput from '../ReferenceInput';
import DateTimeField from '../DateTimeField';
import Heading from '../Heading';
import _ from 'lodash';
import MassBlockInputs from 'resources/relationships/MassBlockInputs';

const sortByName = (arr) => _.sortBy(arr, 'name');

const choices = sortByName(
  choicesFromEnum(ENUMS.Relationship.kind, {
    strings: true,
  }),
);

const RelationName = ({ record, source }: { record?: Record<string, any>; source: string }) => {
  return <>{record?.[source].name ?? record?.[source].display_name}</>;
};

const getRelationshipsTab = (requester, requestee) =>
  function RelationshipTab(props) {
    const reasonType = requester === 'Location' ? 'LocationBlockReason' : 'ProfessionalBlockReason';
    const record = useRecordContext();
    const data = {
      requester_id: record?.id,
      requester_type: requester,
      requestee_type: requestee,
    };
    const search = requester === 'Location' ? { exclude_deactivated: true } : {};
    return (
      <SimpleShowLayout>
        {props.canCreate && (
          <Box my={3}>
            <QuickCreateButton initialValues={data} resource="Relationship">
              <SelectInput
                source="kind"
                choices={choices}
                data-testid="professional_show-relationships_tab_create_kind"
              />
              <FormDataConsumer>
                {(formDataProps) => {
                  const formData = formDataProps.formData || {};
                  if (formData.kind !== 'block') {
                    return;
                  }
                  const blockReasonFilter =
                    reasonType === 'LocationBlockReason' ? { active: true } : {};

                  return (
                    <>
                      <Box>
                        <ReferenceInput
                          reference={reasonType}
                          source="reason_id"
                          sort={{ field: 'label', order: 'ASC' }}
                          perPage={100}
                          filter={blockReasonFilter}
                        >
                          <AutocompleteInput optionText="label" />
                        </ReferenceInput>
                      </Box>
                      <Box>
                        <SelectInput
                          defaultValue={reasonType}
                          source="reason_type"
                          choices={[
                            {
                              id: reasonType,
                              name: reasonType,
                            },
                          ]}
                        />
                      </Box>
                      <MassBlockInputs requester={requester} />
                    </>
                  );
                }}
              </FormDataConsumer>
              <NumberInput
                source="requestee_id"
                data-testid="professional_show-relationships_tab_create_requestee_id"
              />
              <TextInput
                fullWidth
                multiline={true}
                source="comments"
                data-testid="professional_show-relationships_tab_create_comments"
              />
            </QuickCreateButton>
          </Box>
        )}

        <Heading>{`Blocked ${requestee}s`}</Heading>
        <ReferenceManyField
          target="requester_id"
          filter={{
            search,
            requester_type: requester,
            requestee_type: requestee,
            kind: ENUMS.Relationship.kind.block,
          }}
          perPage={10}
          pagination={<Pagination />}
          reference="Relationship"
          label=""
        >
          <Datagrid bulkActionButtons={false}>
            <FunctionField
              label="ID"
              render={(v) => <LinkField to={`/Relationship/${v.id}/show`}>{v.id}</LinkField>}
            />
            <FunctionField
              label="Name"
              render={(v) => <RelationName record={v} source="requestee" />}
            />
            <PolymorphicField source="requestee" />
            <TextField label="Reason" source="reason.label" />
            <TextField source="comments" />
            <DateTimeField source="created_at" />
            {props.canDelete && (
              <DeleteWithConfirmButton
                redirect={`/${requester}/${record?.id}/show/relationships`}
              />
            )}
          </Datagrid>
        </ReferenceManyField>

        <Heading>{`Blocked by ${requestee}s`}</Heading>
        <ReferenceManyField
          target="requestee_id"
          filter={{
            search,
            requestee_type: requester,
            requester_type: requestee,
            kind: ENUMS.Relationship.kind.block,
          }}
          perPage={10}
          pagination={<Pagination />}
          reference="Relationship"
          label=""
        >
          <Datagrid bulkActionButtons={false}>
            <FunctionField
              label="ID"
              render={(v) => <LinkField to={`/Relationship/${v.id}/show`}>{v.id}</LinkField>}
            />
            <FunctionField
              label="Name"
              render={(v) => <RelationName record={v} source="requester" />}
            />
            <PolymorphicField source="requester" />
            <TextField label="Reason" source="reason.label" />
            <TextField source="comments" />
            <DateTimeField source="created_at" />
            {props.canDelete && (
              <DeleteWithConfirmButton
                redirect={`/${requester}/${record?.id}/show/relationships`}
              />
            )}
          </Datagrid>
        </ReferenceManyField>

        {requester === 'Location' && (
          <>
            <Heading>{`Favorite ${requestee}s`}</Heading>
            <ReferenceManyField
              label=""
              target="requester_id"
              filter={{
                search,
                requester_type: requester,
                requestee_type: requestee,
                kind: ENUMS.Relationship.kind.favorite,
              }}
              perPage={10}
              pagination={<Pagination />}
              reference="Relationship"
            >
              <Datagrid bulkActionButtons={false}>
                <FunctionField
                  label="ID"
                  render={(v) => <LinkField to={`/Relationship/${v.id}/show`}>{v.id}</LinkField>}
                />
                <FunctionField
                  label="Name"
                  render={(v) => <RelationName record={v} source="requestee" />}
                />
                <PolymorphicField source="requestee" />
                <TextField label="Reason" source="reason.label" />
                <TextField source="comments" />
                <ArrayField label="Requestee Positions" source="requestee.positions">
                  <SingleFieldList linkType={false}>
                    <ChipField source="display_name" />
                  </SingleFieldList>
                </ArrayField>
                <DateTimeField source="created_at" />
                {props.canDelete && (
                  <DeleteWithConfirmButton
                    redirect={`/${requester}/${record?.id}/show/relationships`}
                  />
                )}
              </Datagrid>
            </ReferenceManyField>
          </>
        )}

        {requester === 'Professional' && (
          <>
            <Heading>{`Favored by ${requestee}s`}</Heading>
            <ReferenceManyField
              label=""
              target="requestee_id"
              filter={{
                search,
                requestee_type: requester,
                requester_type: requestee,
                kind: ENUMS.Relationship.kind.favorite,
              }}
              perPage={10}
              pagination={<Pagination />}
              reference="Relationship"
            >
              <Datagrid
                bulkActionButtons={false}
                data-testid="professional_show-relationships_tab_favored_table"
              >
                <FunctionField
                  label="ID"
                  render={(v) => <LinkField to={`/Relationship/${v.id}/show`}>{v.id}</LinkField>}
                />
                <FunctionField
                  label="Name"
                  render={(v) => <RelationName record={v} source="requester" />}
                  data-testid="professional_show-relationships_tab_favored_table_name"
                />
                <PolymorphicField source="requester" />
                <TextField
                  label="Reason"
                  source="reason.label"
                  data-testid="professional_show-relationships_tab_favored_table_reason"
                />
                <TextField
                  source="comments"
                  data-testid="professional_show-relationships_tab_favored_table_comments"
                />
                <DateTimeField source="created_at" />
                {props.canDelete && (
                  <DeleteWithConfirmButton
                    redirect={`/${requester}/${record?.id}/show/relationships`}
                    data-testid="professional_show-relationships_tab_favored_table_delete_button"
                  />
                )}
              </Datagrid>
            </ReferenceManyField>
          </>
        )}

        {requester === 'Professional' && (
          <>
            <Heading>Favorites Location</Heading>
            <ReferenceManyField
              target="requester_id"
              filter={{
                search,
                requestee_type: requestee,
                requester_type: requester,
                kind: ENUMS.Relationship.kind.favorite,
              }}
              perPage={10}
              pagination={<Pagination />}
              reference="Relationship"
            >
              <Datagrid bulkActionButtons={false}>
                <FunctionField
                  label="ID"
                  render={(v) => <LinkField to={`/Relationship/${v.id}/show`}>{v.id}</LinkField>}
                />
                <FunctionField
                  label="Name"
                  render={(v) => <RelationName record={v} source="requestee" />}
                />
                <PolymorphicField source="requestee" />
                <TextField label="Reason" source="reason.label" />
                <TextField source="comments" />
                <DateTimeField source="created_at" />
                {props.canDelete && (
                  <DeleteWithConfirmButton
                    redirect={`/${requester}/${record?.id}/show/relationships`}
                  />
                )}
              </Datagrid>
            </ReferenceManyField>
          </>
        )}
      </SimpleShowLayout>
    );
  };

export default getRelationshipsTab;
