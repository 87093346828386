import React, { useMemo, useRef, useState, useCallback } from 'react';
import { useNotify, useRefresh } from 'react-admin';
import getAxios from 'getAxios';
import { AxiosHeaders, AxiosRequestConfig } from 'axios';

const axios = getAxios();
const headers = new AxiosHeaders({ 'Content-Type': 'multipart/form-data' });

const displayUploadStatus = (response) => {
  if (!response.data.payload) {
    // Payload's an inner object that the backend might not always return.
    return response.data.message || 'Uploaded';
  }
  const payload = response.data.payload;
  if (!payload.data) {
    return payload.message || 'Uploaded';
  }
  let str = 'Uploaded';
  for (const [p, val] of Object.entries(payload.data)) {
    str += ` ${p}::${val}`;
  }
  return str;
};

export function useFileUpload({
  url,
  accept,
  key = 'file',
  config,
  passedHandleChange,
}: {
  url: string;
  accept?: string;
  key?: string;
  config?: Partial<AxiosRequestConfig>;
  passedHandleChange?: any;
}) {
  const ref = useRef<HTMLInputElement | null>(null);
  const openFilePrompt = useCallback(() => ref.current?.click(), [ref]);
  const [uploading, setUploading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSuccess = (response) => {
    const message = displayUploadStatus(response);
    const autoHideDuration = response.data.payload?.data ? 30000 : 5000;
    notify(message, { type: 'success', autoHideDuration });
    refresh();
  };

  const showError = (e) => notify(e.message, { type: 'error', autoHideDuration: 10000 });

  const handleChange = useCallback(
    (e) => {
      setUploading(true);
      const [file] = e.target.files;
      const formData = new FormData();
      formData.append(key, file);
      axios
        .post(url, formData, { headers } ?? config)
        .then(handleSuccess)
        .catch(showError)
        .finally(() => {
          setUploading(false);
          if (ref.current) {
            ref.current.value = '';
          }
        });
    },
    [setUploading, url, ref, notify, refresh, key, config],
  );

  const onChange = passedHandleChange
    ? (e) => passedHandleChange(e, handleSuccess, showError, setUploading, headers, ref, config)
    : handleChange;

  const hiddenInput = useMemo(
    () => <input hidden accept={accept} type="file" ref={ref} onChange={onChange} />,
    [ref, handleChange, accept],
  );
  return { openFilePrompt, uploading, hiddenInput };
}
