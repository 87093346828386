import { Box } from '@medely/web-components';
import React from 'react';
import { DeleteWithConfirmButton, SaveButton } from 'react-admin';

const EditToolbar = () => {
  const style = {
    display: 'flex',
    justifyContent: 'space-between',
    paddingY: '15px',
    paddingX: '24px',
    backgroundColor: '#f5f5f5',
  };
  const msg =
    'Any job billings and revenue adjustments on this invoice/memo associated with a job and/or timecard will be routed to a new invoice/memo during the next bill run but those without will get deleted.';
  return (
    <Box sx={style}>
      <SaveButton />
      <DeleteWithConfirmButton confirmContent={msg} />
    </Box>
  );
};

export default EditToolbar;
