import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField, TopToolbar, CreateButton } from 'react-admin';
import IdInput from 'components/Filter/IdInput';
import List from 'components/List';
import { SearchFilter } from 'components/Filter';
import IdField from 'components/IdField';
import config from 'config';
import { useFileUpload } from 'hooks/useFileUpload';
import { Button, CircularProgress } from '@mui/material';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const AgenciesFilters = [
  <SearchFilter />,
  <IdInput />,
  <EnumSelectInput alwaysOn label="Status" source="status" />,
];

const AgenciesActions = () => {
  const { hiddenInput, openFilePrompt, uploading } = useFileUpload({
    url: `${config.rails_url}/v3/admin/agencies/import`,
    accept: 'text/csv',
  });

  return (
    <TopToolbar>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {hiddenInput}
        <Button
          disabled={uploading}
          onClick={openFilePrompt}
          variant="outlined"
          size="small"
          endIcon={uploading ? <CircularProgress size={20} /> : undefined}
        >
          Import&nbsp;Agencies&nbsp;CSV
        </Button>
        <CreateButton />
      </div>
    </TopToolbar>
  );
};

const AgenciesList = () => (
  <List hasCreate exporter={false} filters={AgenciesFilters} actions={<AgenciesActions />}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
      <TextField source="name" />
      <TextField label="Legal Name" source="legal_name" />
      <TextField label="Status" source="status" />
    </Datagrid>
  </List>
);

AgenciesList.query = gql`
  query GET_LIST($input: AgencyQueryInput) {
    _agenciesMeta(input: $input) {
      count
    }
    agencies(input: $input) {
      id
      name
      legal_name
      status
    }
  }
`;

export default AgenciesList;
