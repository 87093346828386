import React from 'react';
import { NumberInput } from 'react-admin';
import { IdFilter, SearchFilter, AccountManagerFilter } from 'components/Filter';
import List from 'components/List';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import MoneyInput from 'components/MoneyInput';
import DateFilter from 'components/Filter/DateFilter';
import useUpdateAppLocation from 'hooks/useUpdateAppLocation';
import CreateInvoiceButton from '../components/CreateInvoiceButton';
import { Box, Typography } from '@mui/material';
import { humanize } from 'inflection';
import { Inbox } from '@mui/icons-material';

const CommonFilters = [
  <IdFilter />,
  <SearchFilter />,
  <AccountManagerFilter label="Account Manager" alwaysOn source="search.account_manager_id" />,
  <NumberInput label="Billing Group ID" alwaysOn source="billing_group_id" />,
  <EnumSelectInput label="Status" alwaysOn source="status" />,
  <DateFilter label="Starts Date" alwaysOn source="search.starts_date" />,
  <DateFilter label="Ends Date" alwaysOn source="search.ends_date" />,
  <MoneyInput label="Amount" source="search.total_cents" alwaysOn />,
];

const InvoiceListEmpty = ({ invoice_type }) => (
  <Box textAlign="center" m={1} width="100%">
    <Inbox sx={{ fontSize: 200 }} />
    <Typography variant="h4" paragraph>
      No {humanize(invoice_type)}s yet.
    </Typography>
    <Typography variant="body1">Do you want to add one?</Typography>
    <CreateInvoiceButton invoice_type={invoice_type} />
  </Box>
);

type Props = {
  invoice_type: string;
  title: string;
  additionalActions?: React.ReactNode;
  additionalFilters?: React.ReactElement[];
  children: React.ReactNode;
};

const ListView = ({
  invoice_type,
  title,
  additionalActions,
  additionalFilters = [],
  children,
}: Props) => {
  useUpdateAppLocation(`Invoice_${invoice_type}`, 'Invoice.edit');

  return (
    <List
      title={title}
      empty={<InvoiceListEmpty invoice_type={invoice_type} />}
      additionalActions={
        <>
          <CreateInvoiceButton invoice_type={invoice_type} />
          {additionalActions}
        </>
      }
      exporter={false}
      filters={CommonFilters.concat(additionalFilters)}
      filter={{ invoice_type }}
      data-testid="invoices-list"
    >
      {children}
    </List>
  );
};

export default ListView;
