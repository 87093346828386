export const validateSlug = (value: string) => {
  if (value.length < 3) {
    return 'Must be at least 3 characters long';
  } else if (value.length > 256) {
    return 'Must be 256 characters or less';
  } else if (!/^[a-zA-Z0-9\_]+$/.test(value)) {
    return 'Only alphanumeric characters allowed';
  }
  return undefined;
};

export const validateNonZero = (value: number) => {
  if (value === 0) {
    return 'Must be non-zero';
  }
  return undefined;
};
