import React from 'react';
import { gql } from '@apollo/client';
import {
  Datagrid,
  TextField,
  BooleanField,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import List from 'components/List';
import IdInput from 'components/Filter/IdInput';
import IdField from 'components/IdField';
import { SearchFilter } from 'components/Filter';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const AdminFilters = [
  <IdInput />,
  <SearchFilter alwaysOn />,
  <ReferenceInput
    alwaysOn
    source="search.permission_group_id"
    reference="PermissionGroup"
    label="Permission Group"
    perPage={50}
    filter={{
      account_type: 'administrator',
    }}
  >
    <AutocompleteInput
      optionText="display_name"
      label="Permission Group"
      data-testid="administrators-permission_group_filter"
    />
  </ReferenceInput>,
  <EnumSelectInput resettable source="department" alwaysOn />,
];

const AdministratorsList = () => (
  <List filters={AdminFilters} exporter={false} data-testid="administrators-list">
    <Datagrid bulkActionButtons={false}>
      <IdField label="ID" sortBy="id" />
      <TextField label="First Name" source="account.first_name" />
      <TextField label="Last Name" source="account.last_name" />
      <FunctionField
        label="Permission Group"
        render={(record) =>
          record.account?.permission_groups?.map(({ display_name }) => display_name).join(', ')
        }
      />
      <TextField label="Department" source="department" />
      <TextField label="Email" sortable={false} sortBy="email" source="account.email" />
      <TextField
        label="Phone"
        sortable={false}
        sortBy="phone_number"
        source="account.phone_number"
      />
      <TextField label="Status" source="status" />
      <BooleanField label="Paused" source="paused" />
    </Datagrid>
  </List>
);

AdministratorsList.query = gql`
  query GET_LIST($input: AdministratorQueryInput) {
    _administratorsMeta(input: $input) {
      count
    }
    administrators(input: $input) {
      id
      account {
        id
        first_name
        last_name
        email
        phone_number
        permission_groups {
          id
          display_name
        }
      }
      department
      paused
      status
    }
  }
`;

export default AdministratorsList;
