import React from 'react';
import { Edit } from 'react-admin';
import PositionRateSettingsForm from '../PositionRateSettingsForm';
import { getTransform } from '../rateSettingsTransform';

const StatePositionRateSettingEdit = () => (
  <Edit
    mutationMode="pessimistic"
    transform={getTransform(['state_id'], 'state_position_rate_setting')}
  >
    <PositionRateSettingsForm settingsField="state_position_rate_setting" />
  </Edit>
);

export default StatePositionRateSettingEdit;
