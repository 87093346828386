import React from 'react';
import DateTimeField from './DateTimeField';
import { Labeled, useRecordContext } from 'react-admin';
import { Grid } from '@mui/material';

const FORMAT = 'MM/dd/yyyy HH:mm zzz';

const TimestampFields = (props) => {
  const record = useRecordContext(props);
  const hasCreatedAt = record && record.created_at;
  const hasUpdatedAt = record && record.updated_at;

  return (
    <Grid container spacing={3} data-testid="time-stamp-fields">
      {hasCreatedAt && (
        <Grid item>
          <Labeled label="Created At" data-testid="time-stamp-fields-created-at-field">
            <DateTimeField
              format={FORMAT}
              {...props}
              source="created_at"
              timeZone="America/Los_Angeles"
            />
          </Labeled>
        </Grid>
      )}
      {hasUpdatedAt && (
        <Grid item>
          <Labeled label="Updated At" data-testid="time-stamp-fields-updated-at-field">
            <DateTimeField
              format={FORMAT}
              {...props}
              source="updated_at"
              timeZone="America/Los_Angeles"
            />
          </Labeled>
        </Grid>
      )}
    </Grid>
  );
};

export default TimestampFields;
