import inflection from 'inflection';
import config from '../../config';
import { initializeApp } from 'firebase/app';
export const firebaseApp = initializeApp(config.firebaseConfig);
import _ from 'lodash';

export type RequestType = { resource: string };

const apiResourceMap = {
  Education: 'educations',
};
export const gqlResourceToRails = (resource: string) =>
  apiResourceMap[resource] || _.flowRight(inflection.underscore, inflection.pluralize)(resource);

export const getResourceUrl = (resource: string, id?: number | string) =>
  `${config.rails_url}/v3/admin/${gqlResourceToRails(resource)}${_.isNil(id) ? '' : `/${id}`}`;

export const gqlResourceToRailsRootKey = (resource: string) =>
  _.flowRight(inflection.underscore)(resource);

/**
 * Morphs the response from react-admin to match the expected format of rails
 * @param resource Name of resource
 * @param data any object
 * @returns Object with root key set to resource name OR the same data if resource is undefined
 */
export const setResourceRootKey = (resource: string | undefined, data: object) => {
  let payload = data;
  if (resource) {
    const rootKey = gqlResourceToRailsRootKey(resource);
    payload = { [rootKey]: data };
  }
  return payload;
};

/**
 * Flattens the data object to remove the root key (the inverse of setResourceRootKey)
 * @param resource Name of resource
 * @param data any object
 * @returns Object with root key gets flattened OR the same data if resource is undefined
 */
export const unsetResourceRootKey = (resource: string | undefined, data: object) => {
  let payload = data;
  if (resource) {
    const rootKey = gqlResourceToRailsRootKey(resource);
    if (payload[rootKey]) {
      payload = payload[rootKey];
    }
  }
  return payload;
};
