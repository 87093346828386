import React from 'react';
import DateTimeInput from 'components/DateTimeInput';
import {
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  useRecordContext,
  BooleanInput,
} from 'react-admin';
import useLocation from 'hooks/useLocation';
import { useFormContext } from 'react-hook-form';

const durationChoices = [
  { id: 30, name: '30 min' },
  { id: 60, name: '1 hour' },
];

const JobBreaks = () => {
  const record = useRecordContext();
  const { watch } = useFormContext();
  const locationId = watch('location_id');
  const { data: location } = useLocation(locationId);
  const primaryLocation = location || record?.location;

  return (
    <ArrayInput source="breaks" defaultValue={record?.breaks ?? undefined} data-testid="job-breaks">
      <SimpleFormIterator>
        <DateTimeInput
          time_zone={primaryLocation?.timezone_lookup}
          label="Starts Time"
          source="starts_time"
        />
        <SelectInput
          label="Duration"
          choices={durationChoices}
          source="duration_minutes"
          data-testid="job_breaks-duration"
        />
        {Boolean(record) && (
          <BooleanInput label="Destroy" source="_destroy" data-testid="job_breaks-destroy" />
        )}
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default JobBreaks;
