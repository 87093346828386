import React from 'react';
import { gql } from '@apollo/client';
import { TextField, Datagrid, NumberInput, BooleanField } from 'react-admin';
import PolymorphicField from 'components/PolymorphicField';
import List from 'components/List';
import IdField from 'components/IdField';
import { IdFilter } from 'components/Filter';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const filters = [
  <IdFilter />,
  <EnumSelectInput label="Account Type" alwaysOn source="personable_type" />,
  <NumberInput label="Personable ID" alwaysOn source="personable_id" />,
];
const FundingSourceList = () => (
  <List filters={filters} exporter={false} data-testid="funding-source-list" actions={null}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <PolymorphicField label="Personable" source="personable" />
      <TextField label="Last 4 Digits" source="last4" />
      <TextField label="Account Type" source="account_type" />
      <BooleanField label="Active" source="active" />
    </Datagrid>
  </List>
);

FundingSourceList.query = gql`
  query fundingSources($input: FundingSourceQueryInput) {
    _fundingSourcesMeta(input: $input) {
      count
    }
    fundingSources(input: $input) {
      id
      personable {
        ... on BillingGroup {
          id
          __typename
        }
        ... on Professional {
          id
          __typename
        }
      }
      last4
      account_type
      brand
      active
      exp_month
      exp_year
      instant_pay_eligible
    }
  }
`;

export default FundingSourceList;
