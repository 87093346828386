import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid, TextField } from 'react-admin';
import List from 'components/List';

const EducationList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="ehr_system.label" />
    </Datagrid>
  </List>
);

EducationList.query = gql`
  query GET_LIST($input: ProfessionalProfileEhrSystemQueryInput) {
    _professionalProfileEhrSystemsMeta(input: $input) {
      count
    }
    professionalProfileEhrSystems(input: $input) {
      id
      ehr_system {
        id
        label
      }
    }
  }
`;

export default EducationList;
