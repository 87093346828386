import React from 'react';
import {
  ReferenceManyField,
  SimpleShowLayout,
  Datagrid,
  Pagination,
  NumberField,
  FunctionField,
  ReferenceArrayField,
  TextField,
} from 'react-admin';
import Heading from 'components/Heading';
import LinkField from 'components/LinkField';
import useCheckAccess from 'hooks/useCheckAccess';
import MoneyField from 'components/MoneyField';
import DateTimeField from 'components/DateTimeField';
import JsonField from 'components/JsonField';
import MetabaseUrlField from 'components/MetabaseUrlField';
import { List, ListItem, Typography } from '@medely/web-components';
import { humanize } from 'inflection';

const AuditedChanges = ({ record }) =>
  record.action === 'update' ? (
    <List disablePadding>
      {Object.keys(record.audited_changes).map((key, i) => {
        const prevState = record.audited_changes[key][0];
        const newState = record.audited_changes[key][1];

        return (
          <ListItem
            dense
            disableGutters
            key={i}
            sx={{ alignItems: 'flex-start', flexWrap: 'wrap', gap: '4px' }}
          >
            <Typography component="span" variant="body2" sx={{ fontWeight: 600 }}>
              {`${key}:`}
            </Typography>
            <span>{`${prevState}`}</span>
            <span>→</span>
            <span>{`${newState}`}</span>
          </ListItem>
        );
      })}
    </List>
  ) : (
    <JsonField source="audited_changes" rootKey={`${humanize(record.action)}d Changes`} />
  );

const HistoryTab = ({ target }) => {
  const checkAccess = useCheckAccess();

  return (
    <SimpleShowLayout>
      <Heading>Job Billing Pay</Heading>
      <ReferenceManyField
        perPage={5}
        pagination={<Pagination />}
        reference="JobBilling"
        target={target}
        label=""
      >
        <Datagrid bulkActionButtons={false}>
          {checkAccess('view', 'JobBilling', 'id') && <LinkField label="Job Billing" source="id" />}
          {checkAccess('view', 'Payout', 'payout_id') && (
            <LinkField label="Payout" source="payout_id" />
          )}
          {checkAccess('view', 'JobBilling', 'make_payable_at') && (
            <DateTimeField
              label="Make Payable At"
              source="make_payable_at"
              timeZone="America/Los_Angeles"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'locked_at') && (
            <DateTimeField
              label="Locked At"
              source="locked_at"
              format="MM/dd/yyyy HH:mm zzz"
              timeZone="America/Los_Angeles"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'total_hours') && (
            <NumberField label="Total Hours" source="total_hours" sortable={false} />
          )}
          {checkAccess('view', 'JobBilling', 'total_on_call_hours') && (
            <NumberField
              label="Total On Call Hours"
              source="total_on_call_hours"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_total_amount_cents') && (
            <MoneyField
              label="Payout Total Amount Cents"
              source="payout_total_amount_cents"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_regular_hours') && (
            <NumberField
              label="Payout Regular Hours"
              source="payout_regular_hours"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_overtime_hours') && (
            <NumberField
              label="Payout Overtime Hours"
              source="payout_overtime_hours"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_double_hours') && (
            <NumberField
              label="Payout Double Hours"
              source="payout_double_hours"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_regular_amount_cents') && (
            <MoneyField
              label="Payout Regular Amount Cents"
              source="payout_regular_amount_cents"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_overtime_amount_cents') && (
            <MoneyField
              label="Payout Overtime Amount Cents"
              source="payout_overtime_amount_cents"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_double_amount_cents') && (
            <MoneyField
              label="Payout Double Amount Cents"
              source="payout_double_amount_cents"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_callback_hours') && (
            <NumberField
              label="Payout Callback Hours"
              source="payout_callback_hours"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'payout_callback_amount_cents') && (
            <MoneyField
              label="Payout Callback Amount Cents"
              source="payout_callback_amount_cents"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'total_breaks_minutes') && (
            <NumberField
              label="Total Breaks Minutes"
              source="total_breaks_minutes"
              sortable={false}
            />
          )}
        </Datagrid>
      </ReferenceManyField>
      <Heading>Job Billing Charge</Heading>
      <ReferenceManyField
        perPage={5}
        pagination={<Pagination />}
        reference="JobBilling"
        target={target}
        label=""
      >
        <Datagrid bulkActionButtons={false}>
          {checkAccess('view', 'JobBilling', 'id') && <LinkField label="Job Billing" source="id" />}
          {checkAccess('view', 'Invoice', 'invoice_id') && (
            <LinkField label="Invoice" source="invoice_id" />
          )}
          {checkAccess('view', 'JobBilling', 'make_chargeable_at') && (
            <DateTimeField
              label="Make Chargeable At"
              source="make_chargeable_at"
              timeZone="America/Los_Angeles"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'locked_at') && (
            <DateTimeField
              label="Locked At"
              source="locked_at"
              format="MM/dd/yyyy HH:mm zzz"
              timeZone="America/Los_Angeles"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'total_hours') && (
            <NumberField label="Total Hours" source="total_hours" sortable={false} />
          )}
          {checkAccess('view', 'JobBilling', 'total_on_call_hours') && (
            <NumberField
              label="Total On Call Hours"
              source="total_on_call_hours"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'charge_total_amount_cents') && (
            <MoneyField
              label="Charge Total Amount Cents"
              source="charge_total_amount_cents"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'charge_regular_hours') && (
            <NumberField
              label="Charge Regular Hours"
              source="charge_regular_hours"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'charge_overtime_hours') && (
            <NumberField
              label="Charge Overtime Hours"
              source="charge_overtime_hours"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'charge_double_hours') && (
            <NumberField
              label="Charge Double Hours"
              source="charge_double_hours"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'charge_regular_amount_cents') && (
            <MoneyField
              label="Charge Regular Amount Cents"
              source="charge_regular_amount_cents"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'charge_overtime_amount_cents') && (
            <MoneyField
              label="Charge Overtime Amount Cents"
              source="charge_overtime_amount_cents"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'charge_double_amount_cents') && (
            <MoneyField
              label="Charge Double Amount Cents"
              source="charge_double_amount_cents"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'charge_callback_hours') && (
            <NumberField
              label="Charge Callback Hours"
              source="charge_callback_hours"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'charge_callback_amount_cents') && (
            <MoneyField
              label="Charge Callback Amount Cents"
              source="charge_callback_amount_cents"
              sortable={false}
            />
          )}
          {checkAccess('view', 'JobBilling', 'total_breaks_minutes') && (
            <NumberField
              label="Total Breaks Minutes"
              source="total_breaks_minutes"
              sortable={false}
            />
          )}
        </Datagrid>
      </ReferenceManyField>
      <Heading>Audits</Heading>
      <ReferenceArrayField
        label=""
        pagination={<Pagination />}
        perPage={10}
        reference="Audit"
        sort={{ field: 'id', order: 'DESC' }}
        source="job_audit_ids"
      >
        {checkAccess('view', 'Audit', 'job_audits') && (
          <Datagrid bulkActionButtons={false}>
            <TextField label="ID" source="id" />
            <TextField label="Auditable ID" source="auditable_id" />
            <TextField label="Auditable Type" source="auditable_type" />
            <TextField source="action" sortable={false} />
            <DateTimeField
              label="Created At"
              source="created_at"
              format="MM/dd/yyyy HH:mm zzz"
              timeZone="America/Los_Angeles"
            />
            <FunctionField
              label="Audited Changes"
              render={(record) => <AuditedChanges record={record} />}
            />
          </Datagrid>
        )}
      </ReferenceArrayField>
      {checkAccess('view', 'Audit') && <MetabaseUrlField />}
    </SimpleShowLayout>
  );
};

export default HistoryTab;
