import { Edit } from '@mui/icons-material';
import React from 'react';
import { Button, Link } from 'react-admin';

const EditInvoiceButton = ({ invoice_type, invoice_id }) => {
  return (
    <Button component={Link} label="Edit" to={`/Invoice/${invoice_type}/${invoice_id}`}>
      <Edit />
    </Button>
  );
};

export default EditInvoiceButton;
