import {
  Edit,
  SimpleForm,
  AutocompleteInput,
  TextInput,
  BooleanInput,
  NumberInput,
  SelectInput,
} from 'react-admin';
import React from 'react';
import DisableableDateInput from 'components/DisableableDateInput';
import DateInput from 'components/DateInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import ProfessionalInput from 'components/ProfessionalInput';
import ReferenceInput from 'components/ReferenceInput';
import StateInput from 'components/StateInput';
import PositionInput from 'components/PositionInput';
import { ENUMS } from 'components/Enum/enums';
import { choicesFromEnum } from 'utils/helpers';

const Work_historiesEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <ProfessionalInput />
      <TextInput label="Organization Name" source="organization_name" />
      <TextInput label="Job Title" source="position_title" />
      <PositionInput source="position_id" />
      <BooleanInput label="Clinical" source="clinical" />
      <BooleanInput label="Floating" source="floating" />
      <TextInput label="Floating Details" source="floating_details" />
      <NumberInput label="Hours Per Week" source="hours_per_week" />
      <TextInput label="Travel Agency" source="travel_agency" />
      <TextInput label="Location" source="location" />
      <SelectInput
        label="Location Trauma Level"
        choices={choicesFromEnum(ENUMS.WorkHistory.trauma_level)}
        source="location_trauma_level"
      />
      <NumberInput label="Location Number Of Beds" source="location_number_of_beds" />
      <BooleanInput label="Location Is Teaching" source="location_is_teaching" />
      <DateInput label="Start Date" source="start_date" />
      <DisableableDateInput
        label="End Date"
        dateSource="end_date"
        disableSource="currently_work_here"
        disableLabel="Currently Work Here"
      />
      <TextInput label="Unit" source="unit" />
      <TextInput label="Description" source="description" multiline fullWidth />
      <StateInput />
      <BooleanInput label="Travel" source="travel" />
      <ReferenceInput reference="LocationType" source="location_type_id">
        <AutocompleteInput label="Location Type" optionText="label" />
      </ReferenceInput>
      <EnumSelectInput label="Employment Type" source="employment_type" />
      <BooleanInput label="Direct Care" source="bedside_care" />
    </SimpleForm>
  </Edit>
);

export default Work_historiesEdit;
