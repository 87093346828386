import useApi from 'hooks/useApi';

export const useMergeCompanies = () => {
  const api = useApi();

  return async ({
    id,
    company_id,
    account_ids,
    billing_group_ids,
    location_ids,
    dry_run,
    admin_to_manager,
  }: {
    id: number;
    company_id: number;
    account_ids: number[];
    billing_group_ids: number[];
    location_ids: number[];
    dry_run: boolean;
    admin_to_manager: boolean;
  }) => {
    const data = await api({
      url: `/v3/admin/companies/${id}/merge`,
      method: 'POST',
      data: {
        company_id,
        account_ids,
        billing_group_ids,
        location_ids,
        dry_run,
        admin_to_manager,
      },
    });

    return data;
  };
};
