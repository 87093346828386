import React from 'react';
import { gql } from '@apollo/client';
import IdInput from 'components/Filter/IdInput';
import { BooleanField, Datagrid, BooleanInput } from 'react-admin';
import List from 'components/List';
import LinkField from 'components/LinkField';
import IdField from 'components/IdField';
import LocationInput from 'components/LocationInput';
import AgencyInput from 'components/AgencyInput';

const AgencyLocationRateSettingListFilters = [
  <IdInput />,
  <LocationInput alwaysOn />,
  <AgencyInput alwaysOn />,
  <BooleanInput alwaysOn source="active" />,
];

const AgencyLocationRateSettingList = () => (
  <List filters={AgencyLocationRateSettingListFilters} exporter={false}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Location ID" source="location_id" />
      <LinkField label="Agency ID" source="agency_id" />
      <BooleanField source="active" />
      <BooleanField
        label="Track Unpaid Breaks"
        source="settings.track_unpaid_breaks"
        sortable={false}
      />
    </Datagrid>
  </List>
);

AgencyLocationRateSettingList.query = gql`
  query agencyLocationRateSettings($input: AgencyLocationRateSettingQueryInput) {
    _agencyLocationRateSettingsMeta(input: $input) {
      count
    }
    agencyLocationRateSettings(input: $input) {
      id
      location_id
      agency_id
      active
      settings
    }
  }
`;

export default AgencyLocationRateSettingList;
