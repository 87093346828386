import React, { useEffect } from 'react';
import ReferenceInput, { getChildrenProps } from './ReferenceInput';
import { AutocompleteInput, useChoicesContext } from 'react-admin';

const ProfessionalSelect = (props) => {
  const { selectedChoices } = useChoicesContext();
  const { onChange, optionText } = props;
  const selectedChoice = selectedChoices?.length ? selectedChoices[0] : undefined;

  useEffect(() => {
    if (onChange) {
      onChange(selectedChoice);
    }
  }, [selectedChoice, onChange]);

  return (
    <AutocompleteInput
      optionText={(o) => optionText?.(o) ?? `${o?.id}`}
      {...getChildrenProps(props)}
      data-testid="professional-input"
    />
  );
};

const ProfessionalInput = (props) => (
  <ReferenceInput gql={['id', 'name']} {...props}>
    <ProfessionalSelect {...props} />
  </ReferenceInput>
);

ProfessionalInput.defaultProps = {
  label: 'Professional',
  source: 'professional_id',
  reference: 'Professional',
  sort: { field: 'id', order: 'ASC' },
  perPage: 25,
};

export default ProfessionalInput;
