import * as R from 'ramda';
import inflection from 'inflection';
import { format, isValid, parseISO, differenceInDays } from 'date-fns/fp';
import enums, { LicenseFieldKind } from 'typings/enums';
import * as React from 'react';
import queryString from 'query-string';
import { Credential } from 'typings/graphql';
import { ENUMS } from 'components/Enum/enums';
import _ from 'lodash';

const nameLens = R.lensProp<any, string>('name');

export const humanizeResourceName: any = R.over(
  nameLens,
  _.flowRight(inflection.humanize, inflection.underscore),
);

export const camelToSnakeCase: any = (str) => {
  return str.replace(/[A-Z]/g, (letter, index) => {
    return index === 0 ? letter.toLowerCase() : '_' + letter.toLowerCase();
  });
};

export const camelizeResourceName: any = R.over(
  nameLens,
  _.flowRight(inflection.camelize, inflection.singularize),
);

export const oneQueryName: any = _.flowRight(
  (v: string) => inflection.camelize(v, true),
  R.view(nameLens),
);

const SPECIAL_NAMES = {
  education: 'educations',
};

export const pluralize: any = R.ifElse(
  R.has(R.__, SPECIAL_NAMES),
  R.prop(R.__, SPECIAL_NAMES),
  (v) => inflection.pluralize(v),
);

export const listQueryName: any = _.flowRight(pluralize, oneQueryName);

export function choicesFromEnum(
  enumeration: { [K in string | number]: string | number },
  options: { strings: boolean } = { strings: false },
) {
  const stringKeys = Object.keys(enumeration).filter((k) => isNaN(parseInt(k)));
  return stringKeys.map((k) => ({
    id: options.strings ? enumeration[enumeration[k]] : enumeration[k],
    name: inflection.humanize(k),
  }));
}

export const joinProps: any = (props: string[], str = ' ') =>
  _.flowRight<any, any, any, string>((s) => s.join(str), R.props(props), R.defaultTo({}));

export const dateRange: any = (fmt: string, fallbackValue = 'present') =>
  _.flowRight(
    (s) => s.join(' - '),
    R.map(_.flowRight(R.ifElse(isValid, format(fmt), R.always(fallbackValue)), parseISO)),
  );

const groupBy = (field: string) =>
  _.flowRight<any[], any, any>(R.pluck(0), R.groupBy(R.path<any>(field.split('.'))));

const mergeSA = (questions: any[], answers: any[]) => {
  if (!questions || !answers) {
    return [];
  }
  const questionsByID = groupBy('id')(questions);
  const answersByQuestionID = groupBy('field.id')(answers);
  const ids = _.keys(answersByQuestionID);
  return R.map(
    (id) => R.set(R.lensProp('answer'), answersByQuestionID[id], questionsByID[id]),
    ids,
  );
};

const flatten = (obj, path = '') => {
  const reducer = (acc, key) => {
    const currentPath = path ? `${path}.${key}` : key;
    if (!React.isValidElement(obj[key])) {
      return { ...acc, ...flatten(obj[key], currentPath) };
    }
    return { ...acc, [currentPath]: obj[key] };
  };
  return _.flowRight<any, any, any>((obj) => _.reduce(obj, reducer, {}), _.keys)(obj);
};

export const Utils: any = {
  groupByID: groupBy('id'),
  ids: R.map(_.property('id')),
  skills_assessment_response: {
    merge: mergeSA,
  },
  flatten: flatten,
};

const name = joinProps(['first_name', 'last_name']);
const credentialExpirationDate = _.flowRight<any, any, any, any>(
  R.propOr('', 'value'),
  R.find((a: any) => a.license_field?.kind === LicenseFieldKind.expiration_date),
  R.propOr([], 'attachments'),
);

export const displayDate = _.flowRight(format('MM/dd/yyyy'), parseISO);

export const maybe: any = (displayFn: (...args: any[]) => string, fallbackFn = R.always('')) =>
  R.ifElse(_.isNil, fallbackFn, displayFn);

const money = (cents: number, currency = 'USD') =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(cents / 100);

const rate = (cents: number, period: 'hr' | 'day' | 'week' = 'hr') => `${money(cents)} /${period}`;

const percentage = (percentage: number, maximumSignificantDigits: number = 4) =>
  new Intl.NumberFormat('en-US', { style: 'percent', maximumSignificantDigits }).format(percentage);

const PRO_KINDS = {
  lvn: 'LVN',
  registered_nurse: 'Registered Nurse',
  allied: 'Allied',
  imaging_services: 'Imaging Services',
  physician: 'Physician',
};

export const choicesForProKinds = (kinds) => {
  return Object.keys(kinds).map((kind) => ({ id: kind, name: PRO_KINDS[kind] }));
};

export const DisplayHelpers: any = {
  money,
  rate,
  name,
  percentage,
  nameOf: (prop: string) => _.flowRight<any, any, string>(name, _.property(prop)),
  humanizeProp: function (str: string) {
    return _.flowRight<any, any, string>(
      maybe((v) => inflection.humanize(v)),
      (obj) => _.get(obj, str.split('.')),
    );
  },
  cents: _.flowRight(R.divide(R.__, 100)),
  dateRange,
  credential: {
    status: credentialStatus,
    expiration: (c: Credential) => {
      const date = credentialExpirationDate(c) as string;
      if (!date) {
        return null;
      }
      const expiresInDays = _.flowRight(differenceInDays(new Date()), parseISO)(date);
      return {
        date: _.flowRight(format('MM/dd/yyyy'), parseISO)(date),
        expiresSoon: expiresInDays < 30,
      };
    },
    expirationDate: credentialExpirationDate,
  },
  professional: {
    fullName: function (p) {
      return p?.account && `${p.account.first_name} ${p.account.last_name}`;
    },
    // fullName: joinProps(['first_name', 'last_name']),
    positionNames: _.flowRight<any, any, any, any, string>(
      (s) => s.join(', '),
      R.map(_.property('display_name')),
      R.defaultTo([]),
      _.property('positions'),
    ),
    kinds: _.flowRight<string[], string>(
      (kinds: string[]) => kinds.map((kind) => PRO_KINDS[kind]).join(', '),
      _.property('kinds'),
    ),
    positionsOnboardedCount: _.flowRight<any[], string>(
      (professional_positions: any[]) =>
        `${
          professional_positions.filter(
            (professional_position) => professional_position.completed_onboarding_at !== null,
          ).length
        }`,
      _.property('professional_positions'),
    ),
    dateAdded: _.flowRight<any, any, string>(maybe(displayDate), _.property('created_at')),
    background: _.flowRight<any, any, any>(
      maybe(
        _.flowRight<any, any, any, any, any, any>(
          (v: string) => inflection.humanize(v),
          (v: string) => enums.professional.background_check.report_status[v],
          _.property('report_status'),
          R.last,
          (arr) => _.sortBy(arr, 'created_at'),
        ),
      ),
      //@ts-ignore
      _.property('background_checks'),
    ),
    status: (v) => inflection.humanize(v),
    marketsOrState: (v) =>
      v?.markets?.map((market) => market.display_name)?.join(', ') || v?.account?.state?.name,
  },
  roundToTwoDecimals: (value: number) => {
    return Math.round(value * 100) / 100;
  },
};

export const defaultValuesFromProps: any = _.flowRight<any, any, any, any>(
  R.map(
    R.ifElse(
      (v: any) => isNaN(v),
      _.identity,
      (v: any) => parseInt(v),
    ),
  ),
  (str) => queryString.parse(str),
  (obj) => _.get(obj, ['location', 'search']),
);

function credentialStatus(credential: Pick<Credential, 'expired' | 'status'>) {
  if (!credential?.status) {
    return '';
  }
  return credential.status === 1 && credential.expired
    ? 'Expired'
    : inflection.humanize(ENUMS.Credential.status[credential.status]);
}
