import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

const DisputedJobBillingsShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="job_billing_id" />
    </SimpleShowLayout>
  </Show>
);

DisputedJobBillingsShow.query = gql`
  query GET_ONE($id: Int!) {
    disputedJobBilling(id: $id) {
      id
    }
  }
`;

export default DisputedJobBillingsShow;
