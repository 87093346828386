import React from 'react';
import {
  BooleanField,
  Datagrid,
  Edit,
  Labeled,
  ReferenceManyField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import RichTextInput from 'components/RichTextInput';
import SlugInput from './SlugInput';

const QualificationEdit = () => (
  <Edit redirect="show">
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="long_name" />
      <SlugInput />
      <EnumSelectInput
        label="Qualification Type"
        source="qualification_type"
        validate={[required()]}
      />
      <SelectInput
        choices={[
          { id: 'active', name: 'Active' },
          { id: 'inactive', name: 'Inactive' },
        ]}
        source="status"
      />
      <Labeled label="Instructions">
        <RichTextInput source="instructions" />
      </Labeled>
      <ReferenceManyField
        label="Qualification Attributes"
        reference="QualificationAttribute"
        target="qualification_id"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField label="ID" source="id" />
          <TextField source="name" />
          <TextField source="slug" />
          <TextField source="field_type" />
          <BooleanField source="required" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleForm>
  </Edit>
);

export default QualificationEdit;
