import React from 'react';
import {
  BooleanField,
  Datagrid,
  FunctionField,
  NumberField,
  ReferenceManyField,
  TextField,
  useGetRecordId,
  useResourceContext,
} from 'react-admin';
import { Button } from '@mui/material';
import { DisplayHelpers } from 'utils/helpers';
import { Link } from 'react-router-dom';
import LinkField from 'components/LinkField';
import MoneyField from 'components/MoneyField';

const RateSettingsTab = () => {
  const id = useGetRecordId();
  const resource = useResourceContext();
  const isLocation = resource === 'Location';
  const rateSettingName = `${resource}RateSetting`;
  const target = `${resource.toLowerCase()}_id`;
  const source = JSON.stringify({ [target]: id });
  const addRateSettingsUrl = `/${rateSettingName}/create?source=${source}`;
  const filter = resource === 'State' ? {} : { include_parents: true };

  return (
    <>
      <Button component={Link} color="primary" to={addRateSettingsUrl}>
        Add rate settings
      </Button>
      <ReferenceManyField reference={rateSettingName} filter={filter} target={target}>
        <Datagrid bulkActionButtons={false}>
          <LinkField source="id" />
          <BooleanField source="active" />
          {isLocation && (
            <BooleanField label="Track Unpaid Breaks" source="settings.track_unpaid_breaks" />
          )}
          <TextField source="settings.burden_currency" label="Burden Currency" />
          <NumberField source="settings.vms_fee_percent" label="VMS Fee Percent" />
          <NumberField source="settings.agency_vms_fee_percent" label="Agency VMS Fee Percent" />
          <TextField source="settings.cancellation_fee_window" label="Cancellation Fee Window" />
          <TextField source="settings.other_burden_multiplier" label="Other Burden Multiplier" />
          <TextField source="settings.guaranteed_minimum_hours" label="Guaranteed Minimum Hours" />
          <TextField
            source="settings.billable_orientation_hours"
            label="Billable Orientation Hours"
          />
          {isLocation && (
            <TextField
              source="settings.non_billable_orientation_burden_hours"
              label="Non Billable Orientation Burden Hours"
            />
          )}
          <TextField
            label="W2 Charge Overtime Multiplier"
            source="settings.w2.charge_overtime_multiplier"
          />
          <TextField
            label="1099 Charge Extended Rate Multiplier"
            source="settings.ten99_per_diem.charge_overtime_multiplier"
          />
          <TextField
            label="1099 Charge Second Extended Rate Multiplier"
            source="settings.ten99_per_diem.charge_double_overtime_multiplier"
          />
          <TextField
            label="1099 Charge Extended Rate Starts At"
            source="settings.ten99_per_diem.charge_max_daily_regular_hours"
          />
          <FunctionField
            label="1099 Charge Second Extended Rate Starts At"
            render={({ settings }) =>
              DisplayHelpers.roundToTwoDecimals(
                settings?.ten99_per_diem?.charge_max_daily_regular_hours +
                  settings?.ten99_per_diem?.charge_max_daily_overtime_hours,
              )
            }
          />
          <TextField
            label="1099 Charge Weekly Extended Rate Starts At"
            source="settings.ten99_per_diem.charge_max_weekly_regular_hours"
          />
          <TextField
            label="1099 Charge Seventh Day Rule Enabled"
            source="settings.ten99_per_diem.charge_seventh_day_rule_enabled"
          />
          <TextField
            source="settings.medicare_burden_multiplier"
            label="Medicare Burden Multiplier"
          />
          <TextField
            label="W2 Payout Overtime Multiplier"
            source="settings.w2.payout_overtime_multiplier"
          />
          <TextField
            label="1099 Payout Extended Rate Multiplier"
            source="settings.ten99_per_diem.payout_overtime_multiplier"
          />
          <TextField
            label="1099 Payout Second Extended Rate Multiplier"
            source="settings.ten99_per_diem.payout_double_overtime_multiplier"
          />
          <TextField
            label="1099 Payout Extended Rate Starts At"
            source="settings.ten99_per_diem.payout_max_daily_regular_hours"
          />
          <FunctionField
            label="1099 Payout Second Extended Rate Starts At"
            render={({ settings }) =>
              DisplayHelpers.roundToTwoDecimals(
                settings?.ten99_per_diem?.payout_max_daily_regular_hours +
                  settings?.ten99_per_diem?.payout_max_daily_overtime_hours,
              )
            }
          />
          <TextField
            label="1099 Payout Weekly Extended Rate Starts At"
            source="settings.ten99_per_diem.payout_max_weekly_regular_hours"
          />
          <TextField
            label="1099 Payout Seventh Day Rule Enabled"
            source="settings.ten99_per_diem.payout_seventh_day_rule_enabled"
          />
          <TextField
            source="settings.cancellation_fee_charge_hours"
            label="Cancellation Fee Charge Hours"
          />
          <TextField
            source="settings.social_security_burden_multiplier"
            label="Social Security Burden Multiplier"
          />
          <TextField
            source="settings.state_unemployment_percent_burden_multiplier"
            label="State Unemployment Percent Burden Multiplier"
          />
          <TextField
            source="settings.federal_unemployment_percent_burden_multiplier"
            label="Federal Unemployment Percent Burden Multiplier"
          />
          <MoneyField
            source="settings.state_unemployment_max_burden_cents"
            label="State Unemployment Max Burden"
          />
          <MoneyField
            source="settings.federal_unemployment_max_burden_cents"
            label="Federal Unemployment Max Burden"
          />
          <MoneyField source="settings.compliance_burden_cents" label="Compliance Burden" />
          <MoneyField source="settings.rn_minimum_wage_cents" label="RN Minimum Wage" />
          <MoneyField source="settings.lvn_minimum_wage_cents" label="LVN Minimum Wage" />
          <MoneyField source="settings.allied_minimum_wage_cents" label="Allied Minimum Wage" />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};

export default RateSettingsTab;
