import React from 'react';
import ShowView from '../components/ShowView';
import InvoiceSummaryTab from '../tabs/InvoiceSummaryTab';

const DebitMemosShow = () => {
  return (
    <ShowView
      invoice_type="debit_memo"
      showDisputes={true}
      showChildMemos={false}
      summaryTab={<InvoiceSummaryTab showParentInvoices={true} />}
    />
  );
};

export default DebitMemosShow;
