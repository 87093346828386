import React, { memo, useState } from 'react';
import { Button, useNotify, useRecordContext, useRefresh } from 'react-admin';
import IconContentAdd from '@mui/icons-material/Add';
import ItemizedTransactionBillingGroups from './ItemizedTransactionBillingGroups';
import ItemizedTransactionWizard from './ItemizedTransactionWizard';
import PayInvoicesTable from 'components/PayInvoicesTable';
import ItemizedTransactionRemaining from './ItemizedTransactionRemaining';
import { amountLeft } from './utils';
import getAxios from 'getAxios';

const axios = getAxios();

const ItemizedTransaction = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const record = useRecordContext();
  const readonly = amountLeft(record, [], []) <= 0;

  const handleClose = () => setShowDialog(false);

  const handleCreate = async (values) => {
    try {
      setLoading(true);
      const invoices = values.invoices
        .filter((inv) => inv?.amount_cents)
        .map((inv) => {
          const data = {
            id: inv.id,
            amount_cents: inv.amount_cents,
            notes: inv.notes || '',
          };
          if (values.creditBalances?.[inv.billing_group_id]?.[inv.id]) {
            data['billing_group_payment'] = {
              amount_cents: values.creditBalances[inv.billing_group_id][inv.id],
            };
          }
          return data;
        });

      await axios.post(`/v3/admin/financial_transactions/${record.id}/apply`, {
        user_ids: values.user_ids,
        billing_group_balances: values.billing_group_balances,
        invoices,
      });
      handleClose();
      notify('Successfully applied', { type: 'success' });
      refresh();
    } catch (error) {
      if (error instanceof Error) {
        notify(error.message, { type: 'warning' });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!readonly && (
        <Button
          onClick={() => setShowDialog(true)}
          sx={{ mt: 2, mb: 2 }}
          label="Apply"
          variant="outlined"
          color="primary"
        >
          <IconContentAdd />
        </Button>
      )}

      <ItemizedTransactionWizard
        opened={showDialog}
        onClose={handleClose}
        record={record}
        loading={loading}
        onSubmit={handleCreate}
      >
        <ItemizedTransactionBillingGroups />
        <PayInvoicesTable record={record} amountLeft={amountLeft} showBillingGroupIDField={true} />
        <ItemizedTransactionRemaining record={record} amountLeft={amountLeft} />
      </ItemizedTransactionWizard>
    </>
  );
};

export default memo(ItemizedTransaction);
