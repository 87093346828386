import React from 'react';
import { Datagrid, TextField } from 'react-admin';
import DateTimeField from 'components/DateTimeField';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import useCheckAccess from 'hooks/useCheckAccess';
import ListView from '../components/ListView';

const CreditMemosList = () => {
  const checkAccess = useCheckAccess();

  return (
    <ListView invoice_type="credit_memo" title="Credit Memos">
      <Datagrid bulkActionButtons={false}>
        {checkAccess('view', 'Invoice', 'id') && <LinkField label="ID" source="id" />}
        {checkAccess('view', 'Invoice', 'billing_group_id') && (
          <LinkField label="Billing Group ID" source="billing_group_id" typename="BillingGroup" />
        )}
        {checkAccess('view', 'Invoice', 'assignment_id') && (
          <LinkField label="Assignment ID" source="assignment_id" />
        )}
        {checkAccess('view', 'Invoice', 'agency_id') && (
          <LinkField label="Agency ID" source="agency_id" />
        )}
        {checkAccess('view', 'Invoice', 'total_cents') && (
          <MoneyField label="Total" source="total_cents" />
        )}
        {checkAccess('view', 'Invoice', 'status') && <TextField label="Status" source="status" />}
        {checkAccess('view', 'Invoice', 'paid_date') && (
          <DateTimeField
            label="Paid Date"
            source="paid_date"
            format="MM/dd/yyyy"
            timeZone="America/Los_Angeles"
          />
        )}
        {checkAccess('view', 'Invoice', 'created_at') && (
          <DateTimeField
            label="Created At"
            source="created_at"
            format="MM/dd/yyyy"
            timeZone="America/Los_Angeles"
          />
        )}
      </Datagrid>
    </ListView>
  );
};

export default CreditMemosList;
