import React from 'react';
import { TextField, SimpleShowLayout } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import DateTimeField from 'components/DateTimeField';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import useCheckAccess from 'hooks/useCheckAccess';
import MetabaseUrlField from '../../../components/MetabaseUrlField';
import ParentInvoicesField from '../components/ParentInvoicesField';

const SummaryTab = () => {
  const checkAccess = useCheckAccess();

  return (
    <SimpleShowLayout>
      {checkAccess('view', 'Invoice', 'id') && <TextField label="ID" source="id" />}
      {checkAccess('view', 'Invoice', 'billing_group_id') && (
        <LinkField label="Billing Group ID" source="billing_group_id" typename="BillingGroup" />
      )}
      {checkAccess('view', 'Invoice', 'assignment_id') && (
        <LinkField label="Assignment ID" source="assignment_id" />
      )}
      {checkAccess('view', 'Invoice', 'agency_id') && (
        <LinkField label="Agency ID" source="agency_id" />
      )}
      {checkAccess('view', 'InvoicesLink', 'parent_invoice_id') && (
        <ParentInvoicesField label="Parent Invoices" />
      )}
      {checkAccess('view', 'Invoice', 'status') && <TextField label="Status" source="status" />}
      {checkAccess('view', 'Invoice', 'total_cents') && (
        <MoneyField label="Total" source="total_cents" />
      )}
      {checkAccess('view', 'Invoice', 'starts_time') && (
        <TextField label="Starts Time" source="starts_time" />
      )}
      {checkAccess('view', 'Invoice', 'ends_time') && (
        <TextField label="Ends Time" source="ends_time" />
      )}
      {checkAccess('view', 'Invoice', 'paid_date') && (
        <DateTimeField label="Paid Date" source="paid_date" />
      )}
      {checkAccess('view', 'Invoice', 'locked_at') && (
        <DateTimeField label="Locked At" source="locked_at" format="MM/dd/yyyy HH:mm zzz" />
      )}
      {checkAccess('view', 'Invoice', 'balance_sheet_id') && (
        <LinkField
          label="Invoice Locked Balance Sheet ID"
          source="invoice_locked_balance_sheet_id"
          typename="BalanceSheet"
        />
      )}
      {checkAccess('view', 'Invoice', 'balance_sheet_id') && (
        <LinkField
          label="Invoice Paid Balance Sheet ID"
          source="invoice_paid_balance_sheet_id"
          typename="BalanceSheet"
        />
      )}
      {checkAccess('view', 'Audit') && <MetabaseUrlField />}
      <TimestampFields />
    </SimpleShowLayout>
  );
};

export default React.memo(SummaryTab);
