import React from 'react';
import { Edit, SimpleForm, TextInput, BooleanInput, NumberInput, DateInput } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import MoneyInput from 'components/MoneyInput';
import useCheckAccess from 'hooks/useCheckAccess';
import AdministratorInput from 'components/Filter/AdministratorInput';
import BillingInput from './BillingInput';
import PercentInput from 'components/PercentInput';
import CompanyInput from 'components/CompanyInput';
import BillingGroupParentInput from 'components/BillingGroupParentInput';
import BillingGroupManagementCompanyInput from 'components/BillingGroupManagementCompanyInput';
import useErrorHandler from 'hooks/useErrorHandler';

const BillingGroupsEdit = () => {
  const checkAccess = useCheckAccess();
  const canUpdateBillingGroup = (attribute) => checkAccess('update', 'BillingGroup', attribute);
  const onError = useErrorHandler();

  return (
    <Edit mutationOptions={{ onError }} mutationMode="pessimistic" redirect="show">
      <SimpleForm>
        {canUpdateBillingGroup('company_id') && <CompanyInput disabled />}
        {canUpdateBillingGroup('billing_group_parent_id') && (
          <BillingGroupParentInput
            label="Billing Group Parent ID"
            source="billing_group_parent_id"
          />
        )}
        {canUpdateBillingGroup('billing_group_management_company_id') && (
          <BillingGroupManagementCompanyInput
            label="Billing Group Management Company ID"
            source="billing_group_management_company_id"
          />
        )}
        {canUpdateBillingGroup('name') && <TextInput label="Name" source="name" />}
        {checkAccess('create', 'BillingGroup', 'legal_name') && (
          <TextInput label="Legal Name" source="legal_name" />
        )}
        {canUpdateBillingGroup('status') && (
          <EnumSelectInput label="Status" source="status" resource="BillingGroup" sort="" />
        )}
        {canUpdateBillingGroup('plan') && (
          <EnumSelectInput label="Plan" source="plan" resource="BillingGroup" sort="" />
        )}
        {canUpdateBillingGroup('billing') && <BillingInput restricted />}
        {canUpdateBillingGroup('past_due_allowance_amount_cents') && (
          <MoneyInput label="Past Due Allowance Amount" source="past_due_allowance_amount_cents" />
        )}
        {canUpdateBillingGroup('collections_rep') && (
          <AdministratorInput
            label="Collections Rep"
            source="collections_rep"
            resettable
            filter={{ department: 'Finance' }}
            sort={{ field: 'first_name', order: 'ASC' }}
          />
        )}
        {canUpdateBillingGroup('billing_rep') && (
          <AdministratorInput
            label="Billing Rep"
            source="billing_rep"
            resettable
            filter={{ department: 'Finance' }}
            sort={{ field: 'first_name', order: 'ASC' }}
          />
        )}
        {canUpdateBillingGroup('tier') && (
          <EnumSelectInput label="Tier" source="tier" resource="BillingGroup" sort="" />
        )}
        {canUpdateBillingGroup('credit_insurance_limit') && (
          <NumberInput label="Credit Insurance Limit" source="credit_insurance_limit" />
        )}
        {canUpdateBillingGroup('last_credit_review') && (
          <DateInput label="Last Credit Review" source="last_credit_review" />
        )}
        {canUpdateBillingGroup('duns') && <NumberInput label="DUNS" source="duns" />}
        {canUpdateBillingGroup('eh_id') && <TextInput label="EH ID" source="eh_id" />}
        {canUpdateBillingGroup('vms_fee_percent') && (
          <PercentInput label="VMS Fee Percent" source="vms_fee_percent" />
        )}
        {canUpdateBillingGroup('stripe_bank_account_token') && (
          <TextInput label="Stripe Bank Account Token" source="stripe_bank_account_token" />
        )}
        {canUpdateBillingGroup('plaid_account_id') && (
          <TextInput label="Plaid Account ID" source="plaid_account_id" />
        )}
        {canUpdateBillingGroup('plaid_token') && (
          <TextInput label="Plaid Token" source="plaid_token" />
        )}
        {canUpdateBillingGroup('stripe_last_4') && (
          <TextInput label="Stripe Last 4" source="stripe_last_4" />
        )}
        {canUpdateBillingGroup('bank_last') && <TextInput label="Bank Last" source="bank_last" />}
        {canUpdateBillingGroup('stripe_customer_id') && (
          <TextInput label="Stripe Customer ID" source="stripe_customer_id" />
        )}

        {canUpdateBillingGroup('business_tax_id') && (
          <TextInput label="Business Tax ID" source="business_tax_id" />
        )}
        {canUpdateBillingGroup('payment_gateway') && (
          <BooleanInput label="Payment Gateway" source="payment_gateway" />
        )}
        {canUpdateBillingGroup('account_summary_emails') && (
          <BooleanInput label="Account Summary Emails" source="account_summary_emails" />
        )}

        {canUpdateBillingGroup('invoice_emails') && (
          <BooleanInput label="Invoice Emails" source="invoice_emails" />
        )}
        {canUpdateBillingGroup('auto_pay') && <BooleanInput label="Auto Pay" source="auto_pay" />}
        {canUpdateBillingGroup('statement_fee_percent') && (
          <PercentInput label="Statement Fee Percent" source="statement_fee_percent" />
        )}
        {canUpdateBillingGroup('check_line_of_credit') && (
          <BooleanInput label="Check Line of Credit" source="check_line_of_credit" />
        )}
        {canUpdateBillingGroup('line_of_credit_cents') && (
          <MoneyInput label="Line of Credit" source="line_of_credit_cents" />
        )}
        {canUpdateBillingGroup('net_term') && (
          <EnumSelectInput label="Net Term" source="net_term" resource="BillingGroup" sort="" />
        )}
        {canUpdateBillingGroup('charge_on_call_hourly_rate_cents') && (
          <MoneyInput
            label="Charge On Call Hourly Rate"
            source="charge_on_call_hourly_rate_cents"
          />
        )}
        {canUpdateBillingGroup('msa') && <BooleanInput label="MSA" source="msa" />}
        {canUpdateBillingGroup('collections_late_fee_percent') && (
          <PercentInput
            label="Collections Late Fee Percent"
            source="collections_late_fee_percent"
          />
        )}
        {canUpdateBillingGroup('has_dun_and_bradstreet') && (
          <BooleanInput label="Dun and Bradstreet" source="has_dun_and_bradstreet" />
        )}
      </SimpleForm>
    </Edit>
  );
};

export default BillingGroupsEdit;
