import { gql } from '@apollo/client';
import list from './List';
import show from './Show';
import edit from './Edit';
import create from './Create';

export default {
  name: 'AgencyQualifications',
  inputShow: gql`
    query AgencyQualifications($input: AgencyQualificationQueryInput) {
      agencyQualifications(input: $input) {
        id
        __typename
      }
    }
  `,
  list,
  show,
  edit,
  create,
  options: {
    group: 'Qualifications',
    label: 'Agency Qualifications',
  },
};
