import React from 'react';
import { gql } from '@apollo/client';
import { BooleanField, NumberField, Show, SimpleShowLayout, TextField } from 'react-admin';
import MoneyField from 'components/MoneyField';
import DateTimeField from 'components/DateTimeField';
import BalanceSheetShowActions from './ShowActions';

const BalanceSheetShow = () => (
  <Show title="Financial Reports" actions={<BalanceSheetShowActions />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <NumberField label="Close Period" source="close_period" />
      <MoneyField source="total_cents" label="Total" />
      <BooleanField source="closed" />
      <DateTimeField
        label="First Date and Time"
        source="first_datetime"
        timeZone="America/Los_Angeles"
      />
      <TextField source="date" />
      <TextField source="month" />
      <TextField source="year" />
    </SimpleShowLayout>
  </Show>
);

BalanceSheetShow.query = gql`
  query GET_ONE($id: Int!) {
    balanceSheet(id: $id) {
      id
      close_period
      total_cents
      closed
      date
      month
      year
      first_datetime
    }
  }
`;

export default BalanceSheetShow;
