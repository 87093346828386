import React from 'react';
import { gql } from '@apollo/client';
import { Datagrid } from 'react-admin';
import IdField from 'components/IdField';
import List from 'components/List';

const AccountLocationsList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <IdField />
    </Datagrid>
  </List>
);

AccountLocationsList.query = gql`
  query GET_LIST($input: AccountLocationQueryInput) {
    _accountLocationsMeta(input: $input) {
      count
    }
    accountLocations(input: $input) {
      id
      account_id
      account {
        id
        name
        email
        title
        status
        permission_groups {
          id
          display_name
        }
      }
      location_id
      location {
        id
        name
        status
        location_type {
          id
          label
        }
        phone_number
        account_manager_id
        account_manager {
          id
          account {
            id
            name
          }
        }
        sales_associate_id
        sales_associate {
          id
          account {
            id
            name
          }
        }
      }
      source
    }
  }
`;

export default AccountLocationsList;
