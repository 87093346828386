import { useAppLocationState, useDefineAppLocation } from '@react-admin/ra-navigation';
import { useEffect } from 'react';

/**
 * Updates the react-admin app location.
 * Useful for correctly highlighting the menu item for custom paths
 */
const useUpdateAppLocation = (newPath: string, overridePath?: string) => {
  useDefineAppLocation(newPath);
  const [location, setLocation] = useAppLocationState();
  useEffect(() => {
    // Override for custom paths
    // When the url is first loaded react-admin will not detect the custom path.
    if (location.path === overridePath) {
      setLocation(newPath);
    }
  }, [location, setLocation, newPath, overridePath]);
};

export default useUpdateAppLocation;
