import React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import MoneyInput from 'components/MoneyInput';
import useCheckAccess from 'hooks/useCheckAccess';
import BillingInput from './BillingInput';
import PercentInput from 'components/PercentInput';
import BillingGroupParentInput from 'components/BillingGroupParentInput';
import BillingGroupManagementCompanyInput from 'components/BillingGroupManagementCompanyInput';
import useErrorHandler from 'hooks/useErrorHandler';

const BillingGroupsCreate = () => {
  const checkAccess = useCheckAccess();
  const onError = useErrorHandler();

  return (
    <Create mutationOptions={{ onError }}>
      <SimpleForm>
        {checkAccess('create', 'BillingGroup', 'company_id') && (
          <TextInput label="Company ID" source="company_id" />
        )}
        {checkAccess('create', 'BillingGroup', 'billing_group_parent_id') && (
          <BillingGroupParentInput
            label="Billing Group Parent ID"
            source="billing_group_parent_id"
          />
        )}
        {checkAccess('create', 'BillingGroup', 'billing_group_management_company_id') && (
          <BillingGroupManagementCompanyInput
            label="Billing Group Management Company ID"
            source="billing_group_management_company_id"
          />
        )}
        {checkAccess('create', 'BillingGroup', 'name') && <TextInput label="Name" source="name" />}
        {checkAccess('create', 'BillingGroup', 'legal_name') && (
          <TextInput label="Legal Name" source="legal_name" />
        )}
        {checkAccess('create', 'BillingGroup', 'status') && (
          <EnumSelectInput label="Status" source="status" resource="BillingGroup" sort="" />
        )}
        {checkAccess('create', 'BillingGroup', 'plan') && (
          <EnumSelectInput label="Plan" source="plan" resource="BillingGroup" sort="" />
        )}
        {checkAccess('create', 'BillingGroup', 'billing') && <BillingInput />}
        {checkAccess('create', 'BillingGroup', 'vms_fee_percent') && (
          <PercentInput label="VMS Fee Percent" source="vms_fee_percent" />
        )}
        {checkAccess('create', 'BillingGroup', 'stripe_bank_account_token') && (
          <TextInput label="Stripe Bank Account Token" source="stripe_bank_account_token" />
        )}
        {checkAccess('create', 'BillingGroup', 'plaid_account_id') && (
          <TextInput label="Plaid Account ID" source="plaid_account_id" />
        )}
        {checkAccess('create', 'BillingGroup', 'plaid_token') && (
          <TextInput label="Plaid Token" source="plaid_token" />
        )}
        {checkAccess('create', 'BillingGroup', 'stripe_last_4') && (
          <TextInput label="Stripe Last 4" source="stripe_last_4" />
        )}
        {checkAccess('create', 'BillingGroup', 'bank_last') && (
          <TextInput label="Bank Last" source="bank_last" />
        )}
        {checkAccess('create', 'BillingGroup', 'stripe_customer_id') && (
          <TextInput label="Stripe Customer ID" source="stripe_customer_id" />
        )}
        {checkAccess('create', 'BillingGroup', 'business_tax_id') && (
          <TextInput label="Business Tax ID" source="business_tax_id" />
        )}
        {checkAccess('create', 'BillingGroup', 'payment_gateway') && (
          <BooleanInput label="Payment Gateway" source="payment_gateway" />
        )}
        {checkAccess('create', 'BillingGroup', 'account_summary_emails') && (
          <BooleanInput label="Account Summary Emails" source="account_summary_emails" />
        )}
        {checkAccess('create', 'BillingGroup', 'invoice_emails') && (
          <BooleanInput label="Invoice Emails" source="invoice_emails" />
        )}
        {checkAccess('create', 'BillingGroup', 'auto_pay') && (
          <BooleanInput label="Auto Pay" source="auto_pay" />
        )}
        {checkAccess('create', 'BillingGroup', 'statement_fee_percent') && (
          <PercentInput label="Statement Fee Percent" source="statement_fee_percent" />
        )}
        {checkAccess('create', 'BillingGroup', 'check_line_of_credit') && (
          <BooleanInput label="Check Line of Credit" source="check_line_of_credit" />
        )}
        {checkAccess('create', 'BillingGroup', 'line_of_credit_cents') && (
          <MoneyInput label="Line of Credit" source="line_of_credit_cents" />
        )}
        {checkAccess('create', 'BillingGroup', 'net_term') && (
          <EnumSelectInput label="Net Term" source="net_term" resource="BillingGroup" sort="" />
        )}
        {checkAccess('create', 'BillingGroup', 'charge_on_call_hourly_rate_cents') && (
          <MoneyInput
            label="Charge On Call Hourly Rate"
            source="charge_on_call_hourly_rate_cents"
          />
        )}
        {checkAccess('create', 'BillingGroup', 'msa') && <BooleanInput label="MSA" source="msa" />}
        {checkAccess('create', 'BillingGroup', 'collections_late_fee_percent') && (
          <PercentInput
            label="Collections Late Fee Percent"
            source="collections_late_fee_percent"
          />
        )}
        {checkAccess('create', 'BillingGroup', 'has_dun_and_bradstreet') && (
          <BooleanInput label="Dun and Bradstreet" source="has_dun_and_bradstreet" />
        )}
      </SimpleForm>
    </Create>
  );
};

export default BillingGroupsCreate;
