import { getResourceQueries } from './utils';
import * as R from 'ramda';
import _ from 'lodash';
import { useGraphQLRequest } from 'providers/graphqlPermissions';

const flattenIfNested = R.ifElse(_.flowRight(_.isArray, R.head), R.flatten, _.identity);

export const useGetMany = () => {
  const { gqlRequest } = useGraphQLRequest();

  return (resource, { ids }) => {
    const queries = getResourceQueries(resource);
    const query = queries.inputShow;
    return gqlRequest({
      fetchPolicy: 'network-only',
      query: query,
      variables: { input: { filter: { id: flattenIfNested(ids) } } },
    }).then((response) => {
      const data = response.data[queries.list.name];
      return { data };
    });
  };
};
