import React, { Fragment, useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useGetManyReference } from 'react-admin';
import QualificationAttributeInput from './QualificationAttributeInput';
import QualificationAttributeValueInput from './QuestionAttributeValueInput';
import { getUnzippedAttributes, UnzippedAttributes, DEFAULT_ATTRIBUTES } from './payloadUtils';
import { equals } from 'ramda';

const QualificationAttributeDialog = ({
  isOpen,
  handleClose,
  andCriteria,
  updateAndCriteriaAttributes,
  selectedQualification,
}) => {
  // Unzipped object to maintain order of attributes and easier to use in UI
  const unzippedAttributes = getUnzippedAttributes(andCriteria);

  const { data: qualificationAttributes = [] } = useGetManyReference(
    'QualificationAttribute',
    {
      target: 'qualification_id',
      id: selectedQualification?.id,
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
    },
    {
      enabled: selectedQualification?.id !== undefined,
    },
  );

  const [attributes, setAttributes] = useState<UnzippedAttributes>(DEFAULT_ATTRIBUTES);

  useEffect(() => {
    if (unzippedAttributes && !equals(unzippedAttributes, attributes)) {
      setAttributes(unzippedAttributes);
    }
  }, [unzippedAttributes, attributes]);

  const updateAttributes = (copyAttributes) =>
    updateAndCriteriaAttributes(selectedQualification?.slug, Object.fromEntries(copyAttributes));

  const updateAttribute = ({ target: { value } }, index) => {
    const copyAttributes = [...attributes];
    if (copyAttributes.length > 0) {
      copyAttributes[index] = [value, undefined];
    } else {
      copyAttributes.push([value, undefined]);
    }
    setAttributes(copyAttributes);
    updateAttributes(copyAttributes);
  };

  const updateAttributeValue = (value, index) => {
    const copyAttributes = [...attributes];
    copyAttributes[index][1] = value;
    setAttributes(copyAttributes);
    updateAttributes(copyAttributes);
  };

  const addQualificationAttribute = () => {
    const copyAttributes = [...attributes];
    copyAttributes.push(['', undefined]);
    setAttributes(copyAttributes);
    updateAttributes(copyAttributes);
  };

  const removeAttribute = (index) => {
    const copyAttributes = [...attributes];
    copyAttributes.splice(index, 1);
    setAttributes(copyAttributes);
    updateAttributes(copyAttributes);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{selectedQualification?.long_name ?? 'Qualification'}</DialogTitle>
      <DialogContent>
        {attributes.map(([attributeSlug, attributeValue], index) => (
          <Fragment key={`${attributeSlug}-${index}`}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h3>{`Field ${index + 1}`}</h3>
              <Button
                style={{ minWidth: '20px', fontWeight: 'bold' }}
                type="button"
                onClick={() => removeAttribute(index)}
              >
                <span>X</span>
              </Button>
            </div>
            <div
              style={{
                marginBottom: '42px',
                display: 'flex',
                flexDirection: 'column',
                width: '420px',
              }}
            >
              <QualificationAttributeInput
                value={attributeSlug}
                onChange={(e) => updateAttribute(e, index)}
                qualificationAttributes={qualificationAttributes}
              />
              <QualificationAttributeValueInput
                value={attributeValue}
                qualificationAttributes={qualificationAttributes}
                attributeSlug={attributeSlug}
                onChange={(e) => updateAttributeValue(e, index)}
              />
            </div>
          </Fragment>
        ))}
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={() => addQualificationAttribute()}>Add Field</Button>
        <Button variant="contained" onClick={handleClose}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QualificationAttributeDialog;
