import React from 'react';
import { Create, SimpleForm, BooleanInput } from 'react-admin';
import { useLocation } from 'react-router';
import AgencyInput from 'components/AgencyInput';
import LocationInput from 'components/LocationInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';

const AgencyLocationsCreate = () => {
  const { state } = useLocation();
  const initialValues = {
    location_id: state?.record?.location_id,
  };
  return (
    <Create redirect="show">
      <SimpleForm record={initialValues}>
        <AgencyInput fullWidth />
        <LocationInput fullWidth />
        <EnumSelectInput label="Status" source="status" />
        <BooleanInput
          label="Auto Publish Per Diem"
          source="auto_publish_per_diem_to_professionals"
        />
        <BooleanInput
          label="Auto Publish Assignments"
          source="auto_publish_assignments_to_professionals"
        />
      </SimpleForm>
    </Create>
  );
};

export default AgencyLocationsCreate;
