import React from 'react';
import { AdditionalFilters, InvoicesDataGrid } from '../invoice/List';
import ListView from '../components/ListView';

const DebitMemosList = () => {
  return (
    <ListView invoice_type="debit_memo" title="Debit Memos" additionalFilters={AdditionalFilters}>
      <InvoicesDataGrid />
    </ListView>
  );
};

export default DebitMemosList;
