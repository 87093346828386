import { getResourceQueries, parseResponse } from './utils';
import * as R from 'ramda';
import _ from 'lodash';
import { useGraphQLRequest } from 'providers/graphqlPermissions';

export const useGetOne = () => {
  const { gqlRequest } = useGraphQLRequest();

  return (resource, { id }) => {
    const queries = getResourceQueries(resource);
    return gqlRequest({
      fetchPolicy: 'network-only',
      query: queries.show.query,
      variables: { id: parseInt(id) },
    }).then((response) => {
      return {
        data: _.flowRight(
          R.ifElse<any, any, any>(
            () => resource === 'Credential',
            populateLicenseFieldValues,
            _.identity,
          ),
          parseResponse,
        )(response.data[queries.show.name]),
      };
    });
  };
};

function populateLicenseFieldValues(credential) {
  const { attachments } = credential;
  const getValue = (licenseField) => {
    const value = (attachments ?? []).find(
      (att) => att.license_field?.id === licenseField.id,
    )?.value;
    if (_.isNil(value) || typeof value !== 'string') {
      return value;
    }
    switch (licenseField.field_type) {
      case 4:
        return value === 'true';
      case 5:
        return parseInt(value);
      default:
        return value;
    }
  };
  const setAttachment = (lf) => R.set(R.lensProp('value'), getValue(lf), lf);
  return R.over(R.lensProp('license_fields'), R.map(setAttachment), credential);
}
