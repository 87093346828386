import React from 'react';
import { FunctionField, useRecordContext, WrapperField } from 'react-admin';
import GoogleMapsLinkField from './GoogleMapsLinkField';
import get from 'lodash/get';

export type CoordinatesFieldProps = {
  label?: string;
  sources?: {
    latitude?: string;
    longitude?: string;
  };
  showMapLink?: boolean;
};

export function CoordinatesField(props: CoordinatesFieldProps) {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  const { sources = {}, label = 'Coordinates (Lat, Long)', showMapLink = false } = props;
  const latKey = sources.latitude || 'coordinates.latitude';
  const longKey = sources.longitude || 'coordinates.longitude';

  return (
    <WrapperField label={label}>
      <FunctionField
        label={label}
        render={(resource) => {
          const latitude = limitDecimalPlaces(get(resource, latKey, ''));
          const longitude = limitDecimalPlaces(get(resource, longKey, ''));

          return `${latitude}, ${longitude}`;
        }}
      />
      {showMapLink && <GoogleMapsLinkField />}
    </WrapperField>
  );
}

function limitDecimalPlaces(rawValue: string | number, places = 10) {
  const value = String(rawValue);
  const indexOfDecimal = value.indexOf('.');
  if (indexOfDecimal === -1) {
    return value;
  }

  const decimalPlaces = value.length - indexOfDecimal - 1;
  if (decimalPlaces <= places) {
    return value;
  }

  return value.substring(0, indexOfDecimal + places + 1);
}
