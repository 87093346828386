import React from 'react';
import { gql } from '@apollo/client';
import {
  Show,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  Tab,
  TextField,
  useRecordContext,
  TopToolbar,
  EditButton,
} from 'react-admin';
import JobsTab from '../../components/tabs/JobsTab';
import TimestampFields from 'components/TimestampFields';
import LinkField from 'components/LinkField';
import DateTimeField from 'components/DateTimeField';
import useCheckAccess from 'hooks/useCheckAccess';
import UploadTimesheet from './UploadTimesheet';
import ExpenseAdjustmentsTab from 'components/tabs/ExpenseAdjustmentsTab';
import RevenueAdjustmentsTab from 'components/tabs/RevenueAdjustmentsTab';
import InvoicesTab from './InvoicesTab';
import PayoutsTab from './PayoutsTab';

const TimecardShowActions = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <TopToolbar sx={{ justifyContent: 'flex-start' }}>
      <UploadTimesheet />
      <EditButton sx={{ marginLeft: 'auto' }} />
    </TopToolbar>
  );
};

const TimecardsShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show data-testid="timecards-show" actions={<TimecardShowActions />}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="Info">
          <TextField label="ID" source="id" />
          <TextField label="Status" source="status" />
          <DateTimeField
            label="Starts Time"
            format="MM/dd/yyyy HH:mm zzz"
            source="starts_time"
            timeZone="America/Los_Angeles"
          />
          <DateTimeField
            label="Ends Time"
            format="MM/dd/yyyy HH:mm zzz"
            source="ends_time"
            timeZone="America/Los_Angeles"
          />
          <LinkField label="Assignment ID" source="assignment.id" />
          <LinkField label="Professional ID" source="professional.id" />
          <LinkField label="Primary Payout ID" source="primary_payout_id" typename="Payout" />
          <TextField label="Payout Kind" source="payout_kind" />
          <DateTimeField
            label="Submit by Time"
            format="MM/dd/yyyy HH:mm zzz"
            source="submit_by"
            timeZone="America/Los_Angeles"
          />
          <DateTimeField
            label="Approve by Time"
            format="MM/dd/yyyy HH:mm zzz"
            source="approve_by"
            timeZone="America/Los_Angeles"
          />
          <TimestampFields />
        </Tab>
        {checkAccess('view', 'Job') && (
          <Tab label="Jobs" path="jobs">
            <JobsTab target="timecard_id" />
          </Tab>
        )}
        {checkAccess('view', 'Invoice') && (
          <Tab label="Invoices" path="invoices">
            <InvoicesTab />
          </Tab>
        )}
        {checkAccess('view', 'Payout') && (
          <Tab label="Payouts" path="payouts">
            <PayoutsTab />
          </Tab>
        )}
        {checkAccess('view', 'ExpenseAdjustment') && (
          <Tab label="Expense Adjustments" path="expense-adjustments">
            <ExpenseAdjustmentsTab target="timecard_id" />
          </Tab>
        )}
        {checkAccess('view', 'RevenueAdjustment') && (
          <Tab label="Revenue Adjustments" path="revenue-adjustments">
            <RevenueAdjustmentsTab target="timecard_id" />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

TimecardsShow.query = gql`
  query GET_ONE($id: Int!) {
    timecard(id: $id) {
      id
      status
      starts_time
      ends_time
      primary_payout_id
      payout_kind
      payout_ids
      invoice_ids
      assignment {
        id
      }
      professional {
        id
      }
      submit_by
      approve_by
      created_at
      updated_at
    }
  }
`;

export default TimecardsShow;
