import { useGetList } from './graphql/getList';
import { useGetMany } from './graphql/getMany';
import create from './rails/create';
import update from './rails/update';
import  { useGetInputList } from './graphql/getInputList';
import impersonate from './rails/impersonate';
import remove from './rails/remove';
import getAxios from 'getAxios';
import { DataProvider } from 'react-admin';
import { getApolloContext } from '@apollo/client';
import { useGetOne } from './graphql/getOne';
import { useGetManyReference } from './graphql/getManyReference';

const axios = getAxios();

type impersonateFunc = (id: number) => Promise<{ data: { token: any } }>;

type ExtendedDataProvider = DataProvider & { impersonate: impersonateFunc };

export const useDataProvider = () => {
  const { client } = getApolloContext();

  const getInputList = useGetInputList();
  const getList = useGetList();
  const getMany = useGetMany();
  const getOne = useGetOne();
  const getManyReference = useGetManyReference();

  const dataProvider: ExtendedDataProvider = {
    client,
    create: create(axios),
    delete: remove(axios),
    deleteMany: function () {
      return new Promise(() => {
        console.log('updated_many');
      });
    },
    getInputList: getInputList,
    getList: getList,
    getMany: getMany,
    getManyReference: getManyReference,
    getOne: getOne,
    impersonate: impersonate(axios),
    update: update(axios),
    updateMany: function () {
      return new Promise(() => {
        console.log('updated_many');
      });
    },
  };

  return { dataProvider };
};
