import { Datagrid, TextField } from 'react-admin';
import IdField from 'components/IdField';
import List from 'components/List';
import React from 'react';
import { gql } from '@apollo/client';
import LinkField from 'components/LinkField';
import IdInput from 'components/Filter/IdInput';
import AdministratorInput from 'components/Filter/AdministratorInput';

const Filters = [
  <IdInput />,
  <AdministratorInput label="Last Updated By" source="last_updated_by_admin_id" alwaysOn />,
];

const AgencyQualificationsList = () => (
  <List exporter={false} filters={Filters}>
    <Datagrid bulkActionButtons={false}>
      <IdField />
      <LinkField label="Agency ID" source="agency_id" />
      <TextField label="Agency Name" source="agency.name" />
      <LinkField label="Qualification ID" source="qualification_id" />
      <TextField label="Qualification Name" source="qualification.long_name" />
      <TextField label="Status" source="status" />
    </Datagrid>
  </List>
);

AgencyQualificationsList.query = gql`
  query GET_LIST($input: AgencyQualificationQueryInput) {
    agencyQualifications(input: $input) {
      id
      status
      qualification_id
      qualification {
        id
        long_name
        qualification_type
        slug
      }
      agency_qualification_attributes {
        id
        type
        value
        state
        qualification_attribute {
          id
          slug
          name
        }
      }
      agency_id
      agency {
        id
        name
      }
      created_at
      updated_at
    }
    _agencyQualificationsMeta(input: $input) {
      count
    }
  }
`;

export default AgencyQualificationsList;
