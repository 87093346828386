import React from 'react';
import { gql } from '@apollo/client';
import {
  NumberField,
  TopToolbar,
  EditButton,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  BooleanField,
  TabbedShowLayoutTabs,
  DateField,
  FunctionField,
  ReferenceField,
  useRecordContext,
  SimpleShowLayout,
} from 'react-admin';
import MoneyField from 'components/MoneyField';
import PercentField from 'components/PercentField';
import RefundFormButton from 'resources/refunds/RefundForm/RefundFormButton';
import LinkField from 'components/LinkField';
import useCheckAccess from 'hooks/useCheckAccess';
import BillingGroupAside from './BillingGroupAside';
import LocationsTab from './tabs/LocationsTab';
import InvoicesTab from 'components/tabs/InvoicesTab';
import DisputesTab from 'components/tabs/DisputesTab';
import AccountsTab from './tabs/AccountsTab';
import ItemizedTransactionsTab from './tabs/ItemizedTransactionsTab';
import StripeUrlField from 'components/StripeUrlField';
import config from 'config';
import { Grid, Link, Typography } from '@mui/material';
import NotesTab from 'components/tabs/NotesTab';
import ApplyCreditBalanceButton from './ApplyCreditBalanceButton';

const BillingGroupActions = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <TopToolbar>
      <ApplyCreditBalanceButton record={record} />
      <RefundFormButton billingGroupId={record.id} mr={2} />
      <EditButton />
    </TopToolbar>
  );
};

const BillingGroupGrid = ({ rows }) => {
  return (
    <Grid container spacing={0}>
      {rows.map((row, i) => (
        <Grid container item key={i} xs={12} spacing={2}>
          {row.map((field, j) => (
            <Grid item key={j} xs={3}>
              <SimpleShowLayout sx={{ padding: 0 }}>{field}</SimpleShowLayout>
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

const BillingGroupsShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show actions={<BillingGroupActions />} aside={<BillingGroupAside />}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="info">
          <TextField label="ID" source="id" />
          <LinkField label="Company ID" source="company_id" />
          <BillingGroupGrid
            rows={[
              [
                <LinkField
                  label="Billing Group Parent ID"
                  source="billing_group_parent.id"
                  typename="BillingGroupParent"
                />,
                <TextField label="Billing Group Parent Name" source="billing_group_parent.name" />,
              ],
              [
                <LinkField
                  label="Billing Group Management Company ID"
                  source="billing_group_management_company.id"
                  typename="BillingGroupManagementCompany"
                />,
                <TextField
                  label="Billing Group Management Company Name"
                  source="billing_group_management_company.name"
                />,
              ],
            ]}
          />
          <TextField label="Name" source="name" />
          <TextField label="Legal Name" source="legal_name" />
          <TextField label="Status" source="status" />
          <TextField label="Plan" source="plan" />
          <TextField label="Billing" source="billing" />
          <MoneyField label="Past Due Allowance Amount" source="past_due_allowance_amount_cents" />
          <ReferenceField
            label="Collections Rep"
            source="collections_rep"
            reference="Administrator"
            link="show"
          >
            <FunctionField
              render={(record) => {
                if (record.account) {
                  const { first_name, last_name } = record.account;
                  return `${first_name} ${last_name}`;
                }

                return `Rep ID: ${record.id}`;
              }}
            />
          </ReferenceField>
          <ReferenceField
            label="Billing Rep"
            source="billing_rep"
            reference="Administrator"
            link="show"
          >
            <FunctionField
              render={(record) => {
                if (record.account) {
                  const { first_name, last_name } = record.account;
                  return `${first_name} ${last_name}`;
                }

                return `Rep ID: ${record.id}`;
              }}
            />
          </ReferenceField>
          <TextField label="Tier" source="tier" />
          <NumberField label="Credit Insurance Limit" source="credit_insurance_limit" />
          <DateField label="Last Credit Review" source="last_credit_review" />
          <NumberField label="DUNS" source="duns" />
          <NumberField label="EH ID" source="eh_id" />
          <PercentField label="VMS Fee Percent" source="vms_fee_percent" />
          <TextField label="Stripe Bank Account Token" source="stripe_bank_account_token" />
          <StripeUrlField />
          <TextField label="Plaid Account ID" source="plaid_account_id" />
          <TextField label="Plaid Token" source="plaid_token" />
          <TextField label="Stripe Last 4" source="stripe_last_4" />
          <TextField label="Bank Last" source="bank_last" />
          <TextField label="Business Tax ID" source="business_tax_id" />
          <BooleanField label="Payment Gateway" source="payment_gateway" />
          <FunctionField
            label="Payment Gateway URL"
            render={(r) => (
              <Typography>
                <Link
                  target="_blank"
                  href={`${config.client_url['facility']}/hosted-billing/${r.uid}`}
                >
                  {r.uid}
                </Link>
              </Typography>
            )}
          />
          <BooleanField label="Account Summary Emails" source="account_summary_emails" />
          <BooleanField label="Invoice Emails" source="invoice_emails" />
          <BooleanField label="Auto Pay" source="auto_pay" />
          <PercentField label="Statement Fee Percent" source="statement_fee_percent" />
          <BooleanField label="Check Line of Credit" source="check_line_of_credit" />
          <MoneyField label="Line of Credit" source="line_of_credit_cents" />
          <NumberField label="Net Term" source="net_term" />
          <MoneyField
            label="Charge On Call Hourly Rate"
            source="charge_on_call_hourly_rate_cents"
          />
          <BooleanField label="MSA" source="msa" />
          <PercentField
            label="Collections Late Fee Percent"
            source="collections_late_fee_percent"
          />
          <BooleanField label="Dun and Bradstreet" source="has_dun_and_bradstreet" />
        </Tab>
        <Tab label="Accounts" path="accounts">
          <AccountsTab />
        </Tab>
        {checkAccess('view', 'Location', 'all') && (
          <Tab label="Locations" path="locations">
            <LocationsTab />
          </Tab>
        )}
        {checkAccess('view', 'Invoice', 'all') && (
          <Tab label="Invoices" path="invoices">
            <InvoicesTab target="billing_group_id" showFilters={true} />
          </Tab>
        )}
        {checkAccess('view', 'Dispute', 'all') && (
          <Tab label="Disputes" path="disputes">
            <DisputesTab target="search.billing_group_id" />
          </Tab>
        )}
        {checkAccess('view', 'ItemizedTransaction', 'all') && (
          <Tab label="Itemized Transaction" path="itemized_transaction">
            <ItemizedTransactionsTab />
          </Tab>
        )}
        {checkAccess('view', 'Note', 'all') && (
          <Tab label="Notes" path="notes">
            <NotesTab />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

BillingGroupsShow.query = gql`
  query GET_ONE($id: Int!) {
    billingGroup(id: $id) {
      id
      company_id
      billing_group_parent {
        id
        name
      }
      billing_group_management_company {
        id
        name
      }
      name
      legal_name
      status
      billing
      plan
      vms_fee_percent
      stripe_bank_account_token
      plaid_account_id
      plaid_token
      stripe_last_4
      bank_last
      stripe_customer_id
      business_tax_id
      payment_gateway
      account_summary_emails
      invoice_emails
      auto_pay
      statement_fee_percent
      check_line_of_credit
      line_of_credit_cents
      net_term
      charge_on_call_hourly_rate_cents
      balance_cents
      track_unpaid_breaks
      past_due_allowance_amount_cents
      collections_rep
      billing_rep
      tier
      credit_insurance_limit
      last_credit_review
      duns
      eh_id
      total_due_cents
      current_due_cents
      past_due_cents
      remaining_credit_cents
      uid
      msa
      collections_late_fee_percent
      has_dun_and_bradstreet
    }
  }
`;

export default BillingGroupsShow;
