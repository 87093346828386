import React from 'react';
import { Edit, SimpleForm, BooleanInput } from 'react-admin';
import AgencyInput from 'components/AgencyInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import LocationInput from 'components/LocationInput';

const AgencyLocationsEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <SimpleForm>
        <AgencyInput fullWidth />
        <LocationInput fullWidth />
        <EnumSelectInput label="Status" source="status" />
        <BooleanInput
          label="Auto Publish Per Diem"
          source="auto_publish_per_diem_to_professionals"
        />
        <BooleanInput
          label="Auto Publish Assignments"
          source="auto_publish_assignments_to_professionals"
        />
      </SimpleForm>
    </Edit>
  );
};

export default AgencyLocationsEdit;
