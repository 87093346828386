import React from 'react';
import { Create, NumberInput, required, SimpleForm, TextInput } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import InvoiceInput from 'components/InvoiceInput';
import JobInput from 'components/JobInput';
import DisputeInput from 'components/DisputeInput';
import useCheckAccess from 'hooks/useCheckAccess';
import MoneyInput from 'components/MoneyInput';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { revenueAdjustmentHelperText } from 'constants/stringConstants';
import RevenueAdjustmentDescriptionInput from 'components/RevenueAdjustmentDescriptionInput';
import CustomSaveWrapper from './CustomSaveWrapper';
import { validateNonZero } from 'utils/custom-validations';
import AgencyAdjustmentRouteToPayoutToggle from 'components/AgencyAdjustmentRouteToPayoutToggle';

const defaultValues = {
  status: 'pending',
};

type Props = {
  invoice_type: string;
  title: string;
  defaultValues?: {
    adjustment_type: string;
    amount_cents: string;
  };
  amountCentsValidation?: any;
};

const CreateView = ({
  invoice_type,
  title,
  defaultValues: passedInDefaultValues,
  amountCentsValidation = () => undefined,
}: Props) => {
  const checkAccess = useCheckAccess();
  Object.assign(defaultValues, passedInDefaultValues || {});
  const isInvoice = invoice_type === 'invoice';
  const isCreditMemo = invoice_type === 'credit_memo';
  const isDebitMemo = invoice_type === 'debit_memo';

  return (
    <Create title={title}>
      <CustomSaveWrapper invoice_type={invoice_type} isCreate={true}>
        <SimpleForm defaultValues={defaultValues} shouldUnregister>
          {checkAccess('create', 'Invoice', 'billing_group_id') && (
            <NumberInput label="Billing Group ID" source="billing_group_id" validate={required()} />
          )}
          {checkAccess('create', 'Invoice', 'status') && (
            <EnumSelectInput
              label="Status"
              source="status"
              disabled
              validate={required()}
              helperText={invoice_type === 'credit_memo' ? revenueAdjustmentHelperText.status : ''}
            />
          )}
          {checkAccess('create', 'Invoice', 'assignment_id') && (
            <NumberInput label="Assignment ID" source="assignment_id" />
          )}

          {(isInvoice || isDebitMemo) && (
            <>
              {checkAccess('create', 'Invoice', 'voucher_id') && (
                <TextInput label="Voucher ID" source="voucher_id" />
              )}
              {checkAccess('create', 'Invoice', 'voucher_processing_status') && (
                <EnumSelectInput
                  label="Voucher Process Status"
                  source="voucher_processing_status"
                />
              )}
            </>
          )}

          {(isDebitMemo || isCreditMemo) && (
            <>
              <InvoiceInput source="parent_invoices" label="Parent Invoices" multiselect={true} />
              <Typography>Create with Revenue Adjustment</Typography>
              <Box sx={{ ml: 2 }}>
                {checkAccess('create', 'RevenueAdjustment', 'amount_cents') && (
                  <MoneyInput
                    label="Amount"
                    source="amount_cents"
                    validate={[required(), validateNonZero, amountCentsValidation]}
                  />
                )}
                {checkAccess('create', 'RevenueAdjustment', 'job_id') && <JobInput />}
                {checkAccess('create', 'RevenueAdjustment', 'dispute_id') && <DisputeInput />}
                {checkAccess('create', 'RevenueAdjustment', 'adjustment_type') && (
                  <EnumSelectInput
                    label="Adjustment Type"
                    source="adjustment_type"
                    resource="RevenueAdjustment"
                    format={_.identity}
                    validate={[required()]}
                  />
                )}
                <AgencyAdjustmentRouteToPayoutToggle />
                <br />
                {checkAccess('create', 'RevenueAdjustment', 'description') && (
                  <RevenueAdjustmentDescriptionInput />
                )}
                <br />
                {checkAccess('create', 'RevenueAdjustment', 'notes') && (
                  <TextInput source="notes" helperText={revenueAdjustmentHelperText.notes} />
                )}
              </Box>
            </>
          )}
        </SimpleForm>
      </CustomSaveWrapper>
    </Create>
  );
};

export default CreateView;
