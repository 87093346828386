import * as R from 'ramda';
import _ from 'lodash';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getResourceUrl, setResourceRootKey } from '../utils';
import config from 'config';

export default function update(ajax: AxiosInstance) {
  return (resource, { id, data, previousData }, url: string = '') => {
    const baseUrl = getResourceUrl(resource, id);

    const defaultUpdate = async (
      baseUrl: any,
      body: any,
      method: AxiosRequestConfig['method'] = 'PUT',
    ): Promise<any> =>
      ajax({
        url: baseUrl,
        method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: _.omit(body, ['_action']),
      }).then((res) => ({ data: { id, ...R.mergeAll([data, res.data]) } }));

      if (resource === 'ApprovalGroup') {
        const url = `${config.rails_url}/v3/admin/companies/${data.company_id}/approval_groups/${id}`;
        return defaultUpdate(url, { name: data.name, account_ids: data.account_ids }, 'PATCH');
      }

    if (resource === 'Assignment' && data._action === 'APPROVE') {
      return defaultUpdate(`${baseUrl}/approve`, {}, 'POST');
    }

    if (resource === 'LocationGroup') {
      const url = `${config.rails_url}/v3/admin/companies/${data.company_id}/group_nodes/${id}`;
      return defaultUpdate(url, { description: data.description, name: data.name }, 'PATCH');
    }

    if (resource === 'Statement' && data._action) {
      switch (data._action) {
        case 'revise':
        case 'mark_void':
        case 'mark_uncollectible':
          return defaultUpdate(`${baseUrl}/${data._action}`, {});
      }
    }

    if (resource === 'VmsAssignment' || resource === 'VmsUser') {
      const currentData = previousData?.current_data || {};
      const body = { valid_data: diff(data.valid_data, currentData) };
      return defaultUpdate(baseUrl, setResourceRootKey(resource, body));
    }

    const body = diff(data, previousData ?? {});
    if (body.questions && resource === 'ProfessionalReference') {
      body.questions = JSON.stringify(body.questions);
    }
    if (body.questions && resource === 'Questionnaire') {
      body.questions = data.questions;
      body.questions.forEach(function (q) {
        if (Array.isArray(q.options)) {
          q.options = q.options.join(',');
        }
      });
    }
    return defaultUpdate(url || baseUrl, setResourceRootKey(resource, body));
  };
}

const notEquals = R.complement((a: unknown, b: unknown) => R.equals(a, b));

// shallow diff
function diff(current: any, prev: any): any {
  const wasUpdated = (prevObj) => (val, key) =>
    prevObj[key] === undefined || notEquals(prevObj[key], val);
  return R.pickBy(wasUpdated(prev), current);
}
