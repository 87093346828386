import { signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import { gql } from '@apollo/client';
import { AuthProvider, UserIdentity } from 'react-admin';
import { auth } from './utils';
import { useEffect, useState } from 'react';
import { useApolloClientContext } from './apolloClientContextProvider';
import { useTokenStorage } from 'hooks/useTokenStorage';
import { useGraphQLPermissionsContext } from './graphqlPermissions';

const authQuery = gql`
  query AUTH {
    me {
      id
      email
      account_type
      calculated_permissions
      permission_groups {
        id
        display_name
        role
      }
    }
  }
`;

/**
 * https://marmelab.com/react-admin/Authentication.html
 */

declare global {
  interface Window {
    Cypress: unknown;
  }
}

const USER_STORAGE_EVENT = 'MEDELY_CURRENT_USER';

export const useCurrentUserStorage = () => {
  const { client } = useApolloClientContext();
  const getUser = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState<UserIdentity | null>(getUser());

  const findUser = async () => {
    const response = await client.query({
      fetchPolicy: 'network-only',
      query: authQuery,
    });
    const user = response.data.me;
    const role = user?.account_type ?? '';

    if (role === 'administrator') {
      return user;
    } else {
      throw new Error(`Return to https://medely.com/ to log in as a ${role}.`);
    }
  };

  const clearUser = () => {
    localStorage.removeItem('user');
    window.dispatchEvent(new Event(USER_STORAGE_EVENT));
  };

  const loadUser = async () => {
    setIsLoading(true);

    const userData = await findUser();
    localStorage.setItem('user', JSON.stringify(userData));
    window.dispatchEvent(new Event(USER_STORAGE_EVENT));

    setIsLoading(false);
    return userData;
  };

  const getOrReloadUser = async () => {
    const user = getUser();
    if (user) {
      return user;
    }

    return loadUser();
  };

  useEffect(() => {
    const handleUser = () => {
      const user = getUser();
      setCurrentUser(user);
    };

    window.addEventListener(USER_STORAGE_EVENT, handleUser);
    return () => window.removeEventListener(USER_STORAGE_EVENT, handleUser);
  }, []);

  return { currentUser, clearUser, getOrReloadUser, isLoading, loadUser };
};

export const useAuthProvider = (): AuthProvider => {
  const { clearToken } = useTokenStorage();
  const { clearUser, loadUser, getOrReloadUser } = useCurrentUserStorage();
  const { clearSchemaPermissions, refetchSchemaPermissions } = useGraphQLPermissionsContext();

  return {
    login: async (params) => {
      const { username, password } = params;
      const userCredential = await signInWithEmailAndPassword(auth, username, password);
      await loadUser();
      return userCredential.user;
    },
    logout: async (_options = {}, force = false) => {
      if (force || auth.currentUser) {
        await signOut(auth);
        clearUser();
        clearSchemaPermissions();
      }
    },
    checkAuth: () => {
      return new Promise((resolve, reject) => {
        if (auth.currentUser || !!window.Cypress) {
          getOrReloadUser().then(resolve).catch(reject);
        } else {
          onAuthStateChanged(auth, (aUser) => {
            if (aUser) {
              getOrReloadUser().then(resolve).catch(reject);
            } else {
              if (window.location.pathname === '/login') {
                return reject('/');
              }
              return reject();
            }
          });
        }
      });
    },
    getIdentity: () => {
      return new Promise((resolve, reject) => {
        if (auth.currentUser) {
          resolve(auth.currentUser as unknown as UserIdentity);
        } else {
          onAuthStateChanged(auth, (aUser) => {
            if (aUser) {
              resolve(aUser as unknown as UserIdentity);
            } else {
              reject();
            }
          });
        }
      });
    },
    checkError: (error) => {
      const status = error.status;
      if (status === 401 || status === 403) {
        clearToken();
        clearSchemaPermissions();
        localStorage.removeItem('role');
        return Promise.reject();
      }
      return Promise.resolve();
    },
    getPermissions: () => {
      return new Promise((resolve, reject) => {
        if (auth.currentUser) {
          Promise.all([
            getOrReloadUser(),
            refetchSchemaPermissions(),
          ]).then(([user]) => {
            resolve(user)
          });
        } else {
          onAuthStateChanged(auth, (aUser) => {
            if (aUser || !!window.Cypress) {
              Promise.all([
                getOrReloadUser(),
                refetchSchemaPermissions(),
              ]).then(([user]) => resolve(user));
            } else {
              reject('invalid role');
            }
          });
        }
      });
    },
  };
};
