import React, { useEffect, useRef } from 'react';
import MoneyInput from 'components/MoneyInput';
import { calcPayoutRate, calc1099Margin } from 'resources/rate_settings/rateSettingsCalculations';
import { useFormContext } from 'react-hook-form';
import { mergeDeepWith } from 'ramda';
import { useRecordContext } from 'react-admin';

type Props = {
  source: string;
  inheritedSettings: any;
};

const getSource = (sourceKey, source) => {
  if (sourceKey) {
    return `${sourceKey}.${source}`;
  }
  return source;
};

const LocationAndProfessionalRateInput = ({ source, inheritedSettings = {} }: Props) => {
  const context = useRecordContext();
  const { watch, setValue } = useFormContext();
  const formData = watch(source) ?? {};
  const defaultValue = useRef(null);
  const isCreate = !context;

  const mergedSettings = mergeDeepWith((a, b) => a ?? b, formData, inheritedSettings);
  const perDiemSettings = mergedSettings.ten99_per_diem ?? {};

  useEffect(() => {
    const value = calcPayoutRate(
      mergedSettings.charge_rate_cents,
      mergedSettings.margin_percent_1099,
      perDiemSettings.charge_max_daily_regular_hours,
      perDiemSettings.charge_max_daily_overtime_hours,
      perDiemSettings.charge_overtime_multiplier,
      perDiemSettings.payout_max_daily_regular_hours,
      perDiemSettings.payout_max_daily_overtime_hours,
      perDiemSettings.payout_overtime_multiplier,
    );
    if (value > 0) {
      // When useEffect is called on load, pro_rate_cents isn't registered with the form.
      // So we use defaultValue to prefill the field.
      defaultValue.current = value;
      setValue(getSource(source, 'pro_rate_cents'), value);
    }
  }, [
    setValue,
    source,
    mergedSettings.charge_rate_cents,
    mergedSettings.margin_percent_1099,
    perDiemSettings.charge_max_daily_regular_hours,
    perDiemSettings.charge_max_daily_overtime_hours,
    perDiemSettings.charge_overtime_multiplier,
    perDiemSettings.payout_max_daily_regular_hours,
    perDiemSettings.payout_max_daily_overtime_hours,
    perDiemSettings.payout_overtime_multiplier,
  ]);

  const onProRateBlur = () => {
    const value = calc1099Margin(
      mergedSettings.charge_rate_cents,
      mergedSettings.pro_rate_cents,
      perDiemSettings.charge_max_daily_regular_hours,
      perDiemSettings.charge_max_daily_overtime_hours,
      perDiemSettings.charge_overtime_multiplier,
      perDiemSettings.payout_max_daily_regular_hours,
      perDiemSettings.payout_max_daily_overtime_hours,
      perDiemSettings.payout_overtime_multiplier,
    );
    if (value > 0) {
      setValue(getSource(source, 'margin_percent_1099'), value);
    }
  };

  return (
    <>
      <MoneyInput label="Location Rate" source={getSource(source, 'charge_rate_cents')} />
      <MoneyInput
        label="Professional Rate"
        defaultValue={defaultValue.current}
        source={getSource(source, 'pro_rate_cents')}
        helperText={isCreate ? 'Auto calculation needs 1099 settings filled' : null}
        onBlur={onProRateBlur}
      />
    </>
  );
};

export default LocationAndProfessionalRateInput;
