import { useNotify } from 'react-admin';

const useErrorHandler = () => {
  const notify = useNotify();

  return (error) => {
    const msg =
      error.response?.data?.error ||
      error.response?.data?.message ||
      error.message ||
      'There was an error';
    notify(msg.slice(0, 149), { type: 'warning' });
  };
};

export default useErrorHandler;
