import inRange from 'lodash/inRange';

export const round = (value: number, digits: number = 4) => Number(value.toFixed(digits));

export const subtractPercentage = ({ value, percentage }: { value: number; percentage: number }) =>
  value - round(value * (percentage / 100), 0);

export const differenceInPercentage = ({
  value,
  baseValue,
}: {
  value: number;
  baseValue: number;
}) => round(100 - (value * 100) / baseValue, 2);

export const isWithinMargin = ({
  value,
  target,
  margin,
}: {
  value: number;
  target: number;
  margin: number;
}) => inRange(value, target + margin, target - margin);
