import { Box, Card, CardContent, CardHeader, Grid, TableRow } from '@mui/material';
import * as React from 'react';
import {
  ReferenceManyField,
  BooleanField,
  ShowButton,
  Labeled,
  Datagrid,
  TextField,
  SimpleShowLayout,
  NumberField,
  DatagridBody,
  RecordContextProvider,
  Pagination,
  useRecordContext,
} from 'react-admin';
import StripeUrlField from 'components/StripeUrlField';
import ActionButton from 'components/ActionButton';
import config from 'config';
import IdField from 'components/IdField';

const StripeAccountDatagridRow = ({ record }) => (
  <RecordContextProvider value={record}>
    <TableRow>
      <Grid item xs={12} mb={2}>
        <Card>
          <CardHeader
            data-testid="professional_show-stripe_accounts_tab_card_header"
            title={record.tax_classification?.label}
            action={
              <>
                <ShowButton
                  resource="StripeAccount"
                  data-testid="professional_show-stripe_accounts_tab_show_button"
                />
                <ActionButton
                  resource="StripeAccount"
                  text="Accept TOS"
                  url={`${config.rails_url}/v3/admin/stripe_accounts/${record.id}/accept_tos`}
                  method="PUT"
                />
              </>
            }
          />
          <CardContent>
            <Box display="flex" gap={2}>
              <Labeled
                label="Verified"
                data-testid="professional_show-stripe_accounts_tab_verified"
              >
                <BooleanField source="verified" />
              </Labeled>
              <Labeled label="Active" data-testid="professional_show-stripe_accounts_tab_active">
                <BooleanField source="active" />
              </Labeled>
              <Labeled
                label="Stripe Url"
                data-testid="professional_show-stripe_accounts_tab_stripe_url"
              >
                <StripeUrlField />
              </Labeled>
            </Box>
            <Box display="flex" gap={2}>
              <Labeled
                label="Address First"
                data-testid="professional_show-stripe_accounts_tab_address_first"
              >
                <TextField label="Address First" source="address_first" />
              </Labeled>
              <Labeled
                label="Address Second"
                data-testid="professional_show-stripe_accounts_tab_address_second"
              >
                <TextField label="Address Second" source="address_second" />
              </Labeled>
              <Labeled label="City" data-testid="professional_show-stripe_accounts_tab_city">
                <TextField label="City" source="address_city" />
              </Labeled>
              <Labeled label="State" data-testid="professional_show-stripe_accounts_tab_state">
                <TextField label="State" source="state.abbreviation" />
              </Labeled>
              <Labeled label="Zip" data-testid="professional_show-stripe_accounts_tab_zip">
                <TextField label="Zip" source="address_zip" />
              </Labeled>
            </Box>
            <Box display="flex" gap={2}>
              <Labeled
                label="Latitude"
                data-testid="professional_show-stripe_accounts_tab_latitude"
              >
                <NumberField
                  label="Latitude"
                  options={{ maximumFractionDigits: 10 }}
                  source="coordinates.latitude"
                />
              </Labeled>
              <Labeled
                label="Longitude"
                data-testid="professional_show-stripe_accounts_tab_longitude"
              >
                <NumberField
                  label="Longitude"
                  options={{ maximumFractionDigits: 10 }}
                  source="coordinates.longitude"
                />
              </Labeled>
            </Box>
            <Box>
              <Labeled
                label="External sources"
                fullWidth
                data-testid="professional_show-stripe_accounts_tab_external_sources"
              >
                <ReferenceManyField
                  resource="StripeAccount"
                  reference="ExternalAccount"
                  target="stripe_account_id"
                  label=""
                >
                  <Datagrid
                    bulkActionButtons={false}
                    data-testid="professional_show-stripe_accounts_tab_external_sources_table"
                  >
                    <IdField />
                    <TextField
                      label="Account Type"
                      source="account_type"
                      data-testid="professional_show-stripe_accounts_tab_external_sources_table_account_type"
                    />
                    <TextField
                      label="Bank Name"
                      source="bank_name"
                      data-testid="professional_show-stripe_accounts_tab_external_sources_table_bank_name"
                    />
                    <TextField
                      label="Last 4 Digits"
                      source="last4"
                      data-testid="professional_show-stripe_accounts_tab_external_sources_table_last4"
                    />
                    <TextField
                      label="Exp Month"
                      source="exp_month"
                      data-testid="professional_show-stripe_accounts_tab_external_sources_table_exp_month"
                    />
                    <TextField
                      label="Exp Year"
                      source="exp_year"
                      data-testid="professional_show-stripe_accounts_tab_external_sources_table_exp_year"
                    />
                  </Datagrid>
                </ReferenceManyField>
              </Labeled>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </TableRow>
  </RecordContextProvider>
);

const StripeAccountDatagrid = (props) => (
  <Datagrid
    {...props}
    body={<DatagridBody {...props} row={<StripeAccountDatagridRow {...props} />} />}
  />
);

const StripeAccountTab = () => {
  const record = useRecordContext();
  const target = record?.__typename ? `${record.__typename.toLowerCase()}_id` : '';

  return (
    <SimpleShowLayout sx={{ overflow: 'scroll' }}>
      <ReferenceManyField
        reference="StripeAccount"
        target={target}
        label=""
        pagination={<Pagination perPage={5} />}
      >
        <StripeAccountDatagrid bulkActionButtons={false} />
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default StripeAccountTab;
