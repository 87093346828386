import React from 'react';
import {
  TextField,
  Datagrid,
  ReferenceManyField,
  SimpleShowLayout,
  Pagination,
  useRecordContext,
  FunctionField,
} from 'react-admin';
import LinkField from 'components/LinkField';
import { DisplayHelpers } from 'utils/helpers';

const ProfessionalsTab = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  return (
    <SimpleShowLayout>
      <ReferenceManyField
        label={false}
        reference="Professional"
        pagination={<Pagination />}
        target="agency_id"
      >
        <Datagrid bulkActionButtons={false}>
          <LinkField label="ID" source="id" />
          <TextField label="Name" source="account.name" />
          <TextField label="Email" source="account.email" />
          <FunctionField label="Market" render={DisplayHelpers.professional.marketsOrState} />
          <FunctionField
            label="Positions Onboarded"
            source="professional_positions"
            render={DisplayHelpers.professional.positionsOnboardedCount}
            data-testid="professional_show-positions-onboarded_field"
          />
          <TextField label="Status" source="status" />
          <FunctionField
            label="Positions"
            source="positions"
            render={DisplayHelpers.professional.positionNames}
            data-testid="professional_show-kinds_field"
          />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default ProfessionalsTab;
