import React from 'react';
import { pick } from 'ramda';
import {
  Edit,
  SimpleForm,
  TextField,
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  required,
  Labeled,
  TextInput,
  useRecordContext,
} from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import { ProfessionalQualificationStatus } from 'typings/enums';
import AgencyQualificationAttributesInput from 'resources/professional_qualification_attributes/ProfessionalQualificationAttributesInput';
import { ProfessionalAside } from 'resources/professionals/ProfessionalAside';

const transformPayload = (payload) => {
  const { agency_qualification_attributes, ...rest } = payload;
  const filteredArray = agency_qualification_attributes
    ?.filter((attr) => attr.value !== null && attr.value !== undefined)
    ?.map((attr) =>
      pick(['id', 'qualification_attribute_id', 'value', 'extension', '_destroy'], attr),
    );
  return {
    ...rest,
    agency_qualification_attributes_attributes: filteredArray,
  };
};

type Props = {
  label: string;
  source: string;
};

const CheckrTextField = ({ label, source }: Props) => {
  const record = useRecordContext();
  const isBackgroundCheck = record?.qualification?.qualification_type === 'background_check';

  return isBackgroundCheck ? (
    <Labeled>
      <TextField label={label} source={source} />
    </Labeled>
  ) : null;
};

const CheckrTextInput = ({ label, source }: Props) => {
  const record = useRecordContext();
  const isBackgroundCheck = record?.qualification?.qualification_type === 'background_check';

  return isBackgroundCheck ? (
    <Labeled fullWidth>
      <TextInput label={label} source={source} />
    </Labeled>
  ) : null;
};

const AgencyQualificationEdit = () => (
  <Edit transform={transformPayload} aside={<ProfessionalAside />} mutationMode="pessimistic">
    <SimpleForm>
      <Labeled>
        <TextField label="ID" source="id" />
      </Labeled>
      <Labeled>
        <TextField source="qualification_id" />
      </Labeled>
      <Labeled>
        <TextField label="Qualification Name" source="qualification.long_name" />
      </Labeled>
      <Labeled>
        <TextField label="Agency Name" source="agency.name" />
      </Labeled>
      <Labeled>
        <TextField label="Agency" source="agency_id" />
      </Labeled>
      <EnumSelectInput source="status" filterOut={['pending']} fullWidth />

      <CheckrTextField label="Checkr Report ID" source="metadata.report_id" />
      <CheckrTextField label="Checkr Report Status" source="metadata.report_status" />
      <CheckrTextField label="Checkr Candidate ID" source="metadata.candidate_id" />
      <CheckrTextInput label="Notes" source="metadata.notes" />

      <FormDataConsumer>
        {({ formData }) =>
          formData.status === ProfessionalQualificationStatus.rejected && (
            <ReferenceInput
              perPage={50}
              source="credential_rejected_reason_id"
              label="Reject Reason"
              reference="CredentialRejectedReason"
              validate={required()}
              sort={{ field: 'rank', order: 'ASC' }}
            >
              <AutocompleteInput optionText="label" fullWidth />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <AgencyQualificationAttributesInput
        label="Agency Qualification Attributes"
        source="agency_qualification_attributes"
        inputSource={['admin', 'user', 'system', 'agency']}
      />
    </SimpleForm>
  </Edit>
);

export default AgencyQualificationEdit;
