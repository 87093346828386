import React from 'react';
import {
  SimpleShowLayout,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Datagrid,
  FunctionField,
  ReferenceManyField,
  useRecordContext,
  useNotify,
  useRefresh,
  Pagination,
  useListContext,
} from 'react-admin';
import EnumField from 'components/Enum/EnumField';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { JobTimeField } from 'resources/jobs/JobTimeField';
import useDialog from 'hooks/useDialog';
import useApi from 'hooks/useApi';
import CancelDialog from 'resources/jobs/CancelDialog/CancelDialog';

const BulkCancelButton = () => {
  const record = useRecordContext();
  const api = useApi();
  const notify = useNotify();
  const refresh = useRefresh();
  const dialog = useDialog();
  const { selectedIds, onUnselectItems } = useListContext();
  const label = selectedIds.length > 1 ? 'Cancel Jobs' : 'Cancel Job';
  const handleSubmit = async (attrs) => {
    try {
      const { data } = await api({
        url: `/v3/admin/assignments/${record?.id}/jobs/bulk_cancel`,
        method: 'POST',
        data: {
          ids: selectedIds,
          ...attrs,
        },
      });
      refresh();
      onUnselectItems();
      notify(`Jobs ${data.payload.ids.join(', ')} have been canceled`);
      dialog.closeDialog();
    } catch (error) {
      if (error instanceof Error) {
        notify(error.message, { type: 'warning' });
      }
    }
  };
  return (
    <>
      <Button size="small" variant="outlined" color="primary" onClick={dialog.openDialog}>
        {label}
      </Button>
      <CancelDialog open={dialog.open} onClose={dialog.closeDialog} onSubmit={handleSubmit} />
    </>
  );
};

const JobsTab = () => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }

  return (
    <SimpleShowLayout>
      <Button
        to={`/Job/create?source=${JSON.stringify({
          assignment_id: record.id,
          company_id: record.company_id,
        })}`}
        component={Link}
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<AddIcon />}
      >
        Add job
      </Button>
      <ReferenceManyField
        label=""
        reference="Job"
        target="assignment_id"
        pagination={<Pagination />}
        filter={{ assignment_id: record.id }}
        sort={{ field: 'starts_date', order: 'DESC' }}
      >
        <Datagrid
          rowClick="show"
          bulkActionButtons={<BulkCancelButton />}
          isRowSelectable={(row) => row?.status === 'booked'}
          sx={{ overflow: 'visible !important' }}
        >
          <TextField label="ID" source="id" />
          <FunctionField
            label="Starts Date"
            render={(record) => {
              let source = 'starts_time';

              if (record.shift_type === 'call_back') {
                source = 'callback_starts_time';
              } else if (record.shift_type === 'on_call') {
                source = 'on_call_starts_time';
              }

              return <JobTimeField format="MM/dd/yy" source={source} />;
            }}
            source="starts_date"
          />
          <FunctionField
            label="Starts Time"
            render={(record) => {
              let source = 'starts_time';

              if (record.shift_type === 'call_back') {
                source = 'callback_starts_time';
              } else if (record.shift_type === 'on_call') {
                source = 'on_call_starts_time';
              }

              return <JobTimeField format="HH:mm zzz" source={source} />;
            }}
          />
          <FunctionField
            label="Ends Time"
            render={(record) => {
              if (record.shift_type === 'call_back') {
                return <JobTimeField format="HH:mm zzz" source="callback_ends_time" />;
              } else if (record.shift_type === 'on_call') {
                return <JobTimeField format="HH:mm zzz" source="on_call_ends_time" />;
              } else {
                return <JobTimeField format="HH:mm zzz" source="ends_time" />;
              }
            }}
          />
          <FunctionField label="Shift Type" render={() => <TextField source="shift_type" />} />
          <EnumField label="Status" source="status" />
          <ArrayField source="positions">
            <SingleFieldList>
              <ChipField source="display_name" />
            </SingleFieldList>
          </ArrayField>
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(JobsTab);
