import React from 'react';
import { gql } from '@apollo/client';
import { Navigate, Route, Routes } from 'react-router';

const InvoicesList = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/Invoice/invoice" replace />} />
    </Routes>
  );
};

InvoicesList.query = gql`
  query GET_LIST($input: InvoiceQueryInput) {
    _invoicesMeta(input: $input) {
      count
    }
    invoices(input: $input) {
      id
      invoice_type
      total_cents
      amount_due_cents
      status
      assignment_id
      agency_id
      statement_id
      paid_date
      failed_reason
      failed_count
      due_date
      created_at
      days_late
      voucher_id
      invoice_type
      billing_group_id
      billing_group {
        id
        name
      }
    }
  }
`;

export default InvoicesList;
