import React from 'react';
import { TextField, ReferenceManyField, Datagrid, Pagination, SimpleShowLayout } from 'react-admin';
import IdField from 'components/IdField';
import { Typography } from '@mui/material';
import { currentTimeZone } from 'utils/time';
import DateTimeField from 'components/DateTimeField';

const RequirementsDataGrid = () => (
  <Datagrid bulkActionButtons={false}>
    <IdField label="ID" source="id" />
    <TextField label="Condition Name" source="condition.label" />
    <DateTimeField label="Created At" source="created_at" timeZone={currentTimeZone()} />
    <TextField source="job_type" />
    <TextField source="kind" />
    <TextField label="Position" source="position.display_name" />
    <TextField label="Specialty" source="specialty.label" />
  </Datagrid>
);

const RequirementsTab = ({
  showLocationRequirements = true,
  showCompanyRequirements = true,
  showStateRequirements = true,
  locationRequirementsFilter = {},
  locationRequirementsLabel = 'Location Requirements',
}) => (
  <SimpleShowLayout>
    {showLocationRequirements && (
      <>
        <Typography variant="h5" gutterBottom>
          {locationRequirementsLabel}
        </Typography>
        <ReferenceManyField
          pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
          label=""
          reference="Requirement"
          filter={{
            search: { location_requirements: true },
            ...locationRequirementsFilter,
          }}
          target="location_id"
        >
          <RequirementsDataGrid />
        </ReferenceManyField>
      </>
    )}

    {showCompanyRequirements && (
      <>
        <Typography variant="h5" gutterBottom>
          Company Requirements
        </Typography>
        <ReferenceManyField
          pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
          label=""
          reference="Requirement"
          filter={{ search: { company_requirements: true } }}
          target="company_id"
          source="company_id"
        >
          <RequirementsDataGrid />
        </ReferenceManyField>
      </>
    )}

    {showStateRequirements && (
      <>
        <Typography variant="h5" gutterBottom>
          State Requirements
        </Typography>
        <ReferenceManyField
          pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
          label=""
          reference="Requirement"
          filter={{ search: { state_requirements: true } }}
          target="state_id"
          source="state_id"
        >
          <RequirementsDataGrid />
        </ReferenceManyField>
      </>
    )}
  </SimpleShowLayout>
);

export default RequirementsTab;
