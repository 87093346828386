export const redirectOnUpsert = (resource, id, data) => {
  if (data?.professional_qualification_id) {
    return `ProfessionalQualification/${data.professional_qualification_id}/show`;
  }
  return `${resource}/${id}/show`;
};

enum QualificationAttributeType {
  ImageBack = 'ImageBack',
  ImageFront = 'ImageFront',
  Pdf = 'Pdf',
  SignedDocument = 'SignedDocument',
  Csv = 'Csv',
}

const isAttributeType = (value: string, options: QualificationAttributeType[]) => {
  if (!value) {
    return false;
  }
  return options.some((option) => value.includes(option));
};

export class ProfessionalQualificationAttribute<T> {
  record: {
    id: number;
    professional_qualification_id: number;
    qualification_attribute?: {
      data: {
        values?: string[];
        labels?: string[];
      };
    };
    qualification_attribute_id: number;
    type: string;
    value: T;
  };

  constructor(record) {
    this.record = record;
  }

  get isImage(): boolean {
    return (
      isAttributeType(this.record.type, [
        QualificationAttributeType.ImageFront,
        QualificationAttributeType.ImageBack,
      ]) && !this.isPdf
    );
  }

  get isPdf(): boolean {
    const { value } = this.record;
    if (typeof value === 'string') {
      return value.split('?')[0].split('.').pop().toLowerCase() === 'pdf';
    }

    return false;
  }

  get isUnsignedDocument(): boolean {
    const { type } = this.record;
    return isAttributeType(type, [QualificationAttributeType.SignedDocument]) && !this.isPdf;
  }

  get isAttachment(): boolean {
    const { type } = this.record;
    return isAttributeType(type, [QualificationAttributeType.Csv]);
  }

  get isJson(): boolean {
    return !!this.record.type?.includes('Json');
  }

  get isState(): boolean {
    return !!this.record.type?.includes('State');
  }

  get isDate(): boolean {
    return !!this.record.type?.includes('Date');
  }

  get isEnumarable(): boolean {
    return !!this.record.type?.includes('Enumerable');
  }

  get isEnumerableMulti(): boolean {
    return !!this.record.type?.includes('EnumerableMulti');
  }

  get value(): T {
    return this.record.value;
  }
}
