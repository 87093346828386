import React, { useState } from 'react';
import { AccountInfoStatus } from '../ApplicationStatusControl';
import { Confirm } from 'react-admin';
import { MenuItem } from '@mui/material';

interface AccountInfoItemItemProps {
  updateApplicationStatus: (val: any) => void;
}

const AccountInfoItem = ({ updateApplicationStatus }: AccountInfoItemItemProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>Return to Account Info</MenuItem>
      <Confirm
        isOpen={open}
        title="Return to Account Info"
        content="Return this professional's application to Account Info?"
        onConfirm={() =>
          updateApplicationStatus({ application_status: AccountInfoStatus, archive_reason: null })
        }
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default AccountInfoItem;
