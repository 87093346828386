import React from 'react';
import { gql } from '@apollo/client';
import { Show, SimpleShowLayout, TextField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';

const EducationShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="ehr_system.label" />
      <TimestampFields />
    </SimpleShowLayout>
  </Show>
);

EducationShow.query = gql`
  query GET_ONE($id: Int!) {
    professionalProfileEhrSystem(id: $id) {
      id
      ehr_system {
        id
        label
      }
    }
  }
`;

export default EducationShow;
