import React from 'react';
import { TextField, SimpleShowLayout, NumberField } from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import DateTimeField from 'components/DateTimeField';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import EnumField from 'components/Enum/EnumField';
import useCheckAccess from 'hooks/useCheckAccess';
import MetabaseUrlField from '../../../components/MetabaseUrlField';
import ParentInvoicesField from '../components/ParentInvoicesField';

const SummaryTab = ({ showParentInvoices }) => {
  const checkAccess = useCheckAccess();

  return (
    <SimpleShowLayout>
      {checkAccess('view', 'Invoice', 'id') && <TextField label="ID" source="id" />}
      {checkAccess('view', 'Invoice', 'billing_group_id') && (
        <LinkField label="Billing Group ID" source="billing_group_id" typename="BillingGroup" />
      )}
      {checkAccess('view', 'Invoice', 'status') && <TextField label="Status" source="status" />}
      {checkAccess('view', 'Invoice', 'statement_id') && (
        <LinkField label="Statement ID" source="statement.id" />
      )}
      {checkAccess('view', 'Invoice', 'assignment_id') && (
        <LinkField label="Assignment ID" source="assignment_id" />
      )}
      {checkAccess('view', 'Invoice', 'agency_id') && (
        <LinkField label="Agency ID" source="agency_id" />
      )}
      {checkAccess('view', 'InvoicesLink', 'parent_invoice_id') && showParentInvoices && (
        <ParentInvoicesField label="Parent Invoices" />
      )}
      {checkAccess('view', 'Invoice', 'starts_time') && (
        <DateTimeField label="Starts Time" source="starts_time" timeZone="America/Los_Angeles" />
      )}
      {checkAccess('view', 'Invoice', 'ends_time') && (
        <DateTimeField label="Ends Time" source="ends_time" timeZone="America/Los_Angeles" />
      )}
      {checkAccess('view', 'Invoice', 'failed_count') && (
        <NumberField label="Failed Count" source="failed_count" />
      )}
      {checkAccess('view', 'Invoice', 'failed_reason') && (
        <TextField label="Failed Reason" source="failed_reason" />
      )}
      {checkAccess('view', 'Invoice', 'paid_date') && (
        <DateTimeField label="Paid Date" source="paid_date" timeZone="America/Los_Angeles" />
      )}
      {checkAccess('view', 'Invoice', 'due_date') && (
        <DateTimeField label="Due Date" source="due_date" format="MM/dd/yyyy" />
      )}
      {checkAccess('view', 'Invoice', 'locked_at') && (
        <DateTimeField
          label="Locked At"
          source="locked_at"
          format="MM/dd/yyyy HH:mm zzz"
          timeZone="America/Los_Angeles"
        />
      )}
      {checkAccess('view', 'Invoice', 'balance_sheet_id') && (
        <LinkField
          label="Invoice Locked Balance Sheet ID"
          source="invoice_locked_balance_sheet_id"
          typename="BalanceSheet"
        />
      )}
      {checkAccess('view', 'Invoice', 'balance_sheet_id') && (
        <LinkField
          label="Invoice Paid Balance Sheet ID"
          source="invoice_paid_balance_sheet_id"
          typename="BalanceSheet"
        />
      )}
      {checkAccess('view', 'Statement', 'close_time') && (
        <DateTimeField
          label="Statement Close Time"
          source="statement.close_time"
          format="MM/dd/yyyy"
          timeZone="America/Los_Angeles"
        />
      )}
      {checkAccess('view', 'Invoice', 'total_cents') && (
        <MoneyField label="Original Amount" source="total_cents" />
      )}
      {checkAccess('view', 'Invoice', 'amount_due_cents') && (
        <MoneyField label="Current Amount" source="amount_due_cents" />
      )}
      {checkAccess('view', 'Invoice', 'processing_fee_cents') && (
        <MoneyField label="Fees" source="processing_fee_cents" />
      )}
      {checkAccess('view', 'Invoice', 'stripe_invoice_number') && (
        <TextField label="Stripe Invoice Number" source="stripe_invoice_number" />
      )}
      {checkAccess('view', 'Invoice', 'voucher_id') && (
        <TextField label="Voucher ID" source="voucher_id" />
      )}
      {checkAccess('view', 'Invoice', 'voucher_processing_status') && (
        <EnumField label="Voucher Processing Status" source="voucher_processing_status" />
      )}
      {checkAccess('view', 'Audit') && <MetabaseUrlField />}
      <TimestampFields />
    </SimpleShowLayout>
  );
};

export default React.memo(SummaryTab);
