import React from 'react';
import DateTimeField from 'components/DateTimeField';
import {
  BooleanField,
  TextField,
  NumberField,
  ArrayField,
  SingleFieldList,
  ChipField,
  FunctionField,
  useRecordContext,
  Labeled,
  SimpleShowLayout,
} from 'react-admin';
import LinkField from 'components/LinkField';
import MoneyField from 'components/MoneyField';
import Heading from 'components/Heading';
import { Box, Grid } from '@mui/material';
import { formatter as phoneFormatter } from 'components/PhoneInput';
import MetabaseUrlField from 'components/MetabaseUrlField';
import FavoriteIdsField from 'components/FavoriteIdsField';
import useCheckAccess from 'hooks/useCheckAccess';
import { JobTimeField } from '../JobTimeField';
import { MarketplaceJobProfileInheritedField } from '../MarketplaceJobProfileInheritedField'
import usePostingFields from 'hooks/usePostingFields';
import { IPostingField } from 'components/PostingField';
import { getPostingFieldComponent } from '../../assignments/utils';
import JobFileUpload from '../JobFileUpload';
import { ProfessionalDisplayHelper } from 'utils/displayHelpers/professional';

const JobTimeInfo = () => {
  const record = useRecordContext();

  if (!record) {
    return null;
  }

  if (record.shift_type === 'call_back') {
    return (
      <>
        <Box>
          <Labeled label="Call Back Starts Time">
            <JobTimeField format="HH:mm zzz" record={record} source="callback_starts_time" />
          </Labeled>
        </Box>
        <Box>
          <Labeled label="Call Back Ends Time">
            <JobTimeField format="HH:mm zzz" record={record} source="callback_ends_time" />
          </Labeled>
        </Box>
      </>
    );
  } else if (record.shift_type === 'on_call') {
    return (
      <>
        <Box>
          <Labeled label="On Call Starts Time">
            <JobTimeField format="HH:mm zzz" record={record} source="on_call_starts_time" />
          </Labeled>
        </Box>
        <Box>
          <Labeled label="On Call Ends Time">
            <JobTimeField format="HH:mm zzz" record={record} source="on_call_ends_time" />
          </Labeled>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box>
          <Labeled label="Starts Time">
            <JobTimeField format="HH:mm zzz" record={record} source="starts_time" />
          </Labeled>
        </Box>
        <Box>
          <Labeled label="Ends Time">
            <JobTimeField format="HH:mm zzz" record={record} source="ends_time" />
          </Labeled>
        </Box>
        <Box>
          <Labeled label="Approved Clock In">
            <JobTimeField format="HH:mm zzz" record={record} source="approved_clock_in" />
          </Labeled>
        </Box>
        <Box>
          <Labeled label="Approved Clock Out">
            <JobTimeField format="HH:mm zzz" record={record} source="approved_clock_out" />
          </Labeled>
        </Box>
      </>
    );
  }
};

const InfoTab = () => {
  const checkAccess = useCheckAccess();
  const record = useRecordContext();
  const { data: postingFieldData } = usePostingFields(record.position_ids, false);

  return (
    <SimpleShowLayout>
      <Heading>Job Description</Heading>
      <TextField label="ID" source="id" data-testid="job_show-info_id" />
      <LinkField label="Location" source="location_id" />
      <LinkField label="Billing Group" source="billing_group_id" typename="BillingGroup" />
      <LinkField label="Timecard" source="timecard_id" />
      <TextField label="State" source="location.state.name" data-testid="job_show-info_state" />
      <TextField
        label="Market"
        source="location.market.display_name"
        data-testid="job_show-info_market"
      />
      <TextField
        label="Market's State"
        source="location.market.state.name"
        data-testid="job_show-info_market_state"
      />
      <BooleanField label="Is W2" source="is_w2" data-testid="job_show-info_is_w2" />
      <DateTimeField format="MM/dd/yyyy" label="Starts Date" source="starts_date" />
      <DateTimeField format="MM/dd/yyyy" label="Ends Date" source="ends_date" />
      <TextField label="Shift Type" source="shift_type" data-testid="job_show-info_shift_type" />
      <JobTimeInfo />
      <ArrayField label="Positions" source="positions">
        <SingleFieldList linkType={false} data-testid="job_show-info_positions">
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Specialties" source="specialties">
        <SingleFieldList linkType={false} data-testid="job_show-info_specialties">
          <ChipField source="label" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField label="Surgery Types" source="surgery_types">
        <SingleFieldList linkType={false} data-testid="job_show-info_surgery_types">
          <ChipField source="display_name" />
        </SingleFieldList>
      </ArrayField>
      {postingFieldData?.map((postingField: IPostingField) =>
        getPostingFieldComponent(postingField, record),
      )}
      <TextField
        label="Patient Population"
        source="patient_population"
        data-testid="job_show-info_patient_population"
      />
      <NumberField label="Available Professionals Count" source="available_professional_count" />
      <FavoriteIdsField label="Favorite IDs" />
      <FunctionField
        data-testid="job_show-info_release_to_platform"
        label="Release to Platform"
        render={(v) =>
          v.favorite_ids?.length > 0 ? (
            <TextField label="Release to Platform" source="release_to_platform" />
          ) : (
            ''
          )
        }
      />
      <BooleanField label="Favorites Only" source="reserved_for_favorites_only" />
      <Labeled label="Location Bill Rate">
        <MarketplaceJobProfileInheritedField source="charge_base_hourly_rate_cents" />
      </Labeled>
      <Labeled label="Professional Pay Rate">
        <MarketplaceJobProfileInheritedField source="payout_base_hourly_rate_cents" />
      </Labeled>
      <Labeled label="Bonus One Time Charge to Location">
        <MarketplaceJobProfileInheritedField source="bonus_one_time_cents" />
      </Labeled>
      <Labeled label="Bonus One Time Payout to Professional">
        <MarketplaceJobProfileInheritedField source="payout_facility_bonus_cents" />
      </Labeled>
      <TextField label="Detail" source="detail" data-testid="job_show-info_detail" />
      <MoneyField label="Parking Reimbursement" source="parking_reimbursement_cents" />
      <TextField
        label="Offer Source"
        source="offer_source"
        data-testid="job_show-info_offer_source"
      />
      <BooleanField
        label="Scrubs Provided"
        source="scrubs_provided"
        data-testid="job_show-info_scrubs_provided"
      />
      <BooleanField label="COVID Care" source="covid_care" data-testid="job_show-info_covid_care" />
      <BooleanField
        label="Track Unpaid Breaks"
        source="track_unpaid_breaks"
        data-testid="job_show-info_track_unpaid_breaks"
      />
      <FunctionField
        data-testid="job_show-info_covid_requirement"
        label="Covid Requirement"
        render={(v) =>
          v.location?.covid_requirement ||
          v.location?.market?.state?.covid_requirement ||
          v.location?.state?.covid_requirement ||
          'none'
        }
      />
      <FunctionField
        data-testid="job_show-info_clinical_contact"
        label="Clinical Contact"
        render={(v) =>
          v.clinical_contact
            ? `${v.clinical_contact.first_name} ${
                v.clinical_contact.last_name
              }, ${phoneFormatter.format(v.clinical_contact.phone_number)}`
            : '-'
        }
      />
      <TextField
        label="Condition Ids"
        source="condition_ids"
        data-testid="job_show-condition-ids"
      />
      <TextField
        label="Optional Condition Ids"
        source="optional_condition_ids"
        data-testid="job_show-condition-ids"
      />
      <DateTimeField
        label="Pro Confirmed At"
        source="pro_confirmed_at"
        data-testid="job_show-pro_confirmed_at"
      />
      <Heading>Job Status</Heading>
      <TextField label="Status" source="status" data-testid="job_show-info_status" />
      <TextField
        label="Approval status"
        source="approval_status"
        data-testid="job_show-info_approval_status"
      />
      <FunctionField
        label="Summarized Payouts Status"
        render={(v) => {
          const payables = [...(v.job_billings ?? []), ...(v.expense_adjustments ?? [])];
          const fullyPaid = payables.every((payable) => !!payable.payout?.id);
          const partiallyPaid = payables.some((payable) => !!payable.payout?.id);
          return fullyPaid ? 'Paid' : partiallyPaid ? 'Partially Paid' : 'Pending';
        }}
      />
      <FunctionField
        data-testid="job_show-info_cancellation_reason"
        label="Cancellation Reason"
        render={(v) =>
          (v.last_cancellation &&
            (v.last_cancellation.other_reason ||
              v.last_cancellation.note ||
              v.last_cancellation.reason)) ||
          '-'
        }
      />
      <Labeled label="Professional ID">
        <Grid container columnSpacing={1}>
          <Grid item>
            <LinkField label="Professional ID" source="professional_id" />
          </Grid>
          <Grid item>
            {record.professional
              ? `(${new ProfessionalDisplayHelper(record.professional).type})`
              : ''}
          </Grid>
        </Grid>
      </Labeled>
      <LinkField label="Agency" source="professional.account.agency_id" typename="agency" />
      <DateTimeField label="Created At" source="created_at" tzSource="location.timezone_lookup" />
      <DateTimeField label="Updated At" source="updated_at" tzSource="location.timezone_lookup" />
      <DateTimeField label="Booked At" source="booked_at" tzSource="location.timezone_lookup" />
      <BooleanField
        label="Canceled by Location"
        source="canceled_by_location"
        data-testid="job_show-info_canceled_by_location"
      />
      <BooleanField
        label="Canceled by Professional"
        source="canceled_by_professional"
        data-testid="job_show-info_canceled_by_professional"
      />
      <BooleanField
        label="Extended by Location"
        source="extended_by_location"
        data-testid="job_show-info_extended_by_location"
      />
      <BooleanField
        label="Extended by Professional"
        source="extended_by_professional"
        data-testid="job_show-info_extended_by_professional"
      />
      <BooleanField
        label="Professional can Cancel"
        source="professional_can_cancel"
        data-testid="job_show-info_professional_can_cancel"
      />
      <BooleanField
        label="Updated in Cancellation Window"
        source="updated_in_cancellation_window"
        data-testid="job_show-info_updated_in_cancellation_window"
      />
      <BooleanField label="Suspicious" source="suspicious" data-testid="job_show-info_suspicious" />
      <NumberField
        label="Cancellation Fee Window"
        source="cancellation_fee_window"
        data-testid="job_show-info_cancellation_fee_window"
      />
      <NumberField
        label="Cancellation Fee Charge Hours"
        source="cancellation_fee_charge_hours"
        data-testid="job_show-info_cancellation_fee_charge_hours"
      />
      <NumberField
        label="Guaranteed Minimum Hours"
        source="guaranteed_minimum_hours"
        data-testid="job_show-info_guaranteed_minimum_hours"
      />
      <JobFileUpload type="530" />
      <JobFileUpload type="timesheet-clock-in" />
      <JobFileUpload type="geolocation-override-clock-in" />
      <JobFileUpload type="timesheet-clock-out" />
      <JobFileUpload type="geolocation-override-clock-out" />
      <JobFileUpload type="supervisor-signature" />
      <JobFileUpload type="clock-out-approval" />
      <Heading>Assignment</Heading>
      <LinkField label="Assignment ID" source="assignment.id" />
      <Heading>For Admin</Heading>
      <TextField label="Admin Notes" source="admin_notes" data-testid="job_show-info_admin_notes" />
      {checkAccess('view', 'Audits') && <MetabaseUrlField />}
    </SimpleShowLayout>
  );
};

export default InfoTab;
