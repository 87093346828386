import React, { ReactElement, useState } from 'react';
import { Button } from '@medely/web-components';
import LinksToMemos from './LinksToMemos';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { humanize } from 'inflection';
import { SaveContextProvider, useCreate, useNotify, useResourceContext } from 'react-admin';
import { transformCreatePayload, transformEditPayload } from '../utils';
import { InvoiceProps } from 'typings/invoices';
import useDialog from 'hooks/useDialog';

type WrapperProps = {
  children: ReactElement;
  invoice_type: string;
  isCreate: boolean;
  saveEdits?: (values: any, callbacks: any) => any;
};

const CustomSaveWrapper = ({
  children,
  invoice_type,
  isCreate = false,
  saveEdits,
}: WrapperProps) => {
  const resource = useResourceContext();
  const [mutate, { isLoading: isSaving }] = useCreate();
  const notify = useNotify();
  const navigate = useNavigate();
  const { open, openDialog, closeDialog } = useDialog();
  const [ids, setIds] = useState([]);
  const [dialogTitle, setDialogTitle] = useState('');

  const onSuccess = ({ id }) => {
    const msg = `${humanize(invoice_type)} has been successfully ${
      isCreate ? 'created' : 'edited'
    }`;
    notify(msg, { type: 'success' });
    navigate(`/Invoice/${invoice_type}/${id}/show`);
  };
  const onError = (error: any) => {
    if (error.response?.data?.ids?.length) {
      setDialogTitle(error.message);
      setIds(error.response.data.ids);
      openDialog();
    } else {
      const msg = error.response?.data?.error || error.message;
      notify(msg, { type: 'warning' });
    }
  };
  const handleSave = async (data: InvoiceProps) => {
    if (isCreate) {
      const values = transformCreatePayload(data, invoice_type);
      return mutate(resource, { data: values }, { onSuccess, onError });
    }
    const values = transformEditPayload(data, invoice_type);
    await saveEdits(values, { onSuccess, onError });
  };

  return (
    <>
      <SaveContextProvider
        value={{ save: handleSave, saving: isSaving, mutationMode: 'pessimistic' }}
      >
        {children}
      </SaveContextProvider>
      <Dialog open={open}>
        <DialogTitle fontSize={18}>{dialogTitle}</DialogTitle>
        <DialogContent>
          <LinksToMemos ids={ids} invoice_type={invoice_type} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomSaveWrapper;
