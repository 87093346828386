import StateInput from './StateInput';
import { EnumerableInput, EnumerableMultiInput } from './EnumerableInput';
import BooleanInput from './BooleanInput';
import TextInput from './TextInput';
import DateInput from './DateInput';

const FIELD_TYPES = {
  boolean: BooleanInput,
  date: DateInput,
  integer: TextInput,
  enumerable: EnumerableInput,
  enumerable_multi: EnumerableMultiInput,
  string: TextInput,
  state: StateInput,
  expiration_date: DateInput,
  license_number: TextInput,
  valid_date: DateInput,
  text: TextInput,
};

type KnownFieldType = keyof typeof FIELD_TYPES;

const getFieldTypeComponent = (attributeType: KnownFieldType) => {
  const component = FIELD_TYPES[attributeType];
  if (component) {
    return component;
  }

  return () => null;
};

export default getFieldTypeComponent;
