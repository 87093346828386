import React, { useState } from 'react';
import { Button, Menu } from '@mui/material';
import {
  AccountInfoItem,
  ApproveItem,
  ArchiveItem,
  PhoneScreenPendingItem,
  RejectItem,
  PhoneScreenScheduledItem,
  ResumeReviewItem,
} from './StatusItems';
import { useRecordContext, useShowContext, useUpdate } from 'react-admin';

export const AccountInfoStatus = 'account_info' as const;
export const ApprovedStatus = 'approved' as const;
export const ArchivedStatus = 'archived' as const;
export const PhoneScreenPendingStatus = 'phone_screen_pending' as const;
export const PhoneScreenScheduledStatus = 'phone_screen_scheduled' as const;
export const RejectedStatus = 'rejected' as const;
export const ResumeReviewStatus = 'resume_review' as const;

export type ApplicationStatus =
  | typeof AccountInfoStatus
  | typeof ApprovedStatus
  | typeof ArchivedStatus
  | typeof PhoneScreenPendingStatus
  | typeof PhoneScreenScheduledStatus
  | typeof RejectedStatus
  | typeof ResumeReviewStatus;

const applicationStatusSelection = {
  [ArchivedStatus]: [ResumeReviewItem, PhoneScreenPendingItem, RejectItem, AccountInfoItem],
  [ResumeReviewStatus]: [PhoneScreenPendingItem, ApproveItem, RejectItem, ArchiveItem],
  [PhoneScreenScheduledStatus]: [PhoneScreenPendingItem, ApproveItem, RejectItem, ArchiveItem],
  [PhoneScreenPendingStatus]: [PhoneScreenScheduledItem, ApproveItem, RejectItem, ArchiveItem],
  [RejectedStatus]: [PhoneScreenPendingItem],
};

const getApplicationStatusSelection = (currentApplicationStatus: ApplicationStatus) => {
  const view = applicationStatusSelection[currentApplicationStatus];
  // Approve does not have any actions
  if (currentApplicationStatus === ApprovedStatus) {
    return [];
  }
  // Defaults to rejectable for any other status
  return view ?? [RejectItem];
};

const ApplicationStatusControl = () => {
  const { refetch } = useShowContext();
  const record = useRecordContext();
  const [update] = useUpdate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  if (!record?.application_status) {
    return null;
  }
  const currentApplicationStatus = record.application_status;

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const updateApplicationStatus = (status) => {
    update(
      'Professional',
      {
        id: record.id,
        data: status,
      },
      {
        onSuccess: () => {
          refetch?.();
          handleClose();
        },
      },
    );
  };

  const selections = getApplicationStatusSelection(currentApplicationStatus);
  if (selections.length === 0) {
    return null;
  }

  return (
    <div>
      <Button size="small" variant="contained" onClick={handleClick} data-testid="actions-button">
        Actions ▼
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {selections.map((Item) => (
          <Item
            key={Item.name}
            currentApplicationStatus={currentApplicationStatus}
            updateApplicationStatus={updateApplicationStatus}
          />
        ))}
      </Menu>
    </div>
  );
};

export default ApplicationStatusControl;
