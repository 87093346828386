import { createBrowserHistory } from 'history';
import React, { useEffect } from 'react';
import { Resource, NotFound, CustomRoutes } from 'react-admin';
import { Admin } from '@react-admin/ra-enterprise';
import Dashboard from './components/Dashboard';
import LoginPage from './components/LoginPage';
import Layout from './layout';
import { useAuthProvider } from './providers/authProvider';
import * as _resources from './resources';
import { themeFromType } from './theme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { i18nProvider } from './i18provider';
import { getCustomResourceRoutes } from 'hoc/getCustomResourceRoutes';
import { withReport } from 'hoc/withReport';
import inflection from 'inflection';
import { configureRollbar } from 'utils/rollbar';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryClient } from 'react-query';
import { ApolloClientContextProvider } from 'providers/apolloClientContextProvider';
import { GraphQLPermissionsContextProvider } from 'providers/graphqlPermissions';
import { useDataProvider } from 'providers/data_provider';
import { FeatureGateProvider, useFeatureGateContext } from 'providers/featureGateContext';
import { useTokenStorage } from 'hooks/useTokenStorage';
import config from 'config';
import Merge from 'resources/companies/merge_companies/Merge';
import Migrate from 'resources/locations/migrate_location/Migrate';
import { getSessionStorageStore } from 'utils/sessionStorageStore';

const filterResourceWithPermission = (permissions, permissionName, resource) => {
  const { view, update: edit, create, delete: remove } = permissions;

  return {
    list: view?.[permissionName] ? resource.list : undefined,
    show: view?.[permissionName] ? resource.show : undefined,
    edit: edit?.[permissionName] ? resource.edit : undefined,
    create: create?.[permissionName] ? resource.create : undefined,
    delete: remove?.[permissionName] ? resource.delete : undefined,
    options: view?.[permissionName] ? resource.options : undefined,
  };
};

const roleResources = (permissions) => {
  const resourceNames = Object.keys(_resources);
  return resourceNames.map((resourceName) => {
    const permissionName = inflection.singularize(inflection.camelize(resourceName, false));
    const resource = _resources[resourceName];
    return {
      name: permissionName,
      ...filterResourceWithPermission(permissions, permissionName, resource),
      customSections: resource.customSections
        ? Object.fromEntries(
            Object.entries(resource.customSections).map(([key, value]) => [
              key,
              {
                name: permissionName,
                ...filterResourceWithPermission(permissions, permissionName, value),
              },
            ]),
          )
        : undefined,
    };
  });
};

const history = createBrowserHistory();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      structuralSharing: false,
    },
  },
});

//@ts-ignore
const NotFoundWithErrorHandler = withReport(() => Rollbar.error('Not Found'))(NotFound);

const App = () => {
  const { getExpirationStrategy } = useTokenStorage();

  useEffect(() => {
    const strategy = getExpirationStrategy(config.token_exp_ms / 2);
    strategy.run();
    return strategy.clear;
  }, []);

  return (
    <BrowserRouter>
      <FeatureGateProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ApolloClientContextProvider>
            <GraphQLPermissionsContextProvider>
              <Routes />
            </GraphQLPermissionsContextProvider>
          </ApolloClientContextProvider>
        </LocalizationProvider>
      </FeatureGateProvider>
    </BrowserRouter>
  );
};

export default App;

const Routes = () => {
  const authProvider = useAuthProvider();
  const { getAdminFromToken } = useTokenStorage();
  const { isLoading: isLoadingStatsig } = useFeatureGateContext();
  const { dataProvider } = useDataProvider();
  const store = getSessionStorageStore();

  if (isLoadingStatsig) {
    return <p>Loading...</p>;
  }

  return (
    <Admin
      authProvider={authProvider}
      catchAll={NotFoundWithErrorHandler}
      darkTheme={themeFromType('dark')}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      history={history}
      i18nProvider={i18nProvider}
      layout={Layout}
      lightTheme={themeFromType('light')}
      locale="en"
      loginPage={LoginPage}
      queryClient={queryClient}
      store={store}
    >
      {(permissions) => {
        if (permissions) {
          const admin = getAdminFromToken();
          configureRollbar(admin);
        }

        return roleResources(permissions.calculated_permissions).map((r) => {
          if (r.customSections) {
            return (
              <Resource key={r.name} {...r}>
                {r.customSections && getCustomResourceRoutes(r.customSections)}
              </Resource>
            );
          }
          return <Resource key={r.name} {...r} />;
        });
      }}
      <CustomRoutes>
        <Route path="/Company/:id/merge" element={<Merge />} />
        <Route path="/Location/:id/migrate" element={<Migrate />} />
      </CustomRoutes>
    </Admin>
  );
};
