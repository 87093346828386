import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import useDialog from 'hooks/useDialog';
import { RejectedStatus } from '../ApplicationStatusControl';

interface RejectItemProps {
  updateApplicationStatus: (val: any) => void;
}

const reasonChoices = [
  'Conflict of Interest',
  'Deactivated Account',
  'Duplicate Account',
  'Falsifying Document',
  'No relevant experience',
  'Not a good fit - Bad Attitude',
  'Test Account',
];

const RejectItem = ({ updateApplicationStatus }: RejectItemProps) => {
  const { open, closeDialog, openDialog } = useDialog();

  return (
    <>
      <MenuItem onClick={openDialog}>Reject</MenuItem>
      <RejectDialog
        open={open}
        closeDialog={closeDialog}
        updateApplicationStatus={updateApplicationStatus}
      />
    </>
  );
};

const RejectDialog = ({ open, closeDialog, updateApplicationStatus }) => {
  const [rejectReason, setRejectReason] = useState('');
  const [phoneInterviewed, setPhoneInterviewed] = useState(false);
  const submitUpdateApplicationStatus = () => {
    updateApplicationStatus({
      application_status: RejectedStatus,
      reject_reason: rejectReason,
      phone_interviewed: phoneInterviewed,
    });
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Reject Reason</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          value={rejectReason}
          label="Select Reject Reason"
          onChange={({ target }) => setRejectReason(target.value as string)}
          required
          style={{ width: '550px', marginBottom: '20px' }}
        >
          {reasonChoices.map((reason) => (
            <MenuItem key={reason} value={reason}>
              {reason}
            </MenuItem>
          ))}
        </Select>
        <FormGroup>
          <FormControlLabel
            label="Performed Verbal Screen"
            control={
              <Checkbox
                checked={phoneInterviewed}
                onChange={(event) => setPhoneInterviewed(event.target.checked)}
              />
            }
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          disabled={!rejectReason}
          onClick={submitUpdateApplicationStatus}
          color="primary"
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectItem;
