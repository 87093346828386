import React from 'react';
import { gql } from '@apollo/client';
import {
  Datagrid,
  TextField,
  NumberField,
  FunctionField,
  TextInput,
  CreateButton,
  ReferenceInput,
  AutocompleteInput,
  TopToolbar,
} from 'react-admin';
import List from 'components/List';
import { IdFilter } from 'components/Filter';
import IdField from 'components/IdField';
import renderRole from './AccountRole';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import useRequestCsv from 'hooks/useRequestCsv';
import { Button, CircularProgress } from '@mui/material';
import { useFileUpload } from 'hooks/useFileUpload';
import config from 'config';

const accountsFilters = [
  <IdFilter data-testid="accounts-id_filter" />,
  <TextInput alwaysOn label="Email" source="email" data-testid="accounts-email_filter" />,
  <TextInput alwaysOn label="Phone" source="phone_number" data-testid="accounts-phone_filter" />,
  <ReferenceInput
    alwaysOn
    source="search.permission_group_id"
    reference="PermissionGroup"
    label="Permission Group"
    perPage={50}
    filter={{
      account_type: 'facility',
    }}
  >
    <AutocompleteInput
      optionText="display_name"
      label="Permission Group"
      data-testid="accounts-permission_group_filter"
    />
  </ReferenceInput>,
  <EnumSelectInput label="Account Type" source="account_type" alwaysOn />,
];

const ListActions = () => {
  const requestCsv = useRequestCsv();
  const downloadCsv = () => {
    requestCsv({
      url: `/v3/admin/accounts/download_import_template.csv`,
      filename: `medely_accounts_import.csv`,
    });
  };
  const { hiddenInput, openFilePrompt, uploading } = useFileUpload({
    url: `${config.rails_url}/v3/admin/accounts/import`,
    accept: 'text/csv',
  });

  return (
    <TopToolbar>
      <Button size="small" variant="outlined" onClick={downloadCsv}>
        Download Import Template
      </Button>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {hiddenInput}
        <Button
          disabled={uploading}
          onClick={openFilePrompt}
          variant="outlined"
          size="small"
          endIcon={uploading ? <CircularProgress size={20} /> : undefined}
        >
          Import Accounts
        </Button>
      </div>
      <CreateButton label="Create Facility Account" data-testid="accounts-create_button" />
    </TopToolbar>
  );
};

const AccountsList = () => (
  <List
    exporter={false}
    bulkActionButtons={false}
    filters={accountsFilters}
    actions={<ListActions />}
  >
    <Datagrid bulkActionButtons={false} data-testid="accounts-table">
      <IdField />
      <TextField label="Email" source="email" data-testid="accounts-email_field" />
      <FunctionField
        label="Role Record"
        render={renderRole}
        data-testid="accounts-role_record_field"
      />
      <TextField
        label="Phone Number"
        source="phone_number"
        data-testid="accounts-phone_number_field"
      />
      <TextField label="Status" source="status" data-testid="accounts-status_field" />
    </Datagrid>
  </List>
);

AccountsList.query = gql`
  query GET_LIST($input: AccountQueryInput) {
    accounts(input: $input) {
      account_type
      calculated_group_node_ids
      email
      email_notification
      first_name
      id
      last_name
      name
      phone_number
      sms_notification
      sms_notification_bulk
      status
      title
      professional {
        id
      }
      company {
        id
      }
      administrator {
        id
      }
      agency {
        id
      }
      state {
        id
        name
      }
      permission_groups {
        id
        display_name
      }
    }
    _accountsMeta(input: $input) {
      count
    }
  }
`;

export default AccountsList;
