import React, { useCallback } from 'react';
import { gql } from '@apollo/client';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  NumberField,
  TopToolbar,
  EditButton,
  BooleanField,
  useNotify,
  useRecordContext,
} from 'react-admin';
import TimestampFields from 'components/TimestampFields';
import DateTimeField from 'components/DateTimeField';
import MetabaseUrlField from 'components/MetabaseUrlField';
import { Button } from '@mui/material';
import config from 'config';
import LinkField from 'components/LinkField';
import useCheckAccess from 'hooks/useCheckAccess';

const ProRefActions = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const copyToClipboard = useCallback(() => {
    navigator.clipboard
      .writeText(`${config.client_url.professional}/reference-gateway/${record.uuid}`)
      .then(() => {
        notify('Copied to clipboard');
      });
  }, [notify, record]);

  return (
    <TopToolbar>
      <Button onClick={copyToClipboard} color="primary" variant="outlined" size="small">
        Copy link
      </Button>
      <EditButton />
    </TopToolbar>
  );
};

const ProfessionalReferencesShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show actions={<ProRefActions />}>
      <SimpleShowLayout>
        <TextField label="ID" source="id" />
        <BooleanField label="Contact Me" source="contact_me" />
        <BooleanField label="Receive Emails" source="receive_emails" />
        <LinkField label="Professional ID" source="professional_id" />
        <TextField label="First Name" source="first_name" />
        <TextField label="Last Name" source="last_name" />
        <TextField source="title" />
        <TextField source="email" />
        <TextField label="Phone Number" source="phone_number" />
        <TextField source="location" />
        <TextField source="department" />
        <TextField label="Rehire Eligibility" source="rehire_eligibility" />
        <TextField source="status" />
        <DateTimeField label="Date Obtained" format="MM/dd/yyyy" source="date_obtained" />
        <DateTimeField label="Start Date" format="MM/dd/yyyy" source="start_date" />
        <BooleanField label="Current Position" source="current_position" />
        <DateTimeField label="End Date" format="MM/dd/yyyy" source="end_date" />
        <LinkField label="Work History ID" source="work_history_id" typename="WorkHistory" />
        <ArrayField source="questions">
          <Datagrid bulkActionButtons={false}>
            <TextField source="question" />
            <NumberField source="answer" />
          </Datagrid>
        </ArrayField>
        <TextField fullWidth source="comments" />
        <TimestampFields />
        {checkAccess('view', 'ProfessionalReference') && <MetabaseUrlField />}
      </SimpleShowLayout>
    </Show>
  );
};

ProfessionalReferencesShow.query = gql`
  query GET_ONE($id: Int!) {
    professionalReference(id: $id) {
      id
      work_history_id
      professional_id
      name
      start_date
      end_date
      current_position
      first_name
      last_name
      title
      email
      phone_number
      location
      created_at
      updated_at
      department
      professional {
        id
        account {
          id
          name
        }
      }
      rehire_eligibility
      status
      date_obtained
      questions {
        question
        answer
      }
      comments
      uuid
      contact_me
      receive_emails
    }
  }
`;

export default ProfessionalReferencesShow;
