import React from 'react';
import {
  TextField,
  ReferenceManyField,
  Datagrid,
  Pagination,
  FunctionField,
  type ReferenceManyFieldProps,
} from 'react-admin';
import LinkField from 'components/LinkField';

type AccountTabProps = Pick<ReferenceManyFieldProps, 'target' | 'filter'>;

const AccountsTab = (props: AccountTabProps) => (
  <ReferenceManyField
    label={false}
    reference="Account"
    pagination={<Pagination />}
    sort={{ field: 'status', order: 'ASC' }}
    {...props}
  >
    <Datagrid bulkActionButtons={false}>
      <LinkField label="ID" source="id" typename="Account" />
      <TextField label="Name" source="name" />
      <TextField label="Email" source="email" />
      <TextField label="Title" source="title" />
      <FunctionField
        label="Permission Groups"
        render={(record) =>
          record?.permission_groups?.map(({ display_name }) => display_name)?.join(', ')
        }
      />
      <TextField label="Status" source="status" />
    </Datagrid>
  </ReferenceManyField>
);

export default AccountsTab;
