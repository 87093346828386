import React from 'react';
import {
  TextField,
  Datagrid,
  SimpleShowLayout,
  Pagination,
  ReferenceManyField,
  FunctionField,
  useRecordContext,
} from 'react-admin';
import LinkField from 'components/LinkField';

type AgencyLocationTypes = {
  agency_id: number;
  status: string;
};

const LocationsTab = () => {
  const record = useRecordContext();

  return (
    <SimpleShowLayout>
      <ReferenceManyField
        label=""
        pagination={<Pagination />}
        reference="Location"
        target="agency_id"
      >
        <Datagrid bulkActionButtons={false}>
          <LinkField label="id" source="id" />
          <TextField label="Location Status" source="status" />
          <FunctionField
            label="Agency Location Status"
            render={(r) =>
              r.agency_locations
                .filter(
                  (agency_location: AgencyLocationTypes) => agency_location.agency_id === record.id,
                )
                .shift().status
            }
          />
          <LinkField label="Company" source="company.name" sortBy="company_name" />
          <LinkField label="Name" source="name" />
          <TextField label="Market" source="market.display_name" sortBy="market_name" />
          <TextField label="State" source="state.name" sortBy="state_name" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(LocationsTab);
