import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';

const VALUE_MAP = new Map<boolean | null | string, boolean | null | string>([
  [true, 'true'],
  [false, 'false'],
  [null, 'null'],
  ['true', true],
  ['false', false],
  ['null', null],
]);

const transformValue = (value) => VALUE_MAP.get(value);

const BooleanInput = ({ value, onChange }) => (
  <FormControl fullWidth>
    <Select
      value={transformValue(value)}
      onChange={({ target }) => onChange(transformValue(target.value))}
    >
      <MenuItem value="true">True</MenuItem>
      <MenuItem value="false">False</MenuItem>
      <MenuItem value="null">Unset</MenuItem>
    </Select>
  </FormControl>
);

export default BooleanInput;
