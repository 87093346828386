import React from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { ArrowDropDown, ExitToApp } from '@mui/icons-material';
import useImpersonate from '../hooks/useImpersonate';
import config from 'config';

const ImpersonateButton = ({ account }) => {
  const { impersonate, impersonateLocally, copyImpersonateUrl, loading } = useImpersonate();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleImpersonate = () => {
    impersonate(account);
  };

  const handleLocalImpersonate = () => {
    setOpen(false);
    impersonateLocally(account);
  };

  const handleCopyImpersonateUrl = () => {
    setOpen(false);
    copyImpersonateUrl(account);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  if (account?.account_type === 'administrator') {
    return null;
  }

  if (config.is_production) {
    return (
      <Button
        data-testid="impersonate_button"
        disabled={loading}
        onClick={handleImpersonate}
        size="small"
        color="primary"
        startIcon={<ExitToApp />}
      >
        Log in
      </Button>
    );
  }

  return (
    <React.Fragment>
      <ButtonGroup
        variant="text"
        ref={anchorRef}
        aria-label="Button group with a nested menu"
        sx={{
          '.MuiButtonGroup-firstButton': {
            borderRight: 0,
          },
          '.MuiButtonGroup-lastButton': {
            padding: 0,
            margin: 0,
            minWidth: 0,
          },
        }}
      >
        <Button
          data-testid="impersonate_button"
          disabled={loading}
          onClick={handleImpersonate}
          size="small"
          color="primary"
          startIcon={<ExitToApp />}
        >
          Log in
        </Button>

        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          disabled={loading}
          onClick={handleToggle}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>

      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem disabled={loading} onClick={handleLocalImpersonate}>
                    Log in to local
                  </MenuItem>

                  <MenuItem disabled={loading} onClick={handleCopyImpersonateUrl}>
                    Copy impersonate URL
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default ImpersonateButton;
