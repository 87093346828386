import React from 'react';
import { EditButton, TopToolbar, useRecordContext } from 'react-admin';
import { Button } from '@mui/material';
import CloseMonthButton from './CloseMonthButton';
import useRequestCsv from 'hooks/useRequestCsv';

const BalanceSheetShowActions = () => {
  const record = useRecordContext();
  const downloadCSV = useRequestCsv();
  const today = new Date().toISOString();

  return (
    <TopToolbar>
      <Button
        size="small"
        variant="outlined"
        onClick={() =>
          downloadCSV({
            url: `/v3/admin/balance_sheets/${record.id}`,
            filename: `medely_balance_sheet_${record.id}_${today}.csv`,
          })
        }
      >
        Download revenue
      </Button>
      <Button
        size="small"
        variant="outlined"
        onClick={() =>
          downloadCSV({
            url: `/v3/admin/balance_sheets/${record.id}/transactions`,
            filename: `medely_balance_sheet_${record.id}_transactions_${today}.csv`,
          })
        }
      >
        Download transactions
      </Button>
      <Button
        size="small"
        variant="outlined"
        onClick={() =>
          downloadCSV({
            url: `/v3/admin/balance_sheets/${record.id}/itemized_transactions`,
            filename: `medely_balance_sheet_${record.id}_itemized_transactions_${today}.csv`,
          })
        }
      >
        Download itemized transactions
      </Button>
      <CloseMonthButton />
      <EditButton />
    </TopToolbar>
  );
};

export default BalanceSheetShowActions;
