import React from 'react';
import { FunctionField, SimpleShowLayout, TextField } from 'react-admin';

const InfoTab = () => {
  return (
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField label="Legal Name" source="legal_name" />
      <TextField label="Status" source="status" />
      <FunctionField
        label="Job Types"
        render={(record) => record.job_types?.map((job_type) => job_type).join(', ')}
      />
    </SimpleShowLayout>
  );
};

export default InfoTab;
