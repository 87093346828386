import React from 'react';
import { gql } from '@apollo/client';
import { Show, TabbedShowLayout, Tab, TabbedShowLayoutTabs } from 'react-admin';
import SummaryTab from './tabs/SummaryTab';
import ItemizedTransactionsTab from './tabs/ItemizedTransactionsTab';
import FinancialTransactionShowActions from './ShowActions';
import useCheckAccess from 'hooks/useCheckAccess';
import NotesTab from 'components/tabs/NotesTab';

const FinancialTransactionsShow = () => {
  const checkAccess = useCheckAccess();

  return (
    <Show actions={<FinancialTransactionShowActions />}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" />}>
        <Tab label="Info">
          <SummaryTab />
        </Tab>
        {checkAccess('view', 'ItemizedTransaction', 'financial_transaction_id') && (
          <Tab label="Itemized Transactions" path="itemized-transactions">
            <ItemizedTransactionsTab />
          </Tab>
        )}
        {checkAccess('view', 'Note') && (
          <Tab label="Notes" path="notes">
            <NotesTab />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

FinancialTransactionsShow.query = gql`
  query GET_ONE($id: Int!) {
    financialTransaction(id: $id) {
      id
      billing_group_id
      billing_group {
        id
        name
      }
      balance_sheet {
        id
      }
      amount_cents
      amount_currency
      amount_left_cents
      amount_left_currency
      kind
      created_at
      updated_at
      data_source
      yaypay_external_id
      revenue_date
      transaction_id
      balance_sheet_id
      original_financial_transaction_id
      facility_notes
      itemized_transactions {
        id
        financial_transaction_id
        amount_cents
        amount_currency
        from_source
        billing_group_id
      }
    }
  }
`;

export default FinancialTransactionsShow;
