import React from 'react';
import ShowView from '../components/ShowView';
import InvoiceSummaryTab from '../tabs/InvoiceSummaryTab';

const InvoicesShow = () => {
  return (
    <ShowView
      invoice_type="invoice"
      showDisputes={true}
      summaryTab={<InvoiceSummaryTab showParentInvoices={false} />}
      showChildMemos={true}
    />
  );
};

export default InvoicesShow;
