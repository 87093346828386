import React, { isValidElement } from 'react';
import { Route } from 'react-router';

/**
 * This is similar to react-admin's Resource component,
 * except with 1 extra layer of indirection based on customSection key.
 */
export function getCustomResourceRoutes(customSections: {
  [key: string]: {
    create?: React.ComponentType<any> | React.ReactElement;
    show?: React.ComponentType<any> | React.ReactElement;
    edit?: React.ComponentType<any> | React.ReactElement;
    list?: React.ComponentType<any> | React.ReactElement;
  };
}) {
  return Object.entries(customSections).map(([key, value]) => {
    const { create: Create, show: Show, edit: Edit, list: List } = value;
    return (
      <React.Fragment key={key}>
        {Create && (
          <Route
            path={`${key}/create/*`}
            element={isValidElement<any>(Create) ? Create : <Create />}
          />
        )}
        {Show && (
          <Route path={`${key}/:id/show/*`} element={isValidElement<any>(Show) ? Show : <Show />} />
        )}
        {Edit && (
          <Route path={`${key}/:id/*`} element={isValidElement<any>(Edit) ? Edit : <Edit />} />
        )}
        {List && <Route path={`${key}/*`} element={isValidElement<any>(List) ? List : <List />} />}
      </React.Fragment>
    );
  });
}
