import React, { useCallback } from 'react';
import { FormDataConsumer, TextInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import ProfessionalInput from './ProfessionalInput';
import { revenueAdjustmentHelperText } from 'constants/stringConstants';

const RevenueAdjustmentDescriptionInput = () => {
  const { setValue } = useFormContext();
  const onChange = useCallback(
    (choice) => {
      if (choice) {
        setValue('description', `${choice.name} (ID: ${choice.id})`);
      } else {
        setValue('description', '');
      }
    },
    [setValue],
  );

  return (
    <FormDataConsumer>
      {({ formData }) => {
        return (
          <>
            {formData.adjustment_type === 'opt_out' && <ProfessionalInput onChange={onChange} />}
            <TextInput
              data-testid="revenue_adj_description"
              label="Description"
              source="description"
              helperText={revenueAdjustmentHelperText.description}
            />
          </>
        );
      }}
    </FormDataConsumer>
  );
};

export default RevenueAdjustmentDescriptionInput;
