import React, { Children, cloneElement, memo, useState } from 'react';
import { Button, Dialog, DialogTitle } from '@mui/material';
import { IItemizedTransactionFormValues } from './types';
import { SimpleForm, Toolbar } from 'react-admin';
import { useFormContext } from 'react-hook-form';

const ItemizedTransactionWizardToolbar = ({
  handlePrevious,
  handleSubmit,
  isLastPage,
  loading,
}) => {
  const { getValues } = useFormContext();
  const { blockNextButton, billing_group_ids } = getValues();
  const action = isLastPage ? 'Submit' : 'Next';
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button disabled={loading} onClick={handlePrevious}>
        Cancel
      </Button>
      <Button
        disabled={loading || blockNextButton || !billing_group_ids.length}
        variant="contained"
        color="primary"
        onClick={() => handleSubmit(getValues())}
      >
        {loading ? 'Please, wait...' : action}
      </Button>
    </Toolbar>
  );
};

const ItemizedTransactionWizard = ({
  children,
  opened,
  onClose,
  onSubmit,
  record,
  loading,
  ...rest
}) => {
  const [page, setPage] = useState<number>(0);

  const isLastPage = page === Children.count(children) - 1;

  const initialValues: IItemizedTransactionFormValues = {
    invoices: [],
    billing_group_ids: record.billing_group_id ? [record.billing_group_id] : [],
    billing_group_balances: [{}],
    blockNextButton: false,
  };

  const handleSubmit = async (values) => {
    if (isLastPage) {
      await onSubmit(values);
      setPage(0);
    }
    setPage(Math.min(page + 1, children.length - 1));
  };

  const handlePrevious = () => {
    if (onClose) {
      setPage(0);
      onClose();
    } else {
      setPage(Math.max(page - 1, 0));
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={opened}
      onClose={onClose}
      aria-label="Itemized transactions"
    >
      <DialogTitle>Itemized transactions</DialogTitle>
      <SimpleForm
        record={initialValues}
        mode="all"
        reValidateMode="onChange"
        toolbar={
          <ItemizedTransactionWizardToolbar
            handleSubmit={handleSubmit}
            handlePrevious={handlePrevious}
            isLastPage={isLastPage}
            loading={loading}
          />
        }
      >
        {cloneElement(Children.toArray(children)[page] as any, {
          record,
          ...rest,
        })}
      </SimpleForm>
    </Dialog>
  );
};

export default memo(ItemizedTransactionWizard);
