import React from 'react';
import { FunctionField } from 'react-admin';

const TravelProfileRemainingStepsField = () => {
  return (
    <FunctionField
      label="Profile Remaining items"
      render={(r) => {
        const result = [];
        if (r.has_missing_work_history || r.has_unexplained_work_gaps) {
          result.push('Work History / Gaps');
        }
        if (r.has_missing_education) {
          result.push('Education');
        }
        if (r.has_missing_ehr_software) {
          result.push('EHR');
        }
        if (r.has_missing_references) {
          result.push('References');
        }
        if (r.has_missing_availability) {
          result.push('Availability');
        }
        if (r.has_missing_identity) {
          result.push('Identity Verification');
        }
        return result.join(', ');
      }}
    />
  );
};

TravelProfileRemainingStepsField.defaultProps = {
  label: 'Profile Remaining items',
};

export default TravelProfileRemainingStepsField;
