import usePostingFields from 'hooks/usePostingFields';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import PostingField, { IPostingField } from './PostingField';
import { Box } from '@mui/material';

type PostingFieldsProps = {
  isAssignment?: boolean;
};

const PostingFields = ({ isAssignment = false }: PostingFieldsProps) => {
  const { watch, getValues } = useFormContext();
  const position_ids = watch('position_ids');
  const { data } = usePostingFields(position_ids || [], isAssignment);
  const posting_field_contents = getValues()?.posting_field_contents;

  return (
    <Box ml={1.5} display="flex" flexDirection="column">
      {data?.map((postingField: IPostingField) => {
        const contentValue = posting_field_contents?.[postingField?.slug]?.content;

        return (
          <PostingField
            key={postingField?.slug}
            postingField={postingField}
            defaultValue={contentValue}
          />
        );
      })}
    </Box>
  );
};

export default PostingFields;
