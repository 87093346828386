import React from 'react';
import { Create, SimpleForm, TextInput, useGetOne, FormDataConsumer } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import AdministratorInput from 'components/Filter/AdministratorInput';
import InvoiceInput from 'components/InvoiceInput';
import JobInput from 'components/JobInput';
import DateTimeInput from 'components/DateTimeInput';

const DisputeDateTimeInputs = ({ values }) => {
  const id = values?.job_id;
  const job = useGetOne('Job', { id }, { enabled: !!id });
  const timezone = job?.data?.location?.timezone_lookup;
  return (
    <>
      <DateTimeInput source="adjusted_clock_in" time_zone={timezone} />
      <DateTimeInput source="adjusted_clock_out" time_zone={timezone} />
    </>
  );
};

const DisputesCreate = () => (
  <Create redirect="show">
    <SimpleForm>
      <JobInput />
      <InvoiceInput />
      <FormDataConsumer>
        {({ formData }) => <DisputeDateTimeInputs values={formData} />}
      </FormDataConsumer>
      <EnumSelectInput source="reason" />
      <EnumSelectInput source="category" />
      <EnumSelectInput source="resolution" />
      <EnumSelectInput source="status" />
      <AdministratorInput
        label="Assigned To"
        source="assigned_to_id"
        resettable
        filter={{ department: 'Dispute' }}
        sort={{ field: 'first_name', order: 'ASC' }}
      />
      <TextInput fullWidth multiline source="facility_notes" />
      <TextInput fullWidth multiline source="admin_notes" />
    </SimpleForm>
  </Create>
);

export default DisputesCreate;
