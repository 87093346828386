import { memoryStore, Store } from 'ra-core';

const DEFAULT_KEY = 'MEDELY_ADMIN';

type Subscription = {
  key: string;
  callback: (value: any) => void;
};

const sessionStorageAvailable =
  typeof window !== 'undefined' && window.sessionStorage !== undefined;

const sessionStorageStore = (version: string = '1', appKey: string = ''): Store => {
  const prefix = `${DEFAULT_KEY}${appKey}`;
  const prefixLength = prefix.length;
  const subscriptions: { [key: string]: Subscription } = {};
  const publish = (key: string, value: any) => {
    Object.keys(subscriptions).forEach((id) => {
      if (subscriptions[id]?.key === key) {
        subscriptions[id].callback(value);
      }
    });
  };

  const storage = window.sessionStorage;

  return {
    setup: () => {
      if (sessionStorageAvailable) {
        const storedVersion = storage.getItem(`${prefix}.version`);
        if (storedVersion && storedVersion !== version) {
          Object.keys(storage).forEach((key) => {
            if (key.startsWith(prefix)) {
              storage.removeItem(key);
            }
          });
        }
        storage.setItem(`${prefix}.version`, version);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    teardown: () => {},
    getItem<T = any>(key: string, defaultValue?: T): T {
      const valueFromStorage = storage.getItem(`${prefix}.${key}`);
      return valueFromStorage === null ? defaultValue : tryParse(valueFromStorage);
    },
    setItem<T = any>(key: string, value: T): void {
      if (value === undefined) {
        storage.removeItem(`${prefix}.${key}`);
      } else {
        storage.setItem(`${prefix}.${key}`, JSON.stringify(value));
      }
      publish(key, value);
    },
    removeItem(key: string): void {
      storage.removeItem(`${prefix}.${key}`);
      publish(key, undefined);
    },
    removeItems(keyPrefix: string): void {
      Object.keys(storage).forEach((key) => {
        if (key.startsWith(`${prefix}.${keyPrefix}`)) {
          storage.removeItem(key);
          const publishKey = key.substring(prefixLength + 1);
          publish(publishKey, undefined);
        }
      });
    },
    reset(): void {
      Object.keys(storage).forEach((key) => {
        if (key.startsWith(prefix)) {
          storage.removeItem(key);
          const publishKey = key.substring(prefixLength + 1);
          publish(publishKey, undefined);
        }
      });
    },
    subscribe: (key: string, callback: (value: string) => void) => {
      const id = Math.random().toString();
      subscriptions[id] = {
        key,
        callback,
      };
      return () => {
        delete subscriptions[id];
      };
    },
  };
};

const tryParse = (value: string): any => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const getSessionStorageStore = (version: string = '1', appKey: string = '') => {
  if (sessionStorageAvailable) {
    return sessionStorageStore(version, appKey);
  }

  return memoryStore();
};
