import React, { memo } from 'react';
import { SelectInput } from 'react-admin';
import { sortByName } from 'utils/sortObjectArray';

const choices = sortByName([
  {
    id: 'check',
    name: 'Check',
  },
  {
    id: 'credit',
    name: 'Card',
  },
  {
    id: 'bank_debit',
    name: 'Bank debit',
  },
  {
    id: 'ach',
    name: 'ACH',
  },
  {
    id: 'stripe_ach_credit_transfer',
    name: 'Stripe ACH',
  },
  {
    id: 'stripe_ach_in_app',
    name: 'In-app ACH',
  },
])

const RefundFormType = () => (
  <SelectInput
    label="Please select"
    source="type"
    fullWidth
    placeholder="Select check or card or bank debit"
    choices={choices}
  />
);

export default memo(RefundFormType);
