import React from 'react';
import { gql } from '@apollo/client';
import {
  TopToolbar,
  EditButton,
  Show,
  TextField,
  SimpleShowLayout,
  BooleanField,
} from 'react-admin';
import LinkField from 'components/LinkField';

const AgencyLocationsActions = () => {
  return (
    <TopToolbar>
      <EditButton />
    </TopToolbar>
  );
};

const AgencyLocationsShow = () => {
  return (
    <Show actions={<AgencyLocationsActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <LinkField label="Agency" source="agency_id" />
        <LinkField label="Location" source="location_id" />
        <TextField label="Status" source="status" />
        <BooleanField
          label="Auto Publish Per Diem"
          source="auto_publish_per_diem_to_professionals"
        />
        <BooleanField
          label="Auto Publish Assignments"
          source="auto_publish_assignments_to_professionals"
        />
      </SimpleShowLayout>
    </Show>
  );
};

AgencyLocationsShow.query = gql`
  query GET_ONE($id: Int!) {
    agencyLocation(id: $id) {
      id
      agency_id
      agency {
        id
        name
      }
      location_id
      location {
        id
        name
      }
      status
      auto_publish_per_diem_to_professionals
      auto_publish_assignments_to_professionals
    }
  }
`;

export default AgencyLocationsShow;
