import React from 'react';
import {
  Datagrid,
  TextField,
  BooleanInput,
  NumberField,
  SelectInput,
  TextInput,
} from 'react-admin';
import DateTimeField from 'components/DateTimeField';
import MoneyField from 'components/MoneyField';
import { MarketFilter } from 'components/Filter';
import ImportVoucherIds from '../components/ImportVoucherIds';
import LinkField from 'components/LinkField';
import useCheckAccess from 'hooks/useCheckAccess';
import ListView from '../components/ListView';

export const AdditionalFilters = [
  <SelectInput
    alwaysOn
    label="Days Late"
    source="search.days_late"
    choices={[
      { name: '0-15', id: 0 },
      { name: '16-30', id: 1 },
      { name: '31-45', id: 2 },
      { name: '45+', id: 3 },
    ]}
  />,
  <TextInput label="Voucher ID" alwaysOn source="search.voucher_id" />,
  <BooleanInput alwaysOn label="Failing Payments" source="search.failing_payments" />,
  <BooleanInput alwaysOn label="Failed Payments" source="search.failed_payments" />,
  <MarketFilter />,
];

export const InvoicesDataGrid = () => {
  const checkAccess = useCheckAccess();

  return (
    <Datagrid bulkActionButtons={false}>
      {checkAccess('view', 'Invoice', 'id') && <LinkField label="ID" source="id" />}
      {checkAccess('view', 'Invoice', 'billing_group_id') && (
        <LinkField label="Billing Group ID" source="billing_group_id" typename="BillingGroup" />
      )}
      {checkAccess('view', 'Invoice', 'statement_id') && (
        <LinkField label="Statement ID" source="statement_id" />
      )}
      {checkAccess('view', 'Invoice', 'assignment_id') && (
        <LinkField label="Assignment ID" source="assignment_id" />
      )}
      {checkAccess('view', 'Invoice', 'agency_id') && (
        <LinkField label="Agency ID" source="agency_id" />
      )}
      {checkAccess('view', 'Invoice', 'total_cents') && (
        <MoneyField label="Original Amount" source="total_cents" />
      )}
      {checkAccess('view', 'Invoice', 'amount_due_cents') && (
        <MoneyField label="Current Amount" source="amount_due_cents" />
      )}
      {checkAccess('view', 'Invoice', 'status') && <TextField label="Status" source="status" />}
      {checkAccess('view', 'Invoice', 'failed_count') && (
        <NumberField label="Failed Count" source="failed_count" />
      )}
      {checkAccess('view', 'Invoice', 'failed_reason') && (
        <TextField label="Failed Reason" source="failed_reason" />
      )}
      {checkAccess('view', 'Invoice', 'due_date') && (
        <DateTimeField label="Due Date" source="due_date" format="MM/dd/yyyy" />
      )}
      {checkAccess('view', 'Invoice', 'paid_date') && (
        <DateTimeField
          label="Paid Date"
          source="paid_date"
          format="MM/dd/yyyy"
          timeZone="America/Los_Angeles"
        />
      )}
      {checkAccess('view', 'Invoice', 'created_at') && (
        <DateTimeField
          label="Created At"
          source="created_at"
          format="MM/dd/yyyy"
          timeZone="America/Los_Angeles"
        />
      )}
      {checkAccess('view', 'Invoice', 'days_late') && (
        <NumberField label="Days Late" source="days_late" />
      )}
      {checkAccess('view', 'Invoice', 'voucher_id') && (
        <TextField label="Voucher ID" source="voucher_id" />
      )}
    </Datagrid>
  );
};

const InvoicesList = () => {
  return (
    <ListView
      invoice_type="invoice"
      title="Invoices"
      additionalActions={<ImportVoucherIds />}
      additionalFilters={AdditionalFilters}
    >
      <InvoicesDataGrid />
    </ListView>
  );
};

export default InvoicesList;
