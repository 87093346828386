import { Add } from '@mui/icons-material';
import React from 'react';
import { Button, Link } from 'react-admin';

const CreateInvoiceButton = ({ invoice_type }) => {
  return (
    <Button component={Link} label="Create" to={`/Invoice/${invoice_type}/create`}>
      <Add />
    </Button>
  );
};

export default CreateInvoiceButton;
