import { useEffect } from 'react';

type RollbarAlert = {
  msg: string;
  params?: object;
};
type RollbarAlertFn = () => RollbarAlert | null;
function useRollbarAlert(fn: RollbarAlertFn, triggers: any[]) {
  useEffect(() => {
    let alert = fn();
    if (alert) {
      window.Rollbar?.error(new Error(alert.msg), alert.params ?? {});
    }
  }, triggers);
}

export default useRollbarAlert;
