import React from 'react';
import { ENUMS } from '../Enum/enums';
import AdministratorInput from './AdministratorInput';

const SalesAssociateInput = (props) => <AdministratorInput {...props} />;

SalesAssociateInput.defaultProps = {
  label: 'Sales assoc.',
  source: 'search.sales_associate_id',
  filter: { permission_group_roles: [ENUMS.PermissionGroup.role.sales_associate] },
  perPage: 60,
};

export default SalesAssociateInput;
