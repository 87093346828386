import List from './List';
import Show from './Show';
import CreditMemosCreate from './credit_memo/Create';
import CreditMemosEdit from './credit_memo/Edit';
import CreditMemosList from './credit_memo/List';
import CreditMemosShow from './credit_memo/Show';
import DebitMemosCreate from './debit_memo/Create';
import DebitMemosEdit from './debit_memo/Edit';
import DebitMemosList from './debit_memo/List';
import DebitMemosShow from './debit_memo/Show';
import InvoicesCreate from './invoice/Create';
import InvoicesEdit from './invoice/Edit';
import InvoicesList from './invoice/List';
import InvoicesShow from './invoice/Show';
import { gql } from '@apollo/client';

export default {
  name: 'invoices',
  inputShow: gql`
    query invoices($input: InvoiceQueryInput) {
      invoices(input: $input) {
        id
        billing_group_id
        billing_group {
          name
        }
        statement_id
        assignment_id
        total_cents
        amount_due_cents
        status
        due_date
        paid_date
        days_late
        voucher_id
        invoice_type
        agency_id
        __typename
      }
    }
  `,
  list: List,
  show: Show,
  customSections: {
    invoice: {
      create: InvoicesCreate,
      edit: InvoicesEdit,
      list: InvoicesList,
      show: InvoicesShow,
    },
    credit_memo: {
      create: CreditMemosCreate,
      edit: CreditMemosEdit,
      list: CreditMemosList,
      show: CreditMemosShow,
    },
    debit_memo: {
      create: DebitMemosCreate,
      edit: DebitMemosEdit,
      list: DebitMemosList,
      show: DebitMemosShow,
    },
  },
  options: {
    group: 'Billing',
    label: 'Invoices',
  },
};
