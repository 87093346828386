import React from 'react';
import {
  ReferenceManyField,
  Pagination,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  NumberInput,
  required,
  TextInput,
  DeleteWithConfirmButton,
  SimpleShowLayout,
  useRecordContext,
} from 'react-admin';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import QuickCreateButton from 'components/QuickCreateButton';
import MoneyInput from 'components/MoneyInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import _ from 'lodash';
import IdField from 'components/IdField';
import useCheckAccess from 'hooks/useCheckAccess';
import JobInput from 'components/JobInput';
import DisputeInput from 'components/DisputeInput';
import TimecardInput from 'components/TimecardInput';
import { validateNonZero } from 'utils/custom-validations';

const ExpenseAdjustmentsTab = ({ target }) => {
  const record = useRecordContext();
  const checkAccess = useCheckAccess();
  const isJobContext = target === 'job_id';
  const isTimecardContext = target === 'timecard_id';
  const showPayout = !isJobContext && !isTimecardContext;
  const allowCreate = !isTimecardContext || !!record?.assignment;

  return (
    <SimpleShowLayout>
      {allowCreate && (
        <QuickCreateButton
          initialValues={{
            job_id: record?.job_id,
            payout_id: record?.payout_id,
            [target]: record?.id,
          }}
          resource="ExpenseAdjustment"
        >
          {checkAccess('create', 'ExpenseAdjustment', 'amount_cents') && (
            <MoneyInput
              validate={[required(), validateNonZero]}
              label="Amount"
              source="amount_cents"
            />
          )}
          {checkAccess('create', 'ExpenseAdjustment', 'job_id') && (
            <JobInput disabled={isJobContext} />
          )}
          {checkAccess('create', 'ExpenseAdjustment', 'timecard_id') && isTimecardContext && (
            <TimecardInput disabled={isTimecardContext} />
          )}
          {showPayout && checkAccess('view', 'ExpenseAdjustment', 'payout_id') && (
            <NumberInput label="Payout ID" source="payout_id" />
          )}
          {checkAccess('create', 'ExpenseAdjustment', 'dispute_id') && <DisputeInput />}
          {checkAccess('create', 'ExpenseAdjustment', 'taxable_type') && (
            <EnumSelectInput
              resource="ExpenseAdjustment"
              format={_.identity}
              source="taxable_type"
              validate={[required()]}
            />
          )}
          {checkAccess('create', 'ExpenseAdjustment', 'notes') && (
            <TextInput
              label="Notes"
              source="notes"
              data-testid="job_show-expense_adj_create_notes"
            />
          )}
        </QuickCreateButton>
      )}

      <ReferenceManyField
        pagination={<Pagination />}
        reference="ExpenseAdjustment"
        target={target}
        label=""
      >
        <Datagrid bulkActionButtons={false} data-testid="job_show-expense_adj_table">
          {checkAccess('view', 'ExpenseAdjustment', 'id') && <IdField />}
          {checkAccess('view', 'ExpenseAdjustment', 'amount_cents') && (
            <MoneyField label="Amount Cents" source="amount_cents" />
          )}
          {checkAccess('view', 'Job', 'id') && <LinkField label="Job" source="job.id" />}
          {checkAccess('view', 'Payout', 'id') && <LinkField label="Payout" source="payout.id" />}
          {checkAccess('view', 'Timecard', 'id') && (
            <LinkField label="Timecard" source="timecard.id" />
          )}
          {checkAccess('view', 'ExpenseAdjustment', 'dispute_id') && (
            <LinkField label="Dispute" source="dispute_id" />
          )}
          {checkAccess('view', 'BalanceSheet', 'id') && (
            <LinkField label="Balance sheet" source="balance_sheet.id" />
          )}
          {checkAccess('view', 'ExpenseAdjustment', 'taxable_type') && (
            <TextField
              label="Taxable Type"
              source="taxable_type"
              data-testid="job_show-expense_adj_taxable_type"
            />
          )}
          {checkAccess('view', 'ExpenseAdjustment', 'primary_adjustment') && (
            <BooleanField
              label="Primary Adjustment"
              source="primary_adjustment"
              data-testid="job_show-expense_adj_primary_adjustment"
            />
          )}
          {checkAccess('view', 'ExpenseAdjustment', 'past_month_adjustment') && (
            <BooleanField
              label="Past Month Adjustment"
              source="past_month_adjustment"
              data-testid="job_show-expense_adj_past_month_adjustment"
            />
          )}
          {checkAccess('view', 'ExpenseAdjustment', 'notes') && (
            <TextField label="Notes" source="notes" data-testid="job_show-expense_adj_notes" />
          )}
          {checkAccess('update', 'ExpenseAdjustment') && (
            <EditButton data-testid="job_show-expense_adj_edit_button" />
          )}
          {checkAccess('delete', 'ExpenseAdjustment') && (
            <DeleteWithConfirmButton
              redirect={false}
              data-testid="job_show-expense_adj_delete_button"
            />
          )}
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default ExpenseAdjustmentsTab;
