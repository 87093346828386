import * as React from 'react';
import {
  TextField,
  BooleanField,
  Datagrid,
  ReferenceManyField,
  Pagination,
  EditButton,
  required,
  TextInput,
  DeleteWithConfirmButton,
  SimpleShowLayout,
  useRecordContext,
  maxValue,
  minValue,
  RaRecord,
} from 'react-admin';
import MoneyField from 'components/MoneyField';
import LinkField from 'components/LinkField';
import QuickCreateButton from 'components/QuickCreateButton';
import MoneyInput from 'components/MoneyInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import _ from 'lodash';
import IdField from '../IdField';
import useCheckAccess from 'hooks/useCheckAccess';
import InvoiceInput from 'components/InvoiceInput';
import JobInput from 'components/JobInput';
import DisputeInput from 'components/DisputeInput';
import TimecardInput from 'components/TimecardInput';
import { revenueAdjustmentHelperText } from 'constants/stringConstants';
import RevenueAdjustmentDescriptionInput from 'components/RevenueAdjustmentDescriptionInput';
import { validateNonZero } from 'utils/custom-validations';
import AgencyAdjustmentRouteToPayoutToggle from 'components/AgencyAdjustmentRouteToPayoutToggle';

const showAgencyRoutingToggle = (record: RaRecord, showInvoice: boolean, target: string) => {
  if (!record) {
    return false;
  }
  if (!showInvoice) {
    // We can't attempt to route the adjustment to a payout if it's not related to an invoice.
    return false;
  }
  if (target === 'invoice_id') {
    // The toggle is only relevant to credit/debit memos related to an agency.
    return record.invoice_type === 'credit_memo' || record.invoice_type === 'debit_memo'
      ? !!record?.agency_id
      : false;
  }
  if (target === 'dispute_id') {
    // The toggle is only relevant to disputes related to an agency.
    return !!record.job?.professional?.account.agency_id;
  }
  return false;
};

const RevenueAdjustmentsTab = ({ target }) => {
  const record = useRecordContext();
  const checkAccess = useCheckAccess();

  const isJobContext = target === 'job_id';
  const isTimecardContext = target === 'timecard_id';
  const isInvoiceContext = target === 'invoice_id';

  const showInvoice = !isJobContext && !isTimecardContext;
  const showEditAndDeleteButtons = !record?.locked_at;
  const allowCreate =
    (!isTimecardContext || !!record?.assignment) && !(isInvoiceContext && record?.locked_at);

  let amountCentsValidation = (_value: any, _values: any) => undefined;
  let defaultAmountCents = 0;
  let defaultAdjustmentType = '';
  if (record.invoice_type === 'credit_memo') {
    amountCentsValidation = maxValue(-1, 'Must be a negative amount');
    defaultAdjustmentType = 'rebate';
    defaultAmountCents = -0;
  } else if (record.invoice_type === 'debit_memo') {
    amountCentsValidation = minValue(1, 'Cannot be less than $0.01');
  }

  return (
    <SimpleShowLayout>
      {allowCreate && (
        <QuickCreateButton
          resource="RevenueAdjustment"
          initialValues={{
            job_id: record?.job_id,
            invoice_id: record?.invoice_id,
            [target]: record?.id,
            amount_cents: defaultAmountCents,
            adjustment_type: defaultAdjustmentType,
          }}
          transform={(values) => _.omit(values, ['professional_id'])}
          shouldUnregister={true}
        >
          {checkAccess('create', 'RevenueAdjustment', 'amount_cents') && (
            <MoneyInput
              label="Amount"
              source="amount_cents"
              validate={[required(), validateNonZero, amountCentsValidation]}
            />
          )}
          {checkAccess('create', 'RevenueAdjustment', 'job_id') && (
            <JobInput disabled={isJobContext} />
          )}
          {checkAccess('create', 'RevenueAdjustment', 'timecard_id') && isTimecardContext && (
            <TimecardInput disabled={isTimecardContext} />
          )}
          {checkAccess('create', 'RevenueAdjustment', 'invoice_id') && showInvoice && (
            <InvoiceInput filter={{ search: { hide_locked: true } }} />
          )}
          {checkAccess('create', 'RevenueAdjustment', 'dispute_id') && <DisputeInput />}
          {checkAccess('create', 'RevenueAdjustment', 'adjustment_type') && (
            <EnumSelectInput
              label="Adjustment Type"
              source="adjustment_type"
              resource="RevenueAdjustment"
              format={_.identity}
              validate={[required()]}
            />
          )}
          {showAgencyRoutingToggle(record, showInvoice, target) && (
            <AgencyAdjustmentRouteToPayoutToggle />
          )}
          {checkAccess('create', 'RevenueAdjustment', 'description') && (
            <RevenueAdjustmentDescriptionInput />
          )}
          {checkAccess('create', 'RevenueAdjustment', 'notes') && (
            <TextInput
              label="Notes"
              source="notes"
              data-testid="job_show-revenue_adj_create_notes"
              helperText={revenueAdjustmentHelperText.notes}
            />
          )}
        </QuickCreateButton>
      )}

      <ReferenceManyField
        label=""
        pagination={<Pagination />}
        reference="RevenueAdjustment"
        target={target}
      >
        <Datagrid bulkActionButtons={false} data-testid="job_show-revenue_adj_table">
          {checkAccess('view', 'RevenueAdjustment', 'id') && <IdField />}
          {checkAccess('view', 'RevenueAdjustment', 'amount_cents') && (
            <MoneyField label="Amount" source="amount_cents" />
          )}
          {checkAccess('view', 'RevenueAdjustment', 'job_id') && (
            <LinkField label="Job" source="job.id" />
          )}
          {checkAccess('view', 'RevenueAdjustment', 'adjustment_type') && (
            <TextField
              label="Adjustment Type"
              source="adjustment_type"
              data-testid="job_show-revenue_adj_adjustment_type"
            />
          )}
          {checkAccess('view', 'RevenueAdjustment', 'invoice_id') && (
            <LinkField label="Invoice" source="invoice.id" />
          )}
          {checkAccess('view', 'RevenueAdjustment', 'timecard_id') && (
            <LinkField label="Timecard" source="timecard.id" />
          )}
          {checkAccess('view', 'RevenueAdjustment', 'dispute_id') && (
            <LinkField label="Dispute" source="dispute_id" />
          )}
          {checkAccess('view', 'RevenueAdjustment', 'balance_sheet_id') && (
            <LinkField label="Balance Sheet" source="balance_sheet.id" />
          )}
          {checkAccess('view', 'RevenueAdjustment', 'primary_adjustment') && (
            <BooleanField
              label="Primary Adjustment"
              source="primary_adjustment"
              data-testid="job_show-revenue_adj_primary_adjustment"
            />
          )}
          {checkAccess('view', 'RevenueAdjustment', 'notes') && (
            <TextField label="Notes" source="notes" data-testid="job_show-revenue_adj_notes" />
          )}
          {showEditAndDeleteButtons && checkAccess('update', 'RevenueAdjustment') && (
            <EditButton data-testid="job_show-revenue_adj_edit_button" />
          )}
          {showEditAndDeleteButtons && checkAccess('delete', 'RevenueAdjustment') && (
            <DeleteWithConfirmButton
              redirect={false}
              data-testid="job_show-revenue_adj_delete_button"
            />
          )}
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default RevenueAdjustmentsTab;
