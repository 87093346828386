import React from 'react';
import InvoiceTitle from './InvoiceTitle';
import useUpdateAppLocation from 'hooks/useUpdateAppLocation';
import { Edit, SimpleForm, useEditController, useRecordContext } from 'react-admin';
import CustomSaveWrapper from './CustomSaveWrapper';
import EditToolbar from './EditToolbar';

const formatParentInvoiceLinks = (parent_invoice_links) => {
  return parent_invoice_links?.map((link) => link.parent_invoice_id) ?? [];
};

const EditFormWrapper = ({ children }) => {
  const record = useRecordContext();

  const parentInvoices = formatParentInvoiceLinks(record.parent_invoice_links);

  return (
    <SimpleForm toolbar={<EditToolbar />} defaultValues={{ parent_invoices: parentInvoices }}>
      {children}
    </SimpleForm>
  );
};

const InvoicesEdit = ({ invoice_type, children }) => {
  useUpdateAppLocation(`Invoice_${invoice_type}`, 'Invoice.edit');
  const { save: saveEdits } = useEditController({
    mutationMode: 'pessimistic',
    redirect: 'show',
  });

  return (
    <Edit title={<InvoiceTitle invoice_type={invoice_type} />} data-testid="invoices-edit">
      <CustomSaveWrapper invoice_type={invoice_type} isCreate={false} saveEdits={saveEdits}>
        <EditFormWrapper>{children}</EditFormWrapper>
      </CustomSaveWrapper>
    </Edit>
  );
};

export default InvoicesEdit;
