import { useState } from 'react';
import { DataProvider, useDataProvider, useNotify } from 'react-admin';
import config from '../config';

export default function useImpersonate() {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const dataProvider: DataProvider = useDataProvider();

  const _impersonate = (account, baseUrl: string) => {
    if (loading) {
      return;
    }

    const { account_type, id, first_name, last_name } = account;
    setLoading(true);
    notify(`Signing in as ${account_type} ${first_name} ${last_name} (Account ID: ${id})`, {
      type: 'info',
    });

    getImpersonationToken(account.id)
      .then((token) => {
        const url = `${baseUrl}?admin_referrer=${window.location.pathname}&impersonateToken=${token}`;
        console.log(url);
        window.open(url, '_blank');
      })
      .catch((err) => {
        setError(err);
        notify(`Failed impersonating`, { type: 'warning' });
      })
      .finally(() => setLoading(false));
  };

  const getImpersonationToken = (accountId) => {
    return dataProvider.impersonate(accountId).then((json) => {
      return json.data.token;
    });
  };

  const getImpersonateUrl = (forLocal: boolean, account_type: string, token: string) => {
    const baseUrl = forLocal
      ? config.client_url_local[account_type]
      : config.client_url[account_type];
    return `${baseUrl}?admin_referrer=${window.location.pathname}&impersonateToken=${token}`;
  };

  const impersonate = (account) => {
    _impersonate(account, config.client_url[account.account_type]);
  };

  const impersonateLocally = (account) => {
    _impersonate(account, config.client_url_local[account.account_type]);
  };

  const copyImpersonateUrl = (account) => {
    if (loading) {
      return;
    }

    const { id, account_type, first_name, last_name } = account;
    setLoading(true);

    return getImpersonationToken(account.id)
      .then((token) => {
        const url = getImpersonateUrl(false, account.account_type, token);
        navigator.clipboard.writeText(url);

        notify(
          `Copied impersonate url for ${account_type} ${first_name} ${last_name} (Account ID: ${id})`,
          {
            type: 'info',
          },
        );
      })
      .catch((err) => {
        setError(err);
        notify(`Failed to copy impersonation url`, { type: 'warning' });
      })
      .finally(() => setLoading(false));
  };

  return { impersonate, impersonateLocally, copyImpersonateUrl, loading, error };
}
