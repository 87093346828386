import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';
import AccountPermissionGroupsInput from './AccountPermissionGroupInput';
import CompanyInput from 'components/CompanyInput';

const AccountsCreate = () => {
  const transform = (data) => {
    const alteredData = { ...data, location_ids: data.direct_location_ids };
    delete alteredData.direct_location_ids;
    return alteredData;
  };

  return (
    <Create redirect="show" transform={transform}>
      <SimpleForm>
        <TextInput
          source="email"
          validate={[required()]}
          name="email"
          data-testid="create_account-email_field"
        />
        <CompanyInput />
        <AccountPermissionGroupsInput />
      </SimpleForm>
    </Create>
  );
};

export default AccountsCreate;
