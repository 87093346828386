import React, { useRef, useState, useEffect } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Avatar,
  CardActions,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { Image } from '@mui/icons-material';
import { assocPath } from 'ramda';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import { getObjectPath } from 'utils/object';
import ImageField from './ImageField';
import { PdfPreview } from '@medely/web-components';

const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(String(reader.result));
    reader.onerror = reject;
  });

const getImageSource = (source?: string, dataType?: string) => {
  if (source?.startsWith('https:')) {
    return source;
  }
  if (!dataType || source?.startsWith('data:')) {
    return source;
  }
  return `${dataType},${source}`;
};

const isOfType =
  ({ sources, extensions }) =>
  (source) => {
    const lowecasedSource = (source || '').toLowerCase();
    return (
      sources.some((s) => lowecasedSource.includes(s)) ||
      extensions.some((e) => lowecasedSource.includes(e))
    );
  };

const isPdfFile = (source) =>
  source.includes('application/pdf') || source.toLowerCase().includes('.pdf');

const isCsvFile = isOfType({ sources: ['text/csv'], extensions: ['.csv'] });

const isXlsFile = isOfType({
  sources: [
    'text/xls',
    'text/xlsx',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  extensions: ['.xls', '.xlsx'],
});

const isSpreadsheetFile = (source) => isCsvFile(source) || isXlsFile(source);

const getDataType = (value) => {
  let extension = null;
  const [header, tail] = value.split(';');
  const data = tail?.replace(/^.+,/, '');

  if (isPdfFile(value)) {
    extension = 'pdf';
  } else if (isCsvFile(value)) {
    extension = 'csv';
  } else if (isXlsFile(value)) {
    extension = 'xlsx';
  }

  return { data, type: `${header};base64`, extension };
};

const ImageInput = ({
  source,
  defaultValue,
  label,
  aspectRatio,
  extensionSource,
  destroySource,
  accept = 'image/*, .pdf',
}: any) => {
  const { setValue, watch } = useFormContext();
  const formValues = watch();
  const [imageSource, setImageSource] = useState(
    getObjectPath(formValues, source.split('.'), defaultValue),
  );
  const [dataType, setDataType] = useState<string | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState(null);
  const cropperRef = useRef<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const pickerImgSrc = getImageSource(imageSource, dataType);
  const isPdf = isPdfFile(pickerImgSrc || '');
  const isSpreadsheet = isSpreadsheetFile(pickerImgSrc || '');
  const imageRecord = assocPath(source.split('.'), pickerImgSrc, formValues ?? {});

  const handleEdit = () => setOpen(true);
  const handleFileSelect = async (event) => {
    const file = event.target?.files[0];
    if (!file) {
      return;
    }
    const base64 = await toBase64(file);
    setValue(source, base64, { shouldDirty: true });
    setValue(destroySource, undefined);
    setFileName(file.name);

    if (isSpreadsheetFile(base64)) {
      handleSave(base64);
    } else {
      handleEdit();
    }

    setImageSource(base64);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleRemove = () => {
    setValue(source, null, { shouldDirty: true });
    if (extensionSource) {
      setValue(extensionSource, null);
    }
    if (destroySource) {
      setValue(destroySource, true);
    }
    setImageSource(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleRotate = () => cropperRef.current?.cropper.rotate(-90);

  const handleSave = (newValue = undefined) => {
    const value = newValue || pickerImgSrc || '';
    const { data, extension, type } = getDataType(value);

    if (extension) {
      setValue(source, data, { shouldDirty: true });
      setDataType(type);
      if (extensionSource) {
        setValue(extensionSource, extension);
      }
      setOpen(false);
    } else if (cropperRef.current) {
      const canvas = cropperRef.current.cropper.getCroppedCanvas() as HTMLCanvasElement;
      const imageSrc = canvas.toDataURL(`image/png`, 0.8);
      const [dataType, base64] = imageSrc.split(',');
      setImageSource(base64);
      setValue(source, base64, { shouldDirty: true });
      setDataType(dataType);
      if (extensionSource) {
        setValue(extensionSource, 'png');
      }
      setOpen(false);
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Card elevation={0} style={{ border: '1px solid #ddd' }}>
          <CardHeader
            title={label}
            avatar={
              <Avatar>
                <Image />
              </Avatar>
            }
          />
          {isPdf ? (
            <PdfPreview src={pickerImgSrc} />
          ) : isSpreadsheet ? (
            <Box px={1.5}>{fileName}</Box>
          ) : (
            <ImageField record={imageRecord} source={source} extensionSource={extensionSource} />
          )}
          <CardActions>
            {imageSource && !isPdf && !isSpreadsheet && (
              <Button onClick={handleEdit} size="small">
                Edit
              </Button>
            )}
            <Button
              onClick={() => {
                fileInputRef.current?.click();
              }}
              size="small"
            >
              {imageSource ? 'Replace' : 'Upload'}
            </Button>
            {imageSource && (
              <Button onClick={handleRemove} size="small">
                Remove
              </Button>
            )}
          </CardActions>
        </Card>
        <input hidden accept={accept} type="file" ref={fileInputRef} onChange={handleFileSelect} />
      </Grid>
      <Dialog maxWidth="md" fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          {isPdf ? (
            <PdfPreview src={pickerImgSrc} />
          ) : (
            <Cropper
              aspectRatio={aspectRatio}
              ref={cropperRef}
              src={pickerImgSrc}
              checkCrossOrigin={true}
              checkOrientation={false}
              style={{ maxHeight: '80vh', maxWidth: '100%' }}
              guides={false}
            />
          )}
        </DialogContent>
        <DialogActions>
          {!isPdf && (
            <IconButton color="primary" onClick={handleRotate} size="large">
              <Rotate90DegreesCcwIcon />
            </IconButton>
          )}
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={() => handleSave()}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImageInput;
