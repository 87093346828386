import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import useDialog from 'hooks/useDialog';
import { ArchivedStatus } from '../ApplicationStatusControl';
import { ProfessionalArchiveReasonEnum } from 'typings/enums';
interface ArchiveItemProps {
  updateApplicationStatus: (val: any) => void;
}

const ArchiveItem = ({ updateApplicationStatus }: ArchiveItemProps) => {
  const { open, closeDialog, openDialog } = useDialog();

  return (
    <>
      <MenuItem onClick={openDialog}>Archive</MenuItem>
      <ArchiveDialog
        open={open}
        closeDialog={closeDialog}
        updateApplicationStatus={updateApplicationStatus}
      />
    </>
  );
};

const ArchiveDialog = ({ open, closeDialog, updateApplicationStatus }) => {
  const [archiveReason, setArchiveReason] = useState('');
  const [phoneInterviewed, setPhoneInterviewed] = useState(false);
  const submitUpdateApplicationStatus = () => {
    updateApplicationStatus({
      application_status: ArchivedStatus,
      archive_reason: archiveReason,
      phone_interviewed: phoneInterviewed,
    });
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>Archive Reason</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          value={archiveReason}
          label="Select Archive Reason"
          onChange={({ target }) => setArchiveReason(target.value as string)}
          required
          style={{ width: '550px', marginBottom: '20px' }}
        >
          {Object.keys(ProfessionalArchiveReasonEnum).map((reason) => (
            <MenuItem key={reason} value={ProfessionalArchiveReasonEnum[reason]}>
              {reason}
            </MenuItem>
          ))}
        </Select>
        <FormGroup>
          <FormControlLabel
            label="Performed Verbal Screen"
            control={
              <Checkbox
                checked={phoneInterviewed}
                onChange={(event) => setPhoneInterviewed(event.target.checked)}
              />
            }
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button
          disabled={!archiveReason}
          onClick={submitUpdateApplicationStatus}
          color="primary"
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveItem;
