import React from 'react';
import ShowView from '../components/ShowView';
import CreditMemoSummaryTab from '../tabs/CreditMemoSummaryTab';

const CreditMemosShow = () => {
  return (
    <ShowView
      invoice_type="credit_memo"
      showDisputes={false}
      showChildMemos={false}
      summaryTab={<CreditMemoSummaryTab />}
    />
  );
};

export default CreditMemosShow;
