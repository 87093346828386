import React from 'react';
import { Edit, SimpleForm, NumberInput, TextInput, required } from 'react-admin';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import MoneyInput from 'components/MoneyInput';
import JobInput from 'components/JobInput';
import DisputeInput from 'components/DisputeInput';
import { validateNonZero } from 'utils/custom-validations';

const ExpenseAdjustmentsEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm>
      <MoneyInput source="amount_cents" validate={[required(), validateNonZero]} />
      <JobInput />
      <NumberInput source="payout_id" />
      <DisputeInput />
      <EnumSelectInput source="taxable_type" />
      <TextInput source="notes" />
    </SimpleForm>
  </Edit>
);

export default ExpenseAdjustmentsEdit;
