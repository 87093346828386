import { gql } from '@apollo/client';
import list from './List';
import show from './Show';
import edit from './Edit';
import create from './Create';

export default {
  name: 'AgencyQualificationAttributes',
  inputShow: gql`
    query AgencyQualificationAttributes($input: AgencyQualificationAttributeQueryInput) {
      agencyQualificationAttributes(input: $input) {
        id
        type
        value
        qualification_attribute_id
        agency_qualification_id
        __typename
      }
    }
  `,
  list,
  show,
  edit,
  create,
  options: {
    group: 'Qualifications',
    label: 'Agency Qualification Attributes',
  },
};
