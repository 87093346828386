import {
  Datagrid,
  TextField,
  SimpleForm,
  BooleanInput,
  required,
  DeleteWithConfirmButton,
  BooleanField,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import IdField from 'components/IdField';
import List from 'components/List';
import React from 'react';
import { gql } from '@apollo/client';
import { CreateInDialogButton } from '@react-admin/ra-form-layout';
import PositionInput from 'components/PositionInput';
import SpecialtyInput from 'components/SpecialtyInput';
import EnumSelectInput from 'components/Enum/EnumSelectInput';
import VmsTypeInput from 'components/VmsTypeInput';
import StateInput from 'components/StateInput';
import LinkField from 'components/LinkField';
import LocationInput from 'components/LocationInput';
import CompanyInput from 'components/CompanyInput';
import ConditionInput from 'components/ConditionInput';
import { useFormContext } from 'react-hook-form';
import { Box, Divider, Typography } from '@medely/web-components';
import isNil from 'lodash/isNil';

const transformPayload = (payload) => {
  if (payload.position_id !== undefined && payload.specialty_id !== undefined) {
    delete payload.position_id;
  }
  return payload;
};

enum RequirementFilterFields {
  job_type = 'job_type',
  kind = 'kind',
  position_id = 'position_id',
}

const CreateRequirementFormContent = ({ children }) => {
  const { watch } = useFormContext();
  const values = watch();

  const selectedRequirementFormField = Object.values(RequirementFilterFields).find(
    (field) => values && !isNil(values[field]),
  );

  const isFieldDisabled = (fieldName) =>
    selectedRequirementFormField && fieldName !== selectedRequirementFormField;

  return (
    <>
      <EnumSelectInput label="Requirement Type" source="cohort_eligibility" fullWidth />
      <ConditionInput fullWidth />

      {children}

      <Divider />
      <Box
        width="100%"
        pt={2}
        mb={1}
        sx={(theme) => ({
          borderTop: `1px solid ${theme.palette.grey[200]}`,
          borderBottom: `1px solid ${theme.palette.grey[200]}`,
        })}
      >
        <Typography variant="body2">Select only one of the following:</Typography>

        <EnumSelectInput
          resettable
          source="job_type"
          fullWidth
          disabled={isFieldDisabled(RequirementFilterFields.job_type)}
        />
        <EnumSelectInput
          resettable
          source="kind"
          fullWidth
          disabled={isFieldDisabled(RequirementFilterFields.kind)}
        />
        <PositionInput
          fullWidth
          disabled={
            isFieldDisabled(RequirementFilterFields.position_id) || !isNil(values['specialty_id'])
          }
          resettable={isNil(values['specialty_id'])}
        />
        <Box ml={3}>
          <SpecialtyInput positionSource="position_id" fullWidth />
        </Box>
      </Box>
      <Divider />

      <BooleanInput source="add_to_booked_jobs" defaultValue={false} fullWidth />
    </>
  );
};

const RequirementsList = () => {
  const location = useLocation();
  const pathname = location.pathname;
  let isMedelyRequirements;
  let isCompanyRequirements;
  let isLocationRequirements;
  let isVMSRequirements;
  let isStateRequirements;
  let sourceType = '';
  const RequirementFilters = [
    <EnumSelectInput alwaysOn resettable source="kind" />,
    <EnumSelectInput alwaysOn resettable source="job_type" />,
    <PositionInput alwaysOn label="Positions (Any)" source="search.position_id" />,
    <SpecialtyInput alwaysOn source="specialty_id" />,
  ];
  if (pathname.includes('Requirement/Medely')) {
    isMedelyRequirements = true;
    sourceType += 'Medely ';
  } else if (pathname.includes('Requirement/Company')) {
    isCompanyRequirements = true;
    sourceType += 'Company ';
    RequirementFilters.unshift(<CompanyInput alwaysOn />);
  } else if (pathname.includes('Requirement/Location')) {
    isLocationRequirements = true;
    sourceType += 'Location ';
    RequirementFilters.unshift(<LocationInput alwaysOn />);
  } else if (pathname.includes('Requirement/VMS')) {
    isVMSRequirements = true;
    sourceType += 'VMS ';
    RequirementFilters.unshift(<VmsTypeInput alwaysOn />);
  } else if (pathname.includes('Requirement/State')) {
    isStateRequirements = true;
    sourceType += 'State ';
    RequirementFilters.unshift(<StateInput alwaysOn />);
  } else {
    sourceType = 'All';
  }
  RequirementFilters.unshift(<ConditionInput alwaysOn resettable />);

  return (
    <List
      title={`${sourceType}Requirements`}
      exporter={false}
      filters={RequirementFilters}
      actions={
        <CreateInDialogButton fullWidth transform={transformPayload}>
          <SimpleForm>
            <CreateRequirementFormContent>
              {isMedelyRequirements && <BooleanInput defaultValue={true} source="medely" />}
              {isStateRequirements && <StateInput validate={[required()]} fullWidth />}
              {isLocationRequirements && <LocationInput validate={[required()]} fullWidth />}
              {isCompanyRequirements && <CompanyInput validate={[required()]} fullWidth />}
              {isVMSRequirements && (
                <VmsTypeInput validate={[required()]} source="vms_type_id" fullWidth />
              )}
            </CreateRequirementFormContent>
          </SimpleForm>
        </CreateInDialogButton>
      }
    >
      <Datagrid bulkActionButtons={false}>
        <IdField />
        <TextField label="Condition Name" source="condition.label" />
        {isStateRequirements && <TextField source="state.abbreviation" />}
        {isLocationRequirements && <LinkField source="location_id" />}
        {isCompanyRequirements && (
          <LinkField label="Company" source="company_id" resource="Company" />
        )}
        {isVMSRequirements && <TextField label="VMS Type" source="vms_type.name" />}
        <TextField source="job_type" sortable={false} />
        <TextField source="kind" />
        <TextField label="Position" source="position.display_name" />
        <TextField label="Specialty" source="specialty.label" />
        <BooleanField source="required" />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};

RequirementsList.query = gql`
  query GET_LIST($input: RequirementQueryInput) {
    requirements(input: $input) {
      id
      kind
      position {
        id
        display_name
      }
      specialty {
        id
        label
      }
      medely
      company_id
      vms_type_id
      vms_type {
        id
        name
      }
      state {
        id
        abbreviation
      }
      location_id
      condition {
        id
        label
      }
      required
      created_at
      job_type
    }
    _requirementsMeta(input: $input) {
      count
    }
  }
`;

export default RequirementsList;
