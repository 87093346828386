import React from 'react';
import {
  TextField,
  Datagrid,
  SimpleShowLayout,
  Pagination,
  ReferenceManyField,
  useRecordContext,
  TextInput,
  FunctionField,
  required,
} from 'react-admin';
import LinkField from 'components/LinkField';
import QuickCreateButton from 'components/QuickCreateButton';
import useCheckAccess from 'hooks/useCheckAccess';
import AccountsArrayInput from 'resources/approval_groups/AccountsArrayInput';

const ApprovalGroupsTab = () => {
  const checkAccess = useCheckAccess();
  const record = useRecordContext();
  const company_id = record?.id;

  return (
    <SimpleShowLayout>
      <QuickCreateButton
        resource="ApprovalGroup"
        url={`v3/admin/companies/${record?.id}/approval_groups`}
      >
        {checkAccess('create', 'ApprovalGroup', 'name') && (
          <TextInput label="Name" source="name" validate={[required()]} />
        )}

        {checkAccess('create', 'ApprovalGroup', 'account_ids') && (
          <AccountsArrayInput company_id={company_id} />
        )}
      </QuickCreateButton>
      <ReferenceManyField
        pagination={<Pagination />}
        label=""
        reference="ApprovalGroup"
        target="company_id"
      >
        <Datagrid bulkActionButtons={false}>
          <LinkField label="ID" source="id" />
          <TextField label="Name" source="name" />
          <FunctionField
            label="Status"
            render={(record) => (record.deleted_at ? 'Inactive' : 'Active')}
          />
          <FunctionField label="Accounts" render={(r) => r.account_ids?.length} />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  );
};

export default React.memo(ApprovalGroupsTab);
