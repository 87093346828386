import jwtDecode from 'jwt-decode';
import Axios from 'axios';
import config from 'config';
import { useEffect, useState } from 'react';
import { auth } from 'providers/utils';

const getTokenExpMs = (token: string) => (token ? jwtDecode<{ exp: number }>(token).exp * 1000 : 0);

const TOKEN_STORAGE_EVENT = 'TOKEN_STORAGE_EVENT';

export const useTokenStorage = () => {
  const [token, setToken] = useState<string>();

  const getToken = async function () {
    return auth.currentUser?.getIdToken();
  };

  const setTokenInStorage = (token: string) => {
    if (token !== null) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }

    setToken(token);
    window.dispatchEvent(new Event(TOKEN_STORAGE_EVENT));
  };

  const getExpirationStrategy = (intervalMs: number) => {
    const isExpiring = () => getTokenExpMs(localStorage.getItem('token')) <= Date.now() + intervalMs;
    let intervalId;
    const run = () => {
      intervalId = setInterval(() => {
        if (isExpiring()) {
          refreshToken();
        }
      }, intervalMs);
    };
    return {
      run,
      clear: () => clearInterval(intervalId),
    };
  };

  const getDecodedToken = () => (token ? jwtDecode<{ administrator: any }>(token) : null);

  const getAdminFromToken = () => {
    const tokenData = getDecodedToken();
    const { id, role, email } = tokenData?.administrator ?? {};
    return { id, role, email };
  };

  const refreshToken = () => {
    return Axios({
      url: `${config.rails_url}/auth_token`,
      method: 'POST',
    }).then((res) => {
      if (res.headers.authorization) {
        setTokenInStorage(res.headers.authorization);
      }
    });
  };

  useEffect(() => {
    const handleUpdateToken = () => {
      (async () => {
        const token = await getToken();
        setTokenInStorage(token);
      })();
    };

    window.addEventListener(TOKEN_STORAGE_EVENT, handleUpdateToken);
    return () => window.removeEventListener(TOKEN_STORAGE_EVENT, handleUpdateToken);
  }, []);

  useEffect(() => {
    (async () => {
      const token = await getToken();
      setToken(token);
    })();
  }, []);

  return {
    clearToken: () => setTokenInStorage(null),
    getAdminFromToken,
    getExpirationStrategy,
    getDecodedToken,
    getToken,
    hasToken: !!token,
  };
};
